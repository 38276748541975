import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  resetOrderDeclinedActions,
  orderDeclinedActions,
} from "../../../../actions/ordersActions";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";
import { useCreateTakeOrderMutation } from "../../../../services/galeanaBizApi";

const BtnAdmin = ({ role, orderDetail, isOpen, setIsOpen, transactionId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [takeOrder, takeOrderResult] = useCreateTakeOrderMutation();

  const path = "/driver";

  const [Msj, setMsj] = useState("");

  const { Drivers } = useSelector((state) => state.DriversAvailable);

  const {
    loading: declinedLoading,
    error: declinedError,
    success: declinedSuccess,
  } = useSelector((state) => state.DeclinedOrder);

  useEffect(() => {
    if (takeOrderResult.isSuccess) {
      navigate("/driver");
    }
  }, [takeOrderResult.isSuccess, navigate, dispatch]);

  useEffect(() => {
    if (declinedSuccess) {
      navigate(path, { replace: true });
      dispatch(resetOrderDeclinedActions());
    }
  }, [dispatch, navigate, declinedSuccess]);

  const AdminAssigningOrder = (driverId, transactionId) => {
    takeOrder({ driverId, transactionId }).then((response) => {
      if ("error" in response) {
        return;
      }
    });
  };

  const handleCancelledAdmin = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(orderDeclinedActions(transactionId, Msj));
    setIsOpen(false);
  };

  if (!orderDetail) return null;

  if (orderDetail?.status === "shipped") return null;

  if (role !== "Admin") return null;

  if (declinedLoading || takeOrderResult.isLoading) {
    return <Loader />;
  }

  if (declinedError || takeOrderResult.isError) {
    return (
      <Message error={declinedError || takeOrderResult.isError}>
        Try again another time.
      </Message>
    );
  }

  return (
    <div>
      <div className="adminBtns">
        <button className="orderFailedBtn" onClick={handleCancelledAdmin}>
          Order canceled
        </button>

        <div className="assignButton">
          <select
            onChange={(e) =>
              AdminAssigningOrder(e.target.value, orderDetail?.transaction_id)
            }
          >
            <option value="">Assign Driver</option>
            {Object.values(Drivers || []).map((driver, index) => (
              <option key={index} value={driver?.id}>
                {driver?.username}
              </option>
            ))}
          </select>
        </div>

        <ModalCancel
          isOpen={isOpen}
          onSubmit={onSubmit}
          Msj={Msj}
          setMsj={setMsj}
          handleCancelledAdmin={handleCancelledAdmin}
        />
      </div>
    </div>
  );
};

const ModalCancel = ({
  isOpen,
  onSubmit,
  Msj,
  setMsj,
  handleCancelledAdmin,
}) => {
  return (
    <>
      {isOpen && (
        <div className="logoutModal">
          <div className="background"></div>

          <form onSubmit={onSubmit}>
            <div className="title">
              Why is it not possible to deliver the order?
            </div>

            <div className="textareaContainer">
              <textarea
                type="text"
                value={Msj}
                onChange={(e) => setMsj(e.target.value)}
                placeholder="Enter the reason why the order is canceled..."
                required
              />
            </div>

            <div className="buttonsContainer">
              <button type="button" onClick={handleCancelledAdmin}>
                Cancel
              </button>
              <button className="orderFailedBtn" type="submit">
                Confirm Canceled
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default BtnAdmin;
