import React from "react";

// Components
import LargeSidebar from "./LargeSidebar";
import SmallSidebar from "./SmallSidebar";

// SCSS
import "../../../styles/components/navigation/FooterAndSidebars/FooterMediaQueries.scss";

function BothSidebars({ currentPageUrl }) {
  return (
    <>
      <div className="smallSidebarContainer">
        <SmallSidebar currentPageUrl={currentPageUrl} />
      </div>
      <div className="largeSidebarContainer">
        <LargeSidebar currentPageUrl={currentPageUrl} />
      </div>
    </>
  );
}

export default BothSidebars;
