import React from "react";
import { Link } from "react-router-dom";

import "../../styles/components/authentication/NoAccountBanner.scss";

function NoAccountBanner() {
  return (
    <div className="noAccountContainer">
      <div className="noAccountContent">
        <div className="title">Your not Signed In...</div>
        <div className="loginLinkContainer">
          <Link to="/login" className="loginLink">
            Click Here to Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NoAccountBanner;
