import React, { useState, useEffect } from "react";
import AddToAllPages from "../../../components/AddToAllPages";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AdressUserAction,
  UpdateAdressUserAction,
} from "../../../actions/adress/adressUserActions";
import { ADRESS_USER_PUT_RESET } from "../../../constants/adressConstans";
import { Link } from "react-router-dom";

import "../../../styles/pages/adress/usersadress/UserPostAdress.scss";
import PhoneInput from "./components/PhoneInput";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";

const UserPutAdress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = "/profile/address";

  const [userAddress, setUserAddress] = useState({});

  const [valueAddress, setValueAddress] = useState({
    firstName: "",
    lastName: "",
    neighborhood: "",
    exteriorNumber: null,
    interiorNumber: null,
    street_1: null,
    street_2: null,
    phone: "",
    reference: null,
  });

  const adressMyCompany = useSelector((state) => state.AdressUserGet);
  const { loading: getLoading, error: getError, AdressUser } = adressMyCompany;
  const updateAdressUser = useSelector((state) => state.AdressUserPut);
  const {
    loading: updateLoading,
    error: updateError,
    success: updateSuccess,
  } = updateAdressUser;

  useEffect(() => {
    if (!AdressUser || getLoading) {
      return;
    }
    setUserAddress(AdressUser);
  }, [AdressUser]);

  const onSubmit = (e) => {
    e.preventDefault();

    const adress = {
      adress_id: userAddress?.id,
      first_name: valueAddress?.firstName,
      last_name: valueAddress?.lastName,
      neighborhood: valueAddress?.neighborhood,
      exterior_number: valueAddress?.exteriorNumber,
      interior_number: valueAddress?.interiorNumber,
      street_1: valueAddress?.street_1,
      street_2: valueAddress?.street_2,
      telephone_number: valueAddress?.phone,
      additional_instructions: valueAddress?.reference,
    };

    dispatch(UpdateAdressUserAction(adress));
  };

  useEffect(() => {
    dispatch(AdressUserAction());
  }, [dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch({ type: ADRESS_USER_PUT_RESET });

      if (path) return navigate(path, { replace: true });
    } else {
      if (userAddress) {
        setValueAddress({
          firstName: userAddress.first_name,
          lastName: userAddress.last_name,
          neighborhood: userAddress.neighborhood,
          exteriorNumber: userAddress.exterior_number,
          interiorNumber: userAddress.interior_number,
          street_1: userAddress.street_1,
          street_2: userAddress.street_2,
          reference: userAddress.additional_instructions,
        });
      }
    }
  }, [dispatch, navigate, userAddress, updateSuccess]);

  return (
    <AddToAllPages>
      <FormPutUserAddress
        onSubmit={onSubmit}
        valueAddress={valueAddress}
        setValueAddress={setValueAddress}
        phone={userAddress?.telephone_number}
        loading={updateLoading || getLoading}
        error={updateError || getError}
      />
    </AddToAllPages>
  );
};

export default UserPutAdress;

const FormPutUserAddress = ({
  onSubmit,
  valueAddress,
  setValueAddress,
  phone,
  loading,
  error,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;

    setValueAddress((prev) => ({ ...prev, [name]: value }));
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message error={error}>{error}</Message>;
  }

  return (
    <form className="userPostAddressPage" onSubmit={onSubmit}>
      <div className="pageTitle">Update Address</div>

      <div className="inputContainer">
        <div className="title">First Name</div>
        <input
          type="text"
          name="firstName"
          value={valueAddress?.firstName}
          onChange={(e) => onChange(e)}
          required
        />
      </div>

      <div className="inputContainer">
        <div className="title">Last Name</div>
        <input
          type="text"
          name="lastName"
          value={valueAddress?.lastName}
          onChange={(e) => onChange(e)}
          required
        />
      </div>

      <div className="inputContainer">
        <div className="title">Location</div>
        <select
          type="text"
          name="neighborhood"
          value={valueAddress?.neighborhood}
          onChange={(e) => onChange(e)}
          required
        >
          <option value="" defaultChecked>
            Select Location
          </option>
          <option value="Galeana">Galeana</option>
          <option value="Lagunitas">Lagunitas</option>
          <option value="Lebaron">Colonia Lebaron</option>
          <option value="Angostura">Angostura</option>
          <option value="San Jeronimo">San Jeronimo</option>
        </select>
      </div>

      <div className="inputContainer">
        <div className="title">
          Exterior Number <span className="optionalTxt"></span>
        </div>
        <input
          type="text"
          name="exteriorNumber"
          placeholder="Exterior Number"
          value={valueAddress?.exteriorNumber}
          onChange={(e) => onChange(e)}
        />
      </div>

      <div className="inputContainer">
        <div className="title">
          Interior Number <span className="optionalTxt"></span>
        </div>
        <input
          type="text"
          name="interiorNumber"
          placeholder="Interior Number"
          value={valueAddress?.interiorNumber}
          onChange={(e) => onChange(e)}
        />
      </div>

      <div className="inputContainer">
        <div className="title">Street 1</div>
        <input
          type="text"
          name="street_1"
          value={valueAddress?.street_1}
          onChange={(e) => onChange(e)}
          required
        />
      </div>

      <div className="inputContainer">
        <div className="title">
          Street 2 <span className="optionalTxt"></span>
        </div>
        <input
          type="text"
          name="street_2"
          value={valueAddress?.street_2}
          onChange={(e) => onChange(e)}
        />
      </div>

      <PhoneInput setValueAddress={setValueAddress} phone={phone} />

      <div className="inputContainer">
        <div className="title">Reference</div>
        <textarea
          type="text"
          name="reference"
          placeholder="Please add a descriptive refrence"
          value={valueAddress?.reference}
          onChange={(e) => onChange(e)}
        />
      </div>

      <div className="saveBtnContainer">
        <Link to={"/profile/address"}>
          <button className="cancelBtn">Cancel</button>
        </Link>

        <button type="submit">Save</button>
      </div>
    </form>
  );
};
