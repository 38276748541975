import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAdressCompanyAction,
  AdressCompanyAction,
} from "../../../actions/adress/adressCompanyActions";
import { useNavigate } from "react-router-dom";
import NavigationComponents from "../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

import "../../../styles/pages/adress/companyadress/CompanyPostAdress.scss";
import { ADRESS_COMPANY_ADD_RESET } from "../../../constants/adressConstans";
import { getUserCompany } from "../../../actions/userAction";

const CompanyPostAdress = () => {
  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = "/mybusiness/customization";

  const addAdressCompnay = useSelector((state) => state.AdressCompnayPost);
  const { loading, error, success } = addAdressCompnay;

  const [country, setCountry] = useState("Mexico");
  const [state, setState] = useState("Chihuahua");
  const [municipality, setMunicipality] = useState("Galeana");
  const [neighborhood, setNeighborhood] = useState("");
  const [exterior_number, setExteriorNumber] = useState("");
  const [interior_number, setInteriorNumber] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [street_1, setStreet_1] = useState("");
  const [street_2, setStreet_2] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+52");
  const [telephone_number, setTelephoneNumber] = useState("");
  const [additional_instructions, setAdditionalInstructions] = useState("");

  const combinedPhoneNumber = selectedCountryCode + telephone_number;

  useEffect(() => {
    if (success) {
      dispatch(getUserCompany());
      dispatch({ type: ADRESS_COMPANY_ADD_RESET });
      navigate(path);
    }
  }, [success]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      AddAdressCompanyAction(
        userCompanyId,
        postal_code,
        country,
        state,
        municipality,
        neighborhood,
        exterior_number,
        interior_number,
        street_1,
        street_2,
        combinedPhoneNumber,
        additional_instructions
      )
    );
  };

  return (
    <NavigationComponents>
      <form className="formPostAdressCompany" onSubmit={(e) => onSubmit(e)}>
        <div className="inputContainer">
          <label>Location</label>
          <select
            type="text"
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
            onFocus={(e) => (e.target.options[0].disabled = true)}
            required
          >
            <option>Select Location</option>
            <option value="Galeana">Galeana</option>
            <option value="Lagunitas">Lagunitas</option>
            <option value="Lebaron">Colonia Lebaron</option>
            <option value="Angostura">Angostura</option>
            <option value="San Jeronimo">San Jeronimo</option>
          </select>
        </div>
        <div className="inputContainer">
          <label>Exterior Number</label>
          <input
            type="text"
            placeholder="Number or S/N"
            value={exterior_number}
            onChange={(e) => setExteriorNumber(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label>Interior Number</label>
          <input
            placeholder="Number or S/N"
            type="text"
            value={interior_number}
            onChange={(e) => setInteriorNumber(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label>Postal Code</label>
          <select
            type="text"
            value={postal_code}
            onChange={(e) => setPostalCode(e.target.value)}
            onFocus={(e) => (e.target.options[0].disabled = true)}
            required
          >
            <option>select postal code</option>
            <option value="31870">31870</option>
          </select>
        </div>
        <div className="inputContainer">
          <label>Street 1</label>
          <input
            type="text"
            value={street_1}
            onChange={(e) => setStreet_1(e.target.value)}
            required
          />
        </div>
        <div className="inputContainer">
          <label>Street 2</label>
          <input
            type="text"
            value={street_2}
            onChange={(e) => setStreet_2(e.target.value)}
            required
          />
        </div>
        <div className="inputContainer">
          <label>Telephone Number</label>
          <select
            onChange={(e) => setSelectedCountryCode(e.target.value)}
            value={selectedCountryCode}
          >
            <option value={`+1`}>+1</option>
            <option value={`+52`}>+52</option>
          </select>
          <input
            placeholder="phone number"
            type="tel"
            maxLength="10"
            minLength="10"
            pattern="[0-9]{10}"
            value={telephone_number}
            onChange={(e) => {
              const inputText = e.target.value;
              // Filtra cualquier carácter que no sea un dígito numérico
              const numericInput = inputText.replace(/\D/g, "");
              setTelephoneNumber(numericInput);
            }}
            required
          />
        </div>

        <div className="inputContainer">
          <label>Reference</label>
          <textarea
            type="text"
            placeholder="How to recognize the place"
            value={additional_instructions}
            onChange={(e) => setAdditionalInstructions(e.target.value)}
            required
          />
        </div>

        <div className="buttonsContainer">
          <div className="saveBtnContainer">
            <button type="submit">Save</button>
          </div>
        </div>
      </form>
    </NavigationComponents>
  );
};

export default CompanyPostAdress;
