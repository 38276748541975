import React from "react";

import "../../../styles/pages/Driver/pages/PreviewOrder.scss";

import emptyBoxIcon from "../../../images/emptyBox.png";
const PreviewOrder = () => {
  return (
    <div className="empty-order">
      <img src={emptyBoxIcon} className="box-animation" alt="No order" />
      <p>You do not have any orders assigned at this time.</p>
    </div>
  );
};

export default PreviewOrder;
