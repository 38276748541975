import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//api
import { fetchCategories, fetchZonesService } from "./api/Api";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

//API
import {
  useCreateServicesCompanyMutation,
  useDeletServicesCompanyMutation,
  useGetServicesCompanyQuery,
  useUpdateServicesCompanyMutation,
} from "../../../../services/galeanaBizApiCompanyService";

//Component Form
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import {
  Form,
  Select,
  Toggle,
  ImagePreview,
  TextAreaField,
  InputImage,
  InputPrice,
  InputField,
  InputPhone,
  Buttons,
} from "../../../../components/form/Form";

//Cards
import ServiceCard_V1 from "../../../company_service/components/ServiceCard_V1";
import ServicePinnedCard from "../../../company_service/components/ServicePinnedCard";

//NavBarLocal
import ExtraPagesNavbar, {
  ExtraPagesButton,
} from "../../components/ExtraPagesNavbar";

//styles
import "./styles/GetCompanyServices.scss";
import "./styles/ServiceCustomization.scss";

//pop-notification
import toast from "react-hot-toast";
import Modal from "../../../../components/modal/Modal";
const BusinessService = () => {
  const { user, company } = useSelector((state) => state.userDetails);

  const [currentPage, setCurrentPage] = useState("all_services");

  const { data, isLoading, isFetching, error } = useGetServicesCompanyQuery({
    action: "all",
    parameter: "company_id",
    value: company?._id,
    status: true,
  });

  const CurrentPage = () => {
    if (currentPage === "add_service" || currentPage === "update_service") {
      return <ServiceCustomization company={company} user={user} />;
    }

    return (
      <GetCompanyServices
        data={data}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  const buttonsPages = [
    { status: "all_services", label: "Services Cards" },
    { status: "add_service", label: "Add Service" },
  ];

  return (
    <NavigationComponents>
      <ExtraPagesNavbar>
        {buttonsPages.map((btn, index) => (
          <div key={index}>
            <ExtraPagesButton
              btnName={btn.label}
              value={currentPage}
              status={btn.status}
              setValue={setCurrentPage}
            />
          </div>
        ))}
      </ExtraPagesNavbar>
      <CurrentPage />
    </NavigationComponents>
  );
};

export default BusinessService;

const GetCompanyServices = ({ data, isLoading, isFetching, error }) => {
  const [modal, setModal] = useState({
    isOpen: false,
    data: {},
  });
  const [services, setServices] = useState({
    all: [],
    pinned: [],
  });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setServices({
      all: data?.services,
      pinned: data?.service_pinned,
    });
  }, [data, isFetching]);

  const Services = () => {
    if (isLoading || isFetching) {
      return <Loader />;
    }

    if (error) {
      return <Message>{error}</Message>;
    }

    if (!services.all) {
      return;
    }

    return services.all.map((service, index) => (
      <div className="warpperCard" key={index}>
        <ServiceCard_V1 service={service} />
        <div className="contentLink">
          <Link
            to={`/mybusiness/services?label=${encodeURIComponent(
              "update_service"
            )}&slug=${encodeURIComponent(`${service.slug}`)}`}
            className="actionBtn"
          >
            Edit
          </Link>
          <div
            onClick={() => setModal({ isOpen: true, data: service })}
            className="actionBtn"
          >
            Delete
          </div>
        </div>

        <ModalDelete modal={modal} setModal={setModal} data={service} />
      </div>
    ));
  };

  const ServicePinned = () => {
    if (isLoading || isFetching) {
      return <Loader />;
    }

    if (error) {
      return <Message>{error}</Message>;
    }

    if (!services.pinned) {
      return;
    }
    return services.pinned.map((service, index) => (
      <ServicePinnedCard key={index} service={service} />
    ));
  };

  return (
    <div className="myBusinessServicePage">
      <div className="servicePinnedCardsContainer">
        <div className="servicePinnedCardsWrapper">
          <ServicePinned />
        </div>
      </div>

      <div className="ServiceCardsContainer">
        <div className="ServiceCardsWrapper">
          <Services />
        </div>
      </div>
    </div>
  );
};

const ModalDelete = ({ modal, setModal }) => {
  const { user, company } = useSelector((state) => state.userDetails);

  const [deletservice, deleteServiceResult] = useDeletServicesCompanyMutation();

  const closedModal = () => {
    setModal({ isOpen: false, data: {} });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    deletservice({
      service_id: modal.data.id,
      user_id: user.user.id,
      company_id: company._id,
    }).then((response) => {
      if ("error" in response) {
        toast.error(`${response.error.data.message}`);
        return;
      }
      toast.success("Service Deleted!");
    });
  };
  return (
    <Modal isOpen={modal.isOpen} onClose={() => closedModal()}>
      <div className="modalDeleteService">
        <div className="sectionLeft">
          <div className="contentPhoto">
            <img src={modal?.data?.photo} />
          </div>

          <div className="contentName">
            <div className="textQuestions">
              Do you want to delete this service:{" "}
            </div>
            <div className="textName">{modal?.data?.name}?</div>
          </div>
        </div>
        <form onSubmit={handleDelete} className="sectionRight">
          <button type="button" className="btnCancel" onClick={closedModal}>
            Cancel
          </button>
          <button type="submit" className="btnDelete">
            Delete
          </button>
        </form>
      </div>
    </Modal>
  );
};

const ServiceCustomization = ({ company, user }) => {
  const navigate = useNavigate();
  const path = `${window.location.pathname}?label=all_services`;

  //urls params ✅
  const urlParams = new URLSearchParams(window.location.search);
  const slug = urlParams.get("slug");
  const label = urlParams.get("label");

  //useState ✅
  const [isLoading, setIsLoading] = useState({
    zones: true,
    categories: true,
  });

  const [categories, setCategories] = useState([]);
  const [zonesService, setZonesService] = useState([]);

  const [formData, setFormData] = useState({
    photo: "",
    fakePhoto: "",
  });

  const [serviceData, setServiceData] = useState({
    name: "",
    hour_price: "",
    currency: "MXN",
    contact_phone: "",
    category_id: [],
    description: "",
    zones_ids: [],
    is_available: true,
    subscription_active: false,
  });

  const [messageError, setMessageError] = useState({
    createdError: "",
    updatedError: "",
  });

  //api ✅
  const [createService, createServiceResult] =
    useCreateServicesCompanyMutation();

  const [updateService, updateServiceResult] =
    useUpdateServicesCompanyMutation();

  const {
    data: dataService,
    isLoading: serviceLoading,
    isFetching: serviceFetching,
    error: serviceError,
  } = useGetServicesCompanyQuery({
    action: "details",
    parameter: "slug",
    value: slug || "",
  });

  //useEffect  ???

  useEffect(() => {
    //caraga de funcion categoires zones y categories
    fetchCategories()
      .then((response) => {
        setCategories(response.data);
        setIsLoading((prev) => ({ ...prev, categories: false }));
      })
      .catch((error) => {
        setIsLoading((prev) => ({ ...prev, categories: false }));
      });

    fetchZonesService()
      .then((response) => {
        setZonesService(response.data);
        setIsLoading((prev) => ({ ...prev, zones: false }));
      })
      .catch((error) => {
        setIsLoading((prev) => ({ ...prev, categories: false }));
      });
  }, []);

  useEffect(() => {
    //Este no carga asta que no existe un serevice haci que no presenta problema
    if (dataService && label === "update_service") {
      const zonesIds = dataService?.zones?.map((zone) => zone?.id);

      setFormData((prevData) => ({
        ...prevData,
        fakePhoto: dataService?.photo,
      }));
      setServiceData((prev) => ({
        ...prev,
        name: dataService?.name,
        hour_price: dataService?.hour_price,
        currency: dataService?.currency,
        contact_phone: dataService?.contact_phone,
        category_id: dataService.category?.id,
        description: dataService?.description,
        zones_ids: zonesIds,
        is_available: dataService?.is_available,
        subscription_active: dataService?.subscription_active,
      }));
    }
  }, [dataService, label]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const userId = parseInt(user.user.id);

    if (dataService && label === "update_service") {
      updateService({
        service_id: dataService.id,
        user_id: userId,
        company_id: company?._id,
        photo: formData.photo,
        ...serviceData,
      }).then((response) => {
        if ("error" in response) {
          setMessageError((prev) => ({
            ...prev,
            createdError: response.error,
          }));
          toast.error(`${response.error.data.message}`);
          return;
        }

        toast.success("Service Updated!");
        navigate(path, { replace: true });
      });
    } else {
      createService({
        user_id: userId,
        company_id: company?._id,
        photo: formData.photo,
        ...serviceData,
      }).then((response) => {
        if ("error" in response) {
          setMessageError((prev) => ({
            ...prev,
            createdError: response.error,
          }));
          toast.error(`${response.error.data.message}`);
          return;
        }

        toast.success("Service Created!");
        navigate(path, { replace: true });
      });
    }
  };

  if (serviceLoading) {
    return <Loader />;
  }

  return (
    <div className="serviceForm">
      <ImagePreview img={formData?.fakePhoto || formData?.photo} />

      <Form handleSubmit={handleSubmit}>
        <InputImage
          label="Image"
          name="photo"
          fakename={"fakePhoto"}
          setFormData={setFormData}
          required={label === "add_service" ? true : false}
        />

        <Toggle
          id={"is_available"}
          name="is_available"
          label={"Service Available"}
          setValue={setServiceData}
          isChecked={serviceData?.is_available}
        />

        <Toggle
          id={"subscription_active"}
          name="subscription_active"
          label={"Only visible to LG Members"}
          setValue={setServiceData}
          isChecked={serviceData?.subscription_active}
        />

        <InputField
          id={"serviceName"}
          placeholder="name"
          label="Name"
          name="name"
          type="text"
          value={serviceData?.name}
          setValue={setServiceData}
          required={true}
        />

        <InputPrice
          placeholder="hour price"
          label="Hour Price"
          name="hour_price"
          price={serviceData?.hour_price}
          currency={serviceData?.currency}
          setValue={setServiceData}
          required={false}
        />

        <InputPhone
          placeholder="phone"
          label="Phone Number"
          name="contact_phone"
          value={serviceData?.contact_phone}
          setValue={setServiceData}
          required={true}
        />

        <Select
          name="category_id"
          label="Categories"
          isMulti={false}
          data={serviceData?.category_id}
          setData={setServiceData}
          required={true}
          isLoading={isLoading.categories}
        >
          <option value="" disabled>
            Selected Category
          </option>
          {categories?.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>

        <Select
          name="zones_ids"
          label="Zones the service"
          isMulti={true}
          data={serviceData?.zones_ids}
          setData={setServiceData}
          required={true}
          isLoading={isLoading.zones}
        >
          <option value="" disabled>
            Selected zones
          </option>
          {zonesService?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>

        <TextAreaField
          id={"serviceDescription"}
          placeholder="description"
          label="Description"
          name="description"
          value={serviceData?.description}
          setValue={setServiceData}
          required={true}
        />

        <Buttons
          isLoading={createServiceResult.isLoading}
          returnPath={"/mybusiness/services"}
          paramsKey={"label"}
          valueParams={"all_services"}
        />
      </Form>
    </div>
  );
};
