import React from "react";

import ReactDOM from "react-dom";

import "./modal.scss";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modalWrapper">
      <div className="modalContainer">
        <div className="closed " onClick={onClose}>
          <span>&times;</span>
        </div>
        {children}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
