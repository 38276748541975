import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { AdressCompanyAction } from "../../../../../actions/adress/adressCompanyActions";

import "../../../../../styles/pages/adress/companyadress/CompanyAdress.scss";

import Message from "../../../../../components/Message";
import Loader from "../../../../../components/Loader";
import formatPhoneNumber from "../../../../../helpers/format/formatPhoneNumber";

const CompanyAdress = () => {
  const dispatch = useDispatch();

  const adressMyCompany = useSelector((state) => state.AdressCompanyGet);
  const {
    loading: AddressCompanyLoading,
    error: AddressCompanyError,
    AdressCompany,
  } = adressMyCompany;

  useEffect(() => {
    dispatch(AdressCompanyAction());

    document.title = `Address - GaleanaBiz`;
  }, [dispatch]);

  const LinkPath = () => {
    if (AdressCompany) return null;
    return (
      <div className="addBtnContainer">
        <Link to={`mybusiness/adress/created`} className="addBtn">
          Add Address
        </Link>
      </div>
    );
  };

  if (AddressCompanyLoading) {
    return <Loader />;
  }

  if (AddressCompanyError) {
    return <Message>{AddressCompanyError}</Message>;
  }

  return (
    <div className="addressBusiness">
      <CompanyGetAdress addressCompany={AdressCompany} />
      <LinkPath />
    </div>
  );
};

export default CompanyAdress;

const CompanyGetAdress = ({ addressCompany }) => {
  if (!addressCompany) return null;

  return (
    <div className="companyAddressWrapper">
      <div className="contentAddressTop">
        <div className="sectionTopAddress">
          <h1 className="titleCompanyAddress">Address</h1>
          <div className="contentLink">
            <Link
              to={"/mybusiness/adress/update"}
              className="updateCompanyAddressLink"
            >
              Update address
            </Link>
          </div>
        </div>

        <div className="addressCompanyContainer">
          <AddressCard address={addressCompany} />;
        </div>
      </div>
    </div>
  );
};

const AddressCard = ({ address }) => {
  const formattedPhoneNumber = formatPhoneNumber(address?.telephone_number);

  return (
    <div className="addressCard">
      <div className="addressCardTitle">Business: {address?.company_name}</div>

      <div className="addressCardInfo">
        <div className="addressCardInfoItem">
          <div className="addressCardInfoLabel">Phone:</div>
          <div className="addressCardInfoText">{formattedPhoneNumber}</div>
        </div>

        <div className="addressCardInfoItem">
          <div className="addressCardInfoLabel">Location:</div>
          <div className="addressCardInfoText">
            {address?.municipality} ({address?.postal_code})
          </div>
        </div>

        <div className="addressCardInfoItem">
          <div className="addressCardInfoLabel">Streets:</div>
          <div className="addressCardInfoText">
            {address?.street_1 && `Street 1: ${address?.street_1}`}
            {address?.street_1 && address?.street_2 && ", "}
            {address.street_2 && `Street 2: ${address?.street_2}`}
          </div>
        </div>

        <div className="addressCardInfoItem">
          <div className="addressCardInfoLabel">Numbers:</div>
          <div className="addressCardInfoText">
            {address?.interior_number &&
              `Interior Nº: ${address?.interior_number}`}
            {address?.interior_number && address?.exterior_number && ", "}
            {address.exterior_number &&
              `External Nº: ${address?.exterior_number}`}
          </div>
        </div>

        {address?.additional_instructions && (
          <div className="addressCardInfoTextAreaItem">
            <div className="addressCardInfoLabel">Reference:</div>
            <textarea
              disabled={true}
              value={address?.additional_instructions}
              className="addressCardInfoTextArea"
            />
          </div>
        )}
      </div>
    </div>
  );
};
