import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { login } from "../../actions/userAction";

// Images
import LGTreeLogo from "../../images/placeholders/GBiz-logo.png";

// SCSS
import "../../styles/pages/authentication/Login.scss";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [invalidCredentialsOpen, setInvalidCredentialsOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState({
    email: false,
    password: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { error: errorSignIn, loading: laodingSignIn, userToken } = userLogin;

  useEffect(() => {
    if (userToken) {
      navigate("/", { replace: true });
    }
  }, [userToken, navigate]);

  useEffect(() => {
    const clearError = () => {
      setError({
        email: false,
        password: false,
      });
    };

    const timeoutId = setTimeout(clearError, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    if (errorSignIn && !userToken) {
      setInvalidCredentialsOpen(true);
    }
  }, [errorSignIn, userToken]);

  const submitHandler = (e) => {
    e.preventDefault();

    try {
      const newError = {
        ...error,
        email: !email && "User Name is required",
        password: !password && "Password is required",
      };

      const hashError = Object.values(newError).some(
        (error) => typeof error === "string"
      );

      setError(newError);

      if (!hashError) {
        setIsSubmitting(true);
        dispatch(login(email, password));
        setInvalidCredentialsOpen(false);
      } else {
        setIsSubmitting(false);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const eyePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="loginPage">
      <div className="background"></div>

      <div className="contentContainer">
        <div className="contentBox">
          <Link to={"/"} className="galeanaBizContainer">
            <img src={LGTreeLogo} alt="" className="logoImg" />
            <div className="titleText">GaleanaBiz</div>
          </Link>
          <div className="loginToYourAccountText">
            Login to Your Lebaron-Galeana Account
          </div>

          {invalidCredentialsOpen === true ? (
            <div className="invalidCredentials">
              <div className="text">Error: Invalid credentials</div>
              <div
                onClick={() => setInvalidCredentialsOpen(false)}
                className="closeBtn"
              >
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
          ) : null}

          {laodingSignIn && <Loader />}
          <form className="usernameAndPasswordForm" onSubmit={submitHandler}>
            <div className="usernameInputContainer">
              <div className="inputTitle">Username</div>
              <input
                type="name"
                className={error.email ? "error" : null}
                placeholder="Enter Username"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></input>

              {error.email && (
                <span className="error-message">{error.email}</span>
              )}
            </div>

            <div className="passwordInputContainer">
              <div className="inputTitle">Password</div>
              <div className="inputAndShowpasswordBtn">
                <input
                  type={showPassword ? "text" : "password"}
                  className={error.password ? "error" : null}
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></input>

                <div className="iconContainer">
                  {showPassword ? (
                    <div className="showPasswordBtn">
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={eyePassword}
                        className="icon"
                      />
                    </div>
                  ) : (
                    <div className="showPasswordBtn">
                      <FontAwesomeIcon
                        icon={faEyeSlash}
                        onClick={eyePassword}
                        className="icon"
                      />
                    </div>
                  )}
                </div>
              </div>

              {error.password && (
                <span className="error-message">{error.password}</span>
              )}
            </div>

            <div className="signInBtnContainer">
              <div className="btnBackground"></div>
              <button
                type="submit"
                className="submitBtn"
                id="signInBtn"
                disabled={isSubmitting}
              >
                {/* <div id="blob"></div> */}
                Sign In
              </button>
            </div>
          </form>
          <div className="noAccountText">
            Don't have an account?{" "}
            <a href="https://lebarongaleana.com/registration" target="_blank">
              Register Here
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
