import React from "react";

import "../styles/components/Loader.scss";

function Loader({ textSize, height = '100px' }) {

  return (
    <div className="Spinner" style={{ height: height }}>
      <div className="loadingContainer" style={{ fontSize: `${textSize}px` }}>
        <i className="fa-solid fa-spinner"></i>
      </div>
    </div>
  );
}

export default Loader;
