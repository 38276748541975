import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { COMPANY_UPDATE_RESET } from "../constants/companyConstants";
import {
  updateCompanyAction,
  CompanyCategoryAction,
} from "../actions/companyActions";
import { getUserCompany } from "../actions/userAction";

// SCSS
import "../styles/pages/CompanyPage/UpdateCompany.scss";

// Components
import Loader from "../components/Loader";
import Message from "../components/Message";
import NavigationComponents from "../components/navigation/MyBusiness-Navigation/NavigationComponents";
import StarReviews from "../components/StarReviews";
import { imageCompression } from "../helpers/compressor/imageCompression";

const UpdateCompany = () => {
  const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

  const { company } = useSelector((state) => state.userDetails);

  const companyId = company?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [location, setLocation] = useState("");
  const [category, setCategory] = useState(0);
  const [categoryId, setCategoryId] = useState(0);

  const [fakeImage, setFakeImage] = useState(null);
  const [fakeImageBanner, setFakeImageBanner] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState({
    profileImage: false,
    bannerImage: false,
    name: false,
    location: false,
    category: false,
  });

  const categoriesList = useSelector((state) => state.companyCategories);
  const { categories } = categoriesList;

  const companyUpdate = useSelector((state) => state.companyUpdate);
  const {
    error: errorUpdatecompany,
    loading: loadingUpdateCompany,
    success: successUpdateCompnay,
  } = companyUpdate;

  const CancelForm = () => {
    navigate("/mybusiness");
  };

  useEffect(() => {
    dispatch(CompanyCategoryAction());
  }, [dispatch]);

  useEffect(() => {
    const confirmationMessage =
      "¿Are you sure you want to leave this page? Your progress will be lost.";

    const handleBeforeUnload = (e) => {
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    const clearError = () => {
      setError({
        profileImage: false,
        bannerImage: false,
        name: false,
        location: false,
        category: false,
      });
    };

    const timeoutId = setTimeout(clearError, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    if (successUpdateCompnay) {
      dispatch({ type: COMPANY_UPDATE_RESET });
      navigate("/mybusiness", { replace: true });
      dispatch(getUserCompany());
    } else {
      if (!company.name || company._id !== Number(companyId)) {
        dispatch(getUserCompany());
      } else {
        let profileImage = null;
        let bannerImage = null;

        setName(company.name);
        setLocation(company?.location);
        setCategory(company?.category);
        setCategoryId(company?.category_id);

        const imgProfile = new Image();
        imgProfile.src = company?.profileImage;
        imgProfile.onload = () => {
          profileImage = company?.profileImage;
          setFakeImage(profileImage);
        };

        const imgBanner = new Image();
        imgBanner.src = company?.bannerImage;
        imgBanner.onload = () => {
          bannerImage = company?.bannerImage;
          setFakeImageBanner(bannerImage);
        };
      }
    }
  }, [dispatch, navigate, companyId, company, successUpdateCompnay]);

  const uploadHandler = async (e) => {
    const file = e.target.files[0];
    const newError = { ...error };

    if (file) {
      try {
        const compressedImageFile = await imageCompression(file);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);

        setFakeImage(compressedImageURL);
        setProfileImage(compressedImageFile);
        newError.profileImage = false;
      } catch (err) {
        newError.profileImage =
          "Error compressing the image Profile. Please try again.";
      }
    }
    setError(newError);
  };

  const uploadImageProfileToApi = async () => {
    const formData = new FormData();
    formData.append("profileImage", profileImage);
    formData.append("company_id", companyId);

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/post-image-profile/`,
        formData,
        config
      );

      setProfileImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  const uploadBannerHandler = async (e) => {
    const file = e.target.files[0];
    const newError = { ...error };

    if (file) {
      try {
        const compressedImageFile = await imageCompression(file);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);
        setFakeImageBanner(compressedImageURL);
        setBannerImage(compressedImageFile);
        newError.bannerImage = false;
      } catch (error) {
        newError.bannerImage =
          "Error compressing the image Banner. Please try again.";
      }
    }
    setError(newError);
  };

  const uploadImageBannerToApi = async () => {
    const formData = new FormData();
    formData.append("bannerImage", bannerImage);
    formData.append("company_id", companyId);

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/post-image-banner/`,
        formData,
        config
      );

      setBannerImage(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    try {
      const newErrors = {
        ...error,
        name: !name && "Name is required",
        location: !location && "Location is required",
        category: !category && "Category is required",
      };

      const hashErrors = Object.values(newErrors).some(
        (error) => typeof error === "string"
      );

      setError(newErrors);

      if (!hashErrors) {
        setIsSubmitting(true);

        if (profileImage) {
          await uploadImageProfileToApi();
        }

        if (bannerImage) {
          await uploadImageBannerToApi();
        }

        dispatch(
          updateCompanyAction(
            {
              _id: companyId,
              name,
              location,
            },
            categoryId
          )
        );
        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <NavigationComponents>
      <div className="updateCompanyPage">
        {loadingUpdateCompany && <Loader />}

        <>
          <div className="dummyCompanyCardAndBannerContainer">
            <div className="bannerImageContainer">
              <img
                src={fakeImageBanner ? fakeImageBanner : null}
                alt=""
                className="bannerImage"
                loading="lazy"
                decoding="async"
                draggable={false}
              />
            </div>

            <div className="companyCardContainerWrapper">
              <div className="companyCard_v3_container dummyCompanyCardContainer">
                <Link to="" className="companyCard_v3">
                  <div className="companyImgContainer">
                    <img
                      src={fakeImage ? fakeImage : null}
                      className="companyLogoImg"
                      alt="Company Logo"
                      draggable="false"
                    />
                  </div>

                  <div className="infoSection">
                    <div className="adjustForPadding">
                      <div className="businessName">
                        {name ? name : "Enter Business Name"}
                      </div>
                      <div className="companyLocation">
                        <i className="fa-solid fa-location-dot"></i>{" "}
                        {location ? location : "Enter Location"}
                      </div>

                      <div className="reviewsContainer">
                        <StarReviews value={5} />

                        <div className="reviewsTextContainer">
                          {`123 reviews`}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="companyFormContainer">
            <div className="contentUploadedImage">
              <div className="imageInputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Image Profile</div>
                </div>

                <label
                  className={`customFileUpload ${
                    error.profileImage ? "error" : null
                  }`}
                >
                  <input
                    type="file"
                    placeholder="Image"
                    id="fileUpload"
                    onChange={uploadHandler}
                    disabled={isSubmitting}
                  />
                  Click to Add Image
                </label>
                {error.profileImage && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.profileImage}
                  </span>
                )}
              </div>
              <div className="imageInputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Image Banner</div>
                </div>
                <label
                  className={`customFileUpload ${
                    error.profileImage ? "error" : null
                  }`}
                >
                  <input
                    type="file"
                    placeholder="ImageBanner"
                    className={error.bannerImage ? "error" : null}
                    id="fileUpload"
                    onChange={uploadBannerHandler}
                    disabled={isSubmitting}
                  />
                  Click to Add Image Banner
                </label>
                {error.bannerImage && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.bannerImage}
                  </span>
                )}
              </div>
            </div>
            <form className="updateCompanyForm" onSubmit={handleSubmit}>
              <div className="inputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Name</div>
                </div>
                <input
                  type="text"
                  className={error.name ? "error" : null}
                  placeholder="Enter business name here"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  disabled={isSubmitting}
                />
                {error.name && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.name}
                  </span>
                )}
              </div>
              <div className="inputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Location</div>
                </div>
                <select
                  value={location}
                  className={error.location ? "error" : null}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="" disabled>
                    selected Location
                  </option>
                  <option value="Galeana">Galeana</option>
                  <option value="Lagunitas">Lagunitas</option>
                  <option value="Lebaron">Colonia Lebaron</option>
                  <option value="Angostura">Angostura</option>
                  <option value="San Jeronimo">San Jeronimo</option>
                </select>
                {error.location && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.location}
                  </span>
                )}
              </div>

              <div className="inputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Select Category</div>
                </div>
                <select
                  value={categoryId}
                  className={error.category ? "error" : null}
                  onChange={(e) => setCategoryId(e.target.value)}
                  disabled={isSubmitting}
                >
                  {categories &&
                    categories !== null &&
                    categories !== undefined &&
                    categories.map((category, index) => {
                      return (
                        <option key={index} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                </select>
                {error.category && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.category}
                  </span>
                )}
              </div>

              {errorUpdatecompany && <Message>{errorUpdatecompany}</Message>}
              <div className="saveAndCancelBtnsContainer">
                <div className="deleteBtnContainer">
                  <button id="deleteCompany" onClick={CancelForm}>
                    Cancel
                  </button>
                </div>
                <div className="saveBtnContainer">
                  <button
                    id="saveCompany"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      </div>
    </NavigationComponents>
  );
};

export default UpdateCompany;
