import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateAdminDriver = () => {
  const userDetails = useSelector((state) => state.userDetails);

  const userRole = userDetails.user?.user?.profile?.role;
  if (userRole === "Admin") {
    return <Outlet />;
  }

  return <Navigate replace to="/driver" />;
};

export default PrivateAdminDriver;
