import axios from "axios";
import {
  SOCIAL_GET_REQUEST,
  SOCIAL_GET_SUCCESS,
  SOCIAL_GET_FAIL,
  //
  SOCIAL_ADD_REQUEST,
  SOCIAL_ADD_SUCCESS,
  SOCIAL_ADD_FAIL,
  //
  SOCIAL_PUT_REQUEST,
  SOCIAL_PUT_SUCCESS,
  SOCIAL_PUT_FAIL,
} from "../constants/contactConstans";
const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;
////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getSocialNetworkActions =
  (routeParamsCompanyId) => async (dispatch) => {
    try {
      dispatch({ type: SOCIAL_GET_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/social/get-social-network/${routeParamsCompanyId}/`,

        config
      );

      dispatch({ type: SOCIAL_GET_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: SOCIAL_GET_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const UpdateSocialActions =
  (routeParamsCompanyId, socialId, social) => async (dispatch) => {
    try {
      dispatch({ type: SOCIAL_PUT_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/social/put-social-network/`,
        { company_id: routeParamsCompanyId, social_id: socialId, ...social },
        config
      );

      dispatch({ type: SOCIAL_PUT_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: SOCIAL_PUT_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AddSocialActions =
  (
    CompanyId,
    facebook_url,
    instagram_url,
    youtube_url,
    twiter_url,
    tiktok_url
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: SOCIAL_ADD_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/social/post-social-network/`,
        {
          company_id: CompanyId,
          facebook_url: facebook_url,
          instagram_url: instagram_url,
          youtube_url: youtube_url,
          twiter_url: twiter_url,
          tiktok_url: tiktok_url,
        },
        config
      );

      dispatch({ type: SOCIAL_ADD_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: SOCIAL_ADD_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };
