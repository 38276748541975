const validateFunction = (values) => {
  const errors = [];

  values.forEach((day, index) => {
    const opensPresent = day.opens.trim() !== "";
    const shutsPresent = day.shuts.trim() !== "";
    const breakStartPresent = day.break_start.trim() !== "";
    const breakEndPresent = day.break_end.trim() !== "";

    const opensShutsPresent = opensPresent && shutsPresent;
    const areBreaksPresent = breakStartPresent && breakEndPresent;

    const opensShutsAndBreaks = opensShutsPresent && areBreaksPresent;

    const hasValues =
      opensPresent || shutsPresent || breakStartPresent || breakEndPresent;

    // Condición 1: Si todos los campos están vacíos, entonces es correcto.
    if (!hasValues) {
      return;
    }

    // Condición 2: Si solo está presente un valor en opens o shuts, entonces es incorrecto.
    if ((opensPresent || shutsPresent) && !opensShutsPresent) {
      errors.push({
        message: "There should not be only one value present in opens or shuts",
        index: index,
      });
    }

    // Condición 3: Si break_start o break_end están presentes, entonces opens y shuts también deben estar presentes.
    if ((breakStartPresent || breakEndPresent) && !opensShutsPresent) {
      errors.push({
        message:
          "Make sure opens and shuts are present if break_start or break_end are present",
        index: index,
      });
    }

    // Condición 4: break_start y break_end deben estar presentes los dos valores no solo 1.
    if ((breakStartPresent || breakEndPresent) && !areBreaksPresent) {
      errors.push({
        message: "Make sure break_start and break_end are present together",
        index: index,
      });
    }

    // Condición 5: opens, shuts, break_start y break_end no deben ser iguales.
    const areEqual = (opens, shuts, breakS, breakE) =>
      opens === shuts ||
      opens === breakS ||
      opens === breakE ||
      shuts === breakS ||
      shuts === breakE;

    if (
      opensShutsAndBreaks &&
      areEqual(day.opens, day.shuts, day.break_start, day.break_end)
    ) {
      errors.push({
        message:
          "Make sure opens, shuts, break_start and break_end are not the same",
        index: index,
      });
    }

    // Condición 5:  break_start y break_end no deben ser iguales.
    if (areBreaksPresent && day.break_start === day.break_end) {
      errors.push({
        message: "Make sure break_start and break_end are not the same",
        index: index,
      });
    }

    //// Condición 6:  break_start y break_end deben estar dentro del horario de apertura y cierre
    if (
      opensShutsAndBreaks &&
      (day.break_start < day.opens ||
        day.break_end > day.shuts ||
        day.break_start > day.shuts ||
        day.break_end < day.opens)
    ) {
      errors.push({
        message: "You are outside the range of opens and shuts",
        index: index,
      });
    }
  });

  return errors;
};

export default validateFunction;
