import React from "react";
import { Link } from "react-router-dom";

// SCSS
import "../../styles/components/CompanyAndProductCards/ProductsCard.scss";

import formatCurrency from "../../helpers/format/formatCurrency";

import { VscPinned } from "react-icons/vsc";
import { GoVerified } from "react-icons/go";

const ProductsCard = ({ products }) => {
  const price = formatCurrency(products?.price, products?.currency);
  const priceDiscount = formatCurrency(
    products?.discounted_price,
    products?.currency
  );

  const productName = () => {
    let name = products?.name;

    if (name.length > 10) {
      return (
        <div className="productName" id="productName">
          {name.slice(0, 10)}...
        </div>
      );
    }

    return (
      <div className="productName" id="productName">
        {name}
      </div>
    );
  };

  return (
    <div className="productCardContainer">
      <div className="productCard">
        <Link
          to={`/product/${products.company}/${products?.id}`}
          className="productCardLink"
        >
          <div className="contenPinned">
            <VscPinned className="isPinned" />
          </div>
          <div className="imgContainer">
            {products?.available ? null : (
              <div className="soldOutOverlay">
                <div className="overlayText">Sold Out</div>
              </div>
            )}

            <img src={products?.photo} className="productImg" />
          </div>

          <div className="bottomContainer">
            <div className="contentVerfied">
              <GoVerified className="GoVerified" />
            </div>

            <div className="nameAndBusinessContianer">
              {productName()}

              {products?.rating > 0 && (
                <div className="rating">
                  <span>{products?.rating}</span>
                </div>
              )}
            </div>

            {products.discount > 0 ? (
              <>
                <div className="priceContainer__oldPrice">
                  <div className="lineThrough"></div>
                  <span className="price">
                    <span className="integer"> {price}</span>
                  </span>
                  <span className="currency">{products?.currency}</span>
                </div>

                <div className="priceContainerDiscount">
                  <span className="price">
                    <span className="integer">{priceDiscount}</span>
                    <span className="currency">{products?.currency}</span>
                    <span className="porcentage">
                      {products?.discount}% OFF
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <div className="priceContainer">
                <span className="price">
                  <span className="integer">{price}</span>
                </span>
                <span className="currency">{products?.currency}</span>
              </div>
            )}
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductsCard;
