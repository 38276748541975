import React, { useEffect, useState } from "react";
import parsePhoneNumber from "../../../../helpers/format/parsePhoneNumber";

const PhoneInput = ({ setValueAddress, phone }) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");

  useEffect(() => {
    if (!phone) return;

    const { countryCode, formattedNumber } = parsePhoneNumber(phone);

    setSelectedCountryCode(countryCode);
    setTelephoneNumber(formattedNumber);
  }, [phone]);

  useEffect(() => {
    if (!selectedCountryCode && !telephoneNumber) return;

    setValueAddress((prev) => ({
      ...prev,
      phone: selectedCountryCode + telephoneNumber,
    }));
  }, [selectedCountryCode, telephoneNumber]);

  return (
    <div className="phoneNumberInputContainer">
      <div className="title">Phone Number</div>
      <div className="inputsContainer">
        <select
          onChange={(e) => setSelectedCountryCode(e.target.value)}
          value={selectedCountryCode}
        >
          <option value="" disabled={true}>
            Code
          </option>
          <option value="+1">USA (+1)</option>
          <option value="+52">MX (+52)</option>
        </select>

        <input
          placeholder="phone number"
          type="tel"
          maxLength="10"
          minLength="10"
          pattern="[0-9]{10}"
          value={telephoneNumber}
          onChange={(e) => {
            const inputText = e.target.value;
            // Filtra cualquier carácter que no sea un dígito numérico
            const numericInput = inputText.replace(/\D/g, "");
            setTelephoneNumber(numericInput);
          }}
          required
        />
      </div>
    </div>
  );
};

export default PhoneInput;
