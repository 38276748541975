import React, { useEffect } from "react";

import Navbar from "./navigation/Navbar";
import FooterOrSidebar from "./navigation/FooterAndSidebars/FooterOrSidebar";
import MobileFooter from "./navigation/FooterAndSidebars/MobileFooter";

import "../styles/components/AddToAllPages.scss";
import { useDispatch, useSelector } from "react-redux";
import { getFavorites } from "../actions/favoritesActions";

function AddToAllPages(props) {
  const currentPage = window.location.href;

  const localhost = "http://localhost:3000/";
  const networkServer = "http://192.168.1.236:3000/#/";
  const livePageUrl = "https://galeana.biz/";

  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.favorites);

  const currentPageUrl = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "pageLink activePageLink";
    } else if (currentPage === `${networkServer}${pageUrl}`) {
      return "pageLink activePageLink";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "pageLink activePageLink";
    } else {
      return "pageLink";
    }
  };

  const currentPageUrl_DropdownMenu = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "activeDropDownItem";
    } else if (currentPage === `${networkServer}${pageUrl}`) {
      return "activeDropDownItem";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "activeDropDownItem";
    } else {
      return "dropDownItem";
    }
  };

  useEffect(() => {
    if (favorites.loaded) {
      return;
    }

    dispatch(getFavorites());
  }, [dispatch, favorites.loaded]);

  return (
    <>
      <Navbar />
      <FooterOrSidebar currentPageUrl={currentPageUrl} />
      <div className="thePage">{props.children}</div>
      <div className="mobileFooterContainer">
        <MobileFooter
          currentPageUrl={currentPageUrl}
          currentPageUrl_DropdownMenu={currentPageUrl_DropdownMenu}
        />
      </div>
    </>
  );
}

export default AddToAllPages;
