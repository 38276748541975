import React, { useEffect, useState } from "react";

import DeliveryNavigationComponents from "../../../../components/navigation/Driver-Navigation/DriverNavigationComponents";

import "../../../../styles/pages/Driver/pages/admin/DriversPage.scss";

import ImagePorfile from "../../../../images/default-profile-icon.jpg";

import { formatTimeAndDate } from "../../../../helpers/formatDateTime";
import { useGetDriversAdminQuery } from "../../../../services/galeanaBizApi";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

const DriversPage = () => {
  const [allDrivers, setAllDrivers] = useState([]);

  const { data, isFetching, isLoading, isError, error } =
    useGetDriversAdminQuery();

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setAllDrivers(data);
  }, [data]);

  return (
    <DeliveryNavigationComponents>
      <div className="contentDriverPage">
        <h1 className="TitlePage">Drivers</h1>

        <SectionCard
          allDrivers={allDrivers}
          isLoading={isLoading}
          isError={isError}
          error={error}
        />
      </div>
    </DeliveryNavigationComponents>
  );
};

export default DriversPage;

const SectionCard = ({ allDrivers, isLoading, isError, error }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Message error={isError}>{error}</Message>;
  }

  return (
    <div className="sectionCard">
      {allDrivers.map((driver, index) => {
        return <CardPreviewDrivers key={index} driver={driver} />;
      })}
    </div>
  );
};

const CardPreviewDrivers = ({ driver }) => {
  const image = driver?.photo;
  const settings = driver?.settings;

  const isStatus = settings?.availability_status;
  const start_time = settings?.daily_start_time;
  const end_time = settings?.daily_end_time;

  const Status = () => {
    if (isStatus === "available") {
      return (
        <i className="fas fa-circle" style={{ color: " rgb(8, 203, 18)" }}></i>
      );
    }

    return (
      <i className="fas fa-circle" style={{ color: " rgb(255, 0, 0)" }}></i>
    );
  };

  return (
    <div className="wrapperCardDriver">
      <div className="contentCard">
        <div className="sectionTop">
          <div className="left">
            <div className="contentImagePorfile">
              {image ? (
                <img draggable={false} src={image} alt="" />
              ) : (
                <img draggable={false} src={ImagePorfile} alt="" />
              )}
            </div>

            <div className="contentName">{driver.username}</div>
          </div>

          <div className="right">
            <div className="status">
              <div>Status:</div>
              <Status />
            </div>
            <div className="ordersNum">
              <span className="ordersLabel">Assign./Orders:</span>
              <div className="num">{driver.orders_assigned_count}</div>
            </div>
          </div>
        </div>

        <div className="sectionBottom">
          <div className="hourService">
            <div className="text">ON Service:</div>
            {start_time === null ? (
              <div className="formatDate">---</div>
            ) : (
              <div className="formatDate">{formatTimeAndDate(start_time)}</div>
            )}
          </div>

          <div className="hourService">
            <div className="text">OFF Service:</div>
            {end_time === null ? (
              <div className="formatDate">---</div>
            ) : (
              <div className="formatDate">{formatTimeAndDate(end_time)}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
