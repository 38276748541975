import axios from "axios";
import {
  ABOUT_GET_REQUEST,
  ABOUT_GET_SUCCESS,
  ABOUT_GET_FAIL,
  //
  ABOUT_ADD_REQUEST,
  ABOUT_ADD_SUCCESS,
  ABOUT_ADD_FAIL,
  //
  ABOUT_PUT_REQUEST,
  ABOUT_PUT_SUCCESS,
  ABOUT_PUT_FAIL,
} from "../constants/aboutConstans";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const AboutListAction = (routeParamsCompanyId) => async (dispatch) => {
  try {
    dispatch({ type: ABOUT_GET_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/about/get-about/${routeParamsCompanyId}/`,
      config
    );

    dispatch({ type: ABOUT_GET_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ABOUT_GET_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const UpdateAboutActions =
  (routeParamsCompanyId, aboutId, about) => async (dispatch) => {
    try {
      dispatch({ type: ABOUT_PUT_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/about/put-about/`,
        { company_id: routeParamsCompanyId, about_id: aboutId, ...about },
        config
      );

      dispatch({ type: ABOUT_PUT_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ABOUT_PUT_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AddAboutActions =
  (CompanyId, image, title, description) => async (dispatch) => {
    try {
      dispatch({ type: ABOUT_ADD_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/about/post-about/`,
        {
          company_id: CompanyId,
          image: image,
          title: title,
          description: description,
        },
        config
      );

      dispatch({ type: ABOUT_ADD_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ABOUT_ADD_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };
