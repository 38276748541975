import React, { useState } from "react";
import { DeleteItemAction } from "../../actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import CartItemUnits from "./CartItemUnits";

import "../../styles/pages/Cart/CartItem.scss";

import { GoVerified } from "react-icons/go";
import formatCurrency from "../../helpers/format/formatCurrency";
import FormatDiscount from "../../helpers/format/FormatDiscount";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";

import LGLogo from "../../images/LGWhiteLogo.png";

const CartItemLocalStorage = () => {
  const [openDropdownItems, setOpenDropdownItems] = useState([]);

  const dispatch = useDispatch();
  const GetItemsCart = useSelector((state) => state.CartGetItems);
  const { loading: GetItemsLoading, CartItems } = GetItemsCart;

  const item_info = Object.values(CartItems || []).map((item) => {
    return item.items;
  });

  const UpdateCartItem = useSelector((state) => state.CartGetTotalItems);
  const {
    loading: UpdateItemLoading,
    error: UpdateItemError,
    success: UpdateItemSuccess,
  } = UpdateCartItem;

  const RemoveItem = (item) => {
    dispatch(DeleteItemAction(item));
  };

  const handleDropDown = (e, itemIndex) => {
    e.preventDefault();
    if (openDropdownItems.includes(itemIndex)) {
      setOpenDropdownItems(
        openDropdownItems.filter((index) => index !== itemIndex)
      );
    } else {
      setOpenDropdownItems([...openDropdownItems, itemIndex]);
    }
  };

  const [showProductOptions, setShowProductOptions] = useState(false);

  return (
    <div>
      {GetItemsLoading ? (
        <Loader />
      ) : (
        <div className="cartItemsContainer">
          <div className="cartItemsWrapper">
            {Object.values(item_info || []).map((item) => (
              <>
                {Object.values(item || []).map((item, itemIndex) => {
                  const { product } = item;
                  const price = product?.price;
                  const units = item?.count;
                  const currency = product?.currency;
                  const discount = product?.discount;
                  const available = product?.available;

                  const discountedPrice = product?.discounted_price;

                  const categoryGroup = item?.attribute_groups;

                  const totalPriceUnits =
                    discount > 0
                      ? formatCurrency(discountedPrice * units, currency)
                      : formatCurrency(price * units, currency);

                  const dropDown = openDropdownItems.includes(itemIndex);

                  return (
                    <div className="productContainer" key={itemIndex}>
                      <div className="productCard">
                        <Link
                          className="productImgContainer"
                          to={`/product/${product.company_name}/${product?.id}`}
                        >
                          {product?.subscription_active && (
                            <div className="memberOnlyTag">
                              <img
                                src={LGLogo}
                                alt=""
                                className="LGMemberTagLogo"
                              />
                            </div>
                          )}
                          <img
                            src={product?.photo}
                            alt=""
                            className="productImg"
                            draggable="false"
                          />
                        </Link>
                        <div className="infoSection infoSection__cart">
                          <Link
                            className="topSection"
                            to={`/product/${product.company_name}/${product?.id}`}
                          >
                            <div className="infoText productName">
                              {product?.name}
                            </div>
                            <div className="infoText businessName">
                              {product?.company_name}
                            </div>

                            <div className={`infoText productPrice`}>
                              {price}
                              <span className="productCurrency">
                                {product?.currency}
                              </span>
                            </div>
                            {item?.count > 1 && (
                              <div
                                className={`infoText productPrice productTotalPrice`}
                              >
                                Total: {price * item?.count}
                                <span className="productCurrency">
                                  {product?.currency}
                                </span>
                              </div>
                            )}

                            {product?.discount > 0 && (
                              <div className="infoText discountedPercentage">
                                {product?.discount}% off
                              </div>
                            )}

                            {showProductOptions && (
                              <DropDownComponent
                                dropDown={dropDown}
                                categoryGroup={categoryGroup}
                                handleDropDown={handleDropDown}
                                itemIndex={itemIndex}
                                setShowProductOptions={setShowProductOptions}
                              />
                            )}

                            {product?.available ? null : (
                              <div className="infoText soldOutText">
                                Currently Unavailable
                              </div>
                            )}
                          </Link>

                          <div className="bottomSection">
                            <div
                              className="removeBtn"
                              onClick={() => RemoveItem(item)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </div>

                            {available === true ? (
                              <CartItemUnits
                                item={item}
                                productId={product?.id}
                                UpdateItemSuccess={UpdateItemSuccess}
                                UpdateItemLoading={UpdateItemLoading}
                                UpdateItemError={UpdateItemError}
                              />
                            ) : (
                              "Unavailable"
                            )}

                            <div
                              className="showProductsExtraOptions"
                              onClick={() => setShowProductOptions(true)}
                            >
                              ?
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartItemLocalStorage;

const DropDownComponent = ({ dropDown, categoryGroup }) => {
  if (!dropDown || !categoryGroup || categoryGroup.length === 0) return null;
  return (
    <div className="warpperDropDown">
      {categoryGroup?.map((attribute, index) => {
        return (
          <div className="contentDropDown" key={index}>
            <ul>
              <li>
                <div className="nameCategory">{attribute.categoryTitle}</div>

                <ul>
                  <li>
                    <div>{attribute.attributeName}</div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};
