import {useEffect, useState} from "react"
import axios from "axios"
import "../../styles/pages/Admin/SponsorForms.scss"

const apiHost = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export default function SponsorForms() {
  const [submissions, setSubmissions] = useState({
    approved: [],
    pending: [],
  })
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    axios
      .get(`${apiHost}/api/companies/sponsor`)
      .then(response => {
        setSubmissions({
          approved: response.data.approved || [],
          pending: response.data.pending || [],
        })
      })
  }, [])

  const approve = submissionId => {
    setProcessing(true)

    axios
      .put(`${apiHost}/api/companies/sponsor/${submissionId}`)
      .then(() => {
        const changedSubmission = submissions.pending.find(submission => submission.id === submissionId)
        const updatedPending = submissions.pending.filter(submission => submission.id !== submissionId)
        const updatedApproved = [ ...submissions.approved, changedSubmission ]
        setSubmissions({ pending: updatedPending, approved: updatedApproved })
      })
      .finally(() => {
        setProcessing(false)
      })
  }

  return (
      <div className="sponsor-form-submissions">
        <h2>Pending</h2>
        {submissions.pending.length > 0 ? (
          <>
            <div className="header">
              <p>Company</p>
              <p>Phone Number</p>
              <p>E-mail</p>
              <p>Approve</p>
            </div>
            <div className="data">
              {submissions.pending.map(submission => (
                <div key={submission.id} className="row">
                  <p>{submission.company.name}</p>
                  <p>{submission.phone}</p>
                  <p>{submission.email}</p>
                  <button disabled={processing} onClick={() => approve(submission.id)}>Approve</button>
                </div>
              ))}
            </div>
          </>
        ) : <p>No pending submissions</p>}
        <h3>Approved</h3>
        {submissions.approved.length > 0 ? (
          <>
            <div className="header">
              <p>Company</p>
              <p>Phone Number</p>
              <p>E-mail</p>
              <p>Approve</p>
            </div>
            <div className="data">
              {submissions.approved.map(submission => (
                <div key={submission.id} className="row">
                  <p>{submission.company.name}</p>
                  <p>{submission.phone}</p>
                  <p>{submission.email}</p>
                </div>
              ))}
            </div>
          </>
        ) : <p>No approved submissions</p>}
      </div>
    )
}
