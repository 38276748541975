import axios from "axios";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export async function fetchCategories() {
  const data = await axios.get(
    `${ApiGaleanaBiz}/api/companies/services/categories`
  );

  if (data.status === 200) {
    return data;
  }
}

export async function fetchZonesService() {
  const data = await axios.get(`${ApiGaleanaBiz}/api/companies/services/zones`);

  if (data.status === 200) {
    return data;
  }
}
