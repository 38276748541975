//Company adress

export const ADRESS_COMPANY_GET_REQUEST = "ADRESS_COMPANY_GET_REQUEST";
export const ADRESS_COMPANY_GET_SUCCESS = "ADRESS_COMPANY_GET_SUCCESS";
export const ADRESS_COMPANY_GET_FAIL = "ADRESS_COMPANY_GET_FAIL";
//
export const ADRESS_COMPANY_ADD_REQUEST = "ADRESS_COMPANY_ADD_REQUEST";
export const ADRESS_COMPANY_ADD_SUCCESS = "ADRESS_COMPANY_ADD_SUCCESS";
export const ADRESS_COMPANY_ADD_FAIL = "ADRESS_COMPANY_ADD_FAIL";
export const ADRESS_COMPANY_ADD_RESET = "ADRESS_COMPANY_ADD_RESET";

//
export const ADRESS_COMPANY_PUT_REQUEST = "ADRESS_COMPANY_PUT_REQUEST";
export const ADRESS_COMPANY_PUT_SUCCESS = "ADRESS_COMPANY_PUT_SUCCESS";
export const ADRESS_COMPANY_PUT_FAIL = "ADRESS_COMPANY_PUT_FAIL";
export const ADRESS_COMPANY_PUT_RESET = "ADRESS_COMPANY_PUT_RESET";

//User adress

export const ADRESS_USER_GET_REQUEST = "ADRESS_USER_GET_REQUEST";
export const ADRESS_USER_GET_SUCCESS = "ADRESS_USER_GET_SUCCESS";
export const ADRESS_USER_GET_FAIL = "ADRESS_USER_GET_FAIL";
//
export const ADRESS_USER_ADD_REQUEST = "ADRESS_USER_ADD_REQUEST";
export const ADRESS_USER_ADD_SUCCESS = "ADRESS_USER_ADD_SUCCESS";
export const ADRESS_USER_ADD_FAIL = "ADRESS_USER_ADD_FAIL";
export const ADRESS_USER_ADD_RESET = "ADRESS_USER_ADD_RESET";

//
export const ADRESS_USER_PUT_REQUEST = "ADRESS_USER_PUT_REQUEST";
export const ADRESS_USER_PUT_SUCCESS = "ADRESS_USER_PUT_SUCCESS";
export const ADRESS_USER_PUT_FAIL = "ADRESS_USER_PUT_FAIL";
export const ADRESS_USER_PUT_RESET = "ADRESS_USER_PUT_RESET";
