import React from "react";
import { Link } from "react-router-dom";

// Components
import CompanyCard from "../../../../components/CompanyAndProductCards/CompanyCard";

// SCSS
import "../../../../styles/components/MyCompanyPageComponents/Dashboard/MyBusinessCardContainer.scss";

function MyBusinessCardContainer({ company }) {
  return (
    <div className="myBusinessCardContainer">
      <div className="title">Your Business Card</div>
      <div className="myCompanyCardContainer">
        <div className="companyCardContainer">
          <CompanyCard company={company} dontShowFavorite={true} />
        </div>
        <div className="editBtnContainer">
          <Link
            to={`/business/${[company?._id]}/updateCompany`}
            className="editCardLink"
          >
            Edit
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyBusinessCardContainer;
