import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// SCSS
import "../../styles/pages/MyCompanyPage/MyCompanyPage.scss";

// Components
import NavigationComponents from "../../components/navigation/MyBusiness-Navigation/NavigationComponents";

import AddProductContainer from "./pages/AddProductContainer";

import MyBusinessCardContainer from "./pages/company/MyBusinessCardContainer";
import MyProductsContainer from "./pages/products/MyProductsContainer";

import { ErrorPopup } from "../../helpers/popup/ErrorPopup";
import Disclaimer from "../../helpers/disclaimer/Disclaimer";

const ApiLebaronGaleana = process.env.REACT_APP_LEBARON_GALEANA_HOST;

function MyBusinessDashboardPage() {
  const { company } = useSelector((state) => state.userDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const hasAddress = !!company && !!company?.address;

  return (
    <NavigationComponents>
      {!!company ? (
        <div className="myCompanyPage">
          {!hasAddress && (
            <ErrorPopup
              message="Please register an address for your company"
              link={{
                url: "/mybusiness/adress/created",
                text: "Register address",
              }}
            />
          )}

          <MyBusinessCardContainer company={company} />
          <AddProductContainer companyId={company?._id} />
          <MyProductsContainer company={company} />
        </div>
      ) : (
        <div className="noBusinessPage">
          <div className="titleContainer">
            <div className="title">You currently have not added a business</div>
          </div>

          <div className="createBusinessBtnContainer">
            <Link className="createBusinessBtn" to={"/business/createdCompany"}>
              Add Business to GaleanaBiz
            </Link>
          </div>
        </div>
      )}

      <Disclaimer
        text={`This is a website for businesses only, not individual people that
                just want to sell a personal product; for that you can go to our
                classifieds to publish and sell your personal product as a person at: `}
        link={`${ApiLebaronGaleana}/dashboard/classifieds`}
        textLink={`Lebarón Galeana Classifieds`}
        textButton={"Ok"}
      />
    </NavigationComponents>
  );
}

export default MyBusinessDashboardPage;
