import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

// Style
import "../../styles/pages/products/ProductPage.scss";

// Components
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { SearchFailedMessage } from "../../components/Message";

import { useGetProductsQuery } from "../../services/galeanaBizApi";
import { PRODUCTS_FILTERS_UPDATE } from "../../constants/productsConstans";
import ProductCard_V2 from "../../components/CompanyAndProductCards/ProductCard_V2";

import ProductsCard from "../../components/CompanyAndProductCards/ProductsCard";

const ProductsPage = ({ statusUser, company }) => {
  const [products, setProducts] = useState([]);
  const [productsPinned, setProductsPinned] = useState([]);

  const { user } = useSelector((state) => state.userDetails);
  const { page, search, category, order, sortBy, priceRange } = useSelector(
    (state) => state.productsFilters
  );
  const { data, error, isFetching, isLoading, originalArgs } =
    useGetProductsQuery({
      companyId: company._id,
      subscriptionStatus: user?.subscriptionStatus?.active,
      page,
      search,
      category,
      order,
      sortBy,
      priceRange,
    });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setProductsPinned(data?.porducts_pinned);

    if (data.current_page === 1) {
      setProducts([...data.products]);
    } else {
      setProducts((previousProducts) => [
        ...previousProducts,
        ...data.products,
      ]);
    }
  }, [search, data, dispatch, isFetching, category, order, sortBy, priceRange]);

  useEffect(() => {
    return () => {
      dispatch({
        type: PRODUCTS_FILTERS_UPDATE,
        payload: {
          page: 1,
          search: "",
          category: 0,
          priceRange: "",
          order: "desc",
          sortBy: "date_created",
        },
      });
    };
  }, [dispatch]);

  const Products = () => {
    if (!products) {
      return;
    }

    if (products.length === 0 && !isLoading && !isFetching) {
      return <p>No products found.</p>;
    }

    if (isLoading || (isFetching && originalArgs?.page === 1)) {
      return <Loader />;
    }

    if (error) {
      return <Message>{error}</Message>;
    }

    return products.map((product) => (
      <Fragment key={`product-${product.id}`}>
        <ProductCard_V2 products={product} />
      </Fragment>
    ));
  };

  const ProductsPinned = () => {
    if (!productsPinned) {
      return;
    }

    if (isLoading || isFetching) {
      <Loader />;
    }

    if (error) {
      return <Message>{error}</Message>;
    }

    return productsPinned.map((product) => {
      return (
        <Fragment key={`product-${product.id}`}>
          <ProductsCard products={product} />
        </Fragment>
      );
    });
  };

  const MsjSubscriptionPorduct = () => {
    if (statusUser === true) {
      return (
        <>
          {company?.subscription_product && (
            <Link className="textMsj">
              Contains Lebaron-Galeana member only products.
            </Link>
          )}
        </>
      );
    }

    return (
      <>
        {company?.subscription_product && (
          <Link to={`/login`} className="textMsj">
            Contains private products.
          </Link>
        )}
      </>
    );
  };

  if (search && products.length === 0) {
    return <SearchFailedMessage />;
  }

  return (
    <div className="productsPage">
      <div className="hasMemberOnlyProducts">
        <div className="contentMsj">
          <MsjSubscriptionPorduct />
        </div>
      </div>

      <div className="productPinnedCardsContainer">
        <div className="productPinnedCardsWrapper">
          <ProductsPinned />
        </div>
      </div>

      <div className="productCardsContainer">
        <div className="productCardsWrapper">
          <Products />
        </div>
      </div>

      <LoadMoreButton
        data={data}
        isLoading={isLoading}
        isFetching={isFetching}
        originalArgs={originalArgs}
        page={page}
        dispatch={dispatch}
      />
    </div>
  );
};

export default ProductsPage;

const LoadMoreButton = ({
  data,
  isLoading,
  isFetching,
  originalArgs,
  page,
  dispatch,
}) => {
  if (page === data?.total_page || isLoading || 0) return null;
  return (
    <div className="loadMoreContent">
      {isFetching && originalArgs?.page !== 1 ? (
        <Loader />
      ) : (
        <button
          className="loadMoreButton"
          disabled={isFetching && originalArgs?.page !== 1}
          onClick={() =>
            dispatch({
              type: PRODUCTS_FILTERS_UPDATE,
              payload: { page: page + 1 },
            })
          }
        >
          Load More
        </button>
      )}
    </div>
  );
};
