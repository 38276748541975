import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  AdressCompanyAction,
  UpdateAdressCompanyAction,
} from "../../../actions/adress/adressCompanyActions";
import { ADRESS_COMPANY_PUT_RESET } from "../../../constants/adressConstans";
import NavigationComponents from "../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

import "../../../styles/pages/adress/companyadress/CompanyPostAdress.scss";
import PhoneInput from "./components/phoneaddress/PhoneInput";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";

const CompanyPutAdress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = `/mybusiness/customization?label=${encodeURIComponent(
    "address"
  )}`;

  useEffect(() => {
    dispatch(AdressCompanyAction());
  }, [dispatch]);

  const updateAdressCompany = useSelector((state) => state.AdressCompnayPut);
  const { loading, error, success } = updateAdressCompany;

  const adressMyCompany = useSelector((state) => state.AdressCompanyGet);
  const {
    loading: getLoading,
    error: getError,
    AdressCompany,
  } = adressMyCompany;

  const [valueAddress, setValueAddress] = useState({
    neighborhood: "",
    exteriorNumber: null,
    interiorNumber: null,
    street_1: null,
    street_2: null,
    selectedCountryCode: "",
    telephoneNumber: "",
    reference: null,
  });

  const combinedPhoneNumber =
    valueAddress.selectedCountryCode + valueAddress.telephoneNumber;

  const onSubmit = (e) => {
    e.preventDefault();

    const adress = {
      adress_id: AdressCompany?.id,
      neighborhood: valueAddress.neighborhood,
      exterior_number: valueAddress.exteriorNumber,
      interior_number: valueAddress.interiorNumber,
      street_1: valueAddress.street_1,
      street_2: valueAddress.street_2,
      telephone_number: combinedPhoneNumber,
      additional_instructions: valueAddress.reference,
    };

    dispatch(UpdateAdressCompanyAction(adress));
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: ADRESS_COMPANY_PUT_RESET });

      if (path) return navigate(path, { replace: true });
    } else {
      if (AdressCompany) {
        setValueAddress({
          neighborhood: AdressCompany?.neighborhood,
          exteriorNumber: AdressCompany?.exterior_number,
          interiorNumber: AdressCompany?.interior_number,
          street_1: AdressCompany?.street_1,
          street_2: AdressCompany?.street_2,
          reference: AdressCompany?.additional_instructions,
        });
      }
    }
  }, [dispatch, navigate, AdressCompany, success]);

  return (
    <NavigationComponents>
      <FormUpdateAddress
        onSubmit={onSubmit}
        phone={AdressCompany?.telephone_number}
        value={valueAddress}
        setValue={setValueAddress}
        path={path}
        loading={loading || getLoading}
        error={error || getError}
      />
    </NavigationComponents>
  );
};
export default CompanyPutAdress;

const FormUpdateAddress = ({
  phone,
  value,
  setValue,
  onSubmit,
  path,
  loading,
  error,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;

    setValue((prev) => ({ ...prev, [name]: value }));
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message error={error}>{error}</Message>;
  }
  return (
    <form className="formPostAdressCompany" onSubmit={onSubmit}>
      <div className="inputContainer">
        <label>Location</label>
        <select
          type="text"
          name="neighborhood"
          value={value?.neighborhood}
          onChange={(e) => onChange(e)}
        >
          <option value="">Select Location</option>
          <option value="Galeana">Galeana</option>
          <option value="Lagunitas">Lagunitas</option>
          <option value="Lebaron">Colonia Lebaron</option>
          <option value="Angostura">Angostura</option>
          <option value="San Jeronimo">San Jeronimo</option>
        </select>
      </div>
      <div className="inputContainer">
        <label>Exterior Number</label>
        <input
          type="text"
          name="exteriorNumber"
          value={value?.exteriorNumber}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="inputContainer">
        <label>Interior Number</label>
        <input
          type="text"
          name="interiorNumber"
          value={value?.interiorNumber}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="inputContainer">
        <label>Street 1</label>
        <input
          type="text"
          name="street_1"
          value={value?.street_1}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div className="inputContainer">
        <label>Street 2</label>
        <input
          type="text"
          name="street_2"
          value={value?.street_2}
          onChange={(e) => onChange(e)}
        />
      </div>

      <PhoneInput setValue={setValue} phone={phone} />

      <div className="inputContainer">
        <label>Reference</label>
        <textarea
          type="text"
          name="reference"
          value={value?.reference}
          onChange={(e) => onChange(e)}
        />
      </div>

      <div className="buttonsContainer">
        <Link to={path} className="cancelBtnContainer">
          <button>Cancel</button>
        </Link>

        <div className="saveBtnContainer">
          <button type="submit">Save</button>
        </div>
      </div>
    </form>
  );
};
