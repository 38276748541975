import React from "react";
import "../../styles/components/CompanyAndProductCards/CompanyCard.scss";

function CompanyCardSkeleton() {
  return (
    <div className="companyCardSkeletonContainer">
      <div className="companyCard_v2Skeleton">
        <div className="infoContainer">
          <div className="adjustForPadding">
            <div className="companyName"></div>
            <div className="companyLocation"></div>
            <div className="bottomContainer">
              <div className="reviewsContainer">
                <div className="starReviews"></div>
                <div className="numReviewsText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyCardSkeleton;
