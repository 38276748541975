import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getSocialNetworkActions } from "../../../../actions/socialNetworkActions";

// Images
import TikTokLogo from "../../../../images/TikTok.png";

// Styles
import "../../../../styles/components/CompanyComponents/BusinessSocialMedias.scss";

// Components
import Loader from "../../../../components/Loader";

function BusinessSocialMedias({ companyId }) {
  const dispatch = useDispatch();

  const SocialGet = useSelector((state) => state.GetSocial);
  const {
    loading: SocialLoading,
    error: SocialError,
    companySocialNetwork,
  } = SocialGet;

  useEffect(() => {
    dispatch(getSocialNetworkActions(companyId));
  }, [dispatch]);

  // const modifiedURL = originalURL.replace("https://www.", "");

  return (
    <div className="businessSocialMedias">
      {SocialLoading ? (
        <Loader textSize={18} />
      ) : SocialError ? (
        "error"
      ) : (
        <>
          {Object.values(companySocialNetwork || []).map((social) => {
            return (
              <>
                {social?.facebook_url ? (
                  <Link
                    target="_blank"
                    to={social.facebook_url}
                    className="socialMediaLink"
                  >
                    <i className="fab fa-square-facebook"></i>
                  </Link>
                ) : null}
                {social?.instagram_url ? (
                  <Link
                    target="_blank"
                    to={social.instagram_url}
                    className="socialMediaLink"
                  >
                    <i className="fab fa-instagram"></i>
                  </Link>
                ) : null}
                {social?.tiktok_url ? (
                  <Link
                    target="_blank"
                    to={social.tiktok_url}
                    className="socialMediaLink"
                  >
                    <img
                      src={TikTokLogo}
                      className="tiktokLogo"
                      draggable="false"
                    />
                  </Link>
                ) : null}
                {social?.twiter_url ? (
                  <Link
                    target="_blank"
                    to={social.twiter_url}
                    className="socialMediaLink"
                  >
                    <i className="fab fa-twitter"></i>
                  </Link>
                ) : null}
                {social?.youtube_url ? (
                  <Link
                    target="_blank"
                    to={social.youtube_url}
                    className="socialMediaLink"
                  >
                    <i className="fab fa-youtube"></i>
                  </Link>
                ) : null}
              </>
            );
          })}
        </>
      )}
    </div>
  );
}

export default BusinessSocialMedias;
