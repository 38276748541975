// Carrito Local (frontend)
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_ADD_ITEM_LOCAL_SUCCESS = "CART_ADD_ITEM_LOCAL_SUCCESS";
export const CART_ADD_ITEM_LOCAL_RESET = "CART_ADD_ITEM_LOCAL_RESET";

export const CART_GET_TOTAL = "CART_GET_TOTAL";
export const CART_GET_ITEM_TOTAL = "CART_GET_ITEM_TOTAL";
export const CART_GET_ITEMS = "CART_GET_ITEMS";
export const CART_UPDATE_ITEM = "CART_UPDATE_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_EMPTY_CART = "CART_EMPTY_CART";

// Cart (base de datos)

//
export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
export const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS";
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";
export const CART_ADD_ITEM_RESET = "CART_ADD_ITEM_RESET";
//
export const CART_GET_TOTAL_REQUEST = "CART_GET_TOTAL_REQUEST";
export const CART_GET_TOTAL_SUCCESS = "CART_GET_TOTAL_SUCCESS";
export const CART_GET_TOTAL_FAIL = "CART_GET_TOTAL_FAIL";
export const CART_GET_TOTAL_RESET = "CART_GET_TOTAL_RESET";

//
export const CART_GET_ITEM_TOTAL_REQUEST = "CART_GET_ITEM_TOTAL_REQUEST";
export const CART_GET_ITEM_TOTAL_SUCCESS = "CART_GET_ITEM_TOTAL_SUCCESS";
export const CART_GET_ITEM_TOTAL_FAIL = "CART_GET_ITEM_TOTAL_FAIL";
//
export const CART_GET_ITEMS_REQUEST = "CART_GET_ITEMS_REQUEST";
export const CART_GET_ITEMS_SUCCESS = "CART_GET_ITEMS_SUCCESS";
export const CART_GET_ITEMS_FAIL = "CART_GET_ITEMS_FAIL";
//
export const CART_UPDATE_ITEM_REQUEST = "CART_UPDATE_ITEM_REQUEST";
export const CART_UPDATE_ITEM_SUCCESS = "CART_UPDATE_ITEM_SUCCESS";
export const CART_UPDATE_ITEM_FAIL = "CART_UPDATE_ITEM_FAIL";
export const CART_UPDATE_ITEM_RESET = "CART_UPDATE_ITEM_RESET";

//
export const CART_REMOVE_ITEM_REQUEST = "CART_REMOVE_ITEM_REQUEST";
export const CART_REMOVE_ITEM_SUCCESS = "CART_REMOVE_ITEM_SUCCESS";
export const CART_REMOVE_ITEM_FAIL = "CART_REMOVE_ITEM_FAIL";
export const CART_REMOVE_ITEM_RESET = "CART_REMOVE_ITEM_RESET";

//
export const CART_EMPTY_CART_REQUEST = "CART_EMPTY_CART_REQUEST";
export const CART_EMPTY_CART_SUCCESS = "CART_EMPTY_CART_SUCCESS";
export const CART_EMPTY_CART_FAIL = "CART_EMPTY_CART_FAIL";
//
export const CART_SYNCH_CART_REQUEST = "CART_SYNCH_CART_REQUEST";
export const CART_SYNCH_CART_SUCCESS = "CART_SYNCH_CART_SUCCESS";
export const CART_SYNCH_CART_FAIL = "CART_SYNCH_CART_FAIL";
