import React, { useEffect, useState } from "react";

import "../../styles/components/MyCompanyPageComponents/ImageUploader.scss";

import { ErrorPopup } from "../../helpers/popup/ErrorPopup";

import { imageCompression } from "../../helpers/compressor/imageCompression";

const MAX_IMAGES = 3;

const ImageUploader = ({ images, setImages }) => {
  const [dragActive, setDragActive] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [uploading, setUploading] = useState(false);

  const [errorPopupVisible, setErrorPopupVisible] = useState(false);
  const [error, setError] = useState("");

  const availableSlots = MAX_IMAGES - imageCount;

  useEffect(() => {
    setImageCount(images.length);
  }, [images]);

  const readImageAsFile = (file) => {
    return new Promise((resolve) => {
      resolve(file);
    });
  };

  const handleImageUpload = async (e) => {
    setUploading(true);
    setUploadProgress(0);

    try {
      const selectedImages = Array.from(e.target.files);
      const validImages = [];

      for (const image of selectedImages) {
        if (!image.type.startsWith("image/")) {
          setError(`The file "${image.name}" is not an image.`);
          setErrorPopupVisible(true);
          continue;
        }

        if (imageCount + validImages.length >= MAX_IMAGES) {
          setError(`The limit of allowed images has been reached.`);
          setErrorPopupVisible(true);
          continue;
        }

        validImages.push(image);
      }

      if (validImages.length > 0) {
        const compressedImages = await Promise.all(
          validImages.map(async (image) => {
            return imageCompression(image);
          })
        );

        const totalImages = compressedImages.length;
        let uploadedImages = 0;

        if (imageCount + totalImages <= MAX_IMAGES) {
          const uploadImages = await Promise.all(
            compressedImages.map(async (image) => {
              const imageUrl = await readImageAsFile(image);
              uploadedImages++;
              const progress = Math.round((uploadedImages / totalImages) * 100);
              setUploadProgress(progress);

              await new Promise((resolve) => setTimeout(resolve, 1000));
              return imageUrl;
            })
          );

          setImages([...images, ...uploadImages]);
          setImageCount(imageCount + totalImages);
        }
      } else {
        setError(`No valid images were selected.`);
        setErrorPopupVisible(true);
      }
    } catch (error) {
      setError("An error occurred during image upload.");
      setErrorPopupVisible(true);
    } finally {
      setUploading(false);
      setUploadProgress(0);
    }
  };

  const handleImageRemove = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    setImageCount(imageCount - 1);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => {
    setDragActive(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDragActive(false);

    try {
      setUploading(true);

      if (imageCount >= MAX_IMAGES) {
        setError(`The limit of allowed images has been reached`);
        setErrorPopupVisible(true);
        setUploading(false);
        return;
      }

      const droppedFiles = Array.from(e.dataTransfer.files);

      const validImages = droppedFiles.filter((file) => {
        return file.type.startsWith("image/");
      });

      if (validImages.length === 0) {
        setError(`No valid images were dropped.`);
        setErrorPopupVisible(true);
        setUploading(false);
        return;
      }

      const compressedImages = await Promise.all(
        validImages.map(async (image) => {
          return imageCompression(image);
        })
      );

      const totalImages = compressedImages.length;
      let uploadedImages = 0;

      const uploadImages = await Promise.all(
        compressedImages.map(async (image) => {
          const imageUrl = await readImageAsFile(image);
          uploadedImages++;

          const progress = Math.round((uploadedImages / totalImages) * 100);
          setUploadProgress(progress);

          await new Promise((resolve) => setTimeout(resolve, 1000));
          return imageUrl;
        })
      );

      setImages([...images, ...uploadImages]);
      setImageCount(imageCount + totalImages);
    } catch (error) {
      setError("An error occurred during image upload.");
      setErrorPopupVisible(true);
    } finally {
      setUploadProgress(0);
      setUploading(false);
    }
  };

  return (
    <div className="extraImagesUploader">
      <div className="sectionTitle">Add Extra Images Here</div>
      <div className="canAddNumMoreImagesTxt">
        You can add {availableSlots} more images
      </div>

      {errorPopupVisible && (
        <ErrorPopup
          message={error}
          onClose={() => setErrorPopupVisible(false)}
        />
      )}

      <label
        for="images"
        className={`addImagesContainer ${dragActive ? "drag-active" : ""}`}
        id="dropcontainer"
        onDragOver={uploading ? null : handleDragOver}
        onDragLeave={uploading ? null : handleDragLeave}
        onDrop={uploading ? null : handleDrop}
      >
        <div className="dropTitle">Drop files here</div>
        or
        <label htmlFor="fileInput" className="customUploadFilesBtn">
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            disabled={uploading || uploadProgress}
          />
          Select Images
        </label>
        <div className="bottomSection">
          {Object.values(images).map((image, index) => (
            <div className="imgWrapper" key={index}>
              <img
                className="img"
                id="images"
                src={image?.image || URL.createObjectURL(image)}
                alt={`Image ${index}`}
              />

              <button
                type="button"
                className="btnRemoveImg"
                onClick={() => handleImageRemove(index)}
              >
                Remove
              </button>
            </div>
          ))}

          {uploadProgress > 0 && uploadProgress <= 100 && (
            <div className="progressBarContainer">
              <div
                className="progressBar"
                style={{ width: `${uploadProgress}%` }}
              ></div>
              <div className="progressText">
                Uploading: {uploadProgress == 100 ? "99" : uploadProgress}%
              </div>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default ImageUploader;
