import React from "react";

import "../../../styles/components/navigation/FooterAndSidebars/FooterMediaQueries.scss";

import SmallSidebar from "./SmallSidebar";
import LargeSidebar from "./LargeSidebar";

function FooterOrSidebar({ currentPageUrl }) {
  return (
    <>
      <div className="smallSidebarContainer">
        <SmallSidebar currentPageUrl={currentPageUrl} />
      </div>
      <div className="largeSidebarContainer">
        <LargeSidebar currentPageUrl={currentPageUrl} />
      </div>
    </>
  );
}

export default FooterOrSidebar;
