import {
  ADRESS_USER_GET_REQUEST,
  ADRESS_USER_GET_SUCCESS,
  ADRESS_USER_GET_FAIL,
  //
  ADRESS_USER_ADD_REQUEST,
  ADRESS_USER_ADD_SUCCESS,
  ADRESS_USER_ADD_FAIL,
  ADRESS_USER_ADD_RESET,
  //
  ADRESS_USER_PUT_REQUEST,
  ADRESS_USER_PUT_SUCCESS,
  ADRESS_USER_PUT_FAIL,
  ADRESS_USER_PUT_RESET,
} from "../../constants/adressConstans";

export const UpdateUserAdress = (state = { UpdateUserAdress: [] }, action) => {
  switch (action.type) {
    case ADRESS_USER_PUT_REQUEST:
      return { loading: true, ...state };

    case ADRESS_USER_PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        UpdateCompanyAdress: action.payload,
      };

    case ADRESS_USER_PUT_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADRESS_USER_PUT_RESET:
      return {};

    default:
      return state;
  }
};

export const AddUserAdressReducer = (state = {}, action) => {
  switch (action.type) {
    case ADRESS_USER_ADD_REQUEST:
      return { loading: true };

    case ADRESS_USER_ADD_SUCCESS:
      return { loading: false, success: true };

    case ADRESS_USER_ADD_FAIL:
      return { loading: false, error: action.payload };

    case ADRESS_USER_ADD_RESET:
      return { ...state, success: false };

    default:
      return state;
  }
};

export const UserAddressReducers = (state = { AdressUser: null }, action) => {
  switch (action.type) {
    case ADRESS_USER_GET_REQUEST:
      return { loading: true };

    case ADRESS_USER_GET_SUCCESS:
      return { loading: false, AdressUser: action.payload };

    case ADRESS_USER_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
