import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// React Redux
import { useDispatch, useSelector } from "react-redux";
import { AddSocialActions } from "../../../../actions/socialNetworkActions";

// scss
import "../../../../styles/pages/CompanyPage/SocialMediaPage/PutSocial.scss";

// Components
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

const PostSocial = () => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const path = "/mybusiness/customization";

  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;

  const [facebook_url, setFacebook] = useState(null);
  const [instagram_url, setInstagram] = useState(null);
  const [youtube_url, setYoutube] = useState(null);
  const [twiter_url, setTwiter] = useState(null);
  const [tiktok_url, setTiktok] = useState(null);

  const PostSocial = useSelector((state) => state.PostSocial);
  const { loading, error, success } = PostSocial;

  useEffect(() => {
    if (success) {
      naviagate(path, { replace: true });
    }
  }, [success]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      AddSocialActions(
        userCompanyId,
        facebook_url,
        instagram_url,
        youtube_url,
        twiter_url,
        tiktok_url
      )
    );
  };

  {
    /*No elimar sirve para dectar que la url se correcta segun el campo*/
  }
  // const handleChange = (e) => {
  //   const inputValue = e.target.value;
  //   // Verificar si la URL contiene "facebook.com"
  //   if (inputValue.includes("facebook.com")) {
  //     setFacebook(inputValue);
  //   } else {
  //     if (inputValue.trim() !== "") {
  //       alert("URL no pertenece a Facebook");
  //     }
  //     setFacebook("");
  //   }
  // };

  return (
    <NavigationComponents>
      <form onSubmit={onSubmit} className="putSocial">
        <div className="inputContainer">
          <label>
            Facebook
            <input
              type="url"
              value={facebook_url}
              placeholder="https://www.facebook.com/username"
              onChange={(e) => setFacebook(e.target.value)}
            />
          </label>
        </div>
        <div className="inputContainer">
          <label>
            Instagram
            <input
              type="url"
              value={instagram_url}
              placeholder="https://www.instagram.com/account"
              onChange={(e) => setInstagram(e.target.value)}
            />
          </label>
        </div>
        <div className="inputContainer">
          <label>
            YouTube
            <input
              type="url"
              value={youtube_url}
              placeholder="https://www.youtube.com/@ChannelName"
              onChange={(e) => setYoutube(e.target.value)}
            />
          </label>
        </div>

        <div className="inputContainer">
          <label>
            Twitter
            <input
              type="url"
              value={twiter_url}
              placeholder="https://twitter.com/username"
              onChange={(e) => setTwiter(e.target.value)}
            />
          </label>
        </div>

        <div className="inputContainer">
          <label>
            TikTok
            <input
              type="url"
              value={tiktok_url}
              placeholder="https://www.tiktok.com/@username"
              onChange={(e) => setTiktok(e.target.value)}
            />
          </label>
        </div>

        <div className="saveBtnContainer">
          <button type="submit">Save</button>
        </div>
      </form>
    </NavigationComponents>
  );
};

export default PostSocial;
