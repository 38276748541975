import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCTS_FILTERS_UPDATE } from "../../constants/productsConstans";

const FilterPanel = ({ categoryId, setCategoryId }) => {
  const dispatch = useDispatch();

  const [priceRange, setPriceRange] = useState("");
  const [sortBy, setSortBy] = useState("date_create");
  const [order, setOrder] = useState("desc");

  const [showFilter, setShowFilter] = useState("");

  const [showCategories, setShowCategories] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  const { categoriesInUse } = useSelector(
    (state) => state.ProductCategoriesInUse
  );

  const filterProducts = (e) => {
    e.preventDefault();
    dispatch({
      type: PRODUCTS_FILTERS_UPDATE,
      payload: {
        category: categoryId,
        sortBy,
        order,
        priceRange,
        page: 1,
      },
    });
  };

  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

  const toggleSortBy = () => {
    setShowSortBy(!showSortBy);
  };

  const toggleOrder = () => {
    setShowOrder(!showOrder);
  };

  const togglePrice = () => {
    setShowPrice(!showPrice);
  };
  return (
    <>
      {" "}
      <div className="filterDropdown">
        <button
          className="mainButton"
          onClick={
            showFilter === "categories"
              ? () => setShowFilter("")
              : () => setShowFilter("categories")
          }
        >
          Categories <i className="fa-solid fa-angle-down"></i>
        </button>

        {/* {showCategories && (
          <div className="filterOptionsContainer leftFilterOptionsContainer">
            <form
              className="filterOptionsForm"
              onSubmit={(e) => filterProducts(e)}
            >
              <button
                onClick={() => setCategoryId(0)}
                className={categoryId === 0 ? "active" : ""}
                type="submit"
              >
                All
              </button>

              {Object.values(categoriesInUse).map((categories) => {
                return (
                  <button
                    key={`filter-${categories.id}`}
                    onClick={(e) => setCategoryId(Number(e.target.value))}
                    value={categories.id}
                    className={categoryId === categories.id ? "active" : ""}
                    type="submit"
                  >
                    {categories.name}
                  </button>
                );
              })}
            </form>
          </div>
        )} */}

        {showFilter === "categories" ? (
          <div className="filterOptionsContainer leftFilterOptionsContainer">
            <form
              className="filterOptionsForm"
              onSubmit={(e) => filterProducts(e)}
            >
              <button
                onClick={() => setCategoryId(0)}
                className={categoryId === 0 ? "active" : ""}
                type="submit"
              >
                All
              </button>

              {Object.values(categoriesInUse).map((categories) => {
                return (
                  <button
                    key={`filter-${categories.id}`}
                    onClick={(e) => setCategoryId(Number(e.target.value))}
                    value={categories.id}
                    className={categoryId === categories.id ? "active" : ""}
                    type="submit"
                  >
                    {categories.name}
                  </button>
                );
              })}
            </form>
          </div>
        ) : null}
      </div>
      <div className="filterDropdown">
        <button
          className="mainButton"
          onClick={
            showFilter === "sortBy"
              ? () => setShowFilter("")
              : () => setShowFilter("sortBy")
          }
        >
          Sort By <i className="fa-solid fa-angle-down"></i>
        </button>

        {/* Aquí se muestra o se oculta la lista de categorías según el estado showCategories */}
        {showFilter === "sortBy" ? (
          <div className="filterOptionsContainer">
            <form
              className="filterOptionsForm"
              onSubmit={(e) => filterProducts(e)}
            >
              <button
                onClick={(e) => setSortBy(e.target.value)}
                className={sortBy === "date_create" ? "active" : ""}
                value="date_create"
                type="submit"
              >
                New to Old
              </button>
              <button
                onClick={(e) => setSortBy(e.target.value)}
                className={sortBy === "price" ? "active" : ""}
                value="price"
                type="submit"
              >
                $ - $$$
              </button>
              <button
                onClick={(e) => setSortBy(e.target.value)}
                className={sortBy === "name" ? "active" : ""}
                value="name"
                type="submit"
              >
                A - Z
              </button>
            </form>
          </div>
        ) : null}
      </div>
      <div className="filterDropdown">
        <button
          className="mainButton"
          onClick={
            showFilter === "order"
              ? () => setShowFilter("")
              : () => setShowFilter("order")
          }
        >
          Order <i className="fa-solid fa-angle-down"></i>
        </button>

        {/* Aquí se muestra o se oculta la lista de categorías según el estado showCategories */}
        {showFilter === "order" ? (
          <div className="filterOptionsContainer">
            <form
              className="filterOptionsForm"
              onSubmit={(e) => filterProducts(e)}
            >
              <button
                onClick={(e) => setOrder(e.target.value)}
                className={order === "asc" ? "active" : ""}
                value="asc"
                type="submit"
              >
                Bottom to Top
              </button>

              <button
                onClick={(e) => setOrder(e.target.value)}
                className={order === "desc" ? "active" : ""}
                value="desc"
                type="submit"
              >
                Top to Bottom
              </button>
            </form>
          </div>
        ) : null}
      </div>
      <div className="filterDropdown">
        <button
          className="mainButton"
          onClick={
            showFilter === "price"
              ? () => setShowFilter("")
              : () => setShowFilter("price")
          }
        >
          Price <i className="fa-solid fa-angle-down"></i>
        </button>

        {/* Aquí se muestra o se oculta la lista de categorías según el estado showCategories */}
        {showFilter === "price" ? (
          <div className="filterOptionsContainer rightFilterOptionsContainer">
            <form
              className="filterOptionsForm"
              onSubmit={(e) => filterProducts(e)}
            >
              <button
                onClick={(e) => setPriceRange(e.target.value)}
                className={priceRange === "" ? "active" : ""}
                value=""
                type="submit"
              >
                Any Price
              </button>
              <button
                onClick={(e) => setPriceRange(e.target.value)}
                className={priceRange === "1 - 49" ? "active" : ""}
                value="1 - 49"
                type="submit"
              >
                $1 to $49
              </button>
              <button
                onClick={(e) => setPriceRange(e.target.value)}
                className={priceRange === "50 - 99" ? "active" : ""}
                value="50 - 99"
                type="submit"
              >
                $50 to $99
              </button>
              <button
                onClick={(e) => setPriceRange(e.target.value)}
                className={priceRange === "100 - 499" ? "active" : ""}
                value="100 - 499"
                type="submit"
              >
                $100 to $499
              </button>
              <button
                onClick={(e) => setPriceRange(e.target.value)}
                className={priceRange === "500 - 999" ? "active" : ""}
                value="500 - 999"
                type="submit"
              >
                $500 to $999
              </button>{" "}
              <button
                onClick={(e) => setPriceRange(e.target.value)}
                className={priceRange === "More than 1000" ? "active" : ""}
                value="More than 1000"
                type="submit"
              >
                More than $1000
              </button>
            </form>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default FilterPanel;
