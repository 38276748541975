///////////////ORDER USER/////////////////////////////////////////////////////////
export const ORDERS_USER_GET_REQUEST = "ORDERS_USER_GET_REQUEST";
export const ORDERS_USER_GET_SUCCESS = "ORDERS_USER_GET_SUCCESS";
export const ORDERS_USER_GET_FAIL = "ORDERS_USER_GET_FAIL";

export const ORDERS_COMPANY_ADD_REQUEST = "ORDERS_COMPANY_ADD_REQUEST";
export const ORDERS_COMPANY_ADD_SUCCESS = "ORDERS_COMPANY_ADD_SUCCESS";
export const ORDERS_COMPANY_ADD_FAIL = "ORDERS_COMPANY_ADD_FAIL";

////////////ORDER COMPANY///////////////////////////////////////////////////////////

export const ORDERS_COMPANY_GET_REQUEST = "ORDERS_COMPANY_GET_REQUEST";
export const ORDERS_COMPANY_GET_SUCCESS = "ORDERS_COMPANY_GET_SUCCESS";
export const ORDERS_COMPANY_GET_FAIL = "ORDERS_COMPANY_GET_FAIL";

//ORDER STATUS

export const ORDERS_STATUS_REQUEST = "ORDERS_STATUS_REQUEST";
export const ORDERS_STATUS_SUCCESS = "ORDERS_STATUS_SUCCESS";
export const ORDERS_STATUS_FAIL = "ORDERS_STATUS_FAIL";

//KEYWORDS

export const ORDER_GENERATE_WORDS_REQUEST = "ORDER_GENERATE_WORDS_REQUEST";
export const ORDER_GENERATE_WORDS_SUCCESS = "ORDER_GENERATE_WORDS_SUCCESS";
export const ORDER_GENERATE_WORDS_FAIL = "ORDER_GENERATE_WORDS_FAIL";
export const ORDER_GENERATE_WORDS_RESET = "ORDER_GENERATE_WORDS_RESET";

//DECLINED ORDER

export const ORDER_DECLINED_REQUEST = "ORDER_DECLINED_REQUEST";
export const ORDER_DECLINED_SUCCESS = "ORDER_DECLINED_SUCCESS";
export const ORDER_DECLINED_FAILURE = "ORDER_DECLINED_FAILURE";
export const ORDER_DECLINED_RESET = "ORDER_DECLINED_RESET";
