import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getFavorites,
  incrementPageLimit,
  removeFavorite,
} from "../../actions/favoritesActions";

// scss
import "../../styles/pages/FavoritesPage.scss";

// Components
import AddToAllPages from "../../components/AddToAllPages";
import CompanyCard from "../../components/CompanyAndProductCards/CompanyCard";
import NoAccountBanner from "../../components/authentication/NoAccountBanner";
import { FAVORITES_PAGE_SIZE } from "../../constants/favoritesConstants";
import {
  useDeleteFavoritePorductMutation,
  useGetFavoriteProductsQuery,
} from "../../services/galeanaBizApi";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import ProductCard_V2 from "../../components/CompanyAndProductCards/ProductCard_V2";
import axios from "axios";

function FavoritesPage() {
  const [currentPage, setCurrentPage] = useState("companies");

  const userLogin = useSelector((state) => state.userLogin);
  const { userToken } = userLogin;

  const pages = () => {
    if (currentPage === "companies") {
      return <FavoriteCompanies />;
    }

    return <FavoriteProducts />;
  };

  const buttonPage = [
    { status: "companies", label: "Business" },
    { status: "", label: "Products" },
  ];

  return (
    <AddToAllPages>
      {userToken ? (
        <div className="favoritesPage">
          <div className="navBar">
            {buttonPage.map((btn, index) => (
              <button
                key={index}
                className={`navbarBtn ${
                  currentPage == btn.status && "navbarBtn__active"
                } `}
                onClick={() => setCurrentPage(btn.status)}
              >
                {btn.label}
              </button>
            ))}
          </div>
          {pages()}
        </div>
      ) : (
        <NoAccountBanner />
      )}
    </AddToAllPages>
  );
}

export default FavoritesPage;

const FavoriteProducts = () => {
  const [favoriteProducts, setFavoritePorducts] = useState([]);
  const [nextPage, setNextPage] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));
  const user_id = user.user.id;

  const { data, isFetching, isLoading, isError, error } =
    useGetFavoriteProductsQuery({ user_id });

  const [deleteFavoriteProduct, deleteFavoriteProductResult] =
    useDeleteFavoritePorductMutation();

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setFavoritePorducts(data.products_favorite.results);
    setNextPage(data.next);
  }, [data, isFetching]);

  const deleteFavorite = (product_id) => {
    deleteFavoriteProduct({ user_id: user_id, product_id: product_id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }
      }
    );
  };

  const loadMoreFavorites = async () => {
    if (nextPage) {
      try {
        const response = await axios.get(nextPage);

        if (response.data.results && Array.isArray(response.data.results)) {
          setFavoritePorducts((prevFavorite) => [
            ...prevFavorite,
            ...response.data.results,
          ]);
          setNextPage(response.data.next);
        }
      } catch (error) {
        const errorMessage =
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message;

        return errorMessage;
      }
    }
  };

  if (isLoading || deleteFavoriteProductResult.isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Message error={isError || deleteFavoriteProductResult.error}>
        {error || deleteFavoriteProductResult.error}
      </Message>
    );
  }
  return (
    <>
      {data.count === 0 ? (
        <div className="pageContent">
          <div className="title">You have 0 favorites</div>
        </div>
      ) : (
        <>
          <div className="pageContent">
            <div className="title">
              {data.count === 0
                ? `You have 0 favorite products`
                : data.count === 1 && `You have 1 favorite product`}
            </div>

            <div className="companyCardsSection">
              <div className="companyCardsContainer">
                <div className="productCardsWrapper">
                  {favoriteProducts.map((favorite, index) => (
                    <div
                      key={favorite?.id}
                      className="productCardAndBtnContainer"
                    >
                      <ProductCard_V2
                        products={favorite?.product}
                        key={index}
                      />
                      <button
                        onClick={() => deleteFavorite(favorite?.product?.id)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="loadMoreBtnContainer">
            {!!nextPage && (
              <div className="loadMoreBtn" onClick={loadMoreFavorites}>
                Load more
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

const FavoriteCompanies = () => {
  const dispatch = useDispatch();

  const favorites = useSelector((state) => state.favorites);
  const favoritesToShow = favorites.entries.slice(
    0,
    favorites.pageLimit * FAVORITES_PAGE_SIZE
  );

  const loadMoreFavorites = () => {
    if (favorites.pageLimit * FAVORITES_PAGE_SIZE < favorites.entries.length) {
      dispatch(incrementPageLimit());
      return;
    }

    dispatch(getFavorites(favorites.next, false, true));
  };
  return (
    <>
      {favorites.entries.length === 0 ? (
        <div className="pageContent">
          <div className="title">You have 0 favorites</div>
        </div>
      ) : (
        <>
          <div className="pageContent">
            <div className="title">
              {favorites?.entries?.length === 0
                ? `You have 0 favorite businesses`
                : favoritesToShow?.length === 1
                ? `You have 1 favorite business`
                : `You have ${favorites?.entries?.length} favorite businesses`}
            </div>

            <div className="companyCardsSection">
              <div className="companyCardsContainer">
                <div className="companyCardsWrapper">
                  {favoritesToShow.map((favorite, index) => (
                    <div
                      key={favorite?.id}
                      className="companyCardAndBtnContainer"
                    >
                      <CompanyCard company={favorite?.company} key={index} />
                      <button
                        onClick={() =>
                          dispatch(removeFavorite(favorite?.company._id))
                        }
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="loadMoreBtnContainer">
            {(!!favorites.next ||
              favorites?.pageLimit * FAVORITES_PAGE_SIZE <
                favorites?.entries?.length) && (
              <div className="loadMoreBtn" onClick={loadMoreFavorites}>
                Load more
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
