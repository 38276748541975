import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AboutListAction } from "../../../../actions/aboutActions";

// scss
import "../../../../styles/pages/CompanyPage/AboutCompany.scss";

// Components
import Loader from "../../../../components/Loader";

const CompanyAbout = ({ companyId }) => {
  const dispatch = useDispatch();

  const ListAbout = useSelector((state) => state.AboutList);
  const { loading, error, CompanyAbout } = ListAbout;

  useEffect(() => {
    dispatch(AboutListAction(companyId));
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <>
          <Loader textSize={26} />
        </>
      ) : error ? (
        <>
          <h3>Error Loading</h3>
        </>
      ) : (
        <div className="aboutBusiness aboutBusiness_onBusinessPage">
          <div className="companyAbout">
            <div className="titleContainer"></div>

            {Object.keys(CompanyAbout).length === 0 ? (
              <div className="aboutDoesntExistTxt">
                Business doesnt have an about page
              </div>
            ) : (
              <>
                {Object.values(CompanyAbout || []).map((about) => {
                  return (
                    <div className="companyAboutContainer" key={about.id}>
                      <div className="imgContainer">
                        <img
                          className="aboutUsImage"
                          src={about?.image}
                          alt="about-img"
                          draggable="false"
                        />
                      </div>
                      <div className="companyAboutInfo">
                        <span className="title">{about?.title}</span>
                        <p>{about?.description}</p>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyAbout;
