export const COMPANIES_FILTERS_UPDATE = "COMPANIES_FILTERS_UPDATE";

export const COMPANIES_OPENIG_HOURS_UPDATE = "COMPANIES_OPENIG_HOURS_UPDATE";

export const COMPANY_DETAILS_REQUEST = "COMPANY_DETAILS_REQUEST";
export const COMPANY_DETAILS_SUCCESS = "COMPANY_DETAILS_SUCCESS";
export const COMPANY_DETAILS_FAIL = "COMPANY_DETAILS_FAIL";
export const COMPANY_DETAILS_RESET = "COMPANY_DETAILS_RESET";

export const COMPANY_CREATE_REQUEST = "COMPANY_CREATE_REQUEST";
export const COMPANY_CREATE_SUCCESS = "COMPANY_CREATE_SUCCESS";
export const COMPANY_CREATE_FAIL = "COMPANY_CREATE_FAIL";
export const COMPANY_CREATE_RESET = "COMPANY_CREATE_RESET";

export const COMPANY_UPDATE_REQUEST = "COMPANY_UPDATE_REQUEST";
export const COMPANY_UPDATE_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_UPDATE_FAIL = "COMPANY_UPDATE_FAIL";
export const COMPANY_UPDATE_RESET = "COMPANY_UPDATE_RESET";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCES = "CATEGORY_LIST_SUCCES";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const CATEGORY_DETAILS_REQUEST = "CATEGORY_DETAILS_REQUEST";
export const CATEGORY_DETAILS_SUCCESS = "CATEGORY_DETAILS_SUCCESS";
export const CATEGORY_DETAILS_FAIL = "CATEGORY_DETAILS_FAIL";
export const CATEGORY_DETAILS_RESET = "CATEGORY_DETAILS_RESET";

export const COMPANY_GET_REVIEW_REQUEST = "COMPANY_GET_REVIEW_REQUEST";
export const COMPANY_GET_REVIEW_SUCCCESS = "COMPANY_GET_REVIEW_SUCCCESS";
export const COMPANY_GET_REVIEW_FAIL = "COMPANY_GET_REVIEW_FAIL";
export const COMPANY_GET_REVIEW_RESET = "COMPANY_GET_REVIEW_RESET";

export const COMPANY_REVIEW_REQUEST = "COMPANY_REVIEW_REQUEST";
export const COMPANY_REVIEW_SUCCCESS = "COMPANY_REVIEW_SUCCCESS";
export const COMPANY_REVIEW_FAIL = "COMPANY_REVIEW_FAIL";
export const COMPANY_REVIEW_RESET = "COMPANY_REVIEW_RESET";

export const COMPANY_REVIEW_UPDATE_REQUEST = "COMPANY_REVIEW_UPDATE_REQUEST";
export const COMPANY_REVIEW_UPDATE_SUCCCESS = "COMPANY_REVIEW_UPDATE_SUCCCESS";
export const COMPANY_REVIEW_UPDATE_FAIL = "COMPANY_REVIEW_UPDATE_FAIL";
export const COMPANY_REVIEW_UPDATE_RESET = "COMPANY_REVIEW_UPDATE_RESET";
