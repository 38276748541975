import {useSelector} from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const PrivRouteCompany = () => {
  const userDetails = useSelector(state => state.userDetails)

  if (!('company' in userDetails)) {
    return null;
  }

  return userDetails.company ? <Outlet /> : <Navigate to="/mybusiness" />;
};

export default PrivRouteCompany;
