import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import AddToAllPages from "../components/AddToAllPages";
import "../styles/pages/SponsorPage.scss";

export default function SponsorPage() {
  const { user } = useSelector((state) => state.userDetails);
  const host = process.env.REACT_APP_API_URL_GALEANA_BIZ;
  const { company } = useSelector((state) => state.userDetails);

  const [submitting, setSubmitting] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const [values, setValues] = useState({
    company: company?._id,
    phone:
      company?.address?.telephone_number?.length === 10
        ? "+52" + company?.address?.telephone_number
        : company?.address?.telephone_number,
    email: "",
    banner_image: undefined,
  });
  const [errors, setErrors] = useState({
    phone: "",
    email: "",
    general: "",
    banner_image: "",
  });

  const submit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append("user_id", user.user.id);
    formData.append("company", values.company);
    formData.append("phone", values.phone);
    formData.append("email", values.email);
    formData.append("banner_image", values.banner_image);

    axios
      .post(`${host}/api/companies/sponsor/`, formData)
      .then(() => setSubmittedSuccessfully(true))
      .catch((error) => {
        if (error.response?.data) {
          const errors = error.response.data;
          const errorMessages = {};

          if (Array.isArray(errors.email)) {
            errorMessages.email = errors.email[0];
          }

          if (Array.isArray(errors.phone)) {
            errorMessages.phone = errors.phone[0];
          }

          if (Object.keys(errorMessages).length > 0) {
            setErrors(errorMessages);
            return;
          }
        }

        setErrors({ general: "Something went wrong, please try again later." });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <AddToAllPages>
      <div className="sponsor-company">
        <Description
          company={company}
          errors={errors}
          submitting={submitting}
          submittedSuccessfully={submittedSuccessfully}
          values={values}
          setValues={setValues}
          submit={submit}
        />
      </div>
    </AddToAllPages>
  );
}

function Description({
  company,
  errors,
  submitting,
  submittedSuccessfully,
  values,
  setValues,
  submit,
}) {
  if (!company) {
    return null;
  }

  if (company.sponsor_form_status === "pending approval") {
    return (
      <>
        <p className="title">You've already applied for a feature</p>
        <div className="success">
          <i className="fa-solid fa-circle-check fa-5x"></i>
          <p className="description">
            We're reviewing your request, we'll be in contact with you shortly.
          </p>
          <p className="description">
            If you've got any more questions, feel free to contact{" "}
            <a href="tel:+18018353995">A.D. Lebaron</a>
          </p>
        </div>
      </>
    );
  }

  if (company.sponsor_form_status === "approved") {
    return (
      <>
        <p className="title">You're an approved sponsor.</p>
        <div className="success">
          <i className="fa-solid fa-circle-check fa-5x"></i>
          <p className="description">
            Thank you for the help, you should see your banner in the category
            page for your companies category.
          </p>
          <p className="description">
            If you've got any questions, feel free to contact{" "}
            <a href="tel:+18018353995">A.D. Lebaron</a>
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <p className="title">
        Feature your company at the top of our category page
      </p>
      <div className={`success ${submittedSuccessfully ? "" : "hidden"}`}>
        <i className="fa-solid fa-circle-check fa-5x"></i>
        <p className="description">
          Your request has been sent. Thank you for your interest in being a
          featured company. We will be in touch trough one of the contact
          methods provided shortly.
        </p>
      </div>
      {company && (
        <div className={submittedSuccessfully ? "hidden" : ""}>
          <p className="description">
            To help the development of this website, you can donate any amount
            you like and to thank you, we will feature a banner of your company
            at the top of one of our category pages. If you're interested,
            please review your information below and hit Submit when you're sure
            it's correct. We will review the information and get back to you to
            coordinate the donation shortly.
          </p>
          <p className="description">
            If you've got any more questions, feel free to contact{" "}
            <a href="tel:+18018353995">A.D. Lebaron</a>
          </p>
          <form className="form" onSubmit={submit}>
            {errors.general && <p className="error">{errors.general}</p>}
            <div className="input-container">
              <label htmlFor="name">Company Name</label>
              <div className="input-with-icon">
                <input
                  name="name"
                  id="name"
                  disabled
                  defaultValue={company?.name}
                />
                <i className="fa-solid fa-lock fa-lg"></i>
              </div>
              <p className="disclaimer">
                This is the name from the company you have registered with us in
                GaleanaBiz. If you'd like to change it, you can do so from the{" "}
                <a href="/mybusiness">My business page</a>.
              </p>
            </div>
            <div className="input-container">
              <label htmlFor="phone">Phone Number</label>
              <input
                name="phone"
                id="phone"
                placeholder="+521234567890"
                value={values.phone}
                onChange={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
              />
              {errors.phone && <p className="error">{errors.phone}</p>}
            </div>
            <div className="input-container">
              <label htmlFor="email">E-mail (optional)</label>
              <input
                name="email"
                id="email"
                placeholder="gbiz@gbiz.com"
                value={values.email}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <div className="input-container">
              <label htmlFor="banner_image">Company Banner</label>
              <input
                name="banner_image"
                id="banner_image"
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setValues({ ...values, banner_image: e.target.files[0] })
                }
              />
              <p className="disclaimer">
                Please upload a high quality image in a landscape orientation.
              </p>
            </div>
            <button disabled={submitting} className="submit">
              Submit
            </button>
          </form>
        </div>
      )}
    </>
  );
}
