import axios from "axios";
import {
  ADRESS_USER_GET_REQUEST,
  ADRESS_USER_GET_SUCCESS,
  ADRESS_USER_GET_FAIL,
  //
  ADRESS_USER_ADD_REQUEST,
  ADRESS_USER_ADD_SUCCESS,
  ADRESS_USER_ADD_FAIL,
  ADRESS_USER_ADD_RESET,
  //
  ADRESS_USER_PUT_REQUEST,
  ADRESS_USER_PUT_SUCCESS,
  ADRESS_USER_PUT_FAIL,
  ADRESS_USER_PUT_RESET,
} from "../../constants/adressConstans";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

/////////////////////////////////////////////////////////////////////////////////////////////////
export const UpdateAdressUserAction =
  (adress) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADRESS_USER_PUT_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      if (!user) {
        return;
      }

      const userId = parseInt(user?.user?.id);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/adress/update-adress-user/`,
        {
          user_id: userId,
          ...adress,
        },
        config
      );

      dispatch({ type: ADRESS_USER_PUT_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ADRESS_USER_PUT_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AddAdressUserAction =
  (
    first_name,
    last_name,
    postal_code,
    country,
    state,
    municipality,
    neighborhood,
    exterior_number,
    interior_number,
    street_1,
    street_2,
    combinedPhoneNumber,
    additional_instructions
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ADRESS_USER_ADD_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      if (!user) {
        return;
      }

      const userId = parseInt(user?.user?.id);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/adress/add-adress-user/`,
        {
          user: userId,
          first_name,
          last_name,
          postal_code,
          country,
          state,
          municipality,
          neighborhood,
          exterior_number,
          interior_number,
          street_1,
          street_2,
          telephone_number: combinedPhoneNumber,
          additional_instructions,
        },
        config
      );

      dispatch({ type: ADRESS_USER_ADD_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ADRESS_USER_ADD_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AdressUserAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ADRESS_USER_GET_REQUEST });

    const {
      userLogin: { userToken },
      userDetails: { user },
    } = getState();

    if (!user) {
      return;
    }

    const userId = parseInt(user?.user?.id);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/adress/adress-user/${userId}/`,
      config
    );

    dispatch({ type: ADRESS_USER_GET_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ADRESS_USER_GET_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};
