import React from "react";
import { Link } from "react-router-dom";

import StarReviews from "../StarReviews";
import formatCurrency from "../../helpers/format/formatCurrency";

import ImageLoader from "../img/ImageLoader";

import LGLogo from "../../images/LGWhiteLogo.png";

import "../../styles/components/CompanyAndProductCards/ProductCard_V2.scss";

function ProductCard_V2({ products, customValues }) {
  const price = formatCurrency(products?.price, products?.currency);
  const priceDiscount = formatCurrency(
    products?.discounted_price,
    products?.currency
  );

  const customValuePriceDiscount =
    customValues?.price * ((100 - customValues?.discount) / 100);

  return (
    <Link
      to={customValues ? null : `/product/${products.company}/${products?.id}`}
      className="productCard"
    >
      <div className="productImgContainer">
        <img
          src={
            customValues
              ? customValues.fakeImage
                ? customValues.fakeImage
                : customValues.photo
              : products?.photo
          }
          alt=""
          className="productImg"
          draggable="false"
        />
      </div>
      <div className="infoSection">
        <div className="infoText productName">
          {customValues ? (
            <>{customValues.name ? customValues.name : "Enter product name"}</>
          ) : (
            products?.name
          )}
        </div>
        <div className="infoText businessName">
          {customValues ? "Your business name" : products?.company_name}
        </div>

        <div
          className={`infoText productPrice ${
            products?.discount > 0 && "productOldPrice"
          } ${customValues?.discount > 0 && "productOldPrice"} `}
        >
          {customValues ? (
            <>
              {customValues?.discount ? (
                `$${customValues.price}`
              ) : (
                <>
                  {customValues.price
                    ? `$${customValues.price}`
                    : "Enter Price "}
                </>
              )}
            </>
          ) : products?.discount > 0 ? (
            price
          ) : (
            price
          )}
          <span className="productCurrency">
            {customValues ? customValues.currency : products?.currency}
          </span>
        </div>

        {customValues?.discount ? (
          <>
            <div className="infoText productPrice">
              ${customValuePriceDiscount.toFixed(2)}
              <span className="productCurrency">{products?.currency}</span>
            </div>
            <div className="infoText discountedPercentage">
              {customValues?.discount}% off
            </div>
          </>
        ) : (
          products?.discount > 0 && (
            <>
              <div className="infoText productPrice">
                {priceDiscount}
                <span className="productCurrency">{products?.currency}</span>
              </div>
              <div className="infoText discountedPercentage">
                {products?.discount}% off
              </div>
            </>
          )
        )}

        {products?.subscription_active ? (
          <div className="memberOnlyTag">
            <div className="logoContainer">
              <img src={LGLogo} alt="" className="LGMemberTagLogo" />
            </div>

            <div className="tagTitle">Member Exclusive Product</div>
          </div>
        ) : null}

        {customValues?.available ? null : products?.available ? null : (
          <div className="infoText soldOutText">Currently Unavailable</div>
        )}
      </div>
    </Link>
  );
}

export default ProductCard_V2;

export function SkeletonProductCard() {
  return (
    <div className="skeletonProductCard">
      <div className="imgContainer"></div>
      <div className="infoSection">
        <div className="infoText productName">
          {/* below is invisible characters, that way the skeleton works */}
          ㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤㅤ
        </div>
        <div className="infoText businessName"></div>
        <div className="infoText productPrice"></div>
      </div>
    </div>
  );
}
