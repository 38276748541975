import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// SCSS
import "../../../../../styles/pages/products/TableProducts/TableProducts.scss";

// Components
import NavigationComponents from "../../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import Loader from "../../../../../components/Loader";
import Message from "../../../../../components/Message";
import ExtraPagesNavbar, {
  ExtraPagesLinks,
} from "../../../components/ExtraPagesNavbar";
import formatCurrency from "../../../../../helpers/format/formatCurrency";
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from "../../../../../services/galeanaBizApi";
import Modal from "../../../../../components/modal/Modal";
import { ProductOptionModal } from "../../../components/ProductOptionModal";

const TableProducts = () => {
  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  const [valueProduct, setValueProduct] = useState({
    is_open: false,
    id: 0,
    photo: null,
    name: "",
    company: 0,
  });

  const [valueProductOption, setValueProductOption] = useState({
    is_open: false,
    id: 0,
    companyId: 0,
    name: "",
  });

  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;

  const { data, isFetching, error } = useGetProductsQuery({
    companyId: userCompanyId,
    page,
    subscriptionStatus: true,
  });
  const [deleteProduct, deleteResult] = useDeleteProductMutation();

  const clickedEditProduct = (id, name, photo, company) => {
    setModalOpen(true);
    setValueProduct({
      id: id,
      photo: photo,
      name: name,
      company: company,
    });
  };

  const clickedOptionProduct = (id, companyId, name) => {
    setModalOpen(false);

    setValueProductOption({
      is_open: true,
      id: id,
      companyId: companyId,
      name: name,
    });
  };

  const clickedDeleteProduct = (id, name) => {
    setModalOpen(false);

    setValueProduct({
      is_open: true,
      id: id,
      name: name,
    });
  };

  const deleteHandler = (id) => {
    deleteProduct({ productId: id, companyId: userCompanyId }).then(
      (response) => {
        if ("error" in response) {
          return;
        }

        setValueProduct({ is_open: false });
        setModalOpen(false);
      }
    );
  };

  const handleClosePopup = () => {
    setModalOpen(false);
  };

  const TableProducts = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (error) {
      return <Message>error</Message>;
    }

    return (
      <>
        <p className="total">{data.total} products total</p>
        <div className="tableContainerContainer">
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th className="tableBox_edit">Edit</th>
                  <th className="tableBox_Name">Name</th>
                  <th className="tableBox_Price">Price</th>
                  <th className="tableBox_Price">Taxes 16%</th>
                  <th className="tableBox_Price">No Taxes</th>
                  <th className="tableBox_Category">Category</th>
                  <th className="tableBox_Status">Status</th>
                  <th className="tableBox_Visibility">Visibility</th>
                </tr>
              </thead>
              <tbody>
                {(data?.products || []).map((product) => (
                  <tr key={product?.id}>
                    <td
                      className="tableBox_edit"
                      onClick={() =>
                        clickedEditProduct(
                          product?.id,
                          product?.name,
                          product?.photo,
                          product?.company
                        )
                      }
                    >
                      <i className="fa-solid fa-pencil"></i>
                    </td>
                    <td className="tableBox_Name">{product?.name}</td>
                    <td className="tableBox_Price">
                      {formatCurrency(product?.price)}
                    </td>
                    <td className="tableBox_Price">
                      ${Number(((16 / 100) * product?.price).toFixed(2))}
                    </td>
                    <td className="tableBox_Price">
                      ${Number(((84 / 100) * product?.price).toFixed(2))}
                    </td>
                    <td className="tableBox_Category">
                      {product?.category?.name}
                    </td>
                    <td className="tableBox_Status">
                      {product?.available ? "Available" : "Sold Out"}
                    </td>
                    <td className="tableBox_Visibility">
                      {product?.subscription_active ? "Member" : "All"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <PaginationButtons
          currentPage={page}
          totalPages={data?.total_page}
          setPage={setPage}
        />
      </>
    );
  };

  return (
    <NavigationComponents>
      <div className="tableProducts">
        <ExtraPagesNavbar>
          <ExtraPagesLinks linkName="Product Cards" url="mybusiness/products" />
          <ExtraPagesLinks
            linkName="Products Table"
            url="mybusiness/table-products"
          />
          <ExtraPagesLinks linkName="Add Product" url="mybusiness/addproduct" />
        </ExtraPagesNavbar>

        <TableProducts />
      </div>

      <TableModal
        modalOpen={modalOpen}
        valueProduct={valueProduct}
        handleClosePopup={handleClosePopup}
        clickedDeleteProduct={clickedDeleteProduct}
        clickedOptionProduct={clickedOptionProduct}
      />

      <DeleteProductModalOpen
        valueProduct={valueProduct}
        setValueProduct={setValueProduct}
        deleteHandler={deleteHandler}
        deleteError={deleteResult.error}
      />

      <Modal
        isOpen={valueProductOption.is_open}
        onClose={() => setValueProductOption({ is_open: false })}
      >
        <ProductOptionModal product={valueProductOption} />
      </Modal>
    </NavigationComponents>
  );
};

export default TableProducts;

const PaginationButtons = ({ currentPage, totalPages, setPage }) => {
  if (currentPage === 0 || !currentPage) {
    return null;
  }
  return (
    <div className="paginationContainer">
      <button
        className="paginationBtnBack"
        disabled={currentPage === 1}
        onClick={() => setPage(currentPage - 1)}
      >
        Previous
      </button>
      <button className="currentPageTxt">{`Page ${currentPage} of ${totalPages}`}</button>
      <button
        className="paginationBtnNext"
        disabled={currentPage === totalPages}
        onClick={() => setPage(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

const TableModal = ({
  modalOpen,
  valueProduct,
  handleClosePopup,
  clickedDeleteProduct,
  clickedOptionProduct,
}) => {
  const { id, name, photo, company } = valueProduct;
  return (
    <>
      {modalOpen && (
        <div className="editProductModal">
          <div className="background"></div>

          <div className="modalContent">
            <div className="productInfoContainer">
              <div className="productImgContainer">
                <img src={photo} alt="" />
              </div>
              <div className="productName">{name}</div>
            </div>

            <div className="buttonsContainer">
              <button
                className="cancelEditBtn actionBtn"
                onClick={handleClosePopup}
              >
                Close
              </button>

              <Link
                to={`/product/${id}/updateProduct`}
                className="cancelEditBtn actionBtn"
              >
                Edit
              </Link>
              {/* No eliminar este Button */}
              {/* <button
                onClick={() => clickedOptionProduct(id, company, name)}
                className=" optionPorductBtn actionBtn"
              >
                Options
              </button> */}
              <button
                className="deleteBtn actionBtn"
                onClick={() => clickedDeleteProduct(id, name)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DeleteProductModalOpen = ({
  valueProduct,
  setValueProduct,
  deleteHandler,
  deleteError,
}) => {
  const { is_open, id, name } = valueProduct;
  return (
    <>
      {is_open ? (
        <>
          <div className="deleteProductModal">
            <div className="background"></div>

            <div className="modalContent">
              <div className="textContainer">
                <div className="titleText">
                  Are you sure you want to delete?
                </div>
                {!!deleteError && (
                  <p className="error">
                    Something went wrong, please try again later.
                  </p>
                )}
              </div>

              <div className="productInfoContainer">
                <div className="productName">{name}</div>
              </div>

              <div className="buttonsContainer">
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setValueProduct((prev) => ({
                      ...prev,
                      is_open: false,
                    }))
                  }
                >
                  Cancel
                </button>
                <button
                  className="deleteBtn"
                  onClick={() => deleteHandler(id, name)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
