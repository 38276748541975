import React from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

const DriverSmallSidebar = ({ currentPageUrl }) => {
  const userStatus = useSelector((state) => state.userDetails);
  const { user } = userStatus;

  const role = user?.user?.profile?.role;

  const RouteAdmin = () => {
    if (role !== "Admin") {
      return null;
    }
    return (
      <>
        <Link
          to="/driver-order-in-progress"
          className={currentPageUrl("driver-order-in-progress")}
        >
          <div className="iconContainer">
            <i className="fas fa-truck"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Orders in Progress</div>
          </div>
        </Link>

        <Link
          to="/driver-orders-status"
          className={currentPageUrl("driver-orders-status")}
        >
          <div className="iconContainer">
            <i className="fas fa-book"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Orders Status</div>
          </div>
        </Link>

        <Link to="/drivers" className={currentPageUrl("drivers")}>
          <div className="iconContainer">
            <i className="fas fa-user"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Drivers</div>
          </div>
        </Link>
      </>
    );
  };

  const RouteDriver = () => {
    if (role !== "Galeana Biz driver") {
      return null;
    }
    return (
      <>
        <Link
          to="/driver-order-list"
          className={currentPageUrl("driver-order-list")}
        >
          <div className="iconContainer">
            <i className="fas fa-truck"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Assigned</div>
          </div>
        </Link>
        <Link to="/order-history" className={currentPageUrl("order-history")}>
          <div className="iconContainer">
            <i class="fas fa-scroll"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Order History</div>
          </div>
        </Link>
      </>
    );
  };

  return (
    <div className="DeliverysmallSidebar">
      <div className="linksContainer">
        <Link to="/driver" className={currentPageUrl("driver")}>
          <div className="iconContainer">
            <i className="fas fa-boxes"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">All Orders</div>
          </div>
        </Link>

        <RouteAdmin />
        <RouteDriver />
      </div>
    </div>
  );
};

export default DriverSmallSidebar;
