import { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import AddToAllPages from "../../components/AddToAllPages";
import Loader from "../../components/Loader";
import StarReviews from "../../components/StarReviews";

import DefaultProfileImg from "../../images/default-profile-icon.jpg";
import {
  useCreateProductReviewMutation,
  useGetProductReviewsQuery,
  useUpdateProductReviewMutation,
} from "../../services/galeanaBizApi";

import "../../styles/pages/products/ProductReviews.scss";
import "../../styles/components/reviews/reviewCompanies/AddReviewModal.scss";
import { useSelector } from "react-redux";

export default function ProductReviews() {
  const [page, setPage] = useState(1);
  const [isModalHidden, setIsModalHidden] = useState(true);

  const { productId, businessId } = useParams();
  const { user } = useSelector((state) => state.userDetails);
  const { data, error, isLoading } = useGetProductReviewsQuery({
    productId,
    page,
    userId: user.user.id,
  });

  const Content = () => {
    if (error) {
      return <p>There was an error fetching the reviews.</p>;
    }

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <div className="title-container">
          <h2>{data.company_name} Reviews</h2>
          {!!!data.user_review && (
            <button onClick={() => setIsModalHidden(false)}>Add review</button>
          )}
        </div>
        {!!data.user_review && (
          <div className="user-review-container">
            <div className="header-container">
              <p>Your review</p>
              <button onClick={() => setIsModalHidden(false)}>Edit</button>
            </div>
            <div className="rating-container">
              <div className="top-section">
                <div className="img-container">
                  <img src={DefaultProfileImg} alt="" />
                </div>
                <div className="name-and-stars-container">
                  <div className="name-and-date-container">
                    <div className="name">{data.user_review.name}</div>
                    <div className="date">
                      {`(${data.user_review.createdAt.substring(0, 10)})`}
                    </div>
                  </div>
                  <div className="stars">
                    <StarReviews
                      value={data.user_review.rating}
                      color="#f8e825"
                    />
                  </div>
                </div>
              </div>
              <div className="text">{data.user_review.comment}</div>
            </div>
          </div>
        )}
        {data.results.map((review) => (
          <div key={review.id} className="rating-container">
            <div className="top-section">
              <div className="img-container">
                <img src={DefaultProfileImg} alt="" />
              </div>
              <div className="name-and-stars-container">
                <div className="name-and-date-container">
                  <div className="name">{review?.name}</div>
                  <div className="date">
                    {`(${review.createdAt.substring(0, 10)})`}
                  </div>
                </div>
                <div className="stars">
                  <StarReviews value={review.rating} color="#f8e825" />
                </div>
              </div>
            </div>
            <div className="text">{review.comment}</div>
          </div>
        ))}
        {data.results.length === 0 && !!!data.user_review && (
          <p>No reviews yet</p>
        )}
        {data.results.length > 0 && (
          <div className="pagination-container">
            <button
              className="previous"
              disabled={data.previous === null}
              onClick={() => setPage(page - 1)}
            >
              Previous
            </button>
            <p className="current">{`Page ${data.current_page}`}</p>
            <button
              className="next"
              disabled={data.next === null}
              onClick={() => setPage(page + 1)}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
  };

  return (
    <AddToAllPages>
      <div className="page-container">
        <Link to={`/product/${businessId}/${productId}`} className="back-link">
          <i className="left-icon fa-solid fa-chevron-left"></i>
          Back to product
        </Link>
        <Content />
        <AddReviewModal
          userReview={data?.user_review}
          user={user}
          productId={productId}
          isHidden={isModalHidden}
          setIsHidden={setIsModalHidden}
        />
      </div>
    </AddToAllPages>
  );
}

function AddReviewModal({
  user,
  productId,
  isHidden,
  setIsHidden,
  userReview,
}) {
  const [createReview, createResult] = useCreateProductReviewMutation();
  const [updateReview, updateResult] = useUpdateProductReviewMutation();

  const [values, setValues] = useState({
    user_id: user.user.id,
    name: user.user.first_name,
    rating: 1,
    comment: "",
  });

  useEffect(() => {
    if (userReview) {
      setValues((previousValues) => ({
        ...previousValues,
        rating: userReview.rating,
        comment: userReview.comment,
        review_id: userReview.id,
      }));
    }
  }, [userReview]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userReview) {
      updateReview({
        productId,
        ...values,
      }).then((response) => {
        if ("error" in response) {
          return;
        }

        setIsHidden(true);
      });
    } else {
      createReview({
        productId,
        ...values,
      }).then((response) => {
        if ("error" in response) {
          return;
        }

        setIsHidden(true);
      });
    }
  };

  if (isHidden) {
    return null;
  }

  return (
    <div className="addReviewModal">
      <div className="background"></div>

      <div className="modalContent">
        <div className="topSection">
          <button className="closeModalBtn" onClick={() => setIsHidden(true)}>
            <i className="fa-solid fa-xmark"></i>
          </button>
          <div className="editReviewTitle">Write a Review</div>
        </div>
        {(!!createResult.error || !!updateResult.error) && (
          <p className="error">Something went wrong, please try again later.</p>
        )}
        <div className="contentSection">
          <form className="contentFormReview" onSubmit={handleSubmit}>
            <div className="RatingContainer">
              <div className="ratingText">Give a Rating:</div>

              <span className="starRating">
                {[...Array(5)].map((_, index) => {
                  return index < values.rating ? (
                    <AiFillStar
                      className="starRating"
                      onClick={() =>
                        setValues({ ...values, rating: index + 1 })
                      }
                    />
                  ) : (
                    <AiFillStar
                      className="noStarRating"
                      onClick={() =>
                        setValues({ ...values, rating: index + 1 })
                      }
                    />
                  );
                })}
              </span>
            </div>
            <div className="reviewContainer">
              <div className="titleText">Review</div>
              <textarea
                className="reviewInput"
                type="text"
                placeholder="Review"
                required
                value={values.comment}
                onChange={(e) =>
                  setValues({ ...values, comment: e.target.value })
                }
              />
            </div>

            <button
              disabled={createResult.isLoading || updateResult.isLoading}
              className="submitReviewBtn"
              type="submit"
            >
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
