import React from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import "../../../styles/components/navigation/Delivery-Navigation/DeliveryLargeSidebar.scss";

const DriverLargeSidebar = ({ currentPageUrl }) => {
  const userStatus = useSelector((state) => state.userDetails);
  const { user } = userStatus;

  const role = user?.user?.profile?.role;

  const RouteAdmin = () => {
    if (role !== "Admin") {
      return null;
    }
    return (
      <>
        <Link
          to="/driver-order-in-progress"
          className={currentPageUrl("driver-order-in-progress")}
        >
          <div className="iconContainer">
            <i className="fas fa-truck"></i>
          </div>
          <div className="linkText">Orders in Progress</div>
        </Link>

        <Link
          to="/driver-orders-status"
          className={currentPageUrl("driver-orders-status")}
        >
          <div className="iconContainer">
            <i className="fas fa-book"></i>
          </div>
          <div className="linkText">Orders Status</div>
        </Link>

        <Link to="/drivers" className={currentPageUrl("drivers")}>
          <div className="iconContainer">
            <i className="fas fa-user"></i>
          </div>
          <div className="linkText">Drivers</div>
        </Link>
      </>
    );
  };

  const RouteDriver = () => {
    if (role !== "Galeana Biz driver") {
      return null;
    }
    return (
      <>
        <Link
          to="/driver-order-list"
          className={currentPageUrl("driver-order-list")}
        >
          <div className="iconContainer">
            <i className="fa-solid fa-box"></i>
          </div>
          <div className="linkText">Assigned Orders</div>
        </Link>

        <Link to="/order-history" className={currentPageUrl("order-history")}>
          <div className="iconContainer">
            <i class="fas fa-scroll"></i>
          </div>
          <div className="linkText">Order History</div>
        </Link>
      </>
    );
  };

  return (
    <div className="DeliverylargeSidebar">
      <div className="topLinksContainer">
        <Link to="/driver" className={currentPageUrl("driver")}>
          <div className="iconContainer">
            <i className="fas fa-boxes"></i>
          </div>
          <div className="linkText">All Orders</div>
        </Link>

        <RouteDriver />
        <RouteAdmin />
      </div>

      <div className="extraInfoContainer">
        <div>
          For more information please contact:
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=18018353995"
              target="_blank"
              rel="noopener noreferrer"
            >
              A.D. Lebaron
            </a>
          </div>
          <div>or</div>
          <a
            href="https://api.whatsapp.com/send?phone=526361357593"
            target="_blank"
            rel="noopener noreferrer"
          >
            Martin Arreola jr.
          </a>
        </div>
      </div>
    </div>
  );
};

export default DriverLargeSidebar;
