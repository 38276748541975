import React, { useEffect, useState } from "react";
import {
  useCreateAttributeOptionMutation,
  useCreateOptionProductMutation,
  useDeleteAttributeOptionMutation,
  useDeleteOptionProductMutation,
  useGetAttributeOptionQuery,
  useGetOptionProductQuery,
  useUpdateAttributeOptionMutation,
  useUpdateOptionProductMutation,
} from "../../../services/galeanaBizApi";

import { BsToggleOn, BsToggleOff, BsTrash } from "react-icons/bs";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import { Notification } from "../../../components/notification/Notification";

export const ProductOptionModal = ({ product }) => {
  const [productOptions, setProductOptions] = useState([]);

  const {
    data: optionData,
    isFetching: isOptionFetching,
    isError: isOptionError,
    isLoading: isOptionLoading,
  } = useGetOptionProductQuery({
    productId: product.id,
    companyId: product.companyId,
    pageType: "page_edit",
  });

  const [deleteOptionProduct, deleteOptionProductResult] =
    useDeleteOptionProductMutation();

  const [createOptionProduct, createOptionProductResult] =
    useCreateOptionProductMutation();

  useEffect(() => {
    if (!optionData || isOptionFetching) {
      return;
    }

    setProductOptions(optionData);
  }, [optionData, isOptionFetching]);

  const createCategoryOption = (e) => {
    e.stopPropagation();

    createOptionProduct({
      productId: product.id,
      companyId: product.companyId,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });
  };

  if (isOptionLoading) {
    return <Loader />;
  }

  if (isOptionError) {
    return (
      <Message error={isOptionError}>
        Something went wrong, please try again later
      </Message>
    );
  }

  return (
    <div className="modalContent">
      <Notification result={createOptionProductResult} />
      <Notification result={deleteOptionProductResult} />

      <div className="textContainer">
        <div className="modalTitle"> Extra {product.name} options!!</div>
      </div>
      <div className="optionSection">
        {productOptions.map((option, index) => {
          return (
            <ProductOptionView
              deleteOptionProduct={deleteOptionProduct}
              option={option}
              product={product}
              key={index}
            />
          );
        })}

        <button className="btnCategory" onClick={createCategoryOption}>
          <label>Create new category of options</label>
          <div className="contentIcon">
            <i className="fas fa-plus"></i>
          </div>
        </button>
      </div>
    </div>
  );
};

const ProductOptionView = ({ deleteOptionProduct, option, product }) => {
  const [attributes, setAttributes] = useState([]);
  const [showCategory, setShowCategory] = useState(false);

  const [valueCategoryOption, setValueCategoryOption] = useState({
    option_id: 0,
    option_title: "",
    option_is_required: false,
  });

  const { option_id, option_title, option_is_required } = valueCategoryOption;

  const {
    data: attributeData,
    isFetching: isAttributeFetching,
    isError: isAttributeError,
    error: errorAttribute,
    isLoading: isAttributeLoading,
  } = useGetAttributeOptionQuery({
    companyId: product.companyId,
    productId: product.id,
    optionId: option.id,
    pageType: "page_edit",
  });

  const [updateOptionProduct, updateOptionProductResult] =
    useUpdateOptionProductMutation();

  useEffect(() => {
    if (!attributeData || isAttributeFetching) {
      return;
    }

    setAttributes(attributeData);

    setValueCategoryOption({
      option_id: option?.id,
      option_title: option?.title,
      option_is_required: option?.is_required,
    });
  }, [attributeData, isAttributeFetching, option]);

  const disableCategorySave = () => {
    if (option_title === "") {
      return true;
    }

    if (option.title !== option_title) {
      return false;
    }

    if (option.is_required !== option_is_required) {
      return false;
    }

    return true;
  };

  const updateCategoryOption = (e) => {
    e.preventDefault();
    e.stopPropagation();

    updateOptionProduct({
      companyId: product.companyId,
      productId: product.id,
      optionId: option_id,
      title: option_title,
      is_required: option_is_required,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });
  };

  const deleteCategoryOption = (e) => {
    e.stopPropagation();

    deleteOptionProduct({
      companyId: product.companyId,
      productId: product.id,
      optionId: option_id,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });

    setShowCategory(false);
  };

  const toggleShow = () => {
    setShowCategory(!showCategory);
  };

  return (
    <div className="optionWrapper">
      <Notification result={updateOptionProductResult} />
      <div className="contentDelete">
        <BsTrash onClick={toggleShow} className="iconTrash" />

        {showCategory && (
          <div className="warapperCardDelete">
            <div className="contentCardDelete">
              <div className="contentButton">
                <button onClick={toggleShow} className="btnCancel">
                  Cancel
                </button>
                <button onClick={deleteCategoryOption} className="btnDelete">
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="optionContent">
        <div className="sectionOption">
          <form onSubmit={updateCategoryOption} className="contentFormCategory">
            <div className="sectionTitle">
              <label form="title_option">Title of category</label>
              <input
                id="title_option"
                type="text"
                placeholder="Ej: Size"
                value={option_title}
                onChange={(e) =>
                  setValueCategoryOption((prevState) => ({
                    ...prevState,
                    option_title: e.target.value,
                  }))
                }
              />
            </div>
            <div className="sectionIsRequired">
              <div className="isRequiredLabel">Category is required</div>

              <div className="radioContainer">
                <div className="radioOption">
                  <input
                    type="radio"
                    id="optionalRadio"
                    placeholder="Ej: Size"
                    checked={option_is_required === false}
                    onChange={() => {
                      setValueCategoryOption((prevState) => ({
                        ...prevState,
                        option_is_required: false,
                      }));
                    }}
                  />
                  <label htmlFor="optionalRadio">optional</label>
                </div>

                <div className="radioOption">
                  <input
                    type="radio"
                    id="requiredRadio"
                    placeholder="Ej: Size"
                    checked={option_is_required === true}
                    onChange={() => {
                      setValueCategoryOption((prevState) => ({
                        ...prevState,
                        option_is_required: true,
                      }));
                    }}
                  />
                  <label htmlFor="requiredRadio">required</label>
                </div>
              </div>
            </div>
            <div className="contentBtnSubmit">
              <button
                type="submit"
                disabled={disableCategorySave()}
                className="btnSave"
              >
                Save
              </button>
            </div>
          </form>
        </div>

        <AttributeView
          product={product}
          option={option}
          attributes={attributes}
          isAttributeError={isAttributeError}
          isAttributeLoading={isAttributeLoading}
        />
      </div>
    </div>
  );
};

const AttributeView = ({
  attributes,
  product,
  option,
  isAttributeError,
  isAttributeLoading,
}) => {
  const [deleteAttribute, deleteAttributeResult] =
    useDeleteAttributeOptionMutation();

  const [createAttribute, createAttributeResult] =
    useCreateAttributeOptionMutation();

  const createAttributeProduct = () => {
    createAttribute({
      companyId: product?.companyId,
      productId: product?.id,
      optionId: option?.id,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });
  };

  if (isAttributeLoading) {
    return <Loader />;
  }

  if (isAttributeError) {
    return (
      <Message error={isAttributeError}>
        Something went wrong, please try again later.
      </Message>
    );
  }

  return (
    <div className="attributeContent">
      <Notification result={createAttributeResult} />
      <Notification result={deleteAttributeResult} />

      {attributes.map((attribute, index) => {
        return (
          <AttributeCard
            key={index}
            deleteAttribute={deleteAttribute}
            attribute={attribute}
            product={product}
            option={option}
          />
        );
      })}

      <button className="btnAddNewOption" onClick={createAttributeProduct}>
        <label>Add new Option</label>
        <div className="contentIcon">
          <i className="fas fa-plus"></i>
        </div>
      </button>
    </div>
  );
};

const AttributeCard = ({ deleteAttribute, attribute, product, option }) => {
  const [showAttribute, setShowAttribute] = useState(false);

  const [valueProductAttribute, setValueProductAttribute] = useState({
    attribute_id: 0,
    attribute_name: "",
    attribute_price: 0,
    attribute_available: false,
  });

  const { attribute_id, attribute_name, attribute_price, attribute_available } =
    valueProductAttribute;

  useEffect(() => {
    setValueProductAttribute({
      attribute_id: attribute?.id,
      attribute_name: attribute?.name,
      attribute_price: attribute?.price,
      attribute_available: attribute?.available,
    });
  }, [attribute]);

  const [updateAttribute, updateAttributeResult] =
    useUpdateAttributeOptionMutation();

  const deleteAttributeProduct = (e) => {
    e.stopPropagation();

    deleteAttribute({
      company_id: product?.companyId,
      product_id: product?.id,
      option_id: option?.id,
      attribute_id: attribute_id,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });

    setShowAttribute(false);
  };

  const updateAttributeProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    updateAttribute({
      company_id: product?.companyId,
      product_id: product?.id,
      option_id: option?.id,
      attribute_id: attribute_id,
      name: attribute_name,
      price: attribute_price,
      available: attribute_available,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });
  };

  const disableSubmit = () => {
    if (attribute_name === "" || attribute_price === "") {
      return true;
    }

    if (attribute?.name !== attribute_name) {
      return false;
    }

    if (attribute?.price !== attribute_price) {
      return false;
    }

    if (attribute?.available !== attribute_available) {
      return false;
    }

    return true;
  };

  const functionOnChange = (e) => {
    const { name, value } = e.target;
    setValueProductAttribute((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const toggleShow = () => {
    setShowAttribute(!showAttribute);
  };

  return (
    <div className="attributeCard">
      <Notification result={updateAttributeResult} />
      <div className="contentDelete">
        <BsTrash onClick={toggleShow} className="iconTrash" />

        {showAttribute && (
          <div className="warapperCardDelete">
            <div className="contentCardDelete">
              <div className="contentButton">
                <button className="btnCancel" onClick={toggleShow}>
                  Cancel
                </button>
                <button className="btnDelete" onClick={deleteAttributeProduct}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <form onSubmit={updateAttributeProduct}>
        <div className="sectionName">
          <label>Name</label>
          <input
            className="inputAttribute"
            name="attribute_name"
            value={attribute_name}
            onChange={functionOnChange}
            placeholder="Ej. Size Small"
          />
        </div>
        <div className="sectionPrice">
          <label>Additional Price</label>
          <input
            className="inputAttribute"
            name="attribute_price"
            value={attribute_price}
            onChange={functionOnChange}
          />
        </div>
        <div className="sectionAvailable">
          <label>Available</label>

          <div className="contentToggle">
            {attribute_available ? (
              <BsToggleOn
                className="toggleOn"
                onClick={() =>
                  setValueProductAttribute((prev) => ({
                    ...prev,
                    attribute_available: false,
                  }))
                }
              />
            ) : (
              <BsToggleOff
                className="toggleOff"
                onClick={() =>
                  setValueProductAttribute((prev) => ({
                    ...prev,
                    attribute_available: true,
                  }))
                }
              />
            )}
          </div>
        </div>

        <div className="contentBtnSubmit">
          <button type="submit" className="btnSave" disabled={disableSubmit()}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
