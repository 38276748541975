import axios from "axios";
import {
  DRIVER_GET_PROFILE_REQUEST,
  DRIVER_GET_PROFILE_SUCCESS,
  DRIVER_GET_PROFILE_FAIL,
} from "../constants/driverProfileConstans";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export const DriverProfileActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DRIVER_GET_PROFILE_REQUEST });

    const {
      userDetails: { user },
    } = getState();

    const userId = parseInt(user?.user?.id);

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/driver-profile/profile-driver/${userId}/`,
      config
    );

    dispatch({
      type: DRIVER_GET_PROFILE_SUCCESS,
      payload: data,
    });

    localStorage.setItem("DriverProfile", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: DRIVER_GET_PROFILE_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};
