import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

import "../../styles/components/navigation/NavBarDelivery.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faBell } from "@fortawesome/free-solid-svg-icons";

import LGTreeLogo from "../../images/placeholders/GBiz-logo.png";

import DefaultProfileImg from "../../images/default-profile-icon.jpg";

function NavBarDelivery() {
  const [searchBtnClicked, setSearchBtnClicked] = useState(false);

  const { user } = useSelector((state) => state.userDetails);
  const image = user?.user?.profile?.image;

  return (
    <div className="navbardelivery">
      {searchBtnClicked ? (
        <ActiveMobileSearchbar
          searchBtnClicked={searchBtnClicked}
          setSearchBtnClicked={setSearchBtnClicked}
        />
      ) : (
        <div className="contentContainer">
          <Link to={"/"} className="logoContainer">
            <img src={LGTreeLogo} alt="" className="logoImg" />
            <div className="titleContainer">
              <div className="title">GaleanaBiz</div>
              <div className="subtitle">Delivery</div>
            </div>
          </Link>

          <div className="iconsContainer">
            {/* <i className="fa-regular fa-bell"></i> */}
            <Link to="/profile" className="profilePicture">
              {image ? (
                <img src={image} alt="" className="profilePictureImg" />
              ) : (
                <img
                  src={DefaultProfileImg}
                  alt=""
                  className="profilePictureImg"
                />
              )}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

const ActiveMobileSearchbar = ({ searchBtnClicked, setSearchBtnClicked }) => {
  return (
    <div className="activeMobileSearchbar">
      <div
        className="cancelBtn"
        onClick={() => setSearchBtnClicked(!searchBtnClicked)}
      >
        <i className="fa-solid fa-arrow-left"></i>
      </div>

      <input className="searchBar" type="text" placeholder="Search.." />
    </div>
  );
};

export default NavBarDelivery;
