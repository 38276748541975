import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddToAllPages from "../../../../components/AddToAllPages";
import StarReviews from "../../../../components/StarReviews";
import { Link, useParams } from "react-router-dom";

// Images
import DefaultProfileImg from "../../../../images/default-profile-icon.jpg";

// Components
import ReviewsCompany from "./ReviewsCompany";

// SCSS
import "../../../../styles/components/reviews/reviewCompanies/RatingandCommentCompanies.scss";
import {
  listCompanyReviewsAction,
  resetlistCompanyReviewsAction,
} from "../../../../actions/companyActions";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";
import { COMPANY_GET_REVIEW_FAIL } from "../../../../constants/companyConstants";
import axios from "axios";

const RatingandCommentCompanies = () => {
  const dispatch = useDispatch();

  const { companyId } = useParams();

  const { company_name } = useSelector((state) => state.ListReviews);

  useEffect(() => {
    dispatch(resetlistCompanyReviewsAction());
    dispatch(listCompanyReviewsAction(companyId));
  }, [dispatch, companyId]);

  return (
    <AddToAllPages>
      <div className="RatingAndCommentCompanies">
        <Link to={`/business/${companyId}`} className="backToCompanyText">
          <i className="fa-solid fa-chevron-left"></i>
          Back to {company_name}
        </Link>

        <h2 className="title">{company_name} reviews</h2>

        <ReviewsCompany companyId={companyId} />

        <hr />

        <ListReviews companyId={companyId} />
      </div>
    </AddToAllPages>
  );
};

export default RatingandCommentCompanies;

const ListReviews = () => {
  const dispatch = useDispatch();

  const companyReviews = useSelector((state) => state.ListReviews);
  const {
    loading: loadingReviews,
    error: errorReviews,
    reviews,
    next,
    user_review,
  } = companyReviews;

  const [listReviews, setListReviews] = useState(null);
  const [nextCursor, setNextCursor] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    setListReviews(reviews);
    setNextCursor(next);
  }, [reviews, next]);

  const loadMoreReviews = async () => {
    if (nextCursor) {
      setLoadingMore(true);
      try {
        const resposne = await axios.get(nextCursor);
        setListReviews((prevList) => [...prevList, ...resposne.data.results]);
        setNextCursor(resposne.data.next);
      } catch (error) {
        dispatch({
          type: COMPANY_GET_REVIEW_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      } finally {
        setLoadingMore(false);
      }
    }
  };

  return (
    <>
      {loadingReviews ? (
        <>
          <Loader />
        </>
      ) : errorReviews ? (
        <Message error={errorReviews}>
          The reviews cannot be displayed, try again
        </Message>
      ) : (
        <>
          {!reviews?.length && !user_review ? (
            <p style={{ fontSize: "1.2em", marginLeft: "5px" }}>
              There are no reviews yet
            </p>
          ) : null}

          {listReviews ? (
            <>
              {Object.values(listReviews || []).map((review, index) => {
                return (
                  <div key={index}>
                    <div className="userRatingContainer">
                      <div className="userRating">
                        <div className="topSection">
                          <div className="profileImgContainer">
                            <img
                              src={review?.profile_image || DefaultProfileImg}
                              alt=""
                              className="profileImg"
                              loading="lazy"
                              decoding="async"
                            />
                          </div>
                          <div className="submiterNameAndStarsContainer">
                            <div className="nameAndDateContainer">
                              <div className="submiterName">
                                {review?.name} {review?.last_name}
                              </div>
                              <div className="submiterDate">
                                {`(${review?.createdAt?.substring(0, 10)})`}
                              </div>
                            </div>
                            <div className="starsContainer">
                              <StarReviews
                                value={review?.rating}
                                color="#f8e825"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="reviewText">{review?.comment}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}

          {nextCursor && (
            <div className="conetentLoadMoreBtn">
              {loadingMore ? (
                <Loader />
              ) : (
                <button className="loadMoreBtn" onClick={loadMoreReviews}>
                  Load More
                </button>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
