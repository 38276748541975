import axios from "axios";
import {
  DRIVER_GET_ORDER_REQUEST,
  DRIVER_GET_ORDER_SUCCESS,
  DRIVER_GET_ORDER_FAIL,
  //
  DRIVER_DELIVERY_ORDER_REQUEST,
  DRIVER_DERLIVERY_ORDER_SUCCESS,
  DRIVER_DERLIVERY_ORDER_FAIL,
  DRIVER_DERLIVERY_ORDER_RESET,
  //
  GET_ASSING_DRIVER_REQUEST,
  GET_ASSING_DRIVER_SUCCESS,
  GET_ASSING_DRIVER_FAIL,
  //
  GET_DRIVER_SHIPPING_REQUEST,
  GET_DRIVER_SHIPPING_SUCCESS,
  GET_DRIVER_SHIPPING_FAIL,
  //
  GET_DRIVER_LIST_ORDERS_REQUEST,
  GET_DRIVER_LIST_ORDERS_SUCCESS,
  GET_DRIVER_LIST_ORDERS_FAIL,
} from "../constants/driverConstants";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const DriverOnDeliveryActions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DRIVER_SHIPPING_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/driver/order-in-progress/`,
      config
    );

    dispatch({
      type: GET_DRIVER_SHIPPING_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_DRIVER_SHIPPING_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const DriverAvailableActions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ASSING_DRIVER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/driver/order-list-drivers/`,
      config
    );

    dispatch({
      type: GET_ASSING_DRIVER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_ASSING_DRIVER_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const ResetDeliveryDriverStateAction = () => (dispatch) => {
  dispatch({ type: DRIVER_DERLIVERY_ORDER_RESET });
};

///////////////////////////////////driver////////////////////
export const DriverDeliveryOrderAction =
  (driver_id, transactionId, check, description) => async (dispatch) => {
    try {
      dispatch({ type: DRIVER_DELIVERY_ORDER_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/driver/driver-delivery/`,
        {
          driver_id: driver_id,
          transaction_id: transactionId,
          delivery_successful: check,
          body: description,
        },
        config
      );

      dispatch({ type: DRIVER_DERLIVERY_ORDER_SUCCESS, payload: data });

      dispatch(DriverAllOrderActions());
    } catch (err) {
      dispatch({
        type: DRIVER_DERLIVERY_ORDER_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const DriverListOrdersAsign = (driver_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DRIVER_LIST_ORDERS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/driver/list-Orders-Driver/${driver_id}/`,
      config
    );

    dispatch({
      type: GET_DRIVER_LIST_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_DRIVER_LIST_ORDERS_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const DriverAllOrderActions = () => async (dispatch) => {
  try {
    dispatch({ type: DRIVER_GET_ORDER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/driver/all_orders/`,
      config
    );

    dispatch({
      type: DRIVER_GET_ORDER_SUCCESS,
      payload: {
        next: data.next,
        previous: data.previous,
        DriverOrderAll: data.results,
      },
    });
  } catch (err) {
    dispatch({
      type: DRIVER_GET_ORDER_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};
