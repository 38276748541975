import {
  ADRESS_COMPANY_GET_REQUEST,
  ADRESS_COMPANY_GET_SUCCESS,
  ADRESS_COMPANY_GET_FAIL,
  //
  ADRESS_COMPANY_ADD_REQUEST,
  ADRESS_COMPANY_ADD_SUCCESS,
  ADRESS_COMPANY_ADD_FAIL,
  ADRESS_COMPANY_ADD_RESET,
  //
  ADRESS_COMPANY_PUT_REQUEST,
  ADRESS_COMPANY_PUT_SUCCESS,
  ADRESS_COMPANY_PUT_FAIL,
  ADRESS_COMPANY_PUT_RESET,
} from "../../constants/adressConstans";

export const UpdateCompanyAdress = (state = { adress: [] }, action) => {
  switch (action.type) {
    case ADRESS_COMPANY_PUT_REQUEST:
      return { loading: true, ...state };

    case ADRESS_COMPANY_PUT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adress: action.payload,
      };

    case ADRESS_COMPANY_PUT_FAIL:
      return { ...state, loading: false, error: action.payload };

    case ADRESS_COMPANY_PUT_RESET:
      return {};

    default:
      return state;
  }
};

export const AddCompanyAdress = (state = {}, action) => {
  switch (action.type) {
    case ADRESS_COMPANY_ADD_REQUEST:
      return { loading: true };

    case ADRESS_COMPANY_ADD_SUCCESS:
      return { loading: false, success: true };

    case ADRESS_COMPANY_ADD_FAIL:
      return { loading: false, error: action.payload };

    case ADRESS_COMPANY_ADD_RESET:
      return { ...state, success: false };

    default:
      return state;
  }
};

export const CompnayAdressReducers = (
  state = { AdressCompany: {} },
  action
) => {
  switch (action.type) {
    case ADRESS_COMPANY_GET_REQUEST:
      return { loading: true };

    case ADRESS_COMPANY_GET_SUCCESS:
      return { loading: false, AdressCompany: action.payload };

    case ADRESS_COMPANY_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
