import React, { useEffect, useState } from "react";

// scss
import "../../../styles/components/AddressCard.scss";

// components
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";

//helpers
import formatPhoneNumber from "../../../helpers/format/formatPhoneNumber";

const UserGetAdress = ({
  AdressUser,
  AddressUserLoading,
  AddressUserError,
}) => {
  const [userAddress, setUserAddress] = useState({});

  useEffect(() => {
    if (!AdressUser) {
      return;
    }

    setUserAddress(AdressUser);
  }, [AdressUser]);

  if (AddressUserLoading) {
    return <Loader />;
  }

  if (AddressUserError) {
    return (
      <Message error={AddressUserError}>
        "There was an error getting your address"
      </Message>
    );
  }

  return (
    <div className="addressCard">
      <div className="sectionContainer">
        <div className="infoTxt">
          Full Name: {userAddress.first_name} {userAddress.last_name}
        </div>

        <div className="infoTxt">
          <i className="fa-solid fa-phone"></i>
          {formatPhoneNumber(userAddress.telephone_number)}
        </div>

        <div className="infoTxt">
          <i className="fa-solid fa-location-dot"></i>
          {userAddress.neighborhood}
        </div>
      </div>

      <div className="sectionContainer">
        <div className="infoTxt">Street 1: {userAddress.street_1}</div>
        <div className="infoTxt">Street 2: {userAddress.street_2}</div>
      </div>

      <div className="sectionContainer">
        <div className="infoTxt">
          Interior Number: {userAddress.interior_number}
        </div>
        <div className="infoTxt">
          Exterior Number: {userAddress.exterior_number}
        </div>
      </div>

      <div className="sectionContainer">
        <div className="sectionTitle">Referencia:</div>

        <div className="infoTxt">{userAddress.additional_instructions}</div>
      </div>
    </div>
  );
};

export default UserGetAdress;
