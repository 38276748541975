import React from "react";
import { Link } from "react-router-dom";

import StarReviews from "../../../components/StarReviews";

import ImageLoader from "../../../components/img/ImageLoader";

import LGLogo from "../../../images/LGWhiteLogo.png";

import "../styles/ServiceCard_V1.scss";

function ServiceCard_V1({ service }) {
  const path = `/business/service/${service.slug}`;
  return (
    <div className="productCardV2Container">
      <Link to={path} className="productCard_V2">
        <ImageLoader image={service?.photo} />

        {service.is_available ? null : (
          <div className="soldOutOverlay">
            <div className="soldOutText">Sold Out</div>
          </div>
        )}

        {service?.subscription_active && (
          <div className="memberOnlyTag">
            <div className="logoContainer">
              <img src={LGLogo} alt="" className="LGMemberTagLogo" />
            </div>

            <div className="tagTitle">Member Exclusive</div>
          </div>
        )}

        <div className="infoSection">
          <div className="infoContainer">
            <div className="productName">{service.name}</div>
            <div
              className={
                service.discount > 0
                  ? `productOwner productOwner__discount`
                  : `productOwner`
              }
            >
              {service?.company_name}
            </div>

            <div className="bottomInfoContainer">
              <div className="reviewsContainer">
                <StarReviews value={service.rating} />
                <span className="ratingNum">
                  {service.rating ? service.rating : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ServiceCard_V1;
