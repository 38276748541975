import axios from "axios";

import {
  PRODUCT_CATEGORY_DETAILS_REQUEST,
  PRODUCT_CATEGORY_DETAILS_SUCCESS,
  PRODUCT_CATEGORY_DETAILS_FAIL,
  CATEGORIES_IN_USE_REQUEST,
  CATEGORIES_IN_USE_SUCCESS,
  CATEGORIES_IN_USE_FAIL,
  //
  PRODUCT_RELATED_REQUEST,
  PRODUCT_RELATED_SUCCCESS,
  PRODUCT_RELATED_FAIL,
  //
  PRODUCT_DELETE_IMAGES_REQUEST,
  PRODUCT_DELETE_IMAGES_SUCCESS,
  PRODUCT_DELETE_IMAGES_FAIL,
} from "../constants/productsConstans";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export const DeletedImagesActions = (deletedImageIds) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DELETE_IMAGES_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.delete(
      `${ApiGaleanaBiz}/api/companies/product/delete-photo/`,
      { data: { images_ids: deletedImageIds } },
      config
    );

    dispatch({ type: PRODUCT_DELETE_IMAGES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_IMAGES_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//////////// Get categories ////////////////////////////////////////////////
export const ProductCategoriesAction = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_CATEGORY_DETAILS_REQUEST });

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/product/categories/`
    );

    dispatch({
      type: PRODUCT_CATEGORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ProductCategoriesInUseAction = (companyId) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_IN_USE_REQUEST });

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/product/categories/${companyId}/`
    );

    dispatch({
      type: CATEGORIES_IN_USE_SUCCESS,
      payload: data.categories,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_IN_USE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ProductRelatedAction =
  (category_id, search, routeParamsCompanyId) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_RELATED_REQUEST });

      const {
        userDetails: { user },
      } = getState();

      const subscriptionActive = user?.subscriptionStatus?.active;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          category_id: category_id,
          search: search,
          user_status: subscriptionActive,
        },
      };

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/product/related/${routeParamsCompanyId}/`,
        config
      );

      dispatch({ type: PRODUCT_RELATED_SUCCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: PRODUCT_RELATED_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };
