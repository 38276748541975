import axios from "axios";
import {
  ADRESS_COMPANY_GET_REQUEST,
  ADRESS_COMPANY_GET_SUCCESS,
  ADRESS_COMPANY_GET_FAIL,
  //
  ADRESS_COMPANY_ADD_REQUEST,
  ADRESS_COMPANY_ADD_SUCCESS,
  ADRESS_COMPANY_ADD_FAIL,
  //
  ADRESS_COMPANY_PUT_REQUEST,
  ADRESS_COMPANY_PUT_SUCCESS,
  ADRESS_COMPANY_PUT_FAIL,
} from "../../constants/adressConstans";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const UpdateAdressCompanyAction =
  (adress) => async (dispatch, getState) => {
    const {
      userLogin: { userToken },
      userDetails: { company },
    } = getState();
    try {
      dispatch({ type: ADRESS_COMPANY_PUT_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/adress/update-adress-company/`,
        {
          company: company._id,
          ...adress,
        },
        config
      );

      dispatch({ type: ADRESS_COMPANY_PUT_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ADRESS_COMPANY_PUT_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AddAdressCompanyAction =
  (
    routeParamsCompanyId,
    postal_code,
    country,
    state,
    municipality,
    neighborhood,
    exterior_number,
    interior_number,
    street_1,
    street_2,
    combinedPhoneNumber,
    additional_instructions
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: ADRESS_COMPANY_ADD_REQUEST });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/adress/add-adress-company/`,
        {
          company: routeParamsCompanyId,
          postal_code,
          country,
          state,
          municipality,
          neighborhood,
          exterior_number,
          interior_number,
          street_1,
          street_2,
          telephone_number: combinedPhoneNumber,
          additional_instructions,
        },
        config
      );

      dispatch({ type: ADRESS_COMPANY_ADD_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: ADRESS_COMPANY_ADD_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AdressCompanyAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { company },
  } = getState();
  try {
    dispatch({ type: ADRESS_COMPANY_GET_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/adress/adress-company/${company._id}/`,
      config
    );

    dispatch({ type: ADRESS_COMPANY_GET_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ADRESS_COMPANY_GET_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};
