import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import {
  CART_ADD_ITEM,
  CART_ADD_ITEM_LOCAL_SUCCESS,
  CART_ADD_ITEM_LOCAL_RESET,
  CART_GET_TOTAL,
  CART_GET_ITEM_TOTAL,
  CART_GET_ITEMS,
  CART_UPDATE_ITEM,
  CART_REMOVE_ITEM,
  CART_EMPTY_CART,
  //
  CART_ADD_ITEM_REQUEST,
  CART_ADD_ITEM_SUCCESS,
  CART_ADD_ITEM_FAIL,
  CART_ADD_ITEM_RESET,
  //
  CART_GET_TOTAL_REQUEST,
  CART_GET_TOTAL_SUCCESS,
  CART_GET_TOTAL_FAIL,
  CART_GET_TOTAL_RESET,
  //
  CART_GET_ITEM_TOTAL_REQUEST,
  CART_GET_ITEM_TOTAL_SUCCESS,
  CART_GET_ITEM_TOTAL_FAIL,
  //
  CART_GET_ITEMS_REQUEST,
  CART_GET_ITEMS_SUCCESS,
  CART_GET_ITEMS_FAIL,
  //
  CART_UPDATE_ITEM_REQUEST,
  CART_UPDATE_ITEM_SUCCESS,
  CART_UPDATE_ITEM_FAIL,
  CART_UPDATE_ITEM_RESET,
  //
  CART_REMOVE_ITEM_REQUEST,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_FAIL,
  CART_REMOVE_ITEM_RESET,
  //
  CART_EMPTY_CART_REQUEST,
  CART_EMPTY_CART_SUCCESS,
  CART_EMPTY_CART_FAIL,
  //
  CART_SYNCH_CART_REQUEST,
  CART_SYNCH_CART_SUCCESS,
  CART_SYNCH_CART_FAIL,
  //
} from "../constants/cartConstants";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const EmptycartAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();

  if (userToken) {
    try {
      dispatch({ type: CART_EMPTY_CART_REQUEST });

      const userId = parseInt(user?.user?.id);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.delete(
        `${ApiGaleanaBiz}/api/companies/cart/empty-cart/`,
        { data: { user: userId } },
        config
      );

      dispatch({ type: CART_EMPTY_CART_SUCCESS, payload: data });
      dispatch(CartGetItemsAction());
      dispatch(CartGetTotalPriceAction());
      dispatch(CartGetTotalItemAction());
    } catch (err) {
      dispatch({
        type: CART_EMPTY_CART_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  }
};

export const DeleteItemAction = (item) => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();
  if (userToken) {
    try {
      dispatch({ type: CART_REMOVE_ITEM_REQUEST });

      const userId = parseInt(user?.user?.id);
      const ProductId = item?.product?.id;
      const cartItemId = item?.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.delete(
        `${ApiGaleanaBiz}/api/companies/cart/remove-item/`,
        { data: { item_id: cartItemId, product_id: ProductId, user: userId } },
        config
      );

      dispatch({ type: CART_REMOVE_ITEM_SUCCESS, payload: data });
      dispatch(CartGetItemsAction());
      dispatch(CartGetTotalPriceAction());
      dispatch(CartGetTotalItemAction());
    } catch (err) {
      dispatch({
        type: CART_REMOVE_ITEM_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  } else {
    let cart = [];

    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));

      const new_cart = cart.map((cart_item) => {
        const updatedItems = cart_item.items.filter((items) => {
          return items.id.toString() !== item.id.toString();
        });

        return {
          ...cart_item,
          items: updatedItems,
        };
      });

      dispatch({
        type: CART_REMOVE_ITEM,
        payload: new_cart,
      });

      dispatch(CartGetItemsAction());
      dispatch(CartGetTotalPriceAction());
      dispatch(CartGetTotalItemAction());
    }
  }
};

export const CartUpdateAction = (item, count) => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();

  if (userToken) {
    try {
      dispatch({ type: CART_UPDATE_ITEM_REQUEST });

      const userId = parseInt(user?.user?.id);
      const productId = item?.product?.id;
      const cartItemId = item?.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/cart/update-item/`,
        {
          user: userId,
          item_id: cartItemId,
          product_id: parseInt(productId),
          count: parseInt(count),
        },
        config
      );

      dispatch({ type: CART_UPDATE_ITEM_SUCCESS, payload: data });

      dispatch(CartGetItemsAction());
      dispatch(CartGetTotalPriceAction());
      dispatch(CartGetTotalItemAction());
    } catch (err) {
      dispatch({
        type: CART_UPDATE_ITEM_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  } else {
    let cart = [];

    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));

      const updatedCart = cart.map((company) => {
        const updatedItems = company.items.map((items) => {
          if (items.id.toString() === item.id.toString()) {
            return {
              ...items,
              count: parseInt(count),
            };
          }
          return items;
        });

        return {
          ...company,
          items: updatedItems,
        };
      });

      dispatch({
        type: CART_UPDATE_ITEM,
        payload: updatedCart,
      });

      dispatch(CartGetItemsAction());
      dispatch(CartGetTotalPriceAction());
      dispatch(CartGetTotalItemAction());
    }
  }
};

export const ResetCartAddItemAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();
  if (userToken) {
    dispatch({ type: CART_ADD_ITEM_RESET });
  } else {
    dispatch({ type: CART_ADD_ITEM_LOCAL_RESET });
  }
};

export const CartAddItemAction =
  (product, attributeValues, mxn_to_usd) => async (dispatch, getState) => {
    const {
      userLogin: { userToken },
      userDetails: { user },
    } = getState();
    if (userToken) {
      try {
        dispatch({ type: CART_ADD_ITEM_REQUEST });

        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${userToken.token}`,
          },
        };

        const ProductId = product?.id;
        const userId = parseInt(user?.user?.id);
        const attribute = attributeValues.map((ids) => ids.attributeId);

        const { data } = await axios.post(
          `${ApiGaleanaBiz}/api/companies/cart/add-item/`,
          {
            product_id: parseInt(ProductId),
            user: userId,
            attribute_values: attribute,
          },
          config
        );

        dispatch({ type: CART_ADD_ITEM_SUCCESS, payload: data });
        dispatch(CartGetItemsAction());
      } catch (err) {
        dispatch({
          type: CART_ADD_ITEM_FAIL,
          payload:
            err.response && err.response.data.detail
              ? err.response.data.detail
              : err.message,
        });
      }
    } else {
      let cart = [];
      const attribute = attributeValues.map((ids) => ids.attributeId);

      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      let foundMatchingCompany = false;

      for (const company of cart) {
        if (product.company_name === company.company) {
          let foundMatchingProduct = false;

          for (const item of company.items) {
            const attributeGroups = item.attribute_groups;

            const existIds =
              attribute.length <= attributeGroups.length &&
              attributeGroups.every((id) => attribute.includes(id));

            if (product.id === item.product.id) {
              if (existIds) {
                item.count += 1;
                foundMatchingProduct = true;
                break;
              }
            }
          }

          if (!foundMatchingProduct) {
            company.items.push({
              id: uuidv4(),
              count: 1,
              attribute_groups: attributeValues,
              product: product,
            });
          }

          foundMatchingCompany = true;
          break;
        }
      }

      if (!foundMatchingCompany) {
        const newCartItem = {
          id: uuidv4(),
          count: 1,
          attribute_groups: attributeValues,
          product: product,
        };

        const newCompany = {
          company: product.company_name,
          items: [newCartItem],
        };

        cart.push(newCompany);
      }

      localStorage.setItem("mxn_to_usd", mxn_to_usd);
      dispatch({
        type: CART_ADD_ITEM,
        payload: cart,
      });

      dispatch({
        type: CART_ADD_ITEM_LOCAL_SUCCESS,
      });
    }
  };

export const CartGetTotalItemAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();

  if (userToken) {
    try {
      dispatch({ type: CART_GET_ITEM_TOTAL_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const userId = user?.user?.id;

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/cart/get-item-total/${userId}`,
        config
      );

      dispatch({ type: CART_GET_ITEM_TOTAL_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: CART_GET_ITEM_TOTAL_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  } else {
    let cart = [];
    let total = 0;

    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));

      cart.forEach((company) => {
        const numItems = company.items.map((item) => {
          return item?.count;
        });

        total += numItems.reduce((a, b) => a + b, 0);
      });
    }

    const total_count = {
      total_items: total,
    };

    dispatch({
      type: CART_GET_ITEM_TOTAL,
      payload: total_count,
    });
  }
};

export const CartGetTotalPriceAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();

  if (userToken) {
    try {
      dispatch({ type: CART_GET_TOTAL_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const userId = user?.user?.id;

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/cart/get-total/${userId}`,
        config
      );

      dispatch({ type: CART_GET_TOTAL_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: CART_GET_TOTAL_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  } else {
    let total = 0;
    let cart = [];

    if (localStorage.getItem("cart")) {
      cart = JSON.parse(localStorage.getItem("cart"));

      cart.forEach((company) => {
        const subtotal = company.items.map((item) => {
          let basePrice = parseFloat(item.product.price);
          let additionalPrice = 0;

          if (item.attribute_groups.length > 0) {
            let categoryGroup = item.attribute_groups;

            const attributeprice = categoryGroup.map(
              (attribute) => attribute.attributePrice
            );
            additionalPrice = attributeprice
              .map((c) => parseFloat(c))
              .reduce((a, b) => a + b, 0);

            basePrice += additionalPrice;
          }

          if (item.product.discount > 0) {
            basePrice = item.product.discounted_price;
          }

          if (item.product.currency === "USD") {
            basePrice =
              basePrice * parseFloat(localStorage.getItem("mxn_to_usd") || 1);
          }

          return basePrice * parseFloat(item?.count);
        });

        total += subtotal.reduce((a, b) => a + b, 0);
      });
    }

    const sub_total = {
      total_cost: parseFloat(total.toFixed(2)),
    };

    dispatch({
      type: CART_GET_TOTAL,
      payload: sub_total,
    });
  }
};

export const CartGetItemsAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();

  if (userToken) {
    try {
      dispatch({ type: CART_GET_ITEMS_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const userId = user?.user?.id;

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/cart/cart-items/${userId}/`,
        config
      );

      dispatch({ type: CART_GET_ITEMS_SUCCESS, payload: data.cart });

      localStorage.setItem("cart", JSON.stringify(data));
    } catch (err) {
      dispatch({
        type: CART_GET_ITEMS_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  } else {
    dispatch({
      type: CART_GET_ITEMS,
    });
  }
};

export const CartSynchAction = () => async (dispatch, getState) => {
  const {
    userLogin: { userToken },
    userDetails: { user },
  } = getState();

  const userId = user?.user?.id;

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Token ${userToken.token}`,
    },
  };

  const cart_items = [];

  if (localStorage.getItem("cart")) {
    const cart = JSON.parse(localStorage.getItem("cart"));

    Object.values(cart || []).forEach((company) => {
      Object.values(company?.items || []).forEach((item) => {
        const cart_item = {};
        const attributeGroups = item?.attribute_groups.map(
          (ids) => ids.attributeId
        );

        cart_item.attribute_ids = attributeGroups;
        cart_item.product_id = item?.product?.id;
        cart_item.count = item?.count;
        cart_item.user = userId;

        cart_items.push(cart_item);
      });
    });
  }

  const body = JSON.stringify({ cart_items });

  try {
    dispatch({ type: CART_SYNCH_CART_REQUEST });

    const { data } = await axios.put(
      `${ApiGaleanaBiz}/api/companies/cart/synch/`,
      body,
      config
    );

    dispatch({ type: CART_SYNCH_CART_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CART_SYNCH_CART_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};
