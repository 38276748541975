import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import React from "react";

const PrivateUserorAdminRoute = () => {
  const userStatus = useSelector((state) => state.userDetails);
  const { user } = userStatus;

  const role = user?.user?.profile?.role;

  const isUserOrAdmin = role === "User" || role === "Admin";

  return isUserOrAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateUserorAdminRoute;
