import React, { useEffect } from "react";

import DeliveryNavigationComponents from "../../../components/navigation/Driver-Navigation/DriverNavigationComponents";
import { useDispatch, useSelector } from "react-redux";
import { DriverListOrdersAsign } from "../../../actions/driverActions";
import { Link } from "react-router-dom";

import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import formatCurrency from "../../../helpers/format/formatCurrency";

const OrdersListDriver = () => {
  const driver = JSON.parse(localStorage.getItem("DriverProfile"));

  const driver_id = driver?.id;

  const dispatch = useDispatch();

  const GetListOrdersAsign = useSelector((state) => state.GetListOrdersAsign);
  const { loading, error, DriverListOrders } = GetListOrdersAsign;

  useEffect(() => {
    dispatch(DriverListOrdersAsign(driver_id));
  }, [dispatch]);

  return (
    <DeliveryNavigationComponents>
      <ListOrder
        loading={loading}
        error={error}
        DriverListOrders={DriverListOrders}
      />
    </DeliveryNavigationComponents>
  );
};

export default OrdersListDriver;

const ListOrder = ({ loading, error, DriverListOrders }) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    <Message error={error}>
      An error occurred while loading the page, try again
    </Message>;
  }
  return (
    <div className="homeDriver">
      <div className="titleContainer">
        <div className="title">Your Assigned Orders</div>
      </div>

      <div className="orderCardsSection">
        <div className="orderCardsContainer">
          <div className="allOrdersWrapper">
            {DriverListOrders?.map((order, index) => {
              return (
                <div className="orderCardContainer" key={index}>
                  <Link
                    to={`/driver-order/${order?.transaction_id}/`}
                    key={index}
                    className="orderCard"
                  >
                    <div className="topSection">
                      <div className="nameAndLocationContainer">
                        <div className="nameTxt">
                          {order?.user_first_name} {order?.user_last_name}
                        </div>
                        <div className="locationTxt">
                          <i className="fa-solid fa-location-dot"></i>
                          {""} {order?.order_location}
                        </div>
                      </div>

                      <div className="dateTxt">
                        {new Date(order?.timestamp).toLocaleTimeString(
                          "en-US",
                          {
                            hour12: true,
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                        {" - "}
                        {new Date(order?.timestamp).toLocaleDateString(
                          "es-ES",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </div>
                    </div>
                    <div className="businessesSection">
                      <div className="title">Businesses in order:</div>

                      <div className="contentCompanies">
                        {order?.companies?.map((company, index) => (
                          <div className="businessName" key={index}>
                            {company?.company},
                          </div>
                        ))}
                      </div>
                    </div>

                    {order?.delivery_instructions && (
                      <div className="extraInfoSection">
                        {order?.delivery_instructions}
                      </div>
                    )}

                    <div className="priceContainer">
                      <span className="priceTxt">
                        {formatCurrency(order?.order_total)}
                      </span>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
