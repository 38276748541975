import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import "../../../styles/pages/CompanyPage/Services/ServiceDetailsPage.scss";

import { useGetServicesCompanyQuery } from "../../../services/galeanaBizApiCompanyService";
import { ImageLoader } from "../components/ImageLoader";

import AddToAllPages from "../../../components/AddToAllPages";
import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import FormatDiscount from "../../../helpers/format/FormatDiscount";

const ServiceDetailsPage = () => {
  const { slug } = useParams();

  const { data, isLoading, isFetching, error } = useGetServicesCompanyQuery({
    action: "details",
    parameter: "slug",
    value: slug,
  });

  return (
    <AddToAllPages>
      <ServiceDetailsPageContent
        data={data}
        slug={slug}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
      />
    </AddToAllPages>
  );
};

export default ServiceDetailsPage;

const ServiceDetailsPageContent = ({
  data,
  slug,
  isLoading,
  isFetching,
  error,
}) => {
  const [serviceDetail, setServiceDetails] = useState({});

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setServiceDetails(data);
  }, [data, setServiceDetails, isFetching]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Message error={error}>{error}</Message>;
  }

  return (
    <div className="serviceDetailsPage">
      <ImageLoader
        serviceSlug={serviceDetail.slug}
        photo={serviceDetail?.photo}
        slug={slug}
        className={"serviceImage"}
        alt={"service-img"}
      />

      <div className="infoSection">
        <div className="topSection">
          <div className="productName" id="productName">
            {serviceDetail.name}
          </div>
          <Link
            to={`/business/${data?.company?.pk}?label=${encodeURIComponent(
              "services"
            )}`}
          >
            Back to {data?.company?.name}
          </Link>
        </div>

        <div className="descriptionSection">
          <div className="sectionTitleText">Price per hour:</div>

          <FormatDiscount
            price={serviceDetail.hour_price}
            discount={0}
            discountedPrice={serviceDetail.hour_price}
            currency={serviceDetail.currency}
          />
        </div>

        <div className="descriptionSection">
          <div className="contactPhoneNumber">
            Contact seller: {serviceDetail.contact_phone}
          </div>
        </div>

        {serviceDetail.is_available ? null : (
          <div className="serivceNotAvailable">Serivce is Unavailable</div>
        )}

        <div className="descriptionSection">
          <div className="sectionTitleText">Description and Extra Details</div>
          <div className="descriptionText">{serviceDetail.description}</div>
        </div>
      </div>
    </div>
  );
};
