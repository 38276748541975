import { Link } from "react-router-dom";
import "./style/ErrorPopup.scss";

export const ErrorPopup = ({ message, onClose, link }) => {
  return (
    <div className="error-popup">
      <div className="error-popup-content">
        <p>{message}</p>
        <div className="btnContainer">
          {link && <Link className="link" to={link.url}>{link.text}</Link>}
          {onClose && <button onClick={onClose}>Close</button>}
        </div>
      </div>
    </div>
  );
};
