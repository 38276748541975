import React, { useEffect } from "react";
import { Outlet, Navigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CartGetItemsAction } from "../../actions/cartActions";

const PrivCartRoute = () => {
  const dispatch = useDispatch();
  const cartTotalItems = useSelector((state) => state.CartGetTotalItems);

  const CartItem = cartTotalItems?.CartTotalItems?.total_items;

  useEffect(() => {
    dispatch(CartGetItemsAction());
  }, [dispatch]);

  if (CartItem > 0) {
    return <Outlet />;
  } else {
    return <Navigate to="/cart" />;
  }
};

export default PrivCartRoute;
