import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// SCSS
import "../../../../styles/pages/CompanyPage/companybar/ContactCompany/CompanyContact.scss";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

const GetContact = ({ companyContact, ContactLoading, ContactError }) => {
  if (ContactLoading) {
    return <Loader />;
  }
  if (ContactError) {
    return <Message>{ContactError}</Message>;
  }

  return (
    <div className="companyContact">
      {Object.values(companyContact || []).map((contact) => {
        return (
          <>
            <div className="titleContainer">
              <h2>Contact Information</h2>
              <Link to={"/put-contact-company"} className="updateAboutLink">
                Update Contact
              </Link>
            </div>

            <div className="contactInformation">
              {contact?.address ? <p>Address: {contact?.address}</p> : null}
              {/* <p>
              Tel:
              <a
                href={`tel:${contact?.phone_number}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contact?.phone_number}
              </a>
            </p> */}

              {contact?.whatsapp_number ? (
                <a
                  href={`https://wa.me/${contact?.whatsapp_number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="whatsappLink"
                >
                  Message on WhatsApp <i className="fa-brands fa-whatsapp"></i>
                </a>
              ) : null}
              {contact?.email ? <p>Email: {contact?.email}</p> : null}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default GetContact;
