import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import GetContact from "../../../../CompanyPage/companybar/ContactCompany/GetContact";
import { useDispatch, useSelector } from "react-redux";
import { getContactActions } from "../../../../../actions/contactActions";

function ContactBiusiness() {
  const dispatch = useDispatch();

  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;

  const ContactGet = useSelector((state) => state.GetContact);
  const {
    loading: ContactLoading,
    error: ContactError,
    companyContact,
  } = ContactGet;

  useEffect(() => {
    dispatch(getContactActions(userCompanyId));
    document.title = `Contact - My Business`;
  }, [dispatch, userCompanyId]);

  return (
    <div className="aboutBusiness">
      {companyContact && Object.keys(companyContact).length === 0 ? (
        <div className="addBtnContainer">
          <Link to={"/post-contact-company"} className="addBtn">
            Add Contact
          </Link>
        </div>
      ) : null}

      <div>
        <GetContact
          companyContact={companyContact}
          ContactLoading={ContactLoading}
          ContactError={ContactError}
        />
      </div>
    </div>
  );
}

export default ContactBiusiness;
