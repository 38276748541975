import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import formatCurrency from "../../../helpers/format/formatCurrency";
import { useUpdateOrderItemStatusChangerMutation } from "../../../services/galeanaBizApi";
import { useParams } from "react-router-dom";
import { useDriverId } from "../../../hooks/useDriverId";
import { useUserRole } from "../../../hooks/useUserRole";

import { TiLockClosed } from "react-icons/ti";

const OrderItems = ({ orderDetails }) => {
  const [orderCompanies, setOrderCompanies] = useState([]);

  const [productSelected, setProductSelected] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    if (!orderDetails) {
      return;
    }
    setOrderCompanies(orderDetails?.order_companies);
  }, [orderDetails]);

  useEffect(() => {
    if (productSelected) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [productSelected]);

  const openSelectedProduct = (product) => {
    setProductSelected(true);
    setSelectedProduct(product);
  };

  const closeSelectedProduct = () => {
    setProductSelected(false);
  };

  return (
    <>
      {productSelected && (
        <ProductSelectedModal
          selectedProduct={selectedProduct}
          closeSelectedProduct={closeSelectedProduct}
          driver_has_order={orderDetails?.driver_has_order}
          isShipped={orderDetails?.status === "shipped"}
        />
      )}

      {orderCompanies.map((company, index) => {
        return (
          <OrderCompanies
            key={index}
            company={company}
            openSelectedProduct={openSelectedProduct}
          />
        );
      })}
    </>
  );
};

export default OrderItems;

const OrderCompanies = ({ company, openSelectedProduct }) => {
  return (
    <div className="companySection">
      <div className="header">
        <div className="title">{company?.company_name}</div>
        <div className="bottomInfo">
          <div>Total Price: {formatCurrency(company?.total)} MXN</div>
          <div>Items: {company?.orderItem.length}</div>
        </div>
      </div>
      <div className="companyDetails">
        <div className="infoText">
          <span>
            <i className="fa-solid fa-location-dot"></i> {company?.location}
          </span>
        </div>

        <div className="infoText phoneNumberContainer">
          <span>Phone: </span>
          {company?.telephone}

          <div className="containerLink">
            <a
              href={`tel:${company?.telephone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="iconContainer phoneIconContainer">
                <i className="fas fa-phone"></i>
              </div>
            </a>
          </div>
        </div>
        <div className="infoText">
          <span>Street 1: </span>
          {company?.street_1}
        </div>
        <div className="infoText">
          <span>Street 2: </span>
          {company?.street_2}
        </div>

        {company?.reference ? (
          <div className="infoText">
            <div>Refrences: </div>
            {company?.reference}
          </div>
        ) : null}
      </div>

      <div className="order">
        <div className="title">Order:</div>
        {company?.orderItem.map((item, index) => {
          return (
            <OrderItem
              key={index}
              item={item}
              openSelectedProduct={openSelectedProduct}
            />
          );
        })}
      </div>
    </div>
  );
};

const OrderItem = ({ item, openSelectedProduct }) => {
  const [moreAttribute, setMoreAttribute] = useState(false);

  const validateStatus = ["not_found", "canceled"];

  const notifyAlert = (status) => {
    let message = "";
    if (status === "canceled") {
      message = (
        <>
          "This item was canceled. Item total deducted from the company total
          and the final order total."
        </>
      );
    }

    if (status === "not_found") {
      message = (
        <>
          "This item was not found. Item total deducted from the company total
          and the final order total."
        </>
      );
    }
    if (status === null || status === undefined) {
      message = "Unknown status.";
    }

    toast(message);
  };

  return (
    <div className="productContainer">
      <div
        className="infoContainer"
        onClick={() => {
          openSelectedProduct(item);
        }}
      >
        <div className="contentImageProduct">
          <img src={item?.image_product} alt="" />
        </div>
        <div className="rightSideContainer">
          <div className="name">{item?.name}</div>
          <div className="quantity">Qty: {item?.count}</div>
          <div className="price">
            <div className="singlePrice">
              {item?.discount > 0 ? (
                <div className="priceDiscount">
                  <div className="discountedPrice">
                    {item?.cargo_extra > 0 ? (
                      <div>
                        {formatCurrency(item?.cargo_extra, item?.currency)}
                      </div>
                    ) : (
                      <div>
                        {formatCurrency(item?.discounted_price, item?.currency)}
                      </div>
                    )}
                    {item.currency}
                  </div>
                  <div className="discount">{item?.discount} %OFF</div>
                </div>
              ) : (
                <div className="priceOriginal">
                  {item?.cargo_extra > 0 ? (
                    <div>
                      {formatCurrency(item?.cargo_extra, item?.currency)}
                    </div>
                  ) : (
                    <div> {formatCurrency(item?.price, item?.currency)}</div>
                  )}
                  {item.currency}
                </div>
              )}
            </div>
            {item?.count > 0 ? (
              <span className="totalPrice">
                Total:
                {formatCurrency(item?.item_total, item?.currency)} MXN
              </span>
            ) : null}
          </div>
        </div>

        {item.attributes.length > 0 && (
          <div className="sectionAttributes">
            <button
              className="btnAttributes"
              onClick={() => {
                openSelectedProduct(item);
              }}
            >
              View Attributes...
            </button>

            {moreAttribute && (
              <ProductAttributes attributes={item.attributes} />
            )}
          </div>
        )}
      </div>

      <div className="inputContainer">
        <div className="checkboxWrapper">
          {validateStatus.includes(item?.status) ? (
            <TiLockClosed
              className="iconClosed"
              onClick={() => notifyAlert(item?.status)}
            />
          ) : (
            <>
              <input type="checkbox" />
              <div className="checkbox">
                <i className="fa-solid fa-check"></i>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ProductSelectedModal = ({
  selectedProduct,
  closeSelectedProduct,
  driver_has_order,
  isShipped,
}) => {
  const role = useUserRole();
  const driverId = useDriverId();
  const { transactionId } = useParams();

  const isDriver = role === "Galeana Biz driver";

  const [status, setStatus] = useState(null);

  const [updateOrderItemStatusChanger, updateOrderItemStatusChangerResult] =
    useUpdateOrderItemStatusChangerMutation();

  useEffect(() => {
    if (!selectedProduct.status) return;

    setStatus(selectedProduct.status);
  }, [selectedProduct]);

  useEffect(() => {
    if (updateOrderItemStatusChangerResult.isSuccess) {
      closeSelectedProduct();
      window.scrollTo(0, 0);
    }
  }, [updateOrderItemStatusChangerResult.isSuccess, closeSelectedProduct]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateOrderItemStatusChanger({
      orderItemId: selectedProduct.id,
      driverId: driverId,
      transactionId: transactionId,
      statusItem: status,
    }).then((response) => {
      if ("error" in response) {
        toast.error("Failed to update item status.");
        return;
      }
      toast.success("Item status updated successfully.");
    });
  };
  return (
    <div className="selectedProductModal">
      <div className="background" onClick={() => closeSelectedProduct()}></div>

      <div className="content">
        <div className="imgContainer">
          <img src={selectedProduct.image_product} alt="" />
        </div>
        <div className="infoContainer">
          <div className="infoPiece productName">{selectedProduct.name}</div>
          <div className="infoPiece productQuantity">
            Quantity: {selectedProduct.count}
          </div>
          <div className="infoPiece productPrice">
            {selectedProduct.discount > 0 ? (
              <div className="discountPrice">
                {selectedProduct?.cargo_extra > 0 ? (
                  <div className="price">
                    {formatCurrency(
                      selectedProduct?.cargo_extra,
                      selectedProduct?.currency
                    )}
                  </div>
                ) : (
                  <div className="price">
                    {formatCurrency(
                      selectedProduct?.discounted_price,
                      selectedProduct?.currency
                    )}
                  </div>
                )}

                <div className="contentPorcentageDisocunt">
                  <div className="porcentage">
                    {selectedProduct.discount}% OFF
                  </div>
                </div>
              </div>
            ) : (
              <div className="normalPrice">
                {selectedProduct?.cargo_extra > 0 ? (
                  <div className="price">
                    {formatCurrency(
                      selectedProduct?.cargo_extra,
                      selectedProduct?.currency
                    )}
                  </div>
                ) : (
                  <div className="price">
                    {formatCurrency(
                      selectedProduct?.price,
                      selectedProduct?.currency
                    )}
                  </div>
                )}

                <div className="contentCurrency">
                  <div className="currency">{selectedProduct.currency}</div>
                </div>
              </div>
            )}

            {selectedProduct.count > 0 ? (
              <div className="totalPrice">
                Total:{" "}
                {formatCurrency(
                  selectedProduct.item_total,
                  selectedProduct.currency
                )}{" "}
                MXN
              </div>
            ) : null}

            {isDriver && driver_has_order && isShipped ? (
              <div className="contentChangeStatus">
                <hr className="line" />
                <label className="labelDangerZone">Danger Zone</label>
                <form className="formItemStatus" onSubmit={handleSubmit}>
                  <select
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="approved">Approved</option>
                    <option value="canceled">Canceled</option>
                    <option value="not_found">Not Found</option>
                  </select>

                  <button
                    type="submit"
                    disabled={selectedProduct?.status === status}
                  >
                    save
                  </button>
                </form>
                <p>
                  "When changing the status to [cancelled or not found], the
                  item total is deducted from the company total and the final
                  order total. However, if it remains approved, the total stays
                  as originally added to the order."
                </p>
              </div>
            ) : (
              <div className="contentStatus">
                <label>Status: </label>
                <div className="itemStatus">{selectedProduct.status}</div>
              </div>
            )}
          </div>
        </div>

        <ProductAttributes attributes={selectedProduct.attributes} />
      </div>
    </div>
  );
};

const ProductAttributes = ({ attributes }) => {
  if (attributes.length === 0 || undefined) return null;
  return (
    <div className="sectionAttribute">
      <div className="contentTitleSection">
        <h1 className="titleSection">Attribute</h1>
      </div>

      <div className="wrapperAttributes">
        {attributes.map((attribute, index) => {
          return <ProductAttribute key={index} attribute={attribute} />;
        })}
      </div>
    </div>
  );
};

const ProductAttribute = ({ attribute }) => {
  return (
    <div className="contentAttribute">
      <ul>
        <li>
          <div className="categoryTitle">{attribute.category_title}</div>
          <ul>
            <li className="li">
              <div className="attributeName">{attribute.attribute_name}</div>
              <div className="attributePrice">
                + ${attribute.attribute_price}
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};
