import {
  ABOUT_GET_REQUEST,
  ABOUT_GET_SUCCESS,
  ABOUT_GET_FAIL,
  //
  ABOUT_ADD_REQUEST,
  ABOUT_ADD_SUCCESS,
  ABOUT_ADD_FAIL,
  ABOUT_ADD_RESET,
  //
  ABOUT_PUT_REQUEST,
  ABOUT_PUT_SUCCESS,
  ABOUT_PUT_FAIL,
  ABOUT_PUT_RESET,
} from "../constants/aboutConstans";

export const ListAboutCompanyReducer = (
  state = { CompanyAbout: [] },
  action
) => {
  switch (action.type) {
    case ABOUT_GET_REQUEST:
      return { loading: true };

    case ABOUT_GET_SUCCESS:
      return { loading: false, CompanyAbout: action.payload };

    case ABOUT_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const UpdateAboutReducer = (state = {}, action) => {
  switch (action.type) {
    case ABOUT_PUT_REQUEST:
      return { loading: true };

    case ABOUT_PUT_SUCCESS:
      return { loading: false, success: true };

    case ABOUT_PUT_FAIL:
      return { loading: false, error: action.payload };

    case ABOUT_PUT_RESET:
      return { ...state, success: false, loading: false };

    default:
      return state;
  }
};

export const AddAboutReducer = (state = {}, action) => {
  switch (action.type) {
    case ABOUT_ADD_REQUEST:
      return { loading: true };

    case ABOUT_ADD_SUCCESS:
      return { loading: false, success: true };

    case ABOUT_ADD_FAIL:
      return { loading: false, error: action.payload };

    case ABOUT_ADD_RESET:
      return { ...state, success: false, loading: false };

    default:
      return state;
  }
};
