import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DriverListOrdersAsign } from "../../../../actions/driverActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";
import { useCreateTakeOrderMutation } from "../../../../services/galeanaBizApi";

const BtnDriver = ({ driver, orderDetail }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [takeOrder, takeOrderResult] = useCreateTakeOrderMutation();

  const GetListOrdersAsign = useSelector((state) => state.GetListOrdersAsign);
  const { DriverListOrders } = GetListOrdersAsign;

  const ListTrsaction_id = Object.values(DriverListOrders || []).map((id) => {
    return id.transaction_id;
  });

  useEffect(() => {
    dispatch(DriverListOrdersAsign(driver?.id));
  }, [dispatch, driver?.id]);

  useEffect(() => {
    if (takeOrderResult.isSuccess) {
      navigate("/driver-order-list");
    }
  }, [takeOrderResult.isSuccess, dispatch, navigate]);

  const assingOrder = (transactionId) => {
    takeOrder({ driverId: driver?.id, transactionId }).then((response) => {
      if ("error" in response) {
        return;
      }
    });
  };

  if (!orderDetail) {
    return null;
  }

  if (orderDetail?.driver_has_order) {
    return null;
  }

  if (takeOrderResult.isLoading) {
    return <Loader />;
  }

  if (takeOrderResult.isError) {
    return (
      <Message error={takeOrderResult.isError}>Try again another time.</Message>
    );
  }

  return (
    <div className="adminBtns">
      {ListTrsaction_id.includes(orderDetail?.transaction_id) ||
      ListTrsaction_id.length >= 5 ? null : (
        <div
          className="diverCTAButton"
          onClick={() => assingOrder(orderDetail?.transaction_id)}
        >
          Take Order
        </div>
      )}
    </div>
  );
};

export default BtnDriver;
