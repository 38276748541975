import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";

export default function AdminOrDiverRoute() {
  const userStatus = useSelector((state) => state.userDetails);
  const { user } = userStatus;
  const role = user?.user?.profile?.role;

  return role === "Admin" || role === "Galeana Biz driver" ? <Outlet /> : <Navigate to="/" />;
}
