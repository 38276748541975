import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DriverAvailableActions,
  DriverDeliveryOrderAction,
  ResetDeliveryDriverStateAction,
} from "../../actions/driverActions";
import { DriverProfileActions } from "../../actions/driver-profileActions";

// scss
import "../../styles/pages/Driver/OrderDriver.scss";

// Components
import PreviewOrder from "./components/PreviewOrder";
import DeliveryNavigationComponents from "../../components/navigation/Driver-Navigation/DriverNavigationComponents";
import OrderDetails from "./orderDetails/OrderDetails";
import OrderItems from "./orderDetails/OrderItems";
import BtnDriver from "./orderDetails/btn/BtnDriver";
import BtnAdmin from "./orderDetails/btn/BtnAdmin";

import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useGetDriverOrderDetailQuery } from "../../services/galeanaBizApi";

import { galeanaBizApi } from "../../services/galeanaBizApi";

const OrderDriver = () => {
  const driver = JSON.parse(localStorage.getItem("DriverProfile"));
  const { transactionId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { success: DeliverySuccess } = useSelector(
    (state) => state.DriverDelivery
  );

  useEffect(() => {
    dispatch(DriverAvailableActions());
  }, [dispatch]);

  useEffect(() => {
    if (DeliverySuccess) {
      dispatch(DriverProfileActions());
      dispatch(ResetDeliveryDriverStateAction());
      navigate("/driver-order-list");
    }
  }, [dispatch, navigate, DeliverySuccess]);

  return (
    <DeliveryNavigationComponents>
      {!transactionId ? (
        <PreviewOrder />
      ) : (
        <div className="orderDriver">
          <div className="pageContentContainer">
            <div className="pageTitle">
              <h2>Order Details</h2>
            </div>

            <OrderDetailView driver={driver} transactionId={transactionId} />
          </div>
        </div>
      )}
    </DeliveryNavigationComponents>
  );
};

const OrderDetailView = ({ driver, transactionId }) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useSelector((state) => state.userDetails);
  const role = user?.user?.profile?.role;

  const {
    data: orderDetail,
    isLoading: isLoadingOrderDetail,
    isFetching: isFetchingOrderDetail,
    isError: isErrorOrderDetail,
  } = useGetDriverOrderDetailQuery({
    driverId: driver?.id,
    transactionId: transactionId,
  });

  useEffect(() => {
    if (!orderDetail || isFetchingOrderDetail) {
      return;
    }
    setOrderDetails(orderDetail);
  }, [orderDetail, isFetchingOrderDetail]);

  if (isLoadingOrderDetail) {
    return <Loader />;
  }

  if (isErrorOrderDetail) {
    return (
      <Message error={isErrorOrderDetail}>
        Could not get order details try again
      </Message>
    );
  }

  const sectionKeyWord = (
    <div className="sectionKeyWord">
      {orderDetails?.delivery_password && (
        <>
          <div className="title">Keyword:</div>
          <div className="containerKeyword">
            {orderDetails?.delivery_password}
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <OrderDetails orderDetails={orderDetails} />

      <OrderItems orderDetails={orderDetails} />

      {sectionKeyWord}

      <DriverSection role={role} driver={driver} orderDetail={orderDetail} />
      <AdminSection
        role={role}
        transactionId={transactionId}
        orderDetail={orderDetail}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default OrderDriver;

const AdminSection = ({
  role,
  transactionId,
  orderDetail,
  isOpen,
  setIsOpen,
}) => {
  if (role !== "Admin") {
    return null;
  }

  return (
    <div className="actionButtonsContainer">
      <BtnAdmin
        role={role}
        orderDetail={orderDetail}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        transactionId={transactionId}
      />
    </div>
  );
};

const DriverSection = ({ role, driver, orderDetail }) => {
  const dispatch = useDispatch();

  const transactionId = orderDetail?.transaction_id;

  const { loading: DeliveryLoading, error: DeliveryError } = useSelector(
    (state) => state.DriverDelivery
  );

  const [check, setCheck] = useState(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!check) {
      setDescription("");
    }
  }, [check]);

  const deliverd = (checkValue) => {
    setCheck(checkValue);
  };

  const orderFailed = (checkValue) => {
    setCheck(checkValue);
  };

  const cancel = () => {
    setCheck(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      DriverDeliveryOrderAction(driver?.id, transactionId, check, description)
    );
    dispatch(galeanaBizApi.util.invalidateTags(["Orders"]));

    if (check === true) {
      deliverd();
    }
    if (check === false) {
      orderFailed();
    }
  };

  if (role !== "Galeana Biz driver") {
    return null;
  }

  if (DeliveryLoading) {
    return <Loader />;
  }

  if (DeliveryError) {
    return;
  }

  return (
    <div className="actionButtonsContainer">
      {orderDetail && orderDetail.status === "shipped" && (
        <>
          {orderDetail?.driver_has_order && (
            <div className="buttonsContainer">
              <button
                className="orderFailedBtn"
                onClick={() => orderFailed(false)}
              >
                Order Failed
              </button>
              <button onClick={() => deliverd(true)}>Delivered</button>
            </div>
          )}
          <ModalConfirmFailed
            check={check}
            cancel={cancel}
            handleSubmit={handleSubmit}
            description={description}
            setDescription={setDescription}
          />
          <ModalConfirmDelivered
            check={check}
            cancel={cancel}
            handleSubmit={handleSubmit}
            description={description}
            setDescription={setDescription}
          />
        </>
      )}

      <BtnDriver role={role} orderDetail={orderDetail} driver={driver} />
    </div>
  );
};

const ModalConfirmFailed = ({
  check,
  cancel,
  handleSubmit,
  description,
  setDescription,
}) => {
  return (
    <>
      {check === false && (
        <div className="logoutModal">
          <div className="background"></div>

          <form onSubmit={handleSubmit}>
            <div className="title">Why did the order fail?</div>

            <div className="textareaContainer">
              <textarea
                value={description}
                placeholder="Place why the order failed here..."
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="buttonsContainer">
              <button onClick={cancel}>Cancel</button>
              <button type="submit" className="orderFailedBtn">
                Confirm Failed
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

const ModalConfirmDelivered = ({
  check,
  cancel,
  handleSubmit,
  description,
  setDescription,
}) => {
  return (
    <>
      {check === true && (
        <div className="logoutModal">
          <div className="background"></div>

          <form onSubmit={handleSubmit}>
            <div className="title">Any comments on the order?</div>

            <div className="textareaContainer">
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter a comment here..."
              />
            </div>

            <div className="buttonsContainer">
              <button type="button" onClick={cancel}>
                Cancel
              </button>
              <button className="orderDeliveredBtn" type="submit">
                Confirm
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
