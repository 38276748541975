import axios from "axios";
import {
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_FAIL,
  COMPANY_DETAILS_RESET,
  //
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_CREATE_FAIL,
  COMPANY_CREATE_RESET,
  //
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
  COMPANY_UPDATE_RESET,
  //
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCES,
  CATEGORY_LIST_FAIL,
  //
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_RESET,
  //
  COMPANY_GET_REVIEW_REQUEST,
  COMPANY_GET_REVIEW_SUCCCESS,
  COMPANY_GET_REVIEW_FAIL,
  COMPANY_GET_REVIEW_RESET,
  //
  COMPANY_REVIEW_REQUEST,
  COMPANY_REVIEW_SUCCCESS,
  COMPANY_REVIEW_FAIL,
  COMPANY_REVIEW_RESET,
  //
  COMPANY_REVIEW_UPDATE_REQUEST,
  COMPANY_REVIEW_UPDATE_SUCCCESS,
  COMPANY_REVIEW_UPDATE_FAIL,
  COMPANY_REVIEW_UPDATE_RESET,
} from "../constants/companyConstants";
import { GET_USER_COMPANY } from "../constants/userConstans";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export const updateCompanyAction =
  (company, categoryId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPANY_UPDATE_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      const userId = user?.user?.id;

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/${company._id}/`,
        {
          user_id: userId,
          category_id: categoryId,
          ...company,
        },
        config
      );

      dispatch({ type: COMPANY_UPDATE_SUCCESS, payload: data });

      dispatch({ type: COMPANY_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMPANY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createCompanyAction =
  (name, location, categoryId) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPANY_CREATE_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      const userId = user.user.id;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/create-business/`,
        {
          user_id: userId,
          name: name,
          location: location,
          category_id: categoryId,
        },
        config
      );

      dispatch({ type: COMPANY_CREATE_SUCCESS, payload: data });
      dispatch({ type: GET_USER_COMPANY, payload: data });
    } catch (error) {
      dispatch({
        type: COMPANY_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetlistCompanyDetailsAction = () => {
  return { type: COMPANY_DETAILS_RESET };
};

export const listCompanyDetailsAction =
  (routeParamsCompanyId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPANY_DETAILS_REQUEST,
      });

      const {
        userDetails: { user },
      } = getState();
      const userId = user?.user?.id;
      const url = userId
        ? `${ApiGaleanaBiz}/api/companies/${routeParamsCompanyId}/${userId}/`
        : `${ApiGaleanaBiz}/api/companies/${routeParamsCompanyId}/`;

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.get(url, config); // Note: I can probably make the backend url get company by ID but in frontend by name

      dispatch({ type: COMPANY_DETAILS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMPANY_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//CATEGORYCOMPNAY

export const CategoryDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_DETAILS_REQUEST,
    });

    const {
      userLogin: { userToken },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/categories/${id}/`,
      config
    ); // Note: I can probably make the backend url get company by ID but in frontend by name

    dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const CompanyCategoryAction = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_LIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/categories/`,
      config
    );

    dispatch({ type: CATEGORY_LIST_SUCCES, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

//reviewscompanies//////////////////////////////////////////////////////////////////////////

export const resetlistCompanyReviewsAction = () => {
  return { type: COMPANY_GET_REVIEW_RESET };
};

export const listCompanyReviewsAction =
  (companyId) => async (dispatch, getState) => {
    const {
      userDetails: { user },
    } = getState();

    try {
      dispatch({
        type: COMPANY_GET_REVIEW_REQUEST,
      });

      const userId = user?.user?.id;
      const Id = parseInt(companyId);

      if (!Id) {
        return;
      }

      const config = {
        params: {
          user_id: userId,
        },
      };

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/list-reviews/${Id}/`,
        config
      ); // Note: I can probably make the backend url get company by ID but in frontend by name

      dispatch({
        type: COMPANY_GET_REVIEW_SUCCCESS,
        payload: {
          company_name: data.company_name,
          reviews: data.results,
          user_review: data.user_review,
          count: data.count,
          next: data.next,
          previous: data.previous,
          current_page: data.current_page,
        },
      });
    } catch (error) {
      dispatch({
        type: COMPANY_GET_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const companyUpdateReviewAction =
  (reviewId, rating, comment) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPANY_REVIEW_UPDATE_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      const userId = user.user.id;
      const profileImageURL = user.user.profile.image;
      const userName = user.user.first_name;
      const lastName = user.user.last_name;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/reviePut/${reviewId}`,
        {
          user_id: userId,
          profile_image: profileImageURL,
          name: userName,
          last_name: lastName,

          rating: rating,
          comment: comment,
        },

        config
      );

      dispatch({ type: COMPANY_REVIEW_UPDATE_SUCCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMPANY_REVIEW_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const companyReviewAction =
  (routeParamsCompanyId, rating, comment) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPANY_REVIEW_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      const userId = user.user.id;
      const userName = user.user.first_name;
      const lastName = user.user.last_name;
      const profileImageURL = user.user.profile.image;

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/reviewPost/${routeParamsCompanyId}/`,
        {
          user_id: userId,
          name: userName,
          last_name: lastName,
          profile_image: profileImageURL,
          rating: rating,
          comment: comment,
        },
        config
      );

      dispatch({ type: COMPANY_REVIEW_SUCCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: COMPANY_REVIEW_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
