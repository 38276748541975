export default function parsePhoneNumber(phoneNumber) {
  // Check if the phone number starts with '+52' or '+1'
  let countryCode = "";
  let formattedNumber = "";

  if (phoneNumber.startsWith("+52")) {
    // Remove the '+' and store the country code
    countryCode = phoneNumber.slice(0, 3);

    // Remove the country code and spaces from the rest of the number
    formattedNumber = phoneNumber.slice(3).replace(/\s+/g, "");

    return { countryCode, formattedNumber };
  }

  if (phoneNumber.startsWith("+1")) {
    // Remove the '+' and store the country code
    countryCode = phoneNumber.slice(0, 2);

    // Remove the country code and spaces from the rest of the number
    formattedNumber = phoneNumber.slice(2).replace(/\s+/g, "");

    return { countryCode, formattedNumber };
  }

  return { countryCode, formattedNumber };
}
