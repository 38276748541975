import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { CartUpdateAction } from "../../actions/cartActions";
import { ErrorPopup } from "../../helpers/popup/ErrorPopup";
import { useGetSettingsQuery } from "../../services/galeanaBizApi";

import "../../styles/pages/Cart/CartItemUnits.scss";

const CartItemUnits = ({ item, UpdateItemLoading }) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(1);
  const [showCartMaxAlert, setShowCartMaxAlert] = useState(false);

  const { data: settingsData, isFetching: fetchingSettings } =
    useGetSettingsQuery();
  const { loading: cartTotalLoading, CartTotalPrice: cartTotalObject } =
    useSelector((state) => state.CartGetTotalPrice);

  const dispatch = useDispatch();

  const handleShow = () => {
    setShow(true);
    document.body.style.overflow = "hidden";
  };

  const handleHide = () => {
    setShow(false);
    document.body.style.overflow = "auto";
  };

  const updateUnits = (newValue) => {
    if (newValue > 0) {
      let productPrice =
        item.product.discount > 0
          ? Number(item.product.discounted_price)
          : Number(item.product.price);
      if (item.product.currency === "USD") {
        productPrice = productPrice * settingsData.settings.usd_mxn_value;
      }

      const totalWithoutCurrentItem =
        cartTotalObject.total_cost - productPrice * item.count;
      const newTotal = totalWithoutCurrentItem + productPrice * newValue;
      if (newTotal > 10000) {
        setShowCartMaxAlert(true);
        return;
      }

      dispatch(CartUpdateAction(item, newValue));
      handleShow();
    }

    handleHide();
  };

  useEffect(() => {
    setValue(item?.count);
  }, [item?.count]);

  return (
    <>
      {UpdateItemLoading || cartTotalLoading || fetchingSettings ? (
        <>Loading...</>
      ) : (
        <div className="quantitySelector">
          <div className="amountOfUnitsContainer">
            <div className="amountOfUnits">
              <div
                className="changeAmountBtn"
                onClick={() => updateUnits(item?.count - 1)}
              >
                <i class="fa-solid fa-minus"></i>
              </div>
              <div className="amountSelectedBtn" onClick={handleShow}>
                {item?.count}
              </div>
              <div
                className="changeAmountBtn"
                onClick={() => updateUnits(item?.count + 1)}
              >
                <i class="fa-solid fa-plus"></i>
              </div>
            </div>
          </div>

          {showCartMaxAlert && (
            <ErrorPopup
              message="You can add a maximum of $10,000.00 MXN to your cart."
              onClose={() => setShowCartMaxAlert(false)}
            />
          )}

          {show && (
            <div className="overlayActive">
              <div className="background" onClick={handleHide}></div>

              <div className="modal">
                <div className="topSectionContainer">
                  <div className="titleSectionContainer">
                    <div className="title">Add more units</div>
                  </div>
                </div>
                <div className="inputContainer">
                  <input
                    className="inputUnits"
                    placeholder="0"
                    type="number"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    min="1"
                    max="1000"
                  />
                </div>

                <button
                  type="button"
                  className="saveBtn"
                  onClick={() => updateUnits(Number(value))}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CartItemUnits;
