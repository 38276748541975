import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getContactActions } from "../../../../actions/contactActions";
import { getSocialNetworkActions } from "../../../../actions/socialNetworkActions";

// SCSS
import "../../../../styles/pages/CompanyPage/companybar/ContactCompany/CompanyContact.scss";

const CompanyContact = ({ companyId }) => {
  const dispatch = useDispatch();

  const ContactGet = useSelector((state) => state.GetContact);
  const {
    loading: ConatactLoading,
    error: ContactError,
    companyContact,
  } = ContactGet;

  const SocialGet = useSelector((state) => state.GetSocial);
  const {
    loading: SocialLoading,
    error: SocialError,
    companySocialNetwork,
  } = SocialGet;

  useEffect(() => {
    dispatch(getContactActions(companyId));
    dispatch(getSocialNetworkActions(companyId));
  }, [dispatch]);

  return (
    <div className="companyContact">
      <div className="titleContainer">{/* <h2>Contact Us</h2> */}</div>

      {/* {Object.keys(companyContact).length === 0 ? (
        <div className="aboutDoesntExistTxt">
          Business doesnt have an about page
        </div>
      ) : (
        <>
          {Object.values(companyContact || []).map((contact) => {
            return (
              <div className="contactInformation">
                <p>Address: {contact?.address}</p>
                <a
                  href={`tel:${contact?.whatsapp_number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="callLink"
                >
                  <i className="fa-solid fa-phone-volume"></i>
                  Call
                </a>

               <a
                href={`https://wa.me/${contact?.whatsapp_number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="whatsappLink"
              >
                Message on WhatsApp <i className="fa-brands fa-whatsapp"></i>
              </a> 

              <a
                href={`tel:${contact?.whatsapp_number}`}
                target="_blank"
                rel="noopener noreferrer"
                className="callLink"
              >
                <i className="fa-solid fa-phone-volume"></i>
                Call
              </a>

                <p>Email: {contact?.email}</p>
              </div>
            );
          })}
        </>
      )}  */}

      {companyContact && Object.keys(companyContact).length > 0 ? (
        <>
          {Object.values(companyContact || []).map((contact) => {
            return (
              <>
                <div className="contactInformation">
                  <p>Address: {contact?.address}</p>
                  <a
                    href={`tel:${contact?.whatsapp_number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="callLink"
                  >
                    <i className="fa-solid fa-phone-volume"></i>
                    Call
                  </a>

                  <p>Email: {contact?.email}</p>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <div className="contactDoesntExistTxt">
          Business doesnt have a contact page
        </div>
      )}
    </div>
  );
};

export default CompanyContact;
