import React from "react";
import { Link } from "react-router-dom";

// SCSS
// import "../styles/components/CompanyAndProductCards/ProductsCard.scss";

import { VscPinned } from "react-icons/vsc";
import { GoVerified } from "react-icons/go";
import formatCurrency from "../../../helpers/format/formatCurrency";
import { shortText } from "../../../helpers/text/shortText";

const ServicePinnedCard = ({ service }) => {
  const path = `/business/service/${service.slug}`;
  return (
    <div className="productCardContainer">
      <div className="productCard">
        <Link to={path} className="productCardLink">
          <div className="contenPinned">
            <VscPinned className="isPinned" />
          </div>
          <div className="imgContainer">
            {service?.is_available ? null : (
              <div className="soldOutOverlay">
                <div className="overlayText">Sold Out</div>
              </div>
            )}

            <img src={service?.photo} className="productImg" />
          </div>

          <div className="bottomContainer">
            <div className="contentVerfied">
              <GoVerified className="GoVerified" />
            </div>

            <div className="nameAndBusinessContianer">
              <div className="productName" id="productName">
                {shortText(service?.name, 10)}
              </div>

              {service?.rating > 0 && (
                <div className="rating">
                  <span>{service?.rating}</span>
                </div>
              )}
            </div>

            <div className="priceContainer">
              <span className="price">
                <span className="integer">
                  {formatCurrency(service?.hour_price, service?.currency)}
                </span>
              </span>
              <span className="currency">{service?.currency}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ServicePinnedCard;
