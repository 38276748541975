import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faBuilding } from "@fortawesome/free-solid-svg-icons";

import { FAVORITES_PAGE_SIZE } from "../../../constants/favoritesConstants";
import {
  getFavorites,
  incrementSidebarLimit,
} from "../../../actions/favoritesActions";

import { format, isAfter, isBefore, isWithinInterval, parse } from "date-fns";

import "../../../styles/components/navigation/FooterAndSidebars/SmallSidebar.scss";
import { useGetOpeningHoursQuery } from "../../../services/galeanaBizApi";

function SmallSidebar({ currentPageUrl }) {
  const GetCartTotalItems = useSelector((state) => state.CartGetTotalItems);
  const { CartTotalItems } = GetCartTotalItems;

  const { userDetails, favorites } = useSelector((state) => state);
  const role = userDetails.user?.user?.profile?.role;
  const dispatch = useDispatch();

  const loadMoreFavorites = () => {
    if (
      favorites.sideBarLimit * FAVORITES_PAGE_SIZE <
      favorites.entries.length
    ) {
      dispatch(incrementSidebarLimit());
      return;
    }

    dispatch(getFavorites(favorites.next, true));
  };

  return (
    <div className="smallSidebar">
      <div className="linksContainer">
        {role === "Galeana Biz driver" ? null : (
          <>
            <Link to="/" className={currentPageUrl("")}>
              <div className="iconContainer">
                <FontAwesomeIcon icon={faHouse} className="linkIcon" />
              </div>
              <div className="linkTextContainer">
                <div className="linkText">Home</div>
              </div>
            </Link>

            <Link
              to="/home-business"
              className={currentPageUrl("home-business")}
            >
              <div className="iconContainer">
                <FontAwesomeIcon icon={faBuilding} className="linkIcon" />
              </div>
              <div className="linkTextContainer">
                <div className="linkText">Business</div>
              </div>
            </Link>

            <Link to="/favorites" className={currentPageUrl("favorites")}>
              <div className="iconContainer">
                <i className="fa-solid fa-heart"></i>
              </div>
              <div className="linkTextContainer">
                <div className="linkText">Favorites</div>
              </div>
            </Link>

            <Link to="/cart" className={currentPageUrl("cart")}>
              <div className="cartIconContainer">
                <i className="fa-solid fa-cart-shopping"></i>
                <span className="cartTotalItems">
                  {CartTotalItems &&
                    (CartTotalItems?.total_items !== undefined) !== null &&
                    CartTotalItems?.total_items !== undefined &&
                    CartTotalItems?.total_items !== 0 &&
                    `${CartTotalItems?.total_items}`}
                </span>
              </div>
              <div className="linkTextContainer">
                <div className="linkText">Cart</div>
              </div>
            </Link>

            <Link to="/myorders" className={currentPageUrl("myorders")}>
              <div className="iconContainer">
                <i className="fas fa-box"></i>
              </div>
              <div className="linkTextContainer">
                <div className="linkText">My Orders</div>
              </div>
            </Link>

            <Link to="/mybusiness" className={currentPageUrl("mybusiness")}>
              <div className="iconContainer">
                <i className="fa-solid fa-briefcase"></i>
              </div>
              <div className="linkTextContainer">
                <div className="linkText">My Business</div>
              </div>
            </Link>
          </>
        )}

        {role === "Galeana Biz driver" || role === "Admin" ? (
          <>
            <Link to="/driver" className={currentPageUrl("driver")}>
              <div className="iconContainer">
                <i className="fa-solid fa-truck"></i>
              </div>
              <div className="linkTextContainer">
                <div className="linkText">Delivery</div>
              </div>
            </Link>
          </>
        ) : null}

        {role === "Galeana Biz driver" || role === "Admin" ? (
          <>
            <Link to="/admin" className="pageLink">
              <div className="iconContainer">
                <i className="fa-solid fa-lock" />
              </div>
              <div className="linkTextContainer">
                <div className="linkText">Admin</div>
              </div>
            </Link>
          </>
        ) : null}
      </div>

      {favorites?.entries?.length > 0 ? (
        <>
          {role === "Galeana Biz driver" ? null : (
            <>
              <div className="favoritesSectionContainer">
                <div className="favoritesTitleContainer">
                  <div className="titleText">Favorite Businesses</div>
                </div>

                <div className="favoritesContainer">
                  {favorites.entries
                    .slice(0, favorites.sideBarLimit * FAVORITES_PAGE_SIZE)
                    .map((favorite) => {
                      return (
                        <Link
                          key={favorite.id}
                          to={`/business/${favorite.company._id}`}
                          // className="favorite"
                          className={`favorite ${currentPageUrl(
                            `business/${favorite.company._id}`
                          )}`}
                        >
                          <div className="imageContainer">
                            <img
                              src={favorite.company.profileImage}
                              alt="Biz Logo"
                              draggable="false"
                            />

                            <BusinessIsClosed company={favorite.company} />
                          </div>
                          <div className="companyName">
                            {favorite.company.name}
                          </div>
                          <div className="companyLocation">
                            <i class="fa-solid fa-location-dot"></i>{" "}
                            {favorite.company.location}
                          </div>
                        </Link>
                      );
                    })}
                  {(!!favorites.next ||
                    favorites.sideBarLimit * FAVORITES_PAGE_SIZE <
                      favorites.entries.length) && (
                    <button
                      disabled={favorites.loading}
                      className="seeMore"
                      onClick={loadMoreFavorites}
                    >
                      <div>See more</div>

                      <i className="fa-solid fa-chevron-down"></i>
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </div>
  );
}

export default SmallSidebar;

const BusinessIsClosed = ({ company }) => {
  const [weekDays, setWeekDays] = useState([]);

  const { data, isFetching } = useGetOpeningHoursQuery({
    companyId: company._id,
  });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setWeekDays(data.days);
  }, [data, isFetching]);

  const businessIsClosed = () => {
    const now = new Date();
    const currentWeekDay = format(now, "EEEE");

    for (const day of weekDays) {
      if (currentWeekDay === day.name) {
        if (day.closed === null || day.closed) {
          return false;
        }

        const openingTime = parse(
          day.schedule?.[0]?.opens,
          "HH:mm",
          new Date()
        );
        const closingTime = parse(
          day.schedule?.[0]?.shuts,
          "HH:mm",
          new Date()
        );

        if (isBefore(now, openingTime) || isAfter(now, closingTime)) {
          return true;
        }

        const breakStartString = day.break?.[0]?.start;
        const breakEndString = day.break?.[0]?.end;

        if (breakStartString && breakEndString) {
          const breakStartTime = parse(breakStartString, "HH:mm", new Date());
          const breakEndTime = parse(breakEndString, "HH:mm", new Date());

          if (
            isWithinInterval(now, { start: breakStartTime, end: breakEndTime })
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return (
    <>
      {businessIsClosed() && (
        <div className="closedTextContainer">
          <div className="closedText">Closed</div>
        </div>
      )}
    </>
  );
};
