import React from "react";

import MyBusinessNavbar from "./MyBusinessNavbar";
import BothSidebars from "./BothSidebars";
import MobileFooter from "./MobileFooter";

function NavigationComponents(props) {
  const currentPage = window.location.href;

  const localhost = "http://localhost:3000/";
  const networkServer = "http://192.168.1.236:3000/#/";
  const livePageUrl = "https://galeana.biz/";

  // const currentPageUrl = (pageUrl) => {
  //   if (currentPage === `${localhost}${pageUrl}`) {
  //     return "activePageLink";
  //   } else if (currentPage === `${networkServer}${pageUrl}`) {
  //     return "activePageLink";
  //   } else if (currentPage === `${livePageUrl}${pageUrl}`) {
  //     return "activePageLink";
  //   } else {
  //     return "pageLink";
  //   }
  // };

  const currentPageUrl = (pageUrl, secondaryUrl, thirdUrl, fourthUrl) => {
    if (
      currentPage === `${localhost}${fourthUrl}` ||
      currentPage === `${localhost}${thirdUrl}` ||
      currentPage === `${localhost}${secondaryUrl}` ||
      currentPage === `${localhost}${pageUrl}`
    ) {
      return "activePageLink";
    } else if (
      currentPage === `${livePageUrl}${fourthUrl}` ||
      currentPage === `${livePageUrl}${thirdUrl}` ||
      currentPage === `${livePageUrl}${secondaryUrl}` ||
      currentPage === `${livePageUrl}${pageUrl}`
    ) {
      return "activePageLink";
    } else {
      return "pageLink";
    }
  };

  return (
    <>
      <MyBusinessNavbar />
      <BothSidebars currentPageUrl={currentPageUrl} />
      <div className="thePage">{props.children}</div>
      <div className="mobileFooterContainer">
        <MobileFooter currentPageUrl={currentPageUrl} />
      </div>
    </>
  );
}

export default NavigationComponents;
