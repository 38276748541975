import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DeliveryNavigationComponents from "../../../../components/navigation/Driver-Navigation/DriverNavigationComponents";
import { DriverOnDeliveryActions } from "../../../../actions/driverActions";
import { Link } from "react-router-dom";

//style
import "../../../../styles/pages/Driver/pages/OrdersInProgress.scss";

const OrdersInProgress = () => {
  const dispatch = useDispatch();

  const Delivery = useSelector((state) => state.Delivery);
  const {
    loading: DeliveryLoading,
    error: DeliveryError,
    OrderInProgress,
  } = Delivery;

  useEffect(() => {
    dispatch(DriverOnDeliveryActions());
  }, [dispatch]);

  return (
    <DeliveryNavigationComponents>
      <div className="contentOrdersInProgress">
        <div className="pageTitle">Orders In Progress</div>

        <div className="ordersContainer">
          {Object.values(OrderInProgress || []).map((on_delivery, index) => {
            const driver_ids = on_delivery?.driver_id;

            return (
              <Link
                className="orderInProgressCard"
                to={`/driver-order/${on_delivery?.transaction_id}/${driver_ids}/`}
                key={index}
              >
                <div className="cardInfo cardInfo__marginBottom">
                  <span className="title">Driver:</span>
                  <span className="info">{on_delivery?.username}</span>
                </div>

                <div className="cardInfo">
                  <span className="title cardInfo__fontSize">Client Name:</span>
                  <span className="info">
                    {on_delivery?.order_user_first_name}{" "}
                    {on_delivery?.order_user_last_name}
                  </span>
                </div>

                <div className="cardInfo cardInfo__fontSize cardInfo__marginBottom">
                  <span className="title">Client Number:</span>
                  <span className="info">
                    {on_delivery?.order_user_thelephone}
                  </span>
                </div>

                <div className="cardInfo cardInfo__fontSize cardInfo__marginBottom">
                  <span className="title">Companies in Order:</span>
                  {Object.values(on_delivery?.companies).map(
                    (companies, index) => (
                      <div className="info" key={index}>
                        {companies?.company}
                      </div>
                    )
                  )}
                </div>

                <div className="cardInfo cardInfo__fontSize">
                  <span className="title">Transaction Id:</span>
                  <div className="info">{on_delivery?.transaction_id}</div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </DeliveryNavigationComponents>
  );
};

export default OrdersInProgress;
