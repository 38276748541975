import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useScreenWidth } from "../../hooks/useScreenWidth";

import "../../styles/components/navigation/Navbar.scss";

import LGTreeLogo from "../../images/placeholders/GBiz-logo.png";

import { FaSearch } from "react-icons/fa";

import DefaultProfileImg from "../../images/default-profile-icon.jpg";
import { useDispatch, useSelector } from "react-redux";

import { COMPANIES_FILTERS_UPDATE } from "../../constants/companyConstants";

import { PRODUCTS_HOME_FILTER_UPDATE } from "../../constants/productsConstans";

function Navbar() {
  const [currentPage, setCurrentPage] = useState("products");
  const [showSearch, setShowSearch] = useState(false);
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);

  const { user } = useSelector((state) => state.userDetails);
  const image = user?.user?.profile?.image;

  const locationPathName = window.location.pathname;

  useEffect(() => {
    if (locationPathName === "/") {
      setCurrentPage("products");
    } else if (locationPathName === "/home-business") {
      setCurrentPage("business");
    }
  }, [locationPathName]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();

      window.deferredPrompt = event;

      setIsReadyForInstall(true);
    });
  }, []);

  const searchDisabledHandler = () => {
    document.body.style.overflow = "auto";
    setShowSearch(false);
  };

  const downladApp = async () => {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }
    promptEvent.prompt();
    window.deferredPrompt = null;
    setIsReadyForInstall(false);
  };

  return (
    <>
      <div className="navbar">
        <div className={`contentContainer`}>
          {!showSearch && (
            <div className="sectionLeft">
              <Link to={"/"} className={`logoContainer  `}>
                <img src={LGTreeLogo} alt="" className="logoImg" />
                <div
                  className={` titleContainer ${
                    showSearch ? "none" : "titleContainer"
                  } `}
                >
                  <div className="title">Galeana.biz</div>
                </div>
              </Link>

              {isReadyForInstall && (
                <div className="downloadGb">
                  <button onClick={downladApp}>Download Galeana.biz</button>
                </div>
              )}
            </div>
          )}

          <div className={`iconsContainer ${showSearch ? "show" : ""}`}>
            <SearchrBars
              showSearch={showSearch}
              setShowSearch={setShowSearch}
              user={user}
              currentPage={currentPage}
            />

            {!showSearch && (
              <Link to="/profile" className={`profilePicture`}>
                {image ? (
                  <img src={image} alt="" className="profilePictureImg" />
                ) : (
                  <img
                    src={DefaultProfileImg}
                    alt=""
                    className="profilePictureImg"
                  />
                )}
              </Link>
            )}
          </div>
        </div>
      </div>

      {showSearch ? (
        <div
          className="darkenBackground"
          onClick={() => searchDisabledHandler()}
        ></div>
      ) : null}
    </>
  );
}

export default Navbar;

const SearchrBars = ({ showSearch, setShowSearch, user, currentPage }) => {
  const isDriver = user?.user?.profile?.role === "Galeana Biz driver";

  if (isDriver) return null;

  return (
    <div className={` ${showSearch ? "searchwrapper" : "iconSearch"}`}>
      {currentPage === "products" ? (
        <SearchBarProducts
          showSearch={showSearch}
          setShowSearch={setShowSearch}
        />
      ) : (
        <SearchBarCompanies
          showSearch={showSearch}
          setShowSearch={setShowSearch}
        />
      )}
    </div>
  );
};

const SearchBarCompanies = ({ showSearch, setShowSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const pathname = window.location.pathname === "/home-business";

  const { search, category } = useSelector((state) => state.comapanyFilters);

  const [searchText, setSearchText] = useState("");
  const [isCategoryFiltered, setIsCategoryFiltered] = useState(false);
  const [prevCategory, setPrevCategory] = useState(null);

  const handleSearch = () => {
    if (searchText) {
      dispatch({
        type: COMPANIES_FILTERS_UPDATE,
        payload: { search: searchText },
      });
      setShowSearch(false);
    }
  };

  const handleClearSearch = useCallback(() => {
    dispatch({
      type: COMPANIES_FILTERS_UPDATE,
      payload: { search: "" },
    });
    setSearchText("");
  }, [dispatch, setSearchText]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleSearchBar = () => {
    setShowSearch(true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 15);
  };

  useEffect(() => {
    if (category !== prevCategory) {
      setIsCategoryFiltered(true);
      setPrevCategory(category);
    } else {
      setIsCategoryFiltered(false);
    }

    if (category !== 0 && isCategoryFiltered && search !== "") {
      handleClearSearch();
    }

    if (!!search) {
      setSearchText(search);
    }
  }, [
    navigate,
    search,
    category,
    prevCategory,
    handleClearSearch,
    isCategoryFiltered,
  ]);

  if (!pathname) return null;

  return (
    <div className={`searchBarContainer ${showSearch ? "show" : null}`}>
      <SearchBarPc
        inputRef={inputRef}
        searchText={searchText}
        setSearchText={setSearchText}
        handleClearSearch={handleClearSearch}
        handleKeyPress={handleKeyPress}
        handleSearch={handleSearch}
      />
      <SearchBarMobile
        inputRef={inputRef}
        showSearch={showSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        handleClearSearch={handleClearSearch}
        handleKeyPress={handleKeyPress}
        handleSearch={handleSearch}
        handleSearchBar={handleSearchBar}
      />
    </div>
  );
};

const SearchBarProducts = ({ showSearch, setShowSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const pathname = window.location.pathname === "/";

  const [searchText, setSearchText] = useState("");
  const [isCategoryFiltered, setIsCategoryFiltered] = useState(false);
  const [prevCategory, setPrevCategory] = useState(null);

  const { search, category } = useSelector(
    (state) => state.productsHomeFilters
  );

  const handleSearch = () => {
    if (searchText) {
      dispatch({
        type: PRODUCTS_HOME_FILTER_UPDATE,
        payload: { search: searchText },
      });

      setShowSearch(false);
    }
  };

  const handleClearSearch = useCallback(() => {
    dispatch({
      type: PRODUCTS_HOME_FILTER_UPDATE,
      payload: { search: "" },
    });
    setSearchText("");
  }, [dispatch, setSearchText]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleSearchBar = () => {
    setShowSearch(true);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 15);
  };

  useEffect(() => {
    if (category !== prevCategory) {
      setIsCategoryFiltered(true);
      setPrevCategory(category);
    } else {
      setIsCategoryFiltered(false);
    }

    if (category !== 0 && isCategoryFiltered && search !== "") {
      handleClearSearch();
    }

    if (!!search) {
      setSearchText(search);
    }
  }, [
    navigate,
    search,
    category,
    prevCategory,
    handleClearSearch,
    isCategoryFiltered,
  ]);

  if (!pathname) return null;

  return (
    <div className={`searchBarContainer ${showSearch ? "show" : null}`}>
      <SearchBarPc
        inputRef={inputRef}
        searchText={searchText}
        setSearchText={setSearchText}
        handleClearSearch={handleClearSearch}
        handleKeyPress={handleKeyPress}
        handleSearch={handleSearch}
      />
      <SearchBarMobile
        inputRef={inputRef}
        showSearch={showSearch}
        searchText={searchText}
        setSearchText={setSearchText}
        handleClearSearch={handleClearSearch}
        handleKeyPress={handleKeyPress}
        handleSearch={handleSearch}
        handleSearchBar={handleSearchBar}
      />
    </div>
  );
};

const SearchBarPc = (props) => {
  const screen_width = useScreenWidth();

  const pathname = window.location.pathname === "/";

  if (screen_width < "800") return null;

  return (
    <>
      <input
        type="text"
        placeholder={
          pathname
            ? "Search for Products in Galeana.Biz"
            : "Search for Business in Galeana.Biz"
        }
        className="searchBarInput"
        name="busqueda"
        autoComplete="off"
        ref={props.inputRef}
        value={props.searchText}
        onChange={(e) => {
          const value = e.target.value;
          props.setSearchText(value);
          if (value === "") {
            props.handleClearSearch();
          }
        }}
        onKeyDown={props.handleKeyPress}
        required
      />

      <button className="searchBtn" type="button" onClick={props.handleSearch}>
        <FaSearch />
      </button>
    </>
  );
};

const SearchBarMobile = (props) => {
  const screen_width = useScreenWidth();

  const pathname = window.location.pathname === "/";

  if (screen_width > "800") return null;

  return (
    <>
      {props.showSearch && (
        <input
          type="text"
          placeholder={
            pathname
              ? "Search for Products in Galeana.Biz"
              : "Search for Business in Galeana.Biz"
          }
          className="searchBarMobileInput"
          name="busqueda"
          autoComplete="off"
          ref={props.inputRef}
          value={props.searchText}
          onChange={(e) => {
            const value = e.target.value;
            props.setSearchText(value);
            if (value === "") {
              props.handleClearSearch();
            }
          }}
          onKeyDown={props.handleKeyPress}
          required
        />
      )}

      {!props.showSearch ? (
        <div
          className="searchButtonMobileActive"
          onClick={props.handleSearchBar}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
      ) : (
        <button
          className="searchMobileBtn"
          type="button"
          onClick={props.handleSearch}
        >
          <FaSearch />
        </button>
      )}
    </>
  );
};
