import {
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_FAIL,
  COMPANY_DETAILS_RESET,
  //
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_CREATE_FAIL,
  COMPANY_CREATE_RESET,
  //
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
  COMPANY_UPDATE_RESET,
  //
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCES,
  CATEGORY_LIST_FAIL,
  //
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_RESET,
  //
  COMPANY_GET_REVIEW_REQUEST,
  COMPANY_GET_REVIEW_SUCCCESS,
  COMPANY_GET_REVIEW_FAIL,
  COMPANY_GET_REVIEW_RESET,
  //
  COMPANY_REVIEW_REQUEST,
  COMPANY_REVIEW_SUCCCESS,
  COMPANY_REVIEW_FAIL,
  COMPANY_REVIEW_RESET,
  //
  COMPANY_REVIEW_UPDATE_REQUEST,
  COMPANY_REVIEW_UPDATE_SUCCCESS,
  COMPANY_REVIEW_UPDATE_FAIL,
  COMPANY_REVIEW_UPDATE_RESET,
  //
  COMPANIES_FILTERS_UPDATE,
  //
  COMPANIES_OPENIG_HOURS_UPDATE,
} from "../constants/companyConstants";

export const companyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_UPDATE_REQUEST:
      return { loading: true };

    case COMPANY_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };

    case COMPANY_UPDATE_FAIL:
      return { loding: false, error: action.payload };

    case COMPANY_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const companyCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_CREATE_REQUEST:
      return { loading: true };

    case COMPANY_CREATE_SUCCESS:
      return { loading: false, success: true, company: action.payload };

    case COMPANY_CREATE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const companyDetailsReducer = (
  state = { loading: false, company: {} },
  action
) => {
  switch (action.type) {
    case COMPANY_DETAILS_REQUEST:
      return { loading: true, company: {} };

    case COMPANY_DETAILS_SUCCESS:
      return { loading: false, company: action.payload };

    case COMPANY_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const CompaniesFilterReducer = (
  state = {
    search: "",
    category: 0,
  },
  action
) => {
  switch (action.type) {
    case COMPANIES_FILTERS_UPDATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

//categoryCompany

export const categoryDetailsReducer = (state = { category: {} }, action) => {
  switch (action.type) {
    case CATEGORY_DETAILS_REQUEST:
      return { loading: true, ...state };

    case CATEGORY_DETAILS_SUCCESS:
      return { loading: false, category: action.payload };

    case CATEGORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case CATEGORY_DETAILS_RESET:
      return { category: {} };

    default:
      return state;
  }
};

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true, categories: [] };

    case CATEGORY_LIST_SUCCES:
      return { loading: false, categories: action.payload };

    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////reviewscompanies//////////////////////////////////////////////////////////////

export const ListReviewsCompanyReducers = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_GET_REVIEW_REQUEST:
      return { loading: true };

    case COMPANY_GET_REVIEW_SUCCCESS:
      const {
        reviews,
        user_review,
        count,
        next,
        previous,
        current_page,
        company_name,
      } = action.payload;

      return {
        loading: false,
        company_name: company_name,
        reviews: reviews,
        user_review: user_review,
        count: count,
        next: next,
        previous: previous,
        current_page: current_page,
      };

    case COMPANY_GET_REVIEW_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_GET_REVIEW_RESET:
      return {};

    default:
      return state;
  }
};

export const reviewsUpdateCompaniesReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_REVIEW_UPDATE_REQUEST:
      return { loading: true };

    case COMPANY_REVIEW_UPDATE_SUCCCESS:
      return { loading: false, success: true };

    case COMPANY_REVIEW_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_REVIEW_UPDATE_RESET:
      return { loading: false, success: false };

    default:
      return state;
  }
};

export const reviewsCompaniesReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_REVIEW_REQUEST:
      return { loading: true };

    case COMPANY_REVIEW_SUCCCESS:
      return { loading: false, success: true };

    case COMPANY_REVIEW_FAIL:
      return { loading: false, error: action.payload };

    case COMPANY_REVIEW_RESET:
      return {};

    default:
      return state;
  }
};
