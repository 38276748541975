import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AboutListAction,
  UpdateAboutActions,
} from "../../../../actions/aboutActions";
import { ABOUT_PUT_RESET } from "../../../../constants/aboutConstans";

// SCSS
import "../../../../styles/pages/CompanyPage/companybar/AboutCompany/PutAbout.scss";

// Components
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import { imageCompression } from "../../../../helpers/compressor/imageCompression";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

const PutAbout = () => {
  const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;
  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company?._id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const path = `/mybusiness/customization?label=${encodeURIComponent("about")}`;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fakeImage, setFakeImage] = useState(null);
  const [image, setImage] = useState(null);

  const [uploading, setUploading] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState({
    title: false,
    description: false,
    image: false,
  });

  const PutAbout = useSelector((state) => state.PutAbout);
  const { loading: LoadingPutAbout, error: ErrorPutAbout, success } = PutAbout;

  const AboutList = useSelector((state) => state.AboutList);
  const {
    loading: SocialLoading,
    error: SocialError,
    CompanyAbout,
  } = AboutList;
  const aboutId = CompanyAbout?.[0]?.id;

  useEffect(() => {
    const clearError = () => {
      setError({
        title: false,
        description: false,
        image: false,
      });
    };

    const timeoutId = setTimeout(clearError, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    if (success) {
      dispatch({ type: ABOUT_PUT_RESET });
      navigate(path, { replace: true });
    } else {
      if (!CompanyAbout?.[0]?.company_name || aboutId !== Number(aboutId)) {
        dispatch(AboutListAction(userCompanyId));
      } else {
        setTitle(CompanyAbout?.[0]?.title || null);
        setDescription(CompanyAbout?.[0]?.description || null);
        setFakeImage(CompanyAbout?.[0]?.image || null);
      }
    }
  }, [dispatch, navigate, userCompanyId, success, CompanyAbout]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const newError = { ...error };

    if (file) {
      try {
        const compressedImageFile = await imageCompression(file);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);

        setImage(compressedImageFile);
        setFakeImage(compressedImageURL);

        newError.image = false;
      } catch (error) {
        newError.image =
          "Error compressing the image Profile. Please try again.";
      }
    }
    setError(newError);

    if (newError.image) {
      // Cancela la presentación de la solicitud si la imagen no se pudo cargar
      return Promise.reject(newError.image);
    }
  };

  const uploadHandler = async (e) => {
    const newError = { ...error };
    const formData = new FormData();
    formData.append("image", image);
    formData.append("about_id", aboutId);
    formData.append("company_id", userCompanyId);

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${ApiGaleanaBiz}/api/companies/about/post-about-image/`,
        formData,
        config
      );

      const { data } = response;
      setImage(data);
      newError.image = false;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        newError.image = "The requested resource was not found.";
      } else {
        newError.image =
          "There was an error uploading the image. Please try again.";
      }
      throw error;
    } finally {
      setUploading(false);
    }

    setError(newError);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
      ...error,
      title: !title && "Title is required",
      description: !description && "Description is required",
    };

    const hashError = Object.values(newErrors).some(
      (error) => typeof error === "string"
    );

    if (image) {
      try {
        await uploadHandler();
      } catch (uploadError) {
        newErrors.image =
          "There was an error uploading the image. Please try again.";

        setIsSubmitting(false);
      }
    }

    setError(newErrors);

    if (hashError) {
      setIsSubmitting(false);
      return;
    }

    try {
      setIsSubmitting(true);

      const about = { title: title, description: description };
      dispatch(UpdateAboutActions(userCompanyId, aboutId, about));
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <NavigationComponents>
      {LoadingPutAbout ? (
        <>
          <Loader />
        </>
      ) : ErrorPutAbout ? (
        <>
          <Message error={ErrorPutAbout}>
            An error occurred while processing the POST request. Please try
            again.
          </Message>
        </>
      ) : (
        <div className="putAbout">
          <div className="imageAbout">
            <img src={fakeImage} alt="" className="img" />
          </div>

          <div className="imageInputContainer">
            <label
              className={`customFileUpload ${error.image ? "error" : null}`}
            >
              <div className="inputTitle">
                <div className="inputTitleText">Image</div>
              </div>
              <input
                className={error.image ? "error" : null}
                type="file"
                onChange={handleImageChange}
              />
            </label>
            {error.image && (
              <span className="error-message">{error.image}</span>
            )}
          </div>

          <form onSubmit={onSubmit} className="putAboutForm">
            <div className="inputContainer">
              <div className="inputTitle">
                <div className="inputTitleText">Title About</div>
              </div>
              <input
                type="text"
                className={error.title ? "error" : null}
                placeholder="Place title of about here..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {error.title && (
                <span className="error-message">{error.title}</span>
              )}
            </div>

            <div className="inputContainer">
              <div className="inputTitle">
                <div className="inputTitleText">Description</div>
              </div>
              <textarea
                type="text"
                className={error.description ? "error" : null}
                placeholder="Place description here..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {error.description && (
                <span className="error-message">{error.description}</span>
              )}
            </div>

            <div className="saveBtnContainer">
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      )}
    </NavigationComponents>
  );
};

export default PutAbout;
