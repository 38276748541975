import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// scss
import "../../../styles/components/navigation/ExtraPagesNavbar.scss";

function ExtraPagesNavbar(props) {
  return <div className="extraPagesNavbar">{props.children}</div>;
}

export const ExtraPagesButton = ({
  btnName,
  status,
  value,
  setValue,
  isSlug = false,
}) => {
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);

  const label = urlParams.get("label");
  const slug = urlParams.get("slug");

  useEffect(() => {
    if (!label) return;
    setValue(label);
  }, [label, setValue]);

  const handleCurrentPage = (status) => {
    const updatedUrl = `${window.location.pathname}?label=${status}`;

    if (slug && !isSlug) {
      urlParams.delete("slug");
    }
    setValue(status);
    navigate(updatedUrl, { replace: true });
  };

  const isActive = value === status;

  return (
    <button
      onClick={() => handleCurrentPage(status)}
      className={isActive ? "activePageLink" : "pageLink"}
    >
      {btnName}
    </button>
  );
};

export function ExtraPagesLinks({ linkName, url }) {
  const currentPage = window.location.href;

  const localhost = "http://localhost:3000/";
  const livePageUrl = "https://galeana.biz/";

  const currentPageUrl = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "activePageLink";
    } else {
      return "pageLink";
    }
  };

  return (
    <Link to={`/${url}`} className={currentPageUrl(url)}>
      {linkName}
    </Link>
  );
}

export default ExtraPagesNavbar;
