import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const host = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export const galeanaBizApi = createApi({
  reducerPath: "galeanaBizApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${host}/api` }),

  // refetchOnReconnect: true,

  tagTypes: [
    "FavoriteProducts",
    "ProductsHome",
    "ProductReviews",
    "Settings",
    "SettingUser",
    "SettingDriver",
    "Products",
    "ProductOptions",
    "ProductAttribute",
    "Companies",
    "WeekDays",
    "Checkout",
    "OrdersUser",
    "Orders",
  ],
  endpoints: (builder) => ({
    /* Orders Driver*/

    createTakeOrder: builder.mutation({
      query: ({ driverId, transactionId }) => {
        const body = {
          driver_id: driverId,
          transaction_id: transactionId,
        };
        return {
          url: "/companies/driver/driver/",
          method: "POST",
          body: body,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: (result, error) => (error ? [] : ["Orders"]),
    }),

    getDriverOrderDetail: builder.query({
      query: ({ driverId, transactionId }) => {
        const queryParams = {};
        if (driverId !== undefined) {
          queryParams.driver_id = driverId;
        }

        if (transactionId !== undefined) {
          queryParams.current_order = transactionId;
        }

        const data = `/companies/driver/order-detail/?${new URLSearchParams(
          queryParams
        )}`;

        return data;
      },
      providesTags: ["Orders"],
    }),

    updateOrderItemStatusChanger: builder.mutation({
      query: ({ orderItemId, driverId, transactionId, statusItem }) => {
        const body = {
          driver_id: driverId,
          order_item_id: orderItemId,
          transaction_id: transactionId,
          status_item: statusItem,
        };

        return {
          url: "companies/driver/order-status-change/",
          method: "PUT",
          body: body,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: (result, error) => (error ? [] : ["Orders"]),
    }),

    /* Orders User*/

    updateCancelledOrder: builder.mutation({
      query: ({ userId, transactionId }) => {
        const body = {
          user: userId,
          transaction_id: transactionId,
        };
        return {
          url: "/companies/orders/cancel-order/",
          method: "PUT",
          body: body,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: (result, error) => (error ? [] : ["OrdersUser"]),
    }),

    getOrderDetailUser: builder.query({
      query: ({ userId, transactionId }) => {
        const data = `/companies/orders/get-order/${userId}/${transactionId}`;
        return data;
      },
      providesTags: ["OrdersUser"],
    }),

    /* CHECKOUT*/

    createOrder: builder.mutation({
      query: ({
        user,
        AddressId,
        load_type,
        instruction,
        combinedPhoneNumber,
        deliveryPassword,
      }) => {
        const data = {
          user: user,
          user_address: AddressId,
          load_type: load_type,

          instruction: instruction,
          alternate_number: combinedPhoneNumber,
          delivery_password: deliveryPassword,
        };
        return {
          url: "/companies/orders/create-order/",
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["Checkout"],
    }),

    /* DRIVER ADMIN*/

    getDriversAdmin: builder.query({
      query: () => {
        const data = "/companies/driver/admin-drivers/";
        return data;
      },
    }),

    /* SETTINGS DRIVER */

    updateSettingsDriver: builder.mutation({
      query: ({ driverId, userId, availabilityStatus }) => {
        return {
          url: "/companies/driver-profile/settings/",
          method: "PUT",
          body: {
            user_id: userId,
            driver_id: driverId,
            availability_status: availabilityStatus,
          },
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (resposne) => resposne.error,
      invalidatesTags: ["SettingDriver"],
    }),

    /* MORE OPTION PRODUCT AND ATTRIBUTE */

    //Attribute
    deleteAttributeOption: builder.mutation({
      query: ({ company_id, product_id, option_id, attribute_id }) => {
        return {
          url: "/companies/product/option-attribute/",
          method: "DELETE",
          body: {
            company_id,
            product_id,
            option_id,
            attribute_id,
          },
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["ProductAttribute"],
    }),
    updateAttributeOption: builder.mutation({
      query: ({
        company_id,
        product_id,
        option_id,
        attribute_id,
        name,
        price,
        available,
      }) => {
        return {
          url: "/companies/product/option-attribute/",
          method: "PUT",
          body: {
            company_id,
            product_id,
            option_id,
            attribute_id,
            name,
            price,
            available,
          },
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["ProductAttribute"],
    }),
    createAttributeOption: builder.mutation({
      query: ({ companyId, productId, optionId }) => {
        const data = {
          company_id: companyId,
          product_id: productId,
          option_id: optionId,
        };
        return {
          url: "/companies/product/option-attribute/",
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["ProductAttribute"],
    }),
    getAttributeOption: builder.query({
      query: ({ companyId, productId, optionId, pageType }) => {
        const queryParams = { page_type: pageType };

        if (companyId !== undefined) {
          queryParams.company_id = companyId;
        }
        if (productId !== undefined) {
          queryParams.product_id = productId;
        }
        if (optionId !== undefined) {
          queryParams.option_id = optionId;
        }

        return `/companies/product/option-attribute/?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ["ProductAttribute"],
    }),

    //Option

    deleteOptionProduct: builder.mutation({
      query: ({ companyId, productId, optionId }) => {
        const data = {
          company_id: companyId,
          product_id: productId,
          option_id: optionId,
        };
        return {
          url: "/companies/product/product-option/",
          method: "DELETE",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["ProductOptions"],
    }),

    updateOptionProduct: builder.mutation({
      query: ({ companyId, productId, optionId, title, is_required }) => {
        const data = {
          company_id: companyId,
          product_id: productId,
          option_id: optionId,
          title: title,
          is_required: is_required,
        };

        return {
          url: "/companies/product/product-option/",
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["ProductOptions"],
    }),

    createOptionProduct: builder.mutation({
      query: ({ productId, companyId }) => {
        const data = { product_id: productId, company_id: companyId };

        return {
          url: "/companies/product/product-option/",
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.error,
      invalidatesTags: ["ProductOptions"],
    }),

    getOptionProduct: builder.query({
      query: ({ productId, companyId, pageType }) => {
        const queryParams = { page_type: pageType };

        if (productId !== undefined) {
          queryParams.product_id = productId;
        }

        if (companyId !== undefined) {
          queryParams.company_id = companyId;
        }

        return `/companies/product/product-option/?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ["ProductOptions"],
    }),

    /* OPENING HOURS */

    /* FAVORITE PRODUCTS */

    createFavoriteProduct: builder.mutation({
      query: ({ user_id, product_id }) => ({
        url: `companies/favorites/products/`,
        method: "POST",
        body: { user: user_id, product_id: product_id },
      }),
      invalidatesTags: ["FavoriteProducts"],
    }),

    deleteFavoritePorduct: builder.mutation({
      query: ({ user_id, product_id }) => ({
        url: `companies/favorites/products/`,
        method: "DELETE",
        body: { user_id: user_id, product_id: product_id },
      }),
      invalidatesTags: ["FavoriteProducts"],
    }),

    getFavoriteProducts: builder.query({
      query: ({ user_id, product_id }) => {
        const queryParams = {};

        if (user_id !== undefined) {
          queryParams.user_id = user_id;
        }

        if (product_id !== undefined) {
          queryParams.product_id = product_id;
        }

        return `companies/favorites/products/?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ["FavoriteProducts"],
    }),

    /* OPENING HOURS */
    createOpeningHours: builder.mutation({
      query: ({ companyId, openingHours }) => {
        const data = { company_id: companyId, opening_hours: openingHours };
        return {
          url: `/companies/post-opening/`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      invalidatesTags: ["WeekDays"],
    }),

    getOpeningHours: builder.query({
      query: ({ companyId }) => {
        return `/companies/opening/${companyId}/`;
      },
      providesTags: ["WeekDays"],
    }),

    /* HOME PRODUCTS */

    getProductCategoriesHome: builder.query({
      query: ({ subscriptionStatus }) => {
        const queryParams = {};

        if (subscriptionStatus !== undefined) {
          queryParams.user_status = subscriptionStatus;
        }

        return `/companies/product/home-products-categories/?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ["ProductsHome"],
    }),
    getProductHome: builder.query({
      query: ({
        subscriptionStatus,
        search,
        category,
        page,
        randomOrder = 0,
      }) => {
        const queryParams = {
          random_order: randomOrder,
        };

        if (page !== undefined) {
          queryParams.page = page;
        }

        if (subscriptionStatus !== undefined) {
          queryParams.user_status = subscriptionStatus;
        }

        if (search !== undefined) {
          queryParams.search = search;
        }

        if (category !== undefined) {
          queryParams.category = category;
        }

        return `/companies/product/home-products/?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ["ProductsHome"],
    }),

    /* COMPANIES */

    getCompanies: builder.query({
      query: ({ search, category, randomOrder = 0 }) => {
        const queryParams = {
          random_order: randomOrder,
        };

        if (search !== undefined) {
          queryParams.search = search;
        }

        if (category !== undefined) {
          queryParams.category = category;
        }

        return `/companies/?${new URLSearchParams(queryParams)}`;
      },
      providesTags: ["Companies"],
    }),

    /* SETINGS USER */

    getSettingUser: builder.query({
      query: (user_id) => {
        const data = `/settings/user/${user_id}`;
        return data;
      },
      providesTags: ["SettingUser"],
    }),
    updateSettingUser: builder.mutation({
      query: ({ user_id, ...data }) => {
        return {
          url: `/settings/user/${user_id}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      invalidatesTags: ["SettingUser"],
    }),

    /* SETINGS ADMIN */

    getSettingsLogList: builder.query({
      query: () => "/settings/admin-actions-done/",
      providesTags: ["Settings"],
    }),

    getSettings: builder.query({
      query: () => "settings",
      providesTags: ["Settings"],
    }),
    updateSettings: builder.mutation({
      query: ({ ...data }) => ({
        url: "settings/",
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      invalidatesTags: ["Settings"],
    }),

    /* PRODUCTS */

    getProducts: builder.query({
      query: ({
        companyId,
        subscriptionStatus,
        search,
        page,
        category,
        order,
        sortBy,
        priceRange,
      }) => {
        const queryParams = {};

        if (page !== undefined) {
          queryParams.page = page;
        }

        if (subscriptionStatus !== undefined) {
          queryParams.user_status = subscriptionStatus;
        }

        if (search !== undefined) {
          queryParams.search = search;
        }

        if (order !== undefined) {
          queryParams.order = order;
        }

        if (sortBy !== undefined) {
          queryParams.sort_by = sortBy;
        }

        if (category !== undefined) {
          queryParams.category = category;
        }

        if (priceRange !== undefined) {
          queryParams.price_range = priceRange;
        }

        return `companies/product/product/${companyId}?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ["Products"],
    }),
    getProduct: builder.query({
      query: (productId) => `companies/product/productID/${productId}`,
      providesTags: (_, __, arg) => [{ type: "Products", id: arg }],
    }),
    createProduct: builder.mutation({
      query: ({
        companyId,
        name,
        description,
        price,
        quantityManage,
        photo,
        categoryId,
        subscription,
        currency,
        files,
      }) => {
        const formData = new FormData();
        formData.append("company", companyId);
        formData.append("name", name);
        formData.append("description", description);
        formData.append("price", price);
        formData.append("quantityManage", quantityManage);
        formData.append("photo", photo);
        formData.append("category_id", categoryId);
        formData.append("subscription_active", subscription);
        formData.append("currency", currency);

        if (files && Object.keys(files).length > 0) {
          for (const file of Object.values(files)) {
            formData.append("uploaded_images", file);
          }
        }

        return {
          url: "companies/product/postProduct/",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Products"],
    }),
    updateProduct: builder.mutation({
      query: ({ productId, ...data }) => {
        return {
          url: `companies/product/putProduct/${productId}/`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["Products"],
    }),
    deleteProduct: builder.mutation({
      query: ({ productId, companyId }) => ({
        url: `companies/product/deleteProduct/${productId}/`,
        method: "DELETE",
        body: { company_id: companyId },
      }),
      invalidatesTags: ["Products"],
    }),
    getProductReviews: builder.query({
      query: ({ productId, page, userId }) =>
        `companies/product/product/${productId}/reviews?page=${page}&user_id=${userId}`,
      providesTags: ["ProductReviews"],
    }),
    createProductReview: builder.mutation({
      query: ({ productId, ...data }) => ({
        url: `companies/product/product/${productId}/reviews`,
        method: "POST",
        body: data,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      invalidatesTags: ["ProductReviews"],
    }),
    updateProductReview: builder.mutation({
      query: ({ productId, ...data }) => ({
        url: `companies/product/product/${productId}/reviews`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.status,
      invalidatesTags: ["ProductReviews"],
    }),
  }),
});

export const {
  /* Orders */
  useCreateTakeOrderMutation,
  useGetDriverOrderDetailQuery,
  useUpdateOrderItemStatusChangerMutation,
  /* Orders User*/
  useUpdateCancelledOrderMutation,
  useGetOrderDetailUserQuery,
  /* CHECKOUT*/
  useCreateOrderMutation,
  /* DRIVER ADMIN*/
  useGetDriversAdminQuery,
  /* SETTINGS DRIVER */

  useUpdateSettingsDriverMutation,
  /* MORE OPTION PRODUCT AND ATTRIBUTE */

  //Attribute
  useDeleteAttributeOptionMutation,
  useUpdateAttributeOptionMutation,
  useCreateAttributeOptionMutation,
  useGetAttributeOptionQuery,
  //Option
  useDeleteOptionProductMutation,
  useUpdateOptionProductMutation,
  useCreateOptionProductMutation,
  useGetOptionProductQuery,

  /* FAVORITE PRODUCTS */
  useCreateFavoriteProductMutation,
  useDeleteFavoritePorductMutation,
  useGetFavoriteProductsQuery,

  /* OPENING HOURS */
  useCreateOpeningHoursMutation,
  useGetOpeningHoursQuery,
  /* HOME PRODUCTS */
  useGetProductCategoriesHomeQuery,
  useGetProductHomeQuery,
  /* COMPANIES */
  useGetCompaniesQuery,
  /* SETING USER */
  useGetSettingUserQuery,
  useUpdateSettingUserMutation,
  /* SETING */
  useGetSettingsLogListQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  /* PRODUCTS */
  useGetProductsQuery,
  useGetProductReviewsQuery,
  useCreateProductReviewMutation,
  useUpdateProductReviewMutation,
  useDeleteProductMutation,
  useCreateProductMutation,
  useUpdateProductMutation,
  useGetProductQuery,
} = galeanaBizApi;
