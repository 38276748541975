import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BsToggleOff, BsToggleOn } from "react-icons/bs";

import {
  useGetSettingsLogListQuery,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../services/galeanaBizApi";

import Loader from "../../components/Loader";

import "../../styles/pages/Admin/Settings.scss";
import { formatDateAndTime } from "../../helpers/formatDateTime";

export default function Settings() {
  const { user } = useSelector((state) => state.userDetails);

  const { data, isFetching } = useGetSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const [values, setValues] = useState({
    usd_mxn_value: 0,
    cart_message_value: "",
  });

  useEffect(() => {
    if (!data?.settings) {
      return;
    }

    setValues({
      username: user?.user?.username,
      user_id: user?.user?.profile?.id,
      usd_mxn_value: data.settings.usd_mxn_value,
      cart_message_value: data.settings.cart_message_value,
    });
  }, [data, user]);

  const toggleDelivery = (value) => {
    updateSettings({
      username: user?.user?.username,
      user_id: user?.user?.profile?.id,
      delivery_status: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSettings(values);
  };

  const Toggle = () => {
    if (data.settings.delivery_available) {
      return (
        <BsToggleOn
          onClick={() => toggleDelivery(false)}
          className="toggle delivery-available"
        />
      );
    }

    return (
      <BsToggleOff
        onClick={() => toggleDelivery(true)}
        className="toggle delivery-not-available"
      />
    );
  };

  if (isFetching) {
    return <Loader />;
  }

  return (
    <div className="websiteSettingsPage">
      <h2>Site settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="delivery-container">
          <p>Delivery services</p>
          <Toggle />
        </div>

        <div className="input-container">
          <label htmlFor="usd_mxn_value">USD Dollar value in MXN</label>
          <input
            value={values.usd_mxn_value}
            onChange={(e) =>
              setValues({ ...values, [e.target.name]: e.target.value })
            }
            type="number"
            name="usd_mxn_value"
            id="usd_mxn_value"
          />
        </div>

        <div className="input-container">
          <label htmlFor="cart_message_value">Cart Message: </label>
          <textarea
            value={values.cart_message_value}
            onChange={(e) =>
              setValues({ ...values, [e.target.name]: e.target.value })
            }
            name="cart_message_value"
            id="cart_message_value"
          />
        </div>

        <button type="submit">Save</button>
      </form>

      <TableSettingLog />
    </div>
  );
}

const TableSettingLog = () => {
  const {
    data: settingsLogList,
    error: settingsLogListError,
    isLoading: settingsLogListIsLoading,
    isFetching: settingsLogListIsFetching,
  } = useGetSettingsLogListQuery();

  useEffect(() => {
    if (!settingsLogList || settingsLogListIsFetching) {
      return;
    }
  }, [settingsLogList, settingsLogListIsFetching]);

  if (settingsLogListIsLoading) {
    return <Loader />;
  }

  if (settingsLogListError) {
    return <h1> {settingsLogListError.message}</h1>;
  }

  return (
    <div className="pastActionsLog">
      <table className="actionsLogTable">
        <thead className="actionsLogHeader">
          <th className="tableBox tableBox--userId">User ID</th>
          <th className="tableBox tableBox--dateAndTime">Date and Time</th>
          <th className="tableBox tableBox--available">Delivery Available</th>
          <th className="tableBox tableBox--usdToPesoValue">Dollar to Peso</th>
          <th className="tableBox tableBox--cartMessage">Cart Message</th>
        </thead>
        <tbody className="actionsLogBody">
          {settingsLogList.who_updated_settings.map((updateLog) => {
            const dateTimeString = updateLog.dateAndTime;

            return (
              <tr key={updateLog.id}>
                <td className="tableBox tableBox--userId">
                  {updateLog.user_id}
                  <div className="renderUsername">
                    <div className="username">{updateLog.user}</div>

                    <div className="triangle">
                      <div className="arrow-down"></div>
                    </div>
                  </div>
                </td>

                <td className="tableBox tableBox--dateAndTime">
                  {formatDateAndTime(dateTimeString)}
                </td>
                <td className="tableBox tableBox--available">
                  {updateLog.delivery_available ? "True" : "False"}
                </td>
                <td className="tableBox tableBox--usdToPesoValue">
                  ${updateLog.usd_mxn_value}
                </td>
                <td className="tableBox tableBox--cartMessage">
                  {updateLog.cart_message_value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
