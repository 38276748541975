import {
  CONTACT_GET_REQUEST,
  CONTACT_GET_SUCCESS,
  CONTACT_GET_FAIL,
  //
  CONTACT_ADD_REQUEST,
  CONTACT_ADD_SUCCESS,
  CONTACT_ADD_FAIL,
  //
  CONTACT_PUT_REQUEST,
  CONTACT_PUT_SUCCESS,
  CONTACT_PUT_FAIL,
  CONTACT_PUT_RESET,
} from "../constants/contactConstans";

export const UpdateContactReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_PUT_REQUEST:
      return { loading: true };

    case CONTACT_PUT_SUCCESS:
      return { loading: false, success: true };

    case CONTACT_PUT_FAIL:
      return { loading: false, error: action.payload };

    case CONTACT_PUT_RESET:
      return {};
    default:
      return state;
  }
};

export const AddContactReducer = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_ADD_REQUEST:
      return { loading: true };

    case CONTACT_ADD_SUCCESS:
      return { loading: false, success: true };

    case CONTACT_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getContactReducer = (state = { companyContact: {} }, action) => {
  switch (action.type) {
    case CONTACT_GET_REQUEST:
      return { loading: true };

    case CONTACT_GET_SUCCESS:
      return { loading: false, companyContact: action.payload };

    case CONTACT_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
