import React, { useEffect, useState } from "react";

import DefaultImage from "../../../images/GreyWhiteGradient.jpg";

export const ImageLoader = ({
  serviceSlug,
  photo,
  slug,
  className,
  alt,
  onClick,
}) => {
  const [image, setImage] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    setIsLoadingImage(true);
    setImage(null);

    if (!serviceSlug) return;
    const img = new Image();
    const imageUrl = photo;

    img.src = imageUrl;
    img.onload = () => {
      if (serviceSlug === slug) {
        setImage(imageUrl);
        setIsLoadingImage(false);
      }
    };
  }, [serviceSlug]);

  return (
    <img
      src={isLoadingImage || !serviceSlug || !image ? DefaultImage : image}
      draggable="false"
      loading="lazy"
      decoding="async"
      alt={alt}
      className={className}
      onClick={onClick}
    />
  );
};
