export const ABOUT_GET_REQUEST = "ABOUT_GET_REQUEST";
export const ABOUT_GET_SUCCESS = "ABOUT_GET_SUCCESS";
export const ABOUT_GET_FAIL = "ABOUT_GET_FAIL";
//
export const ABOUT_ADD_REQUEST = "ABOUT_ADD_REQUEST";
export const ABOUT_ADD_SUCCESS = "ABOUT_ADD_SUCCESS";
export const ABOUT_ADD_FAIL = "ABOUT_ADD_FAIL";
export const ABOUT_ADD_RESET = "ABOUT_ADD_RESET ";

//
export const ABOUT_PUT_REQUEST = "ABOUT_PUT_REQUEST";
export const ABOUT_PUT_SUCCESS = "ABOUT_PUT_SUCCESS";
export const ABOUT_PUT_FAIL = "ABOUT_PUT_FAIL";
export const ABOUT_PUT_RESET = "ABOUT_PUT_RESET";
