const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",

    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedDateTime = date.toLocaleString("en-US", options);
  return formattedDateTime;
};

export default formatDateTime;

export const formatTimeAndDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const timeoptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = date.toLocaleString("en-US", timeoptions);

  const dateoptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleString("en-US", dateoptions);

  const formattedDateTime = `${formattedTime} - ${formattedDate}`;

  return formattedDateTime;
};

export const formatDateAndTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const timeoptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = date.toLocaleString("en-US", timeoptions);

  const dateoptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedDate = date.toLocaleString("en-US", dateoptions);

  const formattedDateTime = `${formattedDate} - ${formattedTime}`;

  return formattedDateTime;
};
