import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { AdressUserAction } from "../../actions/adress/adressUserActions";
import { getUserDetails } from "../../actions/userAction";
import {
  GenerateRandomWordAction,
  ResetGenerateRandomWordAction,
} from "../../actions/ordersActions";

//style
import "../../styles/pages/checkout/CheckOut.scss";

//components
import AddToAllPages from "../../components/AddToAllPages";
import Message from "../../components/Message";
import UserGetAdress from "../adress/usersadress/UserGetAdress";
import { useCreateOrderMutation } from "../../services/galeanaBizApi";
import axios from "axios";
import { ErrorPopup } from "../../helpers/popup/ErrorPopup";

const host = process.env.REACT_APP_API_URL_GALEANA_BIZ;

const CheckOut = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const UserAdress = useSelector((state) => state.AdressUserGet);
  const {
    loading: AddressUserLoading,
    error: AddressUserError,
    AdressUser,
  } = UserAdress;

  const existAddressUser = !!AdressUser;
  const AddressId = parseInt(AdressUser?.id);

  const [createOrder, createOrderResult] = useCreateOrderMutation();

  const Randomword = useSelector((state) => state.Randomword);
  const {
    loading: RandomwordLoading,
    error: RandomwordError,
    RandomWord,
  } = Randomword;

  const [load_type, setLoadType] = useState("");
  const [deliveryPassword, setDeliveryPassword] = useState("");
  const [instruction, setInstruction] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [numalternate, setNumAlternate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show, setShow] = useState(false);

  const [errorPopupVisible, setErrorPopupVisible] = useState(false);

  const combinedPhoneNumber = selectedCountryCode + numalternate;

  const [error, setError] = useState({
    load_type: "",
    combinedPhoneNumber: "",
    AddressId: "",
    offDelivery: "",
  });

  useEffect(() => {
    const clearError = () => {
      setError({
        load_type: "",
        combinedPhoneNumber: "",
        AddressId: "",
      });
    };

    const timeoutID = setTimeout(clearError, 6000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [error]);

  useEffect(() => {
    dispatch(AdressUserAction());
    dispatch(getUserDetails());
    fetchSettings();
  }, [dispatch]);

  useEffect(() => {
    if (RandomWord && RandomWord.word) {
      setDeliveryPassword(RandomWord.word || "");
    }

    return () => {
      dispatch(ResetGenerateRandomWordAction());
    };
  }, [RandomWord]);

  const fetchSettings = async () => {
    try {
      const response = await axios.get(`${host}/api/settings/`);
      return response;
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const settingsResponse = await fetchSettings();
    const settingsData = settingsResponse.data?.settings;

    if (settingsData.delivery_available === true) {
      if (isSubmitting) {
        return;
      }

      const newErrors = {
        ...error,
        load_type: "",
        combinedPhoneNumber: "",
      };

      if (isNaN(AddressId)) {
        newErrors.AddressId = "Verify your address";
      }

      if (load_type === "") {
        newErrors.load_type = "Please select a load type";
      }

      if (selectedCountryCode || numalternate) {
        if (!selectedCountryCode || numalternate.length !== 10) {
          newErrors.combinedPhoneNumber =
            "The correct format is: country code and a 10-digit number example: (+52)123-456-7890";
        }
      }

      const hasErrors =
        newErrors.load_type ||
        newErrors.combinedPhoneNumber ||
        newErrors.AddressId;

      setError(newErrors);

      if (!hasErrors) {
        const alert = window.confirm("Send order?");
        if (alert) {
          setIsSubmitting(true);

          createOrder({
            user: AdressUser?.user,
            AddressId,
            load_type,
            instruction,
            combinedPhoneNumber,
            deliveryPassword,
          }).then((response) => {
            if ("error" in response) {
              setIsSubmitting(false);

              return;
            }

            setDeliveryPassword("");
            dispatch(ResetGenerateRandomWordAction());
            navigate("/myorders");
          });
        }
      }
    } else {
      setErrorPopupVisible(true);
      return setError({
        offDelivery: "Sorry, delivery is not available at this time.",
      });
    }
  };

  const genereateKeyWord = (language) => {
    let multilanguage = language;
    dispatch(GenerateRandomWordAction(multilanguage));
  };

  const resetDeliveryPasswordAndWord = () => {
    setDeliveryPassword("");
    dispatch(ResetGenerateRandomWordAction());
  };

  return (
    <AddToAllPages>
      <div className="checkoutPage">
        <div className="pageTitle">Checkout</div>

        <form className="checkoutForm" onSubmit={onSubmit}>
          {createOrderResult?.error && (
            <Message>review order details or report failure</Message>
          )}

          {errorPopupVisible && (
            <ErrorPopup
              message={error.offDelivery}
              onClose={() => setErrorPopupVisible(false)}
            />
          )}

          <div className="userAddressContainer">
            <div className="title">Confirm That Your Address is Correct.</div>
            {existAddressUser ? (
              <div className="notCorrectAddressTxt">
                Not Correct?{" "}
                <Link to={"/profile/address"} className="link">
                  Update Address Here!!
                </Link>
              </div>
            ) : (
              <div className="notCorrectAddressTxt">
                Not Correct?{" "}
                <Link to={"/profile/address"} className="link">
                  Post Address Here!!
                </Link>
              </div>
            )}
            <UserGetAdress
              AdressUser={AdressUser}
              AddressUserLoading={AddressUserLoading}
              AddressUserError={AddressUserError}
            />
            {error.AddressId && (
              <span className="error-message" style={{ color: "red" }}>
                {error.AddressId}
              </span>
            )}
          </div>

          <div className="inputContainer">
            <label>
              <div className="inputTitle">Load Type</div>
              <select
                value={load_type}
                className={error.load_type ? "error" : null}
                onChange={(e) => setLoadType(e.target.value)}
                required
                disabled={isSubmitting}
              >
                <option value="" disabled={true}>
                  Load Type
                </option>
                <option value="light">Light Weight</option>
                <option value="medium">Medium Weight</option>
                <option value="heavy">Heavy Weight</option>
              </select>
              {error.load_type && (
                <span className="error-message">{error.load_type}</span>
              )}
            </label>
          </div>

          <div className="inputContainer">
            <label>
              <div className="inputTitle">
                Extra Instructions <div className="optionalTxt"></div>
              </div>
              <textarea
                placeholder="Enter extra instructions here"
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
                disabled={isSubmitting}
              />
            </label>
          </div>

          <div className="inputContainer">
            <label>
              <div className="inputTitle">
                Generate Keyword <div className="optionalTxt"></div>
              </div>
              <div className="subtitleTxt">
                Generate a Keyword that you will need to provide to the delivery
                man to receive your order in case you want more security
              </div>

              <input
                placeholder="*No keyword currently set*"
                type="text"
                value={deliveryPassword}
                disabled
              />
              {RandomwordError && (
                <span className="error-message">
                  {"Error generating keyword"}
                </span>
              )}
              <div className="contentBtnLenguage">
                <div
                  className="wrapperBtn"
                  type="button"
                  onClick={() => setShow(!show)}
                >
                  <div className="intruction">Change key word language:</div>
                  <div className="btnMultilanguage">
                    {show ? (
                      <div className="multilanguage">
                        <div className="iconContainer">
                          <i className="fa-solid fa-language"></i>
                        </div>
                        <div className="language">Es</div>
                      </div>
                    ) : (
                      <div className="multilanguage">
                        <div className="iconContainer">
                          <i className="fa-solid fa-language"></i>
                        </div>
                        <div className="language">En</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="buttonsContainer">
                {show ? (
                  <button
                    type="button"
                    className="btnGenerate"
                    onClick={() => genereateKeyWord("es")}
                  >
                    Generate Key Word (Spanish)
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btnGenerate"
                    onClick={() => genereateKeyWord("en")}
                  >
                    Generate Key Word (English)
                  </button>
                )}

                <button
                  type="button"
                  className="btnEmpty"
                  onClick={resetDeliveryPasswordAndWord}
                >
                  Empty
                </button>
              </div>
            </label>
          </div>

          <div className="inputContainer">
            <label>
              <div className="inputTitle">
                Backup Number <div className="optionalTxt"></div>
              </div>
              <div className="selectAndInputContainer">
                <select
                  onChange={(e) => setSelectedCountryCode(e.target.value)}
                  value={selectedCountryCode}
                  className={error.combinedPhoneNumber ? "error" : ""}
                >
                  <option value="">code</option>
                  <option value={`+52`}>+52</option>
                  <option value={`+1`}>+1</option>
                </select>
                <input
                  placeholder="Backup number"
                  type="tel"
                  className={error.combinedPhoneNumber ? "error" : ""}
                  maxLength="10"
                  minLength="10"
                  pattern="[0-9]{10}"
                  value={numalternate}
                  onChange={(e) => {
                    const inputText = e.target.value;
                    const numericInput = inputText.replace(/\D/g, "");
                    setNumAlternate(numericInput);
                  }}
                />
              </div>
              {error.combinedPhoneNumber && (
                <span className="error-message">
                  {error.combinedPhoneNumber}
                </span>
              )}
            </label>
          </div>

          <div className="submitBtnContainer">
            <button
              className="submitBtn"
              type={"submit"}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Confirm Checkout"}
            </button>
          </div>
        </form>
      </div>
    </AddToAllPages>
  );
};

export default CheckOut;
