import React from "react";
import { Link } from "react-router-dom";

import "../../../styles/components/navigation/MyBusinessNavigation/SmallSidebar.scss";

function SmallSidebar({
  currentPageUrl,
  currentPage,
  localhost,
  networkServer,
  livePageUrl,
}) {
  return (
    <div className="myBusinessSmallSidebar">
      <div className="linksContainer">
        <Link to="/mybusiness" className={currentPageUrl("mybusiness")}>
          <div className="iconContainer">
            <i className="fa-solid fa-table-cells-large"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Dashboard</div>
          </div>
        </Link>

        <Link
          to="/mybusiness/table-products"
          className={currentPageUrl("mybusiness/table-products")}
        >
          <div className="iconContainer">
            <i className="fas fa-shopping-basket"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Products</div>
          </div>
        </Link>

        <Link
          to={`/mybusiness/services?label=${encodeURIComponent(
            "all_services"
          )}`}
          className={currentPageUrl("mybusiness/services")}
        >
          <div className="iconContainer">
            <i className="fas fa-tools"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Services</div>
          </div>
        </Link>

        <Link
          to="/mybusiness/orders-company"
          className={currentPageUrl("mybusiness/orders-company")}
        >
          <div className="iconContainer">
            <i className="fa-solid fa-truck-ramp-box"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Orders</div>
          </div>
        </Link>

        <Link
          to="/mybusiness/setings-hours"
          className={currentPageUrl("mybusiness/setings-hours")}
        >
          <div className="iconContainer">
            <i class="fa-solid fa-clock"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText"> Hours </div>
          </div>
        </Link>

        <Link
          to="/mybusiness/customization"
          className={currentPageUrl("mybusiness/customization")}
        >
          <div className="iconContainer">
            <i className="fa-solid fa-pencil"></i>
          </div>
          <div className="linkTextContainer">
            <div className="linkText">Customization</div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default SmallSidebar;
