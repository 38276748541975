import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

//formater
import parsePhoneNumber from "../../helpers/format/parsePhoneNumber";

//styles
import "./style/Form.scss";

//img
import DefaultImg from "../../images/GreyWhiteGradient.jpg";
import { imageCompression } from "../../helpers/compressor/imageCompression";

export const Form = ({ handleSubmit, children }) => {
  return (
    <>
      <form className="formComponent" onSubmit={handleSubmit}>
        {children}
      </form>
    </>
  );
};

export const ImagePreview = ({ img }) => {
  return (
    <div className="contentImg">
      <img src={img || DefaultImg} alt="img" />
    </div>
  );
};

export const Buttons = ({ returnPath, paramsKey, valueParams, isLoading }) => {
  const ButtonCancelled = () => {
    const navigate = useNavigate();
    if (!returnPath) return;

    const params = new URLSearchParams(window.location.search);
    const slug = params.get("slug");

    const handleCancelled = () => {
      let url = "";

      if (paramsKey && valueParams) {
        if (slug) {
          params.delete("slug");
        }
        params.set(`${paramsKey}`, `${valueParams}`);

        url = `${returnPath}?${params.toString()}`;
      } else {
        url = returnPath;
      }

      navigate(url, { replace: true });
    };

    return (
      <button
        onClick={handleCancelled}
        type="button"
        disabled={isLoading}
        className="cancelBtn"
      >
        Cancel
      </button>
    );
  };

  const ButtonSave = () => {
    return (
      <button disabled={isLoading} type="submit">
        Submit
      </button>
    );
  };

  return (
    <div className="saveBtnContainer">
      <ButtonCancelled />
      <ButtonSave />
    </div>
  );
};

export const InputField = ({
  id,
  placeholder,
  label,
  name,
  type,
  value,
  setValue,
  required = false,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="formInputContainer">
      <div className="inputTitle">
        <label for={id} className="inputTitleText">
          {label}{" "}
          {required ? (
            <span className="requiredTxt"></span>
          ) : (
            <span className="optionalTxt"></span>
          )}
        </label>
      </div>

      <input
        id={id}
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
      />
    </div>
  );
};

export const TextAreaField = ({
  id,
  placeholder,
  label,
  name,
  value,
  setValue,
  required = false,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="formInputContainer">
      <div className="inputTitle">
        <label for={id} className="inputTitleText">
          {label}{" "}
          {required ? (
            <span className="requiredTxt"></span>
          ) : (
            <span className="optionalTxt"></span>
          )}
        </label>
      </div>

      <textarea
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
      />
    </div>
  );
};

export const InputPrice = ({
  placeholder,
  label,
  name,
  price,
  currency,
  setValue,
  required = false,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    setValue((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="formInputPriceContainer">
      <div className="inputTitle">
        <label for="productPrice" className="inputTitleText">
          {label}{" "}
          {required ? (
            <span className="requiredTxt"></span>
          ) : (
            <span className="optionalTxt"></span>
          )}
        </label>
      </div>

      <div className="priceCurrencyContainer">
        <input
          type="text"
          id="productPrice"
          placeholder={placeholder}
          name={name}
          value={price}
          onChange={(e) => {
            const currentValue = e.target.value;
            if (/^[0-9]{0,7}(\.\d{0,3})?$/.test(currentValue)) {
              onChange(e);
            }
          }}
          required={required}
        />

        <select
          value={currency}
          name="currency"
          onChange={(e) => onChange(e)}
          style={{ width: "70px", height: "44px" }}
        >
          <option value={"MXN"}>MXN</option>
          <option value={"USD"}>USD</option>
        </select>
      </div>
    </div>
  );
};

export const InputImage = ({
  label,
  name,
  fakename,
  setFormData,
  required = false,
}) => {
  const uploadHandler = async (e) => {
    const { name } = e.target;
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const compressedImageFile = await imageCompression(selectedFile);
      const compressedImageURL = URL.createObjectURL(compressedImageFile);
      setFormData((prevData) => ({
        ...prevData,
        [name]: compressedImageFile,
        [fakename]: compressedImageURL,
      }));
    }
  };

  return (
    <div className="formImageInputContainer">
      <div className="inputTitle">
        <label for="fileUpload" className="inputTitleText">
          {label}{" "}
          {required ? (
            <span className="requiredTxt"></span>
          ) : (
            <span className="optionalTxt"></span>
          )}
        </label>
      </div>

      <label className="customFileUpload">
        <input
          id="fileUpload"
          type="file"
          accept="image/*"
          name={name}
          onChange={(e) => uploadHandler(e)}
          required={required}
        />
        Click to Add Image
      </label>
    </div>
  );
};

export const InputPhone = ({
  placeholder,
  label,
  value,
  setValue,
  name,
  required = false,
}) => {
  //Format Required to phone ej(+528882224444)
  //setValue debe tener un valor phone

  const [valuePhone, setValuePhone] = useState({
    phoneNumber: "",
    countryCode: "",
  });

  useEffect(() => {
    if (!value) return;

    const { countryCode, formattedNumber } = parsePhoneNumber(value);

    setValuePhone({
      phoneNumber: formattedNumber,
      countryCode: countryCode,
    });
  }, [value]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setValuePhone((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      [name]: valuePhone.countryCode + valuePhone.phoneNumber,
    }));
  }, [valuePhone]);

  return (
    <div className="phoneNumberInputContainer">
      <div className="inputTitle">
        <div className="inputTitleText">
          {label}{" "}
          {required ? (
            <span className="requiredTxt"></span>
          ) : (
            <span className="optionalTxt"></span>
          )}
        </div>
      </div>

      <div className="inputsContainer">
        <select
          name="countryCode"
          onChange={(e) => onChange(e)}
          value={valuePhone.countryCode}
        >
          <option value="" disabled={true}>
            Code
          </option>
          <option value="+1">USA (+1)</option>
          <option value="+52">MX (+52)</option>
        </select>

        <input
          placeholder={placeholder}
          name="phoneNumber"
          type="tel"
          maxLength="10"
          minLength="10"
          pattern="[0-9]{10}"
          value={valuePhone.phoneNumber}
          onChange={(e) => {
            const inputText = e.target.value;
            // Filtra cualquier carácter que no sea un dígito numérico
            if (/^\d*$/.test(inputText)) {
              onChange(e);
            }
          }}
          required={required}
        />
      </div>
    </div>
  );
};

export const Toggle = ({ id, label, name, setValue, isChecked }) => {
  const onChange = (e) => {
    const { name } = e.target;

    setValue((prevData) => ({
      ...prevData,
      [name]: !isChecked,
    }));
  };

  return (
    <div className="toggleContainer">
      <label class="switch">
        <input
          id={id}
          name={name}
          onChange={(e) => onChange(e)}
          type="checkbox"
          checked={isChecked}
        />
        <span class="slider"></span>
      </label>

      <label className="titleText" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export const Select = ({
  name,
  label,
  data = [],
  setData,
  required = false,
  isMulti = false,
  isLoading = false,
  children,
}) => {
  const [valueData, setValueData] = useState([]);
  const [fakeValue, setFakeValue] = useState([]);

  useEffect(() => {
    if (!data || isLoading) {
      return;
    }
    if (!Array.isArray(data)) {
      setValueData([data]);
    } else {
      setValueData(data);
    }
  }, [data, isLoading]);

  useEffect(() => {
    //funciona correctamente
    const options = children[1]
      .filter((child) => child.type === "option")
      .map((option) => ({
        id: option.props.value,
        label: option.props.children,
      }));

    const filteredOptions = options?.filter((option) => {
      return valueData?.includes(option?.id);
    });

    setFakeValue(filteredOptions);
  }, [valueData]);

  const deleteOption = (e, option) => {
    const { name } = e.target;

    // Eliminar la opción del data
    const filteredValues = valueData.filter((opt) => opt !== option.id);

    // Eliminar la opción del arrayFakeValues
    const filteredFakeValues = fakeValue.filter((opt) => opt.id !== option.id);

    setData((prevData) => ({
      ...prevData,
      [name]: filteredValues,
    }));

    setFakeValue(filteredFakeValues);
  };

  const onChange = (e) => {
    const { name, value: id } = e.target;

    if (isMulti) {
      const existingValue = valueData.findIndex((selection) => {
        return selection === parseInt(id);
      });

      let updateValues = [...valueData];

      if (existingValue !== -1) return;

      updateValues.push(parseInt(id));

      setData((prevData) => ({
        ...prevData,
        [name]: updateValues,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: parseInt(id),
      }));
    }
  };

  const valueDataSelected = Array.isArray(data)
    ? data && data.length > 0
      ? data.filter((value) => typeof value === "number")
      : ""
    : data;

  return (
    <div className="formContentSelect">
      <div className="inputTitle">
        <div className="inputTitleText">
          {label}{" "}
          {required ? (
            <span className="requiredTxt"></span>
          ) : (
            <span className="optionalTxt"></span>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="select">
          <div className="selectedOption">Loading...</div>

          <select
            disabled
            className="selectLoading"
            multiple={isMulti}
          ></select>
        </div>
      ) : (
        <div className="select">
          <div className="selectedOption">
            {((fakeValue?.length > 0 && fakeValue) || []).map(
              (option, index) => {
                return (
                  <div className="contentOption" key={index}>
                    <div className="option">{option?.label}</div>

                    <button
                      name={name}
                      type="button"
                      onClick={(e) => deleteOption(e, option)}
                    >
                      X
                    </button>
                  </div>
                );
              }
            )}
          </div>

          <select
            multiple={isMulti}
            name={name}
            value={valueDataSelected}
            onChange={(e) => onChange(e)}
            required={required}
          >
            {children}
          </select>
        </div>
      )}
    </div>
  );
};
