import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { AddAboutActions } from "../../../../actions/aboutActions";

// SCSS
import "../../../../styles/pages/CompanyPage/companybar/AboutCompany/PutAbout.scss";

// Componentes
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import { imageCompression } from "../../../../helpers/compressor/imageCompression";

import AddImagePlaceholder from "../../../../images/placeholders/AddImage.png";
import { ABOUT_ADD_RESET } from "../../../../constants/aboutConstans";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

const PostAbout = () => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const path = "/mybusiness/customization";

  const { company } = useSelector((state) => state.userDetails);
  const companyId = company._id;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const [fakeImage, setFakeImage] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState({
    title: false,
    description: false,
    image: false,
  });

  const PostAbout = useSelector((state) => state.PostAbout);
  const {
    loading: LoadingPostAbout,
    error: ErrorPostAbout,
    success,
  } = PostAbout;

  useEffect(() => {
    if (success) {
      dispatch({ type: ABOUT_ADD_RESET });
      naviagate(path, { replace: true });
    }
  }, [success]);

  useEffect(() => {
    const clearError = () => {
      setError({
        title: false,
        description: false,
        image: false,
      });
    };

    const timeoutId = setTimeout(clearError, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    const newError = { ...error };

    if (file) {
      try {
        const compressedImageFile = await imageCompression(file);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);

        setImage(compressedImageFile);
        setFakeImage(compressedImageURL);

        newError.image = false;
      } catch (error) {
        newError.image =
          "Error compressing the image Profile. Please try again.";
      }
    }
    setError(newError);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    try {
      const newErrors = {
        ...error,
        title: !title && "Title is required",
        description: !description && "Description is required",
        image: !image && "Image is required",
      };

      const hashError = Object.values(newErrors).some(
        (error) => typeof error === "string"
      );

      setError(newErrors);

      if (!hashError) {
        setIsSubmitting(true);

        dispatch(AddAboutActions(companyId, image, title, description));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NavigationComponents>
      {LoadingPostAbout ? (
        <>
          <Loader />
        </>
      ) : ErrorPostAbout ? (
        <>
          <Message error={ErrorPostAbout}>
            An error occurred while processing the POST request. Please try
            again.
          </Message>
        </>
      ) : (
        <form onSubmit={onSubmit} className="putAbout">
          <div className="imageAbout">
            <img
              src={fakeImage ? fakeImage : AddImagePlaceholder}
              alt=""
              className="img"
            />
          </div>
          <div className="imageInputContainer">
            <label
              className={`customFileUpload ${error.image ? "error" : null}`}
            >
              <div className="inputTitle">
                <div className="inputTitleText">Image</div>
              </div>
              <input
                className={error.image ? "error" : null}
                type="file"
                onChange={handleImageChange}
              />
            </label>
            {error.image && (
              <span className="error-message">{error.image}</span>
            )}
          </div>

          <div className="putAboutForm">
            <div className="inputContainer">
              <div className="inputTitle">
                <div className="inputTitleText">Title About</div>
              </div>
              <input
                type="text"
                className={error.title ? "error" : null}
                placeholder="Place title of about here..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {error.title && (
                <span className="error-message">{error.title}</span>
              )}
            </div>

            <div className="inputContainer">
              <div className="inputTitle">
                <div className="inputTitleText">Description</div>
              </div>
              <textarea
                type="text"
                className={error.description ? "error" : null}
                placeholder="Place description here..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {error.description && (
                <span className="error-message">{error.description}</span>
              )}
            </div>

            <div className="saveBtnContainer">
              <button type="submit" disabled={isSubmitting}>
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </NavigationComponents>
  );
};

export default PostAbout;
