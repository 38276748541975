import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  COMPANY_REVIEW_RESET,
  COMPANY_REVIEW_UPDATE_RESET,
} from "../../../../constants/companyConstants";
import {
  companyReviewAction,
  companyUpdateReviewAction,
  listCompanyReviewsAction,
} from "../../../../actions/companyActions";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";

// Images
import DefaultProfileImg from "../../../../images/default-profile-icon.jpg";

// SCSS
import "../../../../styles/components/reviews/reviewCompanies/ReviewsCompany.scss";
import "../../../../styles/components/reviews/reviewCompanies/AddReviewModal.scss";

import "../../../../styles/components/reviews/reviewCompanies/UpdateReviewModal.scss";

const ReviewsCompany = ({ companyId }) => {
  const [addReviewModalActive, setAddReviewModalActive] = useState(false);

  const userInfo = useSelector((state) => state.userLogin);
  const { userToken } = userInfo;

  const myReviewCompany = useSelector((state) => state.ListReviews);
  const { loading: loadingReviews, error: errorReview } = myReviewCompany;

  const { user_review } = useSelector((state) => state.ListReviews);

  const exisReview = !!user_review;

  if (errorReview) {
    return <p>The reviews cannot be displayed, try again..</p>;
  }

  if (loadingReviews) {
    return <Loader />;
  }

  return (
    <div className="ReviewsCompany">
      {!userToken ? (
        <h2 style={{ fontSize: "1.1em" }}>Here you can enter your review</h2>
      ) : (
        <>
          <GetReviewUser companyId={companyId} exisReview={exisReview} />
          <AddReview
            addReviewModalActive={addReviewModalActive}
            setAddReviewModalActive={setAddReviewModalActive}
            companyId={companyId}
            exisReview={exisReview}
          />
        </>
      )}
    </div>
  );
};

export default ReviewsCompany;

const GetReviewUser = ({ companyId }) => {
  const [show, setShow] = useState(false);

  const { loading: loadingUpdateReview } = useSelector(
    (state) => state.companyReviewUpdate
  );

  const myReviewCompany = useSelector((state) => state.ListReviews);
  const {
    loading: loadingReviews,
    error: errorReview,
    user_review,
  } = myReviewCompany;

  if (errorReview) {
    return <p>The reviews cannot be displayed, try again..</p>;
  }

  if (loadingReviews) {
    return <Loader />;
  }

  if (!user_review) {
    return null;
  }

  return (
    <div className="yourRatingContainer">
      <div className="yourRatingText">Your Review</div>

      <div className="yourRating">
        <div className="topSection">
          <div className="profileImgContainer">
            <img
              src={user_review?.profile_image || DefaultProfileImg}
              alt=""
              loading="lazy"
              decoding="async"
              className="profileImg"
            />
          </div>
          <div className="submiterNameAndStarsContainer">
            <div className="submiterDate">{`(${user_review?.createdAt?.substring(
              0,
              10
            )})`}</div>
            <div lang="en" className="submiterName">
              {user_review?.name} {user_review?.last_name}
            </div>
            <div className="starsContainer">
              {[...new Array(5)].map((star, index) => {
                return index < user_review?.rating ? (
                  <AiFillStar key={index} color="#D4B05C" />
                ) : (
                  <AiOutlineStar key={index} color="#D4B05C" />
                );
              })}
            </div>
          </div>
          <div className="editReviewBtnContainer">
            {/* {loadingUpdateReview ? null : (
              <button
                type="button"
                className="editReviewBtn"
                onClick={() => setShow(true)}
              >
                Edit
              </button>
            )} */}

            <button
              type="button"
              className="editReviewBtn"
              onClick={() => setShow(true)}
            >
              Edit
            </button>

            {show && (
              <UpdateReview
                companyId={companyId}
                setShow={setShow}
                user_review={user_review}
              />
            )}
          </div>
        </div>
        <div className="reviewText">{user_review?.comment}</div>
      </div>
    </div>
  );
};

const AddReview = ({
  addReviewModalActive,
  setAddReviewModalActive,
  companyId,
  exisReview,
}) => {
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");

  const [isSubming, setIsSubming] = useState(false);

  const [error, setError] = useState({
    comment: false,
  });

  const dispatch = useDispatch();

  const companyReviews = useSelector((state) => state.companyReview);
  const {
    loading: loadingReview,
    error: errorCreatedReview,
    success: successReview,
  } = companyReviews;

  useEffect(() => {
    const clearError = () => {
      setError({
        comment: false,
      });
    };

    const timeoutId = setTimeout(clearError, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    if (successReview) {
      setRating(0);
      setComment("");
      dispatch({ type: COMPANY_REVIEW_RESET });
      dispatch(listCompanyReviewsAction(companyId));
      setAddReviewModalActive(true);
    }
  }, [dispatch, successReview, companyId]);

  const submitHandle = (e) => {
    e.preventDefault();

    const newErrors = {
      ...error,
      comment: !comment && "This comment is required",
    };

    const hasError = Object.values(newErrors).some(
      (error) => typeof error === "string"
    );

    setError(newErrors);

    if (!hasError) {
      setIsSubming(true);
      dispatch(companyReviewAction(companyId, rating, comment));
    } else {
      setIsSubming(false);
    }
  };

  if (exisReview) {
    return null;
  }

  if (loadingReview) {
    return <Loader />;
  }

  if (errorCreatedReview) {
    return (
      <Message error={errorCreatedReview}>
        The reviews cannot be displayed, try again
      </Message>
    );
  }

  return (
    <>
      {successReview && <Message>Review Submitted</Message>}

      {addReviewModalActive ? (
        <div className="addReviewModal">
          <div className="background"></div>

          <div className="modalContent">
            <div className="topSection">
              <button
                className="closeModalBtn"
                onClick={() => setAddReviewModalActive(false)}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
              <div className="editReviewTitle">Write a Review</div>
            </div>
            <div className="contentSection">
              <form className="contentFormReview" onSubmit={submitHandle}>
                <div className="RatingContainer">
                  <div className="ratingText">Give a Rating:</div>

                  <span className="starRating">
                    {[...Array(5)].map((star, index) => {
                      return index < rating ? (
                        <AiFillStar
                          className="starRating"
                          onClick={() => setRating(index + 1)}
                        />
                      ) : (
                        <AiFillStar
                          className="noStarRating"
                          onClick={() => setRating(index + 1)}
                        />
                      );
                    })}
                  </span>
                </div>
                <div className="reviewContainer">
                  <div className="titleText">Write a Public Review</div>
                  <textarea
                    className={`reviewInput  ${error.comment ? "error" : null}`}
                    type="text"
                    placeholder="Type review here!!"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />

                  {error.comment && (
                    <span className="errorMessage">{error.comment}</span>
                  )}
                </div>

                <button
                  className="submitReviewBtn"
                  type="submit"
                  disabled={isSubming}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="addReviewBtnContainer">
          <button
            className="addReviewBtn"
            onClick={() => setAddReviewModalActive(true)}
          >
            Add Review
          </button>
        </div>
      )}
    </>
  );
};

const UpdateReview = ({ user_review, companyId, setShow }) => {
  const [rating, setRating] = useState(1);
  const [comment, setComment] = useState("");
  const [reviewId, setReviewId] = useState(null);

  const [isSubming, setIsSubming] = useState(false);

  const [error, setError] = useState({
    comment: false,
  });

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userDetails);

  const profileImageURL = user?.user?.profile?.image;
  const ReviewId = user_review?.id;

  const companyReviewUpdate = useSelector((state) => state.companyReviewUpdate);
  const {
    loading: loadingUpdateReview,
    error: errorUpdateReview,
    success: successUpdateReview,
  } = companyReviewUpdate;

  useEffect(() => {
    const clearError = () => {
      setError({
        comment: false,
      });
    };

    const timeoutId = setTimeout(clearError, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    if (successUpdateReview) {
      dispatch({ type: COMPANY_REVIEW_UPDATE_RESET });
      dispatch(listCompanyReviewsAction(companyId));

      setShow(false);
    } else {
      setReviewId(user_review?.id);
      setRating(user_review?.rating);
      setComment(user_review?.comment);
    }
  }, [dispatch, user_review, companyId, successUpdateReview]);

  const handlesubmit = (e) => {
    e.preventDefault();

    const newErrors = {
      ...error,
      comment: !comment && "This comment is required",
    };

    const hashError = Object.values(newErrors).some(
      (error) => typeof error === "string"
    );

    setError(newErrors);

    if (!hashError) {
      setIsSubming(true);
      dispatch(companyUpdateReviewAction(reviewId, rating, comment, companyId));
    } else {
      setIsSubming(false);
    }
  };

  return (
    <>
      {loadingUpdateReview ? (
        <Loader />
      ) : errorUpdateReview ? (
        <Message error={errorUpdateReview}>
          The reviews cannot be displayed, try again
        </Message>
      ) : (
        <div className="UpdateReviewModal">
          <div className="background"></div>
          <div className="modalContent">
            <div className="topSection">
              <button className="closeModalBtn" onClick={() => setShow(false)}>
                <i className="fa-solid fa-xmark"></i>
              </button>
              <div className="editReviewTitle">Write a Review</div>
              {profileImageURL && (
                <img
                  className=""
                  src={profileImageURL}
                  style={{ width: "50px" }}
                />
              )}
            </div>
            <div className="contentSection">
              <form className="contentFormReview" onSubmit={handlesubmit}>
                <div className="RatingContainer">
                  <div className="ratingText">Give a Rating:</div>

                  <span className="starRating">
                    {[...Array(5)].map((star, index) => {
                      return index < rating ? (
                        <AiFillStar
                          key={index}
                          className="starRating"
                          onClick={() => setRating(index + 1)}
                        />
                      ) : (
                        <AiFillStar
                          key={index}
                          className="noStarRating"
                          onClick={() => setRating(index + 1)}
                        />
                      );
                    })}
                  </span>
                </div>
                <div className="reviewContainer">
                  <div className="titleText">Write a Public Review</div>
                  <textarea
                    className={`reviewInput  ${error.comment ? "error" : null}`}
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  {error.comment && (
                    <span className="errorMessage">{error.comment}</span>
                  )}
                </div>

                <button
                  className="submitReviewBtn"
                  type="submit"
                  disabled={isSubming}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
