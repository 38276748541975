import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";

import { getAllOrderUser } from "../../../../actions/ordersActions";
import { ORDERS_USER_GET_FAIL } from "../../../../constants/ordersConstans";

// scss
import "../../../../styles/pages/checkout/AllOrderUser.scss";

// Components
import AddToAllPages from "../../../../components/AddToAllPages";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

import formatCurrency from "../../../../helpers/format/formatCurrency";

import { formatTimeAndDate } from "../../../../helpers/formatDateTime";

import { shortText } from "../../../../helpers/text/shortText";

const AllOrderUser = () => {
  const dispatch = useDispatch();

  const [orders, setOrders] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const AllorderUser = useSelector((state) => state.GetUserOrder);
  const {
    loading: OrderUserLoading,
    error: OrderUserError,
    next,
    AllUserOrder,
  } = AllorderUser;

  useEffect(() => {
    dispatch(getAllOrderUser());
  }, [dispatch]);

  useEffect(() => {
    if (!AllUserOrder || OrderUserLoading) return;

    setOrders(AllUserOrder);
    setNextCursor(next);
  }, [AllUserOrder, OrderUserLoading, next]);

  const laodMoreOrders = async () => {
    setIsLoading(true);
    if (nextCursor) {
      try {
        const response = await axios.get(nextCursor);
        setOrders((prevOrders) => [...prevOrders, ...response.data.results]);
        setNextCursor(response.data.next);
      } catch (err) {
        dispatch({
          type: ORDERS_USER_GET_FAIL,
          payload:
            err.response && err.response.data.detail
              ? err.response.data.detail
              : err.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <AddToAllPages>
      <div className="allOrderUsers">
        <AllOrdersCard
          orders={orders}
          OrderUserLoading={OrderUserLoading}
          OrderUserError={OrderUserError}
        />

        <div className="loadMoreBtnContainer">
          {nextCursor && (
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <button className="loadMoreBtn" onClick={laodMoreOrders}>
                  Load more
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </AddToAllPages>
  );
};

export default AllOrderUser;

const AllOrdersCard = ({ orders, OrderUserLoading, OrderUserError }) => {
  const OrderItem = ({ company, companyIndex }) => {
    return (
      <div className="productImgContainer" key={companyIndex}>
        <img
          src={company?.image_product}
          alt={`product: ${company?.name}`}
          className="productImg"
        />
      </div>
    );
  };

  const OrderItemsList = ({ company, orderCompanies }) => {
    if (!company) {
      return null;
    }

    return (
      <>
        {company?.orderItem.map((company, companyIndex) => (
          <OrderItem
            key={companyIndex}
            company={company}
            companyIndex={companyIndex}
          />
        ))}
      </>
    );
  };

  const OrderDetails = ({ order }) => {
    const message = order?.cancellation_reason
      ? order?.cancellation_reason
      : order?.delivery_description;

    const InformationDisplay = ({
      isShort = false,
      isShortNum = 16,
      isValueRequired = true,
      className = "informationText",
      title,
      data,
    }) => {
      const dataText = isShort ? shortText(data, isShortNum) : data;

      if (!isValueRequired) return;

      return (
        <div className="informationDisplay">
          <div className="informationTitle">{title}:</div>
          <div className={`${className} ${className}__${data}`}>{dataText}</div>
        </div>
      );
    };

    return (
      <div className="orderCardContainer">
        <Link
          className="orderCard"
          to={`/order-details/${order?.transaction_id}/`}
        >
          <div className="topSection">
            <div className="orderUserName">
              {order?.user_name} {order?.last_name}
            </div>
            <div className="dateTxt">
              {formatTimeAndDate(order?.date_issued)}
            </div>
          </div>
          <InformationDisplay
            className={"status"}
            title={"Status"}
            data={order?.status}
          />
          <InformationDisplay
            className={"loadType"}
            title={"Load Type"}
            data={order?.load_type}
          />

          {order?.delivery_password && (
            <InformationDisplay
              isValueRequired={!!order?.delivery_password}
              className={"keyWord"}
              title={"Key Word"}
              data={order?.delivery_password}
            />
          )}

          <InformationDisplay
            isValueRequired={!!message}
            title={"Message"}
            isShort={true}
            data={message}
          />

          <div className="orderItems">
            {order?.order_companies.map((company, index) => {
              return (
                <OrderItemsList
                  company={company}
                  key={index}
                  orderCompanies={order?.order_companies}
                />
              );
            })}
          </div>
          <div className="orderTotal">
            Order price:
            <div className="totalPrice">
              {formatCurrency(order?.order_total)} MXN
            </div>
          </div>
        </Link>
      </div>
    );
  };

  if (OrderUserLoading) {
    return <Loader />;
  }

  if (OrderUserError) {
    return (
      <Message error={OrderUserError}>
        Can't get your orders, try again...
      </Message>
    );
  }

  return (
    <div className="allOrderCardsContainer">
      {orders?.length > 0 || !orders ? null : (
        <p className="notOrders">There are no orders yet...</p>
      )}
      {orders.map((order, index) => {
        return <OrderDetails order={order} key={index} />;
      })}
    </div>
  );
};
