import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { ProductCategoriesAction } from "../../actions/productsAction";

// SCSS
import "../../styles/pages/products/AddProduct.scss";

//  Images
import LGLogo from "../../images/LGWhiteLogo.png";

// Components
import Disclaimer from "../../helpers/disclaimer/Disclaimer";
import ImageUploader from "../../components/multipleImages/ImageUploader";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import NavigationComponents from "../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import ProductCard_V2 from "../../components/CompanyAndProductCards/ProductCard_V2";
import StarReviews from "../../components/StarReviews";
import { imageCompression } from "../../helpers/compressor/imageCompression";
import { useCreateProductMutation } from "../../services/galeanaBizApi";

const ApiLebaronGaleana = process.env.REACT_APP_LEBARON_GALEANA_HOST;

const AddProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = useState({
    name: "",
    description: "",
    price: null,
    quantityManage: true,
    photo: null,
    currency: "MXN",
    subscriptionActive: false,
    category: 0,
    subCategory: 0,
    fakeImage: null,
  });

  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);

  const { company } = useSelector((state) => state.userDetails);
  const { categories } = useSelector((state) => state.ProductCategories);

  const [createProduct, createProductResult] = useCreateProductMutation();

  const selectedCategoryId =
    parseInt(value.subCategory || value.category) || null;
  const cat = categories?.categories || [];

  useEffect(() => {
    dispatch(ProductCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    const confirmationMessage =
      "Are you sure you want to leave this page? Your progress will be lost.";

    const handleBeforeUnload = (e) => {
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const filesObject = {};

    if (filesObject) {
      for (let i = 0; i < images.length; i++) {
        filesObject[i] = images[i];
      }
    }

    createProduct({
      companyId: company._id,
      name: value.name,
      description: value.description,
      price: value.price,
      quantityManage: value.quantityManage,
      photo: value.photo,
      categoryId: selectedCategoryId,
      subscription: value.subscriptionActive,
      currency: value.currency,
      files: filesObject,
    }).then((response) => {
      if ("error" in response) {
        return;
      }

      navigate("/mybusiness");
    });
  };

  const uploadHandler = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      try {
        const compressedImageFile = await imageCompression(selectedFile);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);

        setValue((prev) => ({
          ...prev,
          photo: compressedImageFile,
          fakeImage: compressedImageURL,
        }));
        setError(null);
      } catch (error) {
        setError("Error compressing the image. Please try again.");
      }
    }
  };

  const formChangeHandler = () => {
    if (value.fakeImage && value.name && value.price) {
      document.getElementById("saveProduct").disabled = false;
    } else {
      document.getElementById("saveProduct").disabled = true;
    }
  };

  const category = cat.find((c) => c.id === parseInt(value.category));
  const subcategories = category?.sub_categories || [];

  const toggleSubscriptionActive = () => {
    setValue((prev) => ({
      ...prev,
      subscriptionActive: !value.subscriptionActive,
    }));
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    setValue((prev) => ({ ...prev, [name]: value }));
  };

  const CancelForm = () => {
    navigate("/mybusiness");
  };

  if (createProductResult.isLoading) {
    return <Loader />;
  }

  if (!!createProductResult.error) {
    return (
      <Message>
        Something went wrong adding the product, please try again later.
      </Message>
    );
  }

  return (
    <NavigationComponents>
      <div className="addProductPage">
        <div className="dummyProductCardSection">
          <div className="title">Product card preview</div>

          <div className="dummyProductCardContainer">
            <ProductCard_V2 customValues={value} />
          </div>
        </div>

        <FormCreatedProduct
          value={value}
          onChange={onChange}
          handleSubmit={handleSubmit}
          formChangeHandler={formChangeHandler}
          uploadHandler={uploadHandler}
          error={error}
          cat={cat}
          subcategories={subcategories}
          toggleSubscriptionActive={toggleSubscriptionActive}
          images={images}
          setImages={setImages}
          CancelForm={CancelForm}
        />
      </div>

      <Disclaimer
        text={`This is a website for businesses only, not individual people that
                just want to sell a personal product; for that you can go to our
                classifieds to publish and sell your personal product as a person at: `}
        link={`${ApiLebaronGaleana}/dashboard/classifieds`}
        textLink={`Lebarón Galeana Classifieds`}
        textButton={"Ok"}
      />
    </NavigationComponents>
  );
};

export default AddProduct;

const DummyProductCard = ({ value }) => {
  return (
    <div className="dummyProductCardsContainer">
      <div className="tallCardAndTitle">
        <h3>Product Card Preview</h3>
        <div className="productCardV2Container">
          <Link className="productCard_V2">
            <img src={value.fakeImage} alt="" draggable="false" />

            {value.subscriptionActive && (
              <div className="memberOnlyTag">
                <div className="logoContainer">
                  <img src={LGLogo} alt="" className="LGMemberTagLogo" />
                </div>

                <div className="tagTitle">Member Exclusive</div>
              </div>
            )}

            <div className="infoSection">
              <div className="infoContainer">
                <div className="productName">
                  {value.name ? value.name : "Enter Name"}
                </div>
                <div className={`productOwner`}>Your Business Name</div>

                <div className="bottomInfoContainer">
                  <div className="reviewsContainer">
                    <StarReviews value={5} />
                    <span className="ratingNum">0</span>
                  </div>
                  <div className="priceTagContainer">
                    <div className="priceTag">
                      <div className="priceContainer">
                        <span className="price">
                          $
                          <span className="integer">
                            {value.price ? value.price : "0.00"}
                          </span>
                        </span>
                        <span className="currency">{value.currency}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const FormCreatedProduct = ({
  value,
  onChange,
  handleSubmit,
  formChangeHandler,
  uploadHandler,
  error,
  cat,
  subcategories,
  toggleSubscriptionActive,
  images,
  setImages,
  CancelForm,
}) => {
  return (
    <div className="productFormContainer">
      <form
        id="created-product-form"
        className="updateProductForm"
        onSubmit={handleSubmit}
        onChange={formChangeHandler}
      >
        <div className="allInputsContainer">
          <div className="imageInputContainer">
            <div className="inputTitle">
              <label for="fileUpload" className="inputTitleText">
                Image
              </label>
            </div>
            <label className={`customFileUpload ${error ? "redError" : ""}`}>
              <input
                type="file"
                placeholder="Image"
                id="fileUpload"
                onChange={uploadHandler}
                required
              />
              Click to Add Image
            </label>
            {error && (
              <div className={`error ${error ? "redError" : ""}`}>{error}</div>
            )}
          </div>

          <div className="inputContainer">
            <div className="inputTitle">
              <label for="productName" className="inputTitleText">
                Name
              </label>
            </div>
            <input
              type="text"
              id="productName"
              name="name"
              placeholder="Enter product name here"
              onChange={(e) => onChange(e)}
              value={value.name}
            />
          </div>

          <div className="inputContainer">
            <div className="inputTitle">
              <label for="productPrice" className="inputTitleText">
                Price
              </label>
            </div>
            <div className="priceCurrencyContainer">
              <input
                type="text"
                id="productPrice"
                placeholder="Enter product price here"
                name="price"
                value={value.price}
                onChange={(e) => {
                  const currentValue = e.target.value;

                  if (!/^[0-9]{0,7}(\.\d{0,2})?$/.test(currentValue)) {
                  } else {
                    onChange(e);
                  }
                }}
                required
              />

              <select
                value={value.currency}
                name="currency"
                onChange={(e) => onChange(e)}
                style={{ width: "70px", height: "44px" }}
              >
                <option value={"MXN"}>MXN</option>
                <option value={"USD"}>USD</option>
              </select>
            </div>
          </div>

          <div className="inputContainer">
            <div className="inputTitle">
              <label for="textdescription" className="inputTitleText">
                Description and Extra Details
              </label>
            </div>
            <textarea
              type="text"
              id="textdescription"
              name="description"
              placeholder="Enter product Description here"
              onChange={(e) => onChange(e)}
              value={value.description}
              required
            />
          </div>

          <div className="inputContainer">
            <div className="inputTitle">
              <div className="inputTitleText">Category</div>
            </div>

            <select
              value={value.category}
              name="category"
              onChange={(e) => onChange(e)}
              style={{ marginBottom: "5px" }}
              required
            >
              <option value={0} disabled>
                selected Category
              </option>

              {cat.map((cat) => {
                return (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                );
              })}
            </select>

            <select
              value={value.subCategory}
              name="subCategory"
              onChange={(e) => onChange(e)}
            >
              <option value={0}>subcategoría</option>

              {subcategories.map((sub) => {
                return (
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="visibleToSubscribersInputContainer">
            <div className="inputTitle">
              <div className="inputTitleText">
                Visibility:{" "}
                {value.subscriptionActive
                  ? "Only visible to LG Members"
                  : "All Users"}
              </div>
              <div className="inputSubtitleText">
                Chose if your product is only visible to users with Lebaron
                Galeana subscription
              </div>
            </div>

            <div className="visibilityBtnsContainer">
              {value.subscriptionActive ? (
                <div
                  className="visiblityBtn"
                  onClick={toggleSubscriptionActive}
                >
                  Visible to All
                </div>
              ) : (
                <div
                  className="visiblityBtn"
                  onClick={toggleSubscriptionActive}
                >
                  Only Visible to LG Members
                </div>
              )}
            </div>
          </div>

          <ImageUploader images={images} setImages={setImages} />
        </div>

        <div className="saveAndCancelBtnsContainer">
          <div className="deleteBtnContainer">
            <button id="canceledProduct" type="button" onClick={CancelForm}>
              Cancel
            </button>
          </div>
          <div className="saveBtnContainer">
            <button id="saveProduct" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
