import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from "redux";

import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import { favoritesReducer } from "./reducers/favoritesReducers";

import {
  companyDetailsReducer,
  companyCreateReducer,
  companyUpdateReducer,
  categoryListReducer,
  categoryDetailsReducer,
  ListReviewsCompanyReducers,
  reviewsCompaniesReducer,
  reviewsUpdateCompaniesReducer,
  //
  CompaniesFilterReducer,
} from "./reducers/companyReducers";

import {
  productCategoriesReducers,
  categoriesInUseReducer,
  listRelatedReducer,
  productsFiltersReducer,
  //
  productsHomeFiltersReducer,
} from "./reducers/productsReducer";

import {
  CartGetItemsReducers,
  CartGetTotalPrice,
  CartGetTotalItem,
  AddCartItems,
  UpdateCartItem,
  DeleteItemReducers,
  EmptyCartReducers,
  SynchCartReducers,
} from "./reducers/cartReducers";

import {
  ListAboutCompanyReducer,
  UpdateAboutReducer,
  AddAboutReducer,
} from "./reducers/aboutRedcuers";
import {
  CompnayAdressReducers,
  AddCompanyAdress,
  UpdateCompanyAdress,
} from "./reducers/adress/adressCompanyRedcuers";
import {
  UserAddressReducers,
  UpdateUserAdress,
  AddUserAdressReducer,
} from "./reducers/adress/adressUserReducers";

import {
  GetOrderReducer,
  //
  getOrderCompanyReducer,
  //
  orderStatusReducer,
  //
  GenerateRandomWord,
  //
  DeclinedOrderReducers,
} from "./reducers/ordersReducers";

import {
  getContactReducer,
  AddContactReducer,
  UpdateContactReducer,
} from "./reducers/contactReducers";

import {
  UpdateSocialNetworkReducer,
  AddSocialNetworkReducer,
  getSocialNetworkReducer,
} from "./reducers/socialNetworkReducers";

import {
  DriverDeliveryOrderReducer,
  DriverAllOrderReducer,
  //
  DriversInDeliveryProcess,
  DriversAvailable,
  //
  DriverListOrders,
  //
} from "./reducers/driverReducers";

import { DriverProfileReducer } from "./reducers/driver-profileReducers";

import { userLoginReducer, userDetailReducer } from "./reducers/userReducer";

import { galeanaBizApi } from "./services/galeanaBizApi";
import { galeanaBizApiCompanyService } from "./services/galeanaBizApiCompanyService";

const reducer = combineReducers({
  //USER
  userLogin: userLoginReducer,
  userDetails: userDetailReducer,

  //COMPANY
  comapanyFilters: CompaniesFilterReducer,
  //
  companyDetails: companyDetailsReducer,
  companyCreate: companyCreateReducer,
  companyUpdate: companyUpdateReducer,
  //
  ListReviews: ListReviewsCompanyReducers,
  companyReview: reviewsCompaniesReducer,
  companyReviewUpdate: reviewsUpdateCompaniesReducer,
  //

  companyCategories: categoryListReducer,
  companyCategory: categoryDetailsReducer,

  //FAVORITES
  favorites: favoritesReducer,

  //
  ProductCategories: productCategoriesReducers,
  ProductCategoriesInUse: categoriesInUseReducer,
  //
  ProductRelated: listRelatedReducer,
  productsFilters: productsFiltersReducer,
  productsHomeFilters: productsHomeFiltersReducer,

  //CartItem
  CartGetItems: CartGetItemsReducers,
  CartGetTotalPrice: CartGetTotalPrice,
  CartGetTotalItems: CartGetTotalItem,
  CartAddItems: AddCartItems,
  CartUpdateItem: UpdateCartItem,
  CartRemoveItem: DeleteItemReducers,
  CartTheEmptyItems: EmptyCartReducers,
  CartSynch: SynchCartReducers,

  //about
  AboutList: ListAboutCompanyReducer,
  PutAbout: UpdateAboutReducer,
  PostAbout: AddAboutReducer,
  //contact
  GetContact: getContactReducer,
  PostContact: AddContactReducer,
  PutContact: UpdateContactReducer,

  //socialnetwork
  PutSocial: UpdateSocialNetworkReducer,
  PostSocial: AddSocialNetworkReducer,
  GetSocial: getSocialNetworkReducer,
  //CompanyAdress
  AdressCompanyGet: CompnayAdressReducers,
  AdressCompnayPost: AddCompanyAdress,
  AdressCompnayPut: UpdateCompanyAdress,
  //UserAdress
  AdressUserGet: UserAddressReducers,
  AdressUserPost: AddUserAdressReducer,
  AdressUserPut: UpdateUserAdress,
  //order user
  GetUserOrder: GetOrderReducer,

  //wordRandoom
  Randomword: GenerateRandomWord,
  //admin
  DeclinedOrder: DeclinedOrderReducers,
  //company order
  GetAllOrder: getOrderCompanyReducer,
  GetListOrdersAsign: DriverListOrders,
  //
  getOrderStatus: orderStatusReducer,
  //

  //drievr-profile
  Driverprofile: DriverProfileReducer,

  //driver
  DriverDelivery: DriverDeliveryOrderReducer,

  DriverAllOrder: DriverAllOrderReducer,

  //
  Delivery: DriversInDeliveryProcess,
  DriversAvailable: DriversAvailable,

  // API
  [galeanaBizApi.reducerPath]: galeanaBizApi.reducer,
  [galeanaBizApiCompanyService.reducerPath]:
    galeanaBizApiCompanyService.reducer,
});

const userTokenStorge = localStorage.getItem("userToken")
  ? JSON.parse(localStorage.getItem("userToken"))
  : null;

const userStorage = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

// Cart
const cartGetItems = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : [];

const initialState = {
  userLogin: { userToken: userTokenStorge },
  userDetails: { user: userStorage },
  CartGetItems: { CartGetItems: cartGetItems },
};

const middleware = [
  thunk,
  galeanaBizApi.middleware,
  galeanaBizApiCompanyService.middleware,
];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
