import React, { useEffect, useState } from "react";
import "./styles/CompanyService.scss";
import ServiceCard_V1 from "./components/ServiceCard_V1";
import { useGetServicesCompanyQuery } from "../../services/galeanaBizApiCompanyService";
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import ServicePinnedCard from "./components/ServicePinnedCard";

const CompanyService = () => {
  const { businessId } = useParams();

  const [services, setServices] = useState([]);
  const [servicesPinned, setServicesPinned] = useState([]);

  const { user } = useSelector((state) => state.userDetails);
  const { data, isLoading, isFetching, error } = useGetServicesCompanyQuery({
    action: "all",
    parameter: "company_id",
    value: businessId,
    status: user?.subscriptionStatus?.active,
  });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }
    setServicesPinned(data?.service_pinned);
    setServices(data?.services);
  }, [data, isFetching]);

  const Services = () => {
    if (isLoading || isFetching) {
      return <Loader />;
    }

    if (error) {
      return <Message>{error}</Message>;
    }

    if (services.length === 0 && !isFetching && !isLoading) {
      return <p>Business doesnt have an Services page</p>;
    }

    if (!services) {
      return;
    }

    return services?.map((service, index) => (
      <ServiceCard_V1 key={index} service={service} />
    ));
  };

  const ServicePinned = () => {
    if (isLoading || isFetching) {
      return <Loader />;
    }

    if (error) {
      return <Message>{error}</Message>;
    }

    if (!servicesPinned) {
      return;
    }
    return servicesPinned?.map((service, index) => (
      <ServicePinnedCard key={index} service={service} />
    ));
  };

  return (
    <div className="ServicePage">
      <div className="servicePinnedCardsContainer">
        <div className="servicePinnedCardsWrapper">
          <ServicePinned />
        </div>
      </div>

      <div className="ServiceCardsContainer">
        <div className="ServiceCardsWrapper">
          <Services />
        </div>
      </div>
    </div>
  );
};

export default CompanyService;
