//////////////////product////////////////////////////////////////////
export const PRODUCT_DELETE_IMAGES_REQUEST = "PRODUCT_DELETE_IMAGES_REQUEST";
export const PRODUCT_DELETE_IMAGES_SUCCESS = "PRODUCT_DELETE_IMAGES_SUCCESS";
export const PRODUCT_DELETE_IMAGES_FAIL = "PRODUCT_DELETE_IMAGES_FAIL";

////////////////////////////Category//////////////////////////////////

export const PRODUCT_CATEGORY_DETAILS_REQUEST =
  "PRODUCT_CATEGORY_DETAILS_REQUEST";
export const PRODUCT_CATEGORY_DETAILS_SUCCESS =
  "PRODUCT_CATEGORY_DETAILS_SUCCESS";
export const PRODUCT_CATEGORY_DETAILS_FAIL = "PRODUCT_CATEGORY_DETAILS_FAIL";
export const PRODUCT_CATEGORY_DETAILS_RESET = "PRODUCT_CATEGORY_DETAILS_RESET";

export const CATEGORIES_IN_USE_REQUEST = "CATEGORIES_IN_USE_REQUEST";
export const CATEGORIES_IN_USE_SUCCESS = "CATEGORIES_IN_USE_SUCCESS";
export const CATEGORIES_IN_USE_FAIL = "CATEGORIES_IN_USE_FAIL";

//////////////Serach and Filters /////////////////////////////

export const PRODUCT_RELATED_REQUEST = "PRODUCT_RELATED_REQUEST";
export const PRODUCT_RELATED_SUCCCESS = "PRODUCT_RELATED_SUCCCESS";
export const PRODUCT_RELATED_FAIL = "PRODUCT_RELATED_FAIL";
export const PRODUCT_RELATED_RESET = "PRODUCT_RELATED_RESET";

export const PRODUCTS_FILTERS_UPDATE = "PRODUCT_FILTERS_UPDATE";

export const PRODUCTS_HOME_FILTER_UPDATE = "PRODUCTS_HOME_FILTER_UPDATE";

////////////////////Reviews/////////////////////////////////////////////

export const PRODCUT_REVIEW_REQUEST = "PRODCUT_REVIEW_REQUEST";
export const PRODCUT_REVIEW_SUCCCESS = "PRODCUT_REVIEW_SUCCCESS";
export const PRODCUT_REVIEW_FAIL = "PRODCUT_REVIEW_FAIL";
export const PRODCUT_REVIEW_RESET = "PRODCUT_REVIEW_RESET";

export const PRODCUT_REVIEW_UPDATE_REQUEST = "PRODCUT_REVIEW_UPDATE_REQUEST";
export const PRODCUT_REVIEW_UPDATE_SUCCCESS = "PRODCUT_REVIEW_UPDATE_SUCCCESS";
export const PRODCUT_REVIEW_UPDATE_FAIL = "PRODCUT_REVIEW_UPDATE_FAIL";
export const PRODCUT_REVIEW_UPDATE_RESET = "PRODCUT_REVIEW_UPDATE_RESET";
