import React, { useState, useEffect } from "react";
import AddToAllPages from "../../../../components/AddToAllPages";

import { Link, useNavigate } from "react-router-dom";

import "../../../../styles/pages/checkout/OrderDetails.scss";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

import formatCurrency from "../../../../helpers/format/formatCurrency";
import formatDateTime from "../../../../helpers/formatDateTime";
import formatPhoneNumber from "../../../../helpers/format/formatPhoneNumber";
import useGetOrderDetails from "../hooks/useGetOrderDetails";
import usePutCancelledOrder from "../hooks/usePutCancelledOrder";

const OrderDetails = () => {
  const { orderDetail, orderDetailFeching, orderDatailLoading, orderError } =
    useGetOrderDetails();

  useEffect(() => {
    if (!orderDetail || orderDetailFeching) {
      return;
    }
  }, [orderDetail, orderDetailFeching]);

  return (
    <AddToAllPages>
      <div className="contentLink">
        <Link to={`/myorders`} className="visitLink">
          <i className="fa-solid fa-angle-left"></i>
          Visit the My Orders
        </Link>
      </div>

      <OrderDetailsUser
        deatailOrderLoading={orderDatailLoading}
        deatailOrderError={orderError}
        detailUserOrder={orderDetail}
      />
    </AddToAllPages>
  );
};

export default OrderDetails;

const OrderDetailsUser = ({
  deatailOrderLoading,
  deatailOrderError,
  detailUserOrder,
}) => {
  const navigate = useNavigate();

  const [openDropdownItems, setOpenDropdownItems] = useState([]);
  const [companiesInOrder, setCompaniesInOrder] = useState([]);

  const { isRedirected, resultCancelledOrder } = usePutCancelledOrder();

  const { addressUser } = useGetOrderDetails();

  useEffect(() => {
    if (!detailUserOrder?.order_companies || deatailOrderLoading) {
      return;
    }
    setCompaniesInOrder(detailUserOrder?.order_companies);
  }, [detailUserOrder, deatailOrderLoading]);

  useEffect(() => {
    if (isRedirected && resultCancelledOrder.isSuccess) {
      navigate("/myorders", { replace: true });
    }
  }, [isRedirected, resultCancelledOrder.isSuccess, navigate]);

  const price = (cargo_extra, price, currency) => {
    return cargo_extra > 0 ? (
      <div>{formatCurrency(cargo_extra, currency)}</div>
    ) : (
      <div>{formatCurrency(price, currency)}</div>
    );
  };

  const handleDropDown = (e, orderItemId) => {
    e.preventDefault();

    if (openDropdownItems.includes(orderItemId)) {
      setOpenDropdownItems(
        openDropdownItems.filter((index) => index !== orderItemId)
      );
    } else {
      setOpenDropdownItems([...openDropdownItems, orderItemId]);
    }
  };

  const RenderOrderItem = ({ item }) => {
    const categoryGroup = item?.attributes;
    const dropDown = openDropdownItems.includes(item?.id);
    return (
      <div className="wrapperItem">
        <div className="item" onClick={(e) => handleDropDown(e, item?.id)}>
          <div className="itemLabelContainer">
            <label>P.Name:</label>
            <div className="itemDetail">{item?.name}</div>
          </div>

          <div className="itemLabelContainer">
            <label>P.Status:</label>
            <div className="itemDetail">{item?.status}</div>
          </div>

          <div className="itemDetails">
            <div className="itemAmount">P.Qty: {item?.count} </div>

            <span>x</span>

            <div className="itemPrice">
              {item?.discount > 0 ? (
                <div className="discountedPriceContainer">
                  <div className="containerDiscount">
                    <div className="discountedPrice">
                      {price(
                        item?.cargo_extra,
                        item?.discounted_price,
                        item?.currency
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="price">
                  {price(item?.cargo_extra, item?.price, item?.currency)}
                </div>
              )}
              <div className="currency">{item?.currency}</div>

              <div className="wrapperIcon">
                {categoryGroup.length > 0 && (
                  <div className="contentIcon">
                    {dropDown ? (
                      <i className="fas fa-chevron-up"></i>
                    ) : (
                      <i className="fas fa-chevron-down"></i>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <DropDownComponent dropDown={dropDown} categoryGroup={categoryGroup} />
      </div>
    );
  };

  const RenderCompanyInfo = ({ orderCompanies }) => {
    return (
      <div className="companyInfo" key={orderCompanies?.company_name}>
        <div className="companyItem">
          Company: {orderCompanies?.company_name}
        </div>
        <div className="companyTotalPrice">
          Total Price: {formatCurrency(orderCompanies?.total)} MXN
        </div>
        {orderCompanies?.orderItem.map((orderItems, index) => {
          return <RenderOrderItem key={index} item={orderItems} />;
        })}
      </div>
    );
  };

  const MessageOrder = () => {
    const message = detailUserOrder?.cancellation_reason
      ? detailUserOrder?.cancellation_reason
      : detailUserOrder?.delivery_description;

    if (!message) return null;

    return (
      <div className="orderMessage">
        <div className="subtitle">Message order:</div>

        <div className="contentMessage">
          <textarea value={message} className="message" />
        </div>
      </div>
    );
  };

  if (deatailOrderLoading) {
    return <Loader />;
  }

  if (deatailOrderError) {
    return <Message>{"try refreshing the page"}</Message>;
  }

  return (
    <div className="orderDetails">
      <div className="orderDetailsContainer">
        <div className="orderDetails">
          <div className="topSection">
            <div className="orderUserName">
              {detailUserOrder?.user_name} {detailUserOrder?.last_name}
            </div>
            <div className="dateTxt">
              {formatDateTime(detailUserOrder?.date_issued)}
            </div>
          </div>
          <div className="orderInfo">
            <div className="order-status">
              Status: {detailUserOrder?.status}
            </div>
            <div className="order-load-type">
              Load Type: {detailUserOrder?.load_type}
            </div>
            <div className="order-key-word">
              {detailUserOrder?.delivery_password ? (
                <>
                  <label>Key Word:</label>
                  <div className="keyWord">
                    {detailUserOrder?.delivery_password}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="orderProducts">
            {companiesInOrder.map((orderCompanies, index) => {
              return (
                <RenderCompanyInfo
                  key={index}
                  orderCompanies={orderCompanies}
                />
              );
            })}
          </div>
          <div className="orderTotal">
            Order Total: {formatCurrency(detailUserOrder?.order_total)} MXN
          </div>
        </div>

        <br />

        <MessageOrder />

        <br />

        <div className="orderAdress">
          <div className="subtitle">My Address:</div>
          <div className="addressCard">
            <div className="sectionContainer">
              <div className="infoTxt">
                Full Name: {addressUser.first_name} {addressUser.last_name}
              </div>

              <div className="infoTxt">
                <i className="fa-solid fa-phone"></i>
                {formatPhoneNumber(addressUser.telephone_number)}
              </div>

              <div className="infoTxt">
                <i className="fa-solid fa-location-dot"></i>
                {addressUser.location}
              </div>
            </div>

            <div className="sectionContainer">
              <div className="infoTxt">Street 1: {addressUser.street_1}</div>
              <div className="infoTxt">Street 2: {addressUser.street_2}</div>
            </div>
          </div>
        </div>

        <SectionCancelledOrder detailUserOrder={detailUserOrder} />
      </div>
    </div>
  );
};

const SectionCancelledOrder = ({ detailUserOrder }) => {
  const { handleCancelledOrder, resultCancelledOrder } = usePutCancelledOrder();

  if (
    ["cancelled", "declined", "shipped", "delivered"].includes(
      detailUserOrder?.status
    )
  ) {
    return null;
  }

  return (
    <div className="cancelBtnContainer">
      {resultCancelledOrder.isLoading ? (
        <Loader />
      ) : resultCancelledOrder.isError ? (
        <Message error={resultCancelledOrder.error}>
          {resultCancelledOrder.error}
        </Message>
      ) : (
        <button className="loadMoreBtn" onClick={handleCancelledOrder}>
          Cancel Order
        </button>
      )}
    </div>
  );
};

const DropDownComponent = ({ dropDown, categoryGroup }) => {
  if (!dropDown || !categoryGroup || categoryGroup.length === 0) return null;

  return (
    <div className="warpperDropDown">
      {categoryGroup?.map((attribute, index) => {
        return (
          <div className="contentDropDown" key={index}>
            <ul>
              <li>
                <div className="nameCategory">{attribute.category_title}</div>

                <ul>
                  <li>
                    <div>
                      <div>{attribute.attribute_name}</div>
                      <div>+ {formatCurrency(attribute.attribute_price)}</div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );
};
