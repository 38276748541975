import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Redux (Actions)
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from "../../../../services/galeanaBizApi";

// SCSS
import "../../../../styles/components/MyCompanyPageComponents/Dashboard/MyProductsContainer.scss";

// Components
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";
import ProductCard_V2 from "../../../../components/CompanyAndProductCards/ProductCard_V2";
import ProductsCard from "../../../../components/CompanyAndProductCards/ProductsCard";

import { ProductOptionModal } from "../../components/ProductOptionModal";
import Modal from "../../../../components/modal/Modal";

function MyProductsContainer({ company }) {
  const [products, setPorducts] = useState([]);
  const [productsPinned, setProductsPinned] = useState([]);

  const { data, error, isFetching } = useGetProductsQuery({
    companyId: company._id,
    subscriptionStatus: true,
  });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setPorducts(data.products);
    setProductsPinned(data.porducts_pinned);
  }, [data, isFetching]);

  if (isFetching) {
    return <Loader />;
  }

  if (error) {
    return (
      <Message error={error}>
        Something went wrong retrieving the products, please try again later.
      </Message>
    );
  }

  return (
    <div className="myProductsContainer">
      <PorductsPinned productsPinned={productsPinned} />
      <LatestPorducts company={company} products={products} />
    </div>
  );
}

export default MyProductsContainer;

const PorductsPinned = ({ productsPinned }) => {
  if (productsPinned.length === 0) return null;
  const fromUrl = `fromUrl=${encodeURIComponent(window.location.pathname)}`;

  return (
    <div className="contentPorductsPinned">
      <div className="title">Your Pinned Products</div>

      <div className="productPinnedCardsContainer">
        <div className="productPinnedCardsWrapper">
          {productsPinned.map((product, index) => {
            return (
              <div className="productCard" key={index}>
                <ProductsCard key={index} products={product} />

                <div className="contentLink">
                  <Link
                    to={`/product/${[product?.id]}/updateProduct?${fromUrl}`}
                    className="actionBtn"
                  >
                    Edit
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// Section LatestPorducts

const LatestPorducts = ({ company, products }) => {
  const [valueDeleteProduct, setValueDeleteProduct] = useState({
    is_open: false,
    id: 0,
    photo: null,
    name: "",
  });

  const [valueProductOption, setValueProductOption] = useState({
    is_open: false,
    id: 0,
    companyId: 0,
    name: "",
  });

  const [deleteProduct, deleteProductResult] = useDeleteProductMutation();

  const clickedDeleteProduct = (id, name, photo) => {
    setValueDeleteProduct({
      is_open: true,
      id: id,
      photo: photo,
      name: name,
    });
  };

  const clickedOptionProduct = (id, companyId, name) => {
    setValueProductOption({
      is_open: true,
      id: id,
      companyId: companyId,
      name: name,
    });
  };

  const deleteHandler = (id) => {
    deleteProduct({ productId: id, companyId: company._id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }

        setValueDeleteProduct({ is_open: false });
      }
    );
  };
  return (
    <div className="contentLatestPorducts">
      <div className="title">Your Latest 5 Products</div>
      <div className="productsContainer">
        {products.slice(0, 5).map((product, index) => (
          <ProductInfo
            key={index}
            product={product}
            clickedDeleteProduct={clickedDeleteProduct}
            clickedOptionProduct={clickedOptionProduct}
          />
        ))}
      </div>

      {/* Link */}
      <div className="moreProductsLinkContainer">
        <Link to={"/mybusiness/products"} className="moreProductsLink">
          More Products
        </Link>
      </div>

      {/* Modals */}
      <ModalDeletePorduct
        valueDeleteProduct={valueDeleteProduct}
        setValueDeleteProduct={setValueDeleteProduct}
        deleteProductResult={deleteProductResult}
        deleteHandler={deleteHandler}
      />

      <Modal
        isOpen={valueProductOption.is_open}
        onClose={() => setValueProductOption({ is_open: false })}
      >
        <ProductOptionModal product={valueProductOption} />
      </Modal>
    </div>
  );
};

const ProductInfo = ({
  product,
  clickedDeleteProduct,
  clickedOptionProduct,
}) => {
  const fromUrl = `fromUrl=${encodeURIComponent(window.location.pathname)}`;

  return (
    <div className="productInfoContainer">
      <ProductCard_V2 products={product} />

      <div className="buttonsContainer">
        <div
          className="deleteBtn actionBtn"
          onClick={() =>
            clickedDeleteProduct(product?.id, product?.name, product?.photo)
          }
        >
          Delete
        </div>
        <Link
          to={`/product/${[product?.id]}/updateProduct?${fromUrl}`}
          className="editBtn actionBtn"
        >
          Edit
        </Link>
        {/* No eliminar este Button */}
        {/* <button
          onClick={() =>
            clickedOptionProduct(product?.id, product?.company, product?.name)
          }
          className="optionsBtn actionBtn"
        >
          Options
        </button> */}
      </div>
    </div>
  );
};

const ModalDeletePorduct = ({
  valueDeleteProduct,
  setValueDeleteProduct,
  deleteProductResult,
  deleteHandler,
}) => {
  if (!valueDeleteProduct.is_open) return null;

  return (
    <div className="deleteProductModal">
      <div className="background"></div>
      <div className="modalContent">
        <div className="textContainer">
          <div className="titleText">Are you sure you want to delete?</div>
          {!!deleteProductResult.error && (
            <p className="error">
              Something went wrong, please try again later.
            </p>
          )}
        </div>

        <div className="productInfoContainer">
          <div className="productImgContainer">
            <img src={valueDeleteProduct.photo} alt="" />
          </div>
          <div className="productName">{valueDeleteProduct.name}</div>
        </div>

        <div className="buttonsContainer">
          <button
            className="cancelBtn"
            onClick={() => setValueDeleteProduct({ is_open: false })}
          >
            Cancel
          </button>
          <button
            className="deleteBtn"
            onClick={() =>
              deleteHandler(valueDeleteProduct.id, valueDeleteProduct.name)
            }
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
