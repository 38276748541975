import {
  DRIVER_GET_ORDER_REQUEST,
  DRIVER_GET_ORDER_SUCCESS,
  DRIVER_GET_ORDER_FAIL,
  //
  DRIVER_DELIVERY_ORDER_REQUEST,
  DRIVER_DERLIVERY_ORDER_SUCCESS,
  DRIVER_DERLIVERY_ORDER_FAIL,
  DRIVER_DERLIVERY_ORDER_RESET,
  //
  GET_ASSING_DRIVER_REQUEST,
  GET_ASSING_DRIVER_SUCCESS,
  GET_ASSING_DRIVER_FAIL,
  //
  GET_DRIVER_SHIPPING_REQUEST,
  GET_DRIVER_SHIPPING_SUCCESS,
  GET_DRIVER_SHIPPING_FAIL,
  //
  GET_DRIVER_LIST_ORDERS_REQUEST,
  GET_DRIVER_LIST_ORDERS_SUCCESS,
  GET_DRIVER_LIST_ORDERS_FAIL,
} from "../constants/driverConstants";

////////////////////////////////////////////////////////////////////
export const DriversInDeliveryProcess = (
  state = { OrderInProgress: [] },
  action
) => {
  switch (action.type) {
    case GET_DRIVER_SHIPPING_REQUEST:
      return { loading: true };

    case GET_DRIVER_SHIPPING_SUCCESS:
      return { loading: false, OrderInProgress: action.payload };

    case GET_DRIVER_SHIPPING_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const DriversAvailable = (state = { Drivers: [] }, action) => {
  switch (action.type) {
    case GET_ASSING_DRIVER_REQUEST:
      return { loading: true };

    case GET_ASSING_DRIVER_SUCCESS:
      return { loading: false, Drivers: action.payload };

    case GET_ASSING_DRIVER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const DriverDeliveryOrderReducer = (
  state = { loading: false, error: null },
  action
) => {
  switch (action.type) {
    case DRIVER_DELIVERY_ORDER_REQUEST:
      return { loading: true };

    case DRIVER_DERLIVERY_ORDER_SUCCESS:
      return { loading: false, success: true };

    case DRIVER_DERLIVERY_ORDER_FAIL:
      return { loading: false, error: action.payload };

    case DRIVER_DERLIVERY_ORDER_RESET:
      return {
        ...state,
        success: false,
      };

    default:
      return state;
  }
};

export const DriverListOrders = (state = { DriverListOrders: [] }, action) => {
  switch (action.type) {
    case GET_DRIVER_LIST_ORDERS_REQUEST:
      return { loading: true };

    case GET_DRIVER_LIST_ORDERS_SUCCESS:
      return { loading: false, DriverListOrders: action.payload };

    case GET_DRIVER_LIST_ORDERS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const DriverAllOrderReducer = (
  state = { DriverOrderAll: [], next: null, previous: null },
  action
) => {
  switch (action.type) {
    case DRIVER_GET_ORDER_REQUEST:
      return { loading: true };

    case DRIVER_GET_ORDER_SUCCESS:
      const { next, previous, DriverOrderAll } = action.payload;
      return {
        loading: false,
        next: next,
        previous: previous,
        DriverOrderAll: DriverOrderAll,
      };

    case DRIVER_GET_ORDER_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
