import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  DeletedImagesActions,
  ProductCategoriesAction,
} from "../../actions/productsAction";

//icon
import { BsToggleOn } from "react-icons/bs";
import { BsToggleOff } from "react-icons/bs";

// Styles
import "../../styles/pages/products/UpdateProducts.scss";

//  Images
import LGLogo from "../../images/LGWhiteLogo.png";

// Components
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import ProductCard_V2 from "../../components/CompanyAndProductCards/ProductCard_V2";
import StarReviews from "../../components/StarReviews";

import NavigationComponents from "../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import ImageUploaderUpdate from "../../components/multipleImages/ImageUploaderUpdate";
import { imageCompression } from "../../helpers/compressor/imageCompression";
import {
  useGetProductQuery,
  useUpdateProductMutation,
} from "../../services/galeanaBizApi";

const UpdateProduct = () => {
  const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const productId = id;

  const [searchParams] = useSearchParams();
  const fromUrl = searchParams.get("fromUrl");

  const path = fromUrl || "/mybusiness/products";

  const { company } = useSelector((state) => state.userDetails);
  const companyId = company._id;
  const {
    data: product,
    isFetching: isFetchingProduct,
    error: productError,
  } = useGetProductQuery(productId);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState("");

  const [quantityManage, setQuantityManage] = useState(true);
  const [photo, setPhoto] = useState(null);
  const [fakeImage, setFakeImage] = useState(null);
  const [images, setImages] = useState([]);
  const [fakeimages, setFakeImages] = useState([]);
  const [category_id, setCategory_id] = useState("");
  const [subcategory_id, setSubcategory_id] = useState("");
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [available, setAvailable] = useState(false);
  const [currency, setCurrency] = useState("MXN");

  const [deletedImageIds, setDeletedImageIds] = useState([]);

  const [listCategories, setListCategoires] = useState([]);

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const [valueProduct, setValueProduct] = useState({
    is_pin: null,
    count_pinned: null,
  });

  const [updateProduct, updateProductResult] = useUpdateProductMutation();
  const { categories } = useSelector((state) => state.ProductCategories);

  useEffect(() => {
    dispatch(ProductCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    setListCategoires(categories?.categories);
  }, [categories?.categories]);

  useEffect(() => {
    const confirmationMessage =
      "¿Are you sure you want to leave this page? Your progress will be lost.";

    const handleBeforeUnload = (e) => {
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.onbeforeunload = handleBeforeUnload;

    return () => {
      window.onbeforeunload = null;
    };
  });

  useEffect(() => {
    if (product === undefined) {
      return;
    }

    setName(product.name);
    setFakeImage(product.photo);
    setDescription(product.description);
    setPrice(product.price);
    setQuantityManage(product.quantityManage);
    setSubscriptionActive(product.subscription_active);
    setAvailable(product.available);
    setFakeImages(product.images);
    setDiscount(product.discount);
    setDiscountedPrice(product.discounted_price);

    setValueProduct({
      is_pin: product?.is_pin,
      count_pinned: product?.count_pinned,
    });
  }, [product]);

  const category =
    listCategories && listCategories.find((c) => c?.id === category_id);

  const subcategories = category?.sub_categories || [];

  useEffect(() => {
    const category = product?.category?.id;
    const parent = product?.category?.parent_category?.id;

    if (parent) {
      setCategory_id(parent);
      setSubcategory_id(category);
    } else {
      setCategory_id(category);
    }
  }, [product]);

  useEffect(() => {
    let newPrice = 0.0;

    if (discount > 0) {
      newPrice = price - (price * discount) / 100;
    }

    setDiscountedPrice(newPrice);
  }, [discount, price]);

  const uploadHandler = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      try {
        const compressedImageFile = await imageCompression(selectedFile);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);
        setFakeImage(compressedImageURL);
        setPhoto(compressedImageFile);
        setError(null);
      } catch (error) {
        setError("Error compressing the image. Please try again.");
      }
    }
  };

  const uploadImageToApi = async () => {
    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("product_id", productId);

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/product/postPhoto/`,
        formData,
        config
      );

      setPhoto(data);
      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  const uploadMultipleImageToApi = async () => {
    const formData = new FormData();

    formData.append("product_id", productId);

    setUploading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      for (let i = 0; i < images.length; i++) {
        formData.append("uploaded_images", images[i]);
      }

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/product/post-multiPhoto/`,
        formData,
        config
      );

      setImages(data);

      setUploading(false);
    } catch (error) {
      setUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (photo) {
      await uploadImageToApi();
    }

    if (images.length > 0) {
      await uploadMultipleImageToApi();
      setImages([]);
    }

    if (deletedImageIds.length > 0) {
      dispatch(DeletedImagesActions(deletedImageIds));
    }

    const category = parseInt(subcategory_id || category_id);

    updateProduct({
      productId,
      company: companyId,
      name,
      description,
      price,
      quantityManage,
      category_id: category,
      subscription_active: subscriptionActive,
      currency: currency,
      available,
      discount,
      is_pin: valueProduct.is_pin,
    }).then((response) => {
      if ("error" in response) {
        return;
      }

      navigate(path);
    });
  };

  const CancelForm = () => {
    navigate(path);
  };

  const handleBlur = () => {
    if (discount === "" || parseFloat(discount) < 0) {
      setDiscount(0);
    } else if (parseFloat(discount) > 90) {
      setDiscount(90);
    }
  };

  const handleCategoryChange = (e) => {
    setCategory_id(parseInt(e.target.value));
    setSubcategory_id("");
  };

  const handleSubcategoryChange = (e) => {
    setSubcategory_id(parseInt(e.target.value));
  };

  const toggleAvailable = () => {
    setAvailable(!available);
  };

  const limitPinnedProduct = () => {
    const max = 5;
    const count = valueProduct.count_pinned;

    let res = max - count;

    return res;
  };

  const handleTogglePin = (newValue) => {
    setValueProduct((prev) => ({
      ...prev,
      is_pin: newValue,
    }));
  };

  const priceIntegerPart = Math.floor(price); // Get the integer part
  const priceDecimalPart = (price - priceIntegerPart).toFixed(2).slice(2); // Get the decimal part

  const discountIntegerPart = Math.floor(discountedPrice); // Get the integer part
  const discountDecimalPart = (discountedPrice - discountIntegerPart)
    .toFixed(2)
    .slice(2); // Get the decimal part

  return (
    <NavigationComponents>
      {updateProductResult.isLoading && <Loader />}
      {!!updateProductResult.error && (
        <Message>
          Something went wrong with the update, please try again later
        </Message>
      )}

      {isFetchingProduct ? (
        <Loader />
      ) : productError ? (
        <Message>
          Something went wrong fetching the product, please try again later
        </Message>
      ) : (
        <>
          <div className="addProductPage">
            <div className="dummyProductCardSection">
              <div className="title">Product card preview</div>
              <div className="dummyProductCardContainer">
                <ProductCard_V2
                  customValues={{
                    photo: fakeImage,
                    name: name,
                    price: price,
                    discount: discount,
                    available: available,
                  }}
                />
              </div>
            </div>

            {/* ___________________________________________ */}
            {/* ___________________________________________ */}
            {/* ___________________________________________ */}

            <div className="productFormContainer">
              <form
                className="updateProductForm"
                method="PUT"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <div className="allInputsContainer">
                  <div className="imageInputContainer">
                    <div className="inputTitle">
                      <label htmlFor="fileUpload" className="inputTitleText">
                        Image
                      </label>
                    </div>
                    <label
                      className={`customFileUpload ${error ? "redError" : ""}`}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        placeholder="Image"
                        id="fileUpload"
                        onChange={uploadHandler}
                      />
                      Click to Add Image
                    </label>
                    {error && (
                      <div className={`error ${error ? "redError" : ""}`}>
                        {error}
                      </div>
                    )}
                  </div>
                  <div className="inputContainer">
                    <div className="inputTitle">
                      <label htmlFor="productName" className="inputTitleText">
                        Name
                      </label>
                    </div>
                    <input
                      type="text"
                      id="productName"
                      placeholder="Enter product name here"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>

                  {quantityManage ? (
                    <div className="inputContainer">
                      <div className="inputTitle">
                        <div className="inputTitleText">Available</div>
                      </div>
                      <div className="icon-container">
                        {available ? (
                          <BsToggleOn
                            className="available"
                            onClick={toggleAvailable}
                          />
                        ) : (
                          <BsToggleOff
                            className="exhausted"
                            onClick={toggleAvailable}
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="inputContainer">
                    <div
                      className="inputTitle"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div className="inputTitleText">Pin to top</div>

                      {limitPinnedProduct() === 0 ? (
                        <div className="inputIntrusctionText">
                          (Reached the maximum of pinned products)
                        </div>
                      ) : (
                        <div className="inputIntrusctionText">
                          You can pin a max of 5 products (
                          {limitPinnedProduct() - 5} pinned so far)
                        </div>
                      )}
                    </div>
                    <div className="icon-container">
                      {valueProduct.is_pin ? (
                        <BsToggleOn
                          className="available"
                          onClick={() => handleTogglePin(false)}
                        />
                      ) : limitPinnedProduct() === 0 && !product.is_pin ? (
                        <BsToggleOff className="exhausted" />
                      ) : (
                        <BsToggleOff
                          className="exhausted"
                          onClick={() => handleTogglePin(true)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="inputContainer">
                    <div className="inputTitle">
                      <label htmlFor="productPrice" className="inputTitleText">
                        Price
                      </label>
                    </div>
                    <div className="priceCurrencyContainer">
                      <input
                        className="inputPrice"
                        id="productPrice"
                        placeholder="Enter product price here (MAX 6 DIGITS)"
                        type="text"
                        step="0.01"
                        min="0"
                        value={price}
                        onChange={(e) => {
                          const currentValue = e.target.value;

                          if (!/^[0-9]{0,7}(\.\d{0,2})?$/.test(currentValue)) {
                          } else {
                            setPrice(currentValue);
                          }
                        }}
                        required
                      />
                      <select
                        value={currency}
                        name="currency"
                        onChange={(e) => setCurrency(e.target.value)}
                        style={{ width: "70px", height: "44px" }}
                      >
                        <option value={"MXN"}>MXN</option>
                        <option value={"USD"}>USD</option>
                      </select>
                    </div>
                  </div>
                  <div className="inputContainer">
                    <div className="inputTitle">
                      <label
                        htmlFor="productDiscount"
                        className="inputTitleText"
                      >
                        Discount (%)
                      </label>
                    </div>

                    <input
                      className=""
                      type="text"
                      id="productDiscount"
                      placeholder="Enter product discount (0 - 90%) here"
                      onChange={(e) => {
                        let newValue = e.target.value;

                        if (
                          newValue === "" ||
                          (parseFloat(newValue) >= 0 &&
                            parseFloat(newValue) <= 90)
                        ) {
                          setDiscount(newValue);
                        }
                      }}
                      onBlur={handleBlur}
                      value={discount}
                      required
                    />
                  </div>
                  <div className="inputContainer">
                    <div className="inputTitle">
                      <label
                        htmlFor="textdescription"
                        className="inputTitleText"
                      >
                        Description
                      </label>
                    </div>
                    <textarea
                      type="text"
                      id="textdescription"
                      placeholder="Enter product Description here"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </div>
                  <div className="inputContainer">
                    <div className="inputTitle">
                      <div className="inputTitleText">Category</div>
                    </div>

                    <select value={category_id} onChange={handleCategoryChange}>
                      <option value="" disabled>
                        Categories
                      </option>

                      {listCategories &&
                        Object.values(listCategories).map((cat) => {
                          return (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          );
                        })}
                    </select>

                    <select
                      value={subcategory_id}
                      onChange={handleSubcategoryChange}
                    >
                      <option value="">subcategoría</option>
                      {subcategories &&
                        subcategories.map((sub) => {
                          return (
                            <option key={sub.id} value={sub.id}>
                              {sub.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="visibleToSubscribersInputContainer">
                    <div className="inputTitle">
                      <div className="inputTitleText">
                        Visibility:
                        {subscriptionActive
                          ? "Only visible to LG subscribers"
                          : "All Users"}
                      </div>
                      <div className="inputSubtitleText">
                        Chose if your product is only visible to users with
                        Lebaron Galeana subscription
                      </div>
                    </div>

                    <div className="visibilityBtnsContainer">
                      {subscriptionActive ? (
                        <div
                          className="visiblityBtn"
                          onClick={() => setSubscriptionActive(false)}
                        >
                          Visible to All
                        </div>
                      ) : (
                        <div
                          className="visiblityBtn"
                          onClick={() => setSubscriptionActive(true)}
                        >
                          Only Visible to LG subscribers
                        </div>
                      )}
                    </div>
                  </div>

                  <ImageUploaderUpdate
                    images={images}
                    setImages={setImages}
                    fakeimages={fakeimages}
                    setFakeImages={setFakeImages}
                    deletedImageIds={deletedImageIds}
                    setDeletedImageIds={setDeletedImageIds}
                  />
                  <div className="saveAndCancelBtnsContainer">
                    <div className="deleteBtnContainer">
                      <button id="canceledProduct" onClick={CancelForm}>
                        Cancel
                      </button>
                    </div>
                    <div className="saveBtnContainer">
                      <button id="saveProduct" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </NavigationComponents>
  );
};

export default UpdateProduct;
