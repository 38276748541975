import React, { useEffect, useState } from "react";

import "../../styles/components/Search/SearchBar.scss";
import { useDispatch } from "react-redux";
import { ProductCategoriesInUseAction } from "../../actions/productsAction";

import SearchBar from "./SearchBar";
import FilterPanel from "./FilterPanel";

const SearchAndFilterBar = ({ companyId }) => {
  const dispatch = useDispatch();

  const [categoryId, setCategoryId] = useState(0);

  useEffect(() => {
    if (companyId) {
      dispatch(ProductCategoriesInUseAction(companyId));
    }
  }, [dispatch, companyId]);

  return (
    <div className="SearchBar">
      <div className="searchBarAndSuggestionsContainer">
        <SearchBar companyId={companyId} categoryId={categoryId} />
      </div>

      <div className="allFiltersContainer">
        <FilterPanel
          companyId={companyId}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
        />
      </div>
    </div>
  );
};

export default SearchAndFilterBar;
