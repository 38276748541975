import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

// SCSS
import "../../../../styles/pages/CompanyPage/ProductsPage/MyCompanyProductsPage.scss";

// Components
import Loader from "../../../../components/Loader";
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import Message from "../../../../components/Message";
import ExtraPagesNavbar from "../../components/ExtraPagesNavbar";
import { ExtraPagesLinks } from "../../components/ExtraPagesNavbar";
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from "../../../../services/galeanaBizApi";
import ProductCard_V2 from "../../../../components/CompanyAndProductCards/ProductCard_V2";
import Modal from "../../../../components/modal/Modal";
import { ProductOptionModal } from "../../components/ProductOptionModal";

const MyCompanyProductsPage = () => {
  const [page, setPage] = useState(1);

  const [valueDeleteProduct, setValueDeleteProduct] = useState({
    is_open: false,
    id: 0,
    photo: null,
    name: "",
  });

  const [valueProductOption, setValueProductOption] = useState({
    is_open: false,
    id: 0,
    companyId: 0,
    name: "",
  });

  const { company } = useSelector((state) => state.userDetails);
  const { data, error, isFetching } = useGetProductsQuery({
    companyId: company._id,
    page,
    subscriptionStatus: true,
  });
  const [deleteProduct, deleteResult] = useDeleteProductMutation();

  const clickedDeleteProduct = (id, name, photo) => {
    setValueDeleteProduct({
      is_open: true,
      id: id,
      photo: photo,
      name: name,
    });
  };

  const clickedOptionProduct = (id, companyId, name) => {
    setValueProductOption({
      is_open: true,
      id: id,
      companyId: companyId,
      name: name,
    });
  };

  const deleteHandler = (id) => {
    deleteProduct({ productId: id, companyId: company._id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }

        setValueDeleteProduct({ is_open: false });
      }
    );
  };

  const ProductDashboard = () => {
    if (isFetching) {
      return <Loader />;
    }

    if (error) {
      return <Message error>Products could not be loaded, try again</Message>;
    }

    return (
      <div className="productManagementContainer">
        {page > 0 ? (
          <p className="total">{data.total} products total</p>
        ) : (
          <p className="notPorducts">There are no products yet...</p>
        )}

        <div className="productsContainer">
          {(data?.products || []).map((products, key) => (
            <div className="productInfoContainer">
              <ProductCard_V2 products={products} key={key} />

              <div className="buttonsContainer">
                <div
                  className="deleteBtn actionBtn"
                  onClick={() =>
                    clickedDeleteProduct(
                      products.id,
                      products.name,
                      products.photo
                    )
                  }
                >
                  Delete
                </div>
                <Link
                  to={`/product/${[products.id]}/updateProduct`}
                  className="editBtn actionBtn"
                >
                  Edit
                </Link>
                {/* No eliminar este Button */}
                {/* <button
                  onClick={() =>
                    clickedOptionProduct(
                      products?.id,
                      products?.company,
                      products?.name
                    )
                  }
                  className="optionsBtn actionBtn"
                >
                  Options
                </button> */}
              </div>
            </div>
          ))}
        </div>

        <PaginationButtons
          currentPage={page}
          totalPages={data?.total_page}
          setPage={setPage}
        />
      </div>
    );
  };

  return (
    <NavigationComponents>
      <div className="myCompanyProductsPage">
        <ExtraPagesNavbar>
          <ExtraPagesLinks linkName="Product Cards" url="mybusiness/products" />
          <ExtraPagesLinks
            linkName="Products Table"
            url="mybusiness/table-products"
          />

          <ExtraPagesLinks linkName="Add Product" url="mybusiness/addproduct" />
        </ExtraPagesNavbar>

        <ProductDashboard />

        <DeleteProductModalOpen
          valueDeleteProduct={valueDeleteProduct}
          setValueDeleteProduct={setValueDeleteProduct}
          deleteHandler={deleteHandler}
          deleteError={deleteResult.error}
        />

        <Modal
          isOpen={valueProductOption.is_open}
          onClose={() => setValueProductOption({ is_open: false })}
        >
          <ProductOptionModal product={valueProductOption} />
        </Modal>
      </div>
    </NavigationComponents>
  );
};

export default MyCompanyProductsPage;

const PaginationButtons = ({ currentPage, totalPages, setPage }) => {
  if (currentPage === 0 || !currentPage) {
    return null;
  }

  return (
    <>
      <div className="switchPageContainer">
        <button
          className="switchPageBtnBack"
          disabled={currentPage === 1}
          onClick={() => setPage(currentPage - 1)}
        >
          Previous
        </button>
        <button className="currentPageTxt">{`Page ${currentPage} of ${totalPages}`}</button>
        <button
          className="switchPageBtnNext"
          disabled={currentPage === totalPages}
          onClick={() => setPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};

const DeleteProductModalOpen = ({
  valueDeleteProduct,
  setValueDeleteProduct,
  deleteHandler,
  deleteError,
}) => {
  const { is_open, id, photo, name } = valueDeleteProduct;

  return (
    <>
      {is_open ? (
        <>
          <div className="deleteProductModal">
            <div className="background"></div>

            <div className="modalContent">
              <div className="textContainer">
                <div className="titleText">
                  Are you sure you want to delete?
                </div>
                {!!deleteError && (
                  <p className="error">
                    Something went wrong, please try again later.
                  </p>
                )}
              </div>

              <div className="productInfoContainer">
                <div className="productImgContainer">
                  <img src={photo} alt="" />
                </div>
                <div className="productName">{name}</div>
              </div>

              <div className="buttonsContainer">
                <button
                  className="cancelBtn"
                  onClick={() =>
                    setValueDeleteProduct((prev) => ({
                      ...prev,
                      is_open: false,
                    }))
                  }
                >
                  Cancel
                </button>
                <button
                  className="deleteBtn"
                  onClick={() => deleteHandler(id, name)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
