import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  // faCartShopping,
  faStar,
  faBriefcase,
  faFireFlameCurved,
  faShirt,
  faUtensils,
  faShop,
  faSignHanging,
  faHouseCircleCheck,
  faAngleDown,
  faAngleUp,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import "../../../styles/components/navigation/MyBusinessNavigation/LargeSidebar.scss";

function LargeSidebar({
  currentPageUrl,
  currentPage,
  localhost,
  networkServer,
  livePageUrl,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  // const currentPageUrl = (pageUrl, secondaryUrl) => {
  //   if (
  //     currentPage === `${localhost}${secondaryUrl}` ||
  //     currentPage === `${localhost}${pageUrl}`
  //   ) {
  //     return "activePageLink";
  //   } else if (
  //     currentPage === `${networkServer}${secondaryUrl}` ||
  //     currentPage === `${localhost}${pageUrl}`
  //   ) {
  //     return "activePageLink";
  //   } else if (
  //     currentPage === `${localhost}${secondaryUrl}` ||
  //     currentPage === `${localhost}${pageUrl}`
  //   ) {
  //     return "activePageLink";
  //   } else {
  //     return "pageLink";
  //   }
  // };

  return (
    <div className="myBusinessLargeSidebar">
      <div className="topLinksContainer">
        <Link to="/mybusiness" className={currentPageUrl("mybusiness")}>
          <div className="iconContainer">
            <i className="fa-solid fa-table-cells-large"></i>
          </div>
          <div className="linkText">Dashboard</div>
        </Link>
        <Link
          to="/mybusiness/products"
          className={currentPageUrl(
            "mybusiness/table-products",
            "mybusiness/products"
          )}
        >
          <div className="iconContainer">
            <i className="fas fa-shopping-basket"></i>
          </div>
          <div className="linkText">Products</div>
        </Link>

        <Link
          to={`/mybusiness/services?label=${encodeURIComponent(
            "all_services"
          )}`}
          className={currentPageUrl("mybusiness/servicess")}
        >
          <div className="iconContainer">
            <i className="fas fa-tools"></i>
          </div>

          <div className="linkText">Services</div>
        </Link>

        <Link
          to="/mybusiness/orders-company"
          className={currentPageUrl("mybusiness/orders-company")}
        >
          <div className="iconContainer">
            <i className="fa-solid fa-truck-ramp-box"></i>
          </div>
          <div className="linkText">Orders</div>
        </Link>

        <Link
          to="/mybusiness/setings-hours"
          className={currentPageUrl("mybusiness/setings-hours")}
        >
          <div className="iconContainer">
            <i class="fa-solid fa-clock"></i>
          </div>
          <div className="linkText">Open Hours Settings</div>
        </Link>

        <Link
          to="/mybusiness/customization"
          className={currentPageUrl("mybusiness/customization")}
        >
          <div className="iconContainer">
            <i className="fa-solid fa-pencil"></i>
          </div>
          <div className="linkText">Customization</div>
        </Link>
      </div>

      <div className="extraInfoContainer">
        <div>
          For more information please contact:
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=18018353995"
              target="_blank"
            >
              A.D. Lebaron
            </a>
          </div>
          <div>or</div>
          <a
            href="https://api.whatsapp.com/send?phone=526361357593"
            target="_blank"
          >
            Martin Arreola jr.
          </a>
        </div>
      </div>
    </div>
  );
}

export default LargeSidebar;
