import React from "react";
import DriverSmallSidebar from "./DriverSmallSidebar";
import DriverLargeSidebar from "./DriverLargeSidebar";

import "../../../styles/components/navigation/Delivery-Navigation/BothSidebarsDelivery.scss";

const BothSidebarsDriver = ({ currentPageUrl }) => {
  return (
    <>
      <div className="DeliverysmallSidebarContainer">
        <DriverSmallSidebar currentPageUrl={currentPageUrl} />
      </div>
      <div className="DeliverylargeSidebarContainer">
        <DriverLargeSidebar currentPageUrl={currentPageUrl} />
      </div>
    </>
  );
};

export default BothSidebarsDriver;
