import axios from "axios";
import {
  ORDERS_USER_GET_REQUEST,
  ORDERS_USER_GET_SUCCESS,
  ORDERS_USER_GET_FAIL,

  ///COMPANY///
  ORDERS_COMPANY_GET_REQUEST,
  ORDERS_COMPANY_GET_SUCCESS,
  ORDERS_COMPANY_GET_FAIL,
  //
  ORDERS_STATUS_REQUEST,
  ORDERS_STATUS_SUCCESS,
  ORDERS_STATUS_FAIL,
  //
  ORDER_GENERATE_WORDS_REQUEST,
  ORDER_GENERATE_WORDS_SUCCESS,
  ORDER_GENERATE_WORDS_FAIL,
  ORDER_GENERATE_WORDS_RESET,
  //
  ORDER_DECLINED_REQUEST,
  ORDER_DECLINED_SUCCESS,
  ORDER_DECLINED_FAILURE,
  ORDER_DECLINED_RESET,
} from "../constants/ordersConstans";
const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

////Order Declined/////

export const resetOrderDeclinedActions = () => async (dispatch) => {
  dispatch({ type: ORDER_DECLINED_RESET });
};

export const orderDeclinedActions =
  (transactionId, Msj) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: ORDER_DECLINED_REQUEST });

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/orders/declined/`,
        { transaction_id: transactionId, cancellation_reason: Msj },
        config
      );

      dispatch({
        type: ORDER_DECLINED_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ORDER_DECLINED_FAILURE,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

//////Random///////////////////////////////////////////////////////////////////////////

export const ResetGenerateRandomWordAction = () => (dispatch) => {
  dispatch({ type: ORDER_GENERATE_WORDS_RESET });
};

export const GenerateRandomWordAction =
  (multilanguage) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_GENERATE_WORDS_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      if (!user) {
        return;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/orders/random-word/${multilanguage}/`,
        config
      );

      dispatch({
        type: ORDER_GENERATE_WORDS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ORDER_GENERATE_WORDS_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

//////////Orders Failed or Declined///////////////////////////////////////////////////////////////////////////////

export const orderStatusAction =
  (page, status) => async (dispatch, getState) => {
    const {
      userLogin: { userToken },
      userDetails: { user },
    } = getState();
    try {
      dispatch({ type: ORDERS_STATUS_REQUEST });

      if (!user) {
        return;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
        params: {
          page: page,
          status: status,
        },
      };

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/orders/order-status/`,
        config
      );

      dispatch({
        type: ORDERS_STATUS_SUCCESS,
        payload: {
          orderStatus: data.results,
          current_page: data.current_page,
          num_pages: data.num_pages,
        },
      });
    } catch (err) {
      dispatch({
        type: ORDERS_STATUS_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

////////////////////////////////////////////////////////////////////////////////////////////

export const getCompanyOrderAction =
  (routeParamsCompanyId, page) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDERS_COMPANY_GET_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
      } = getState();

      if (!user) {
        return;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
        params: {
          page: page,
        },
      };

      const { data } = await axios.get(
        `${ApiGaleanaBiz}/api/companies/orders/order-all-company/${routeParamsCompanyId}/`,
        config
      );

      dispatch({
        type: ORDERS_COMPANY_GET_SUCCESS,
        payload: {
          count: data.count,
          current_page: data.current_page,
          num_pages: data.num_pages,
          AllCompanyOrder: data.results,
        },
      });
    } catch (err) {
      dispatch({
        type: ORDERS_COMPANY_GET_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

///////ORDER USER///////////////////////////////////////////////////////////////////////////////////////////////

export const getAllOrderUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDERS_USER_GET_REQUEST });

    const {
      userLogin: { userToken },
      userDetails: { user },
    } = getState();

    if (!user) {
      return;
    }

    const userId = parseInt(user?.user?.id);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/orders/get-orders/${userId}/`,
      config
    );

    dispatch({
      type: ORDERS_USER_GET_SUCCESS,
      payload: {
        AllUserOrder: data.results,
        next: data.next,
        previous: data.previous,
      },
    });
  } catch (err) {
    dispatch({
      type: ORDERS_USER_GET_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};
