import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AboutListAction } from "../../../../../actions/aboutActions";

// SCSS
import "../../../../../styles/pages/CompanyPage/AboutCompany.scss";

// Components
import GetAbout from "../../../../CompanyPage/companybar/AboutCompany/GetAbout";

const AboutBusiness = () => {
  const dispatch = useDispatch();

  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;

  const AboutList = useSelector((state) => state.AboutList);
  const { loading: AboutLoading, error: AboutError, CompanyAbout } = AboutList;

  //funciona la llamda a la api
  useEffect(() => {
    dispatch(AboutListAction(userCompanyId));
    document.title = `About - My Business`;
  }, [dispatch, userCompanyId]);

  return (
    <div className="aboutBusiness">
      {CompanyAbout && Object.keys(CompanyAbout).length === 0 ? (
        <div className="addBtnContainer">
          <Link to={"/post-about-company"} className="addBtn">
            Add About
          </Link>
        </div>
      ) : null}

      <GetAbout
        CompanyAbout={CompanyAbout}
        AboutLoading={AboutLoading}
        AboutError={AboutError}
      />
    </div>
  );
};

export default AboutBusiness;
