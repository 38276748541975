import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FaQuestionCircle } from "react-icons/fa";

// Redux - Actions
import {
  CartAddItemAction,
  CartGetItemsAction,
  CartGetTotalItemAction,
  CartGetTotalPriceAction,
  ResetCartAddItemAction,
} from "../../actions/cartActions";

// Icons
import { GoVerified } from "react-icons/go";

//style
import "../../styles/pages/products/ProductDetailsPage.scss";

// Components
import AddToAllPages from "../../components/AddToAllPages";
import StarReviews from "../../components/StarReviews";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { ErrorPopup } from "../../helpers/popup/ErrorPopup";

import FormatDiscount from "../../helpers/format/FormatDiscount";

import { pluralize } from "../../helpers/str";
import {
  useGetAttributeOptionQuery,
  useGetOptionProductQuery,
  useCreateFavoriteProductMutation,
  useDeleteFavoritePorductMutation,
  useGetFavoriteProductsQuery,
  useGetProductQuery,
  useGetSettingsQuery,
} from "../../services/galeanaBizApi";

const ProductDetailsPage = () => {
  const [currentImage, setCurrentImage] = useState(null);
  const [photo, setphoto] = useState(null);
  const [images, setImages] = useState([]);

  const [imageClicked, setImageClicked] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [isLoadingCurrentImage, setIsLoadingCurrentImage] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);

  const [showAlert, setShowAlert] = useState({
    showCartMaxAlert: false,
    showOptionRequiredAlert: false,
  });

  const [attributeValues, setAttributeValues] = useState([]);
  const [optionRequired, setOptionRequired] = useState([]);

  const userDetails = useSelector((state) => state.userDetails);
  const user_id = userDetails?.user?.user?.id;

  const { businessId, productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useGetFavoriteProductsQuery({
    user_id: user_id,
    product_id: productId,
  });

  const is_favorite = data?.is_favorite;

  const [createFavoritePorduct, createFavoritePorductResult] =
    useCreateFavoriteProductMutation();

  const [deleteFavoriteProduct, deleteFavoriteProductResult] =
    useDeleteFavoritePorductMutation();

  const { company: userCompany } = useSelector((state) => state.userDetails);

  const {
    data: product,
    isFetching: isFetchingProduct,
    error: productError,
  } = useGetProductQuery(productId);
  const { data: settingsData, isFetching: fetchingSettings } =
    useGetSettingsQuery();

  const { loading: cartTotalLoading, CartTotalPrice: cartTotalObject } =
    useSelector((state) => state.CartGetTotalPrice);

  const addToFavorite = (product_id) => {
    createFavoritePorduct({ user_id: user_id, product_id: product_id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }
      }
    );
  };

  const deleteFavorite = (product_id) => {
    deleteFavoriteProduct({ user_id: user_id, product_id: product_id }).then(
      (response) => {
        if ("error" in response) {
          return;
        }
      }
    );
  };

  const nextImage = () => {
    const nextIndex = (currentImageIndex + 1) % (images?.length + 1);
    setCurrentImageIndex(nextIndex);
  };

  const prevImage = () => {
    const prevIndex =
      (currentImageIndex - 1 + (images?.length + 1)) % (images?.length + 1);
    setCurrentImageIndex(prevIndex);
  };

  useEffect(() => {
    setCurrentImage(null);
    setphoto(null);
    setImages([]);
    if (product) {
      setIsLoadingCurrentImage(true);
      setIsLoadingImages(true);
      setIsLoadingPhoto(true);

      let currenImage = null;
      let photo = null;
      let images = [];

      const newImageURL = product?.photo || null;

      const img = new Image();
      img.src = newImageURL;
      img.onload = () => {
        if (img.complete && img.naturalWidth > 0) {
          currenImage = newImageURL;
          photo = newImageURL;

          if (product.id === Number(productId)) {
            setCurrentImage(currenImage);
            setphoto(photo);
            setIsLoadingCurrentImage(false);
            setIsLoadingPhoto(false);
          }
        }
      };

      const uniqueImagesSet = new Set();
      let loadedImageCount = 0;
      const newImagesURL = product?.images || [];

      newImagesURL.map((url) => {
        const newImg = new Image();
        newImg.src = url?.image;
        newImg.onload = () => {
          if (newImg.complete && newImg.naturalWidth > 0) {
            if (!uniqueImagesSet.has(url)) {
              uniqueImagesSet.add(url);
            }
            loadedImageCount++;
            if (loadedImageCount === newImagesURL?.length) {
              images = [...uniqueImagesSet];

              if (product.id === Number(productId)) {
                setImages([...images]);
                setIsLoadingImages(false);
              }
            }
          }
        };
      });
    }
  }, [product, productId]);

  const handleImageClicked = () => {
    setImageClicked(!imageClicked);
  };

  const handleThumbnailClick = (imageSrc, index) => {
    setCurrentImage(imageSrc);
    setCurrentImageIndex(index + 1);
  };

  const setFirstImageAsMain = (index) => {
    const firstImage = photo;
    if (firstImage) {
      setCurrentImage(firstImage);
      setCurrentImageIndex(index);
    }
  };

  const CartAddItems = useSelector((state) => state.CartAddItems);
  const { success: AddCartSuccess, loading: cartAdditionLoading } =
    CartAddItems;

  useEffect(() => {
    dispatch(CartGetItemsAction());

    window.scrollTo(0, document.documentElement.scrollTo);
  }, [dispatch, productId]);

  useEffect(() => {
    if (AddCartSuccess) {
      navigate("/cart");
      dispatch(ResetCartAddItemAction());
    }
  }, [AddCartSuccess]);

  const is_validation = () => {
    const categoryOption = attributeValues.map((ids) => ids.categoryId);

    const requiredFieldsPresent = optionRequired.every((id) =>
      categoryOption.includes(id.id)
    );

    if (!requiredFieldsPresent) {
      setShowAlert((prev) => ({ ...prev, showOptionRequiredAlert: true }));
      return;
    }

    return (
      product !== null &&
      product !== undefined &&
      product.available !== undefined
    );
  };

  const addToCart = (e) => {
    e.preventDefault();

    if (is_validation()) {
      let productPrice =
        product.discount > 0
          ? Number(product.discounted_price)
          : Number(product.price);
      if (product.currency === "USD") {
        productPrice = productPrice * settingsData.settings.usd_mxn_value;
      }

      if (cartTotalObject.total_cost + productPrice > 10000) {
        setShowAlert((prev) => ({ ...prev, showCartMaxAlert: true }));
        return;
      }

      dispatch(
        CartAddItemAction(
          product,
          attributeValues,
          settingsData.settings.usd_mxn_value
        )
      );
      dispatch(CartGetTotalPriceAction());
      dispatch(CartGetTotalItemAction());
    }
  };

  return (
    <AddToAllPages>
      {isFetchingProduct || cartTotalLoading || fetchingSettings ? (
        <Loader />
      ) : productError ? (
        <Message>An error has occurred. Please try again later.</Message>
      ) : (
        <>
          {imageClicked ? (
            <div className="expandedImageModal">
              <div className="background" onClick={handleImageClicked}></div>
              <div className="modalContent">
                {currentImageIndex === 0 ? (
                  <img src={photo} alt="Product" />
                ) : (
                  <img
                    src={images[currentImageIndex - 1]?.image}
                    alt="Product"
                  />
                )}
                <div
                  className="switchImgButton leftSwitchImgButton"
                  onClick={prevImage}
                >
                  <i className="fa-solid fa-angle-left"></i>
                </div>
                <div
                  className="switchImgButton rightSwitchImgButton"
                  onClick={nextImage}
                >
                  <i className="fa-solid fa-angle-right"></i>
                </div>
              </div>
            </div>
          ) : null}
          {showAlert.showOptionRequiredAlert && (
            <ErrorPopup
              message="A Required Field was not selected"
              onClose={() =>
                setShowAlert((prev) => ({
                  ...prev,
                  showOptionRequiredAlert: false,
                }))
              }
            />
          )}

          {showAlert.showCartMaxAlert && (
            <ErrorPopup
              message="You can add a maximum of $10,000.00 MXN to your cart."
              onClose={() =>
                setShowAlert((prev) => ({ ...prev, showCartMaxAlert: false }))
              }
            />
          )}
          <div className="productDetailsPage">
            <div className="topSection">
              <Link
                to={`/business/${product?.company}`}
                className="visitBusinessLink"
              >
                <i className="fa-solid fa-angle-left"></i>
                Visit the {product?.company_name} business
              </Link>
              <div className="productName" id="productName">
                {product?.name}
              </div>

              <div className="starRatingContainer">
                {is_favorite ? (
                  <button
                    className="RemoveFavoritesBtn"
                    onClick={() => deleteFavorite(product?.id)}
                  >
                    <i className="fa-solid fa-heart"></i>
                  </button>
                ) : (
                  <button
                    className="addToFavoritesBtn"
                    onClick={() => addToFavorite(product?.id)}
                  >
                    <i className="fa-regular fa-heart"></i>
                  </button>
                )}
                <StarReviews value={product.rating || 0} />
                <Link
                  to={`/product/${businessId}/${product.id}/reviews`}
                  className="numRatingsText"
                >
                  {product.reviews_count}{" "}
                  {pluralize("Review", "Reviews", product.reviews_count || 0)}
                </Link>
              </div>

              {product?.subscription_active ? (
                <div className="exclusiveproductswithsubscription">
                  <GoVerified className="verified" /> Lebaron-Galeana Member
                  Exlusive
                </div>
              ) : null}
            </div>

            <div className="productImagesContainer">
              <div className="imageContainer">
                <div className="imageWrapper">
                  {isLoadingCurrentImage ? (
                    <Loader />
                  ) : (
                    currentImage && (
                      <img
                        src={currentImage}
                        className="productImg"
                        loading="lazy"
                        decoding="async"
                        alt="Product"
                        onClick={handleImageClicked}
                      />
                    )
                  )}
                </div>
              </div>
              <div className="productImageBtnsContainer">
                {images?.length !== 0 && (
                  <div
                    onClick={() => setFirstImageAsMain(0)}
                    className={`imageOption ${
                      currentImage === photo ? "activeImage" : ""
                    }`}
                  >
                    <div className="background"></div>
                    {isLoadingPhoto ? (
                      <Loader />
                    ) : (
                      photo && (
                        <img
                          src={photo}
                          alt="Thumbnail 0"
                          loading="lazy"
                          decoding="async"
                          role="presentation"
                        />
                      )
                    )}
                  </div>
                )}

                {Object.values(images || []).map((image, index) => (
                  <div
                    key={index}
                    onClick={() => handleThumbnailClick(image?.image, index)}
                    className={`imageOption ${
                      currentImage === image?.image
                        ? `activeImage activeImage__${index}`
                        : ""
                    }`}
                  >
                    <div className="background"></div>

                    {isLoadingImages ? (
                      <Loader />
                    ) : (
                      image?.image && (
                        <img
                          src={image?.image}
                          alt={`Img ${index}`}
                          loading="lazy"
                          decoding="async"
                        />
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="priceContainer">
              <FormatDiscount
                price={product?.price}
                discount={product?.discount}
                discountedPrice={product?.discounted_price}
                currency={product?.currency}
              />
            </div>

            <div className="inStockTextContainer">
              {product?.available === false ? (
                <div className="onlyAFewInStockText">Currently Unavailable</div>
              ) : (
                <div className="inStockText">In Stock</div>
              )}
            </div>

            <div className="addToCartSection">
              {userCompany?._id === Number(businessId) ? (
                <Link
                  to={`/product/${[product?.id]}/updateProduct`}
                  className="editMyProductBtn"
                >
                  Edit My Product
                </Link>
              ) : product?.available === false ? (
                <button className="outOfStock">Currently Unavailable</button>
              ) : cartAdditionLoading ? (
                <Loader />
              ) : (
                <button onClick={addToCart} className="addCartBtn">
                  Add to Cart
                </button>
              )}
            </div>

            <ProductExtraOptionsSection
              product={product}
              attributeValues={attributeValues}
              setAttributeValues={setAttributeValues}
              setOptionRequired={setOptionRequired}
            />

            <div className="descriptionSection">
              <div className="sectionTitleText">
                Description and Extra Details
              </div>
              <div className="descriptionText">{product?.description}</div>
            </div>
          </div>

          {/* ===================================================== */}
          {/* ====================== Desktop ====================== */}
          {/* ===================================================== */}

          <div className="desktopProductDetailsPage">
            <div className="leftSection">
              <div className="productImagesContainer">
                <div className="imageContainer">
                  <div className="imageWrapper">
                    {isLoadingCurrentImage ? (
                      <Loader />
                    ) : (
                      currentImage && (
                        <img
                          src={currentImage}
                          className="productImg"
                          loading="lazy"
                          decoding="async"
                          alt="Product"
                          onClick={() => handleImageClicked(true)}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="productImageBtnsContainer">
                  {images?.length !== 0 && (
                    <div
                      onClick={() => setFirstImageAsMain(0)}
                      className={`imageOption ${
                        currentImage === photo ? "activeImage" : ""
                      }`}
                    >
                      <div className="background"></div>
                      {isLoadingPhoto ? (
                        <Loader />
                      ) : (
                        photo && (
                          <img
                            src={photo}
                            alt="Thumbnail 0"
                            loading="lazy"
                            decoding="async"
                            role="presentation"
                          />
                        )
                      )}
                    </div>
                  )}
                  {Object.values(images || []).map((image, index) => (
                    <div
                      key={index}
                      onClick={() => handleThumbnailClick(image?.image, index)}
                      className={`imageOption ${
                        currentImage === image?.image
                          ? `activeImage activeImage__${index}`
                          : ""
                      }`}
                    >
                      <div className="background"></div>

                      {isLoadingImages ? (
                        <Loader />
                      ) : (
                        image?.image && (
                          <img
                            src={image?.image}
                            alt={`Img ${index}`}
                            loading="lazy"
                            decoding="async"
                          />
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="addToCartSection">
                {userCompany?._id === product?.company ? (
                  <Link
                    to={`/product/${[product?.id]}/updateProduct`}
                    className="editMyProductBtn"
                  >
                    Edit My Product
                  </Link>
                ) : product?.available === false ? (
                  <button className="outOfStock">Currently Unavailable</button>
                ) : cartAdditionLoading ? (
                  <Loader />
                ) : (
                  <button onClick={addToCart} className="addCartBtn">
                    Add to Cart
                  </button>
                )}
              </div>
            </div>

            <div className="rightSection">
              <div className="topSection">
                <div className="productName" id="productName">
                  {product?.name}
                </div>
                <Link
                  to={`/business/${product?.company}`}
                  className="visitBusinessLink"
                >
                  Visit the {product?.company_name} business
                </Link>

                <div className="starRatingContainer">
                  {is_favorite ? (
                    <button
                      className="RemoveFavoritesBtn"
                      onClick={() => deleteFavorite(product?.id)}
                    >
                      <i className="fa-solid fa-heart"></i>
                    </button>
                  ) : (
                    <button
                      className="addToFavoritesBtn"
                      onClick={() => addToFavorite(product?.id)}
                    >
                      <i className="fa-regular fa-heart"></i>
                    </button>
                  )}

                  <StarReviews value={product.rating || 0} />
                  <Link
                    to={`/product/${businessId}/${product.id}/reviews`}
                    className="numRatingsText"
                  >
                    {product.reviews_count}{" "}
                    {pluralize("Review", "Reviews", product.reviews_count || 0)}
                  </Link>
                </div>

                {product?.subscription_active ? (
                  <div className="exclusiveproductswithsubscription">
                    <GoVerified className="verified" /> Lebaron-Galeana Member
                    Exlusive
                  </div>
                ) : null}
              </div>

              <div className="descriptionSection">
                <div className="sectionTitleText">Price</div>

                <FormatDiscount
                  price={product?.price}
                  discount={product?.discount}
                  discountedPrice={product?.discounted_price}
                  currency={product?.currency}
                />
              </div>

              <ProductExtraOptionsSection
                product={product}
                attributeValues={attributeValues}
                setAttributeValues={setAttributeValues}
                setOptionRequired={setOptionRequired}
              />

              <div className="descriptionSection">
                <div className="sectionTitleText">
                  Description and Extra Details
                </div>
                <div className="descriptionText">{product?.description}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </AddToAllPages>
  );
};

export default ProductDetailsPage;

const ProductExtraOptionsSection = ({
  product,
  attributeValues,
  setAttributeValues,
  setOptionRequired,
}) => {
  const [productOptions, setProductOptions] = useState([]);
  const [showText, setShowText] = useState(false);

  const { data: optionData, isFetching: isOptionFetching } =
    useGetOptionProductQuery({
      productId: product?.id,
      companyId: product?.company,
      pageType: "",
    });

  useEffect(() => {
    if (!optionData || isOptionFetching) {
      return;
    }

    setProductOptions(optionData);
  }, [optionData, isOptionFetching]);

  useEffect(() => {
    setOptionRequired(productOptions.filter((option) => option.is_required));
  }, [productOptions]);

  const handleQuestion = () => {
    setShowText(!showText);

    setTimeout(() => {
      setShowText(false);
    }, 5000);
  };

  if (productOptions.length === 0) return null;

  return (
    <div className="ProductExtraOptionsSection">
      <div className="nameSection">
        Category Option
        <FaQuestionCircle className="iconQuestion" onClick={handleQuestion} />
      </div>

      {showText && (
        <div className="descriptionCategoryOption">
          <p>
            You can select an additional option of the product if available.
            This option can be optional or required. The price of this attribute
            will be added to the default price of the product. In summary, when
            choosing an extra option, whether optional or required, the cost
            associated with that option will be added to the base price of the
            product.
          </p>
        </div>
      )}

      <div className="warapperOption">
        {productOptions.map((option, index) => {
          return (
            <ProductOptionView
              key={index}
              option={option}
              product={product}
              attributeValues={attributeValues}
              setAttributeValues={setAttributeValues}
            />
          );
        })}
      </div>
    </div>
  );
};

const ProductOptionView = ({
  option,
  product,
  attributeValues,
  setAttributeValues,
}) => {
  const [attributes, setAttributes] = useState([]);

  const onChange = (
    categoryId,
    categoryTitle,
    attributeId,
    attributeName,
    attributePrice
  ) => {
    const existingSelectionIndex = attributeValues.findIndex((selection) => {
      return selection.categoryId === categoryId;
    });

    const existingAttributeId = attributeValues.findIndex((selection) => {
      return selection.attributeId === attributeId;
    });

    let updateAttributeValues = [...attributeValues];

    if (existingAttributeId !== -1) {
      updateAttributeValues = updateAttributeValues.filter(
        (i) => i.attributeId !== attributeId
      );
    } else if (existingSelectionIndex !== -1) {
      updateAttributeValues[existingSelectionIndex] = {
        categoryId,
        categoryTitle,
        attributeId,
        attributeName,
        attributePrice,
      };
    } else {
      updateAttributeValues.push({
        categoryId,
        categoryTitle,
        attributeId,
        attributeName,
        attributePrice,
      });
    }

    setAttributeValues(updateAttributeValues);
  };

  const { data: attributeData, isFetching: isAttributeFetching } =
    useGetAttributeOptionQuery({
      companyId: product.company,
      productId: product.id,
      optionId: option.id,
    });

  useEffect(() => {
    if (!attributeData || isAttributeFetching) {
      return;
    }

    setAttributes(attributeData);
  }, [attributeData, isAttributeFetching]);

  return (
    <div className="contentOption">
      <div className="titleOption">
        <div className="title">{option.title}</div>
        <div className="isRequired">
          {option.is_required ? "required" : "optional"}
        </div>
      </div>
      <div className="warpperAttribute">
        {attributes.map((attribute, index) => {
          const isSelected = attributeValues.some(
            (selection) =>
              selection.categoryId === option.id &&
              selection.attributeId === attribute.id
          );
          return (
            <ProductAttributeView
              key={index}
              attribute={attribute}
              categoryId={option.id}
              categoryTitle={option.title}
              selected={isSelected}
              onChange={onChange}
            />
          );
        })}
      </div>
    </div>
  );
};

const ProductAttributeView = ({
  attribute,
  selected,
  onChange,
  categoryId,
  categoryTitle,
}) => {
  return (
    <div
      className={selected ? "selected" : "contentAttribute"}
      onClick={() =>
        onChange(
          categoryId,
          categoryTitle,
          parseInt(attribute?.id),
          attribute?.name,
          attribute?.price
        )
      }
    >
      <input
        type="radio"
        id="radioOption"
        checked={selected}
        value={attribute?.id}
      />
      <label form="radioOption" className="attributeName">
        {attribute.name}
      </label>
      <label form="radioOption" className="attributePrice">
        + ${attribute.price}
      </label>
    </div>
  );
};
