import React from "react";

import "../../../styles/components/navigation/Delivery-Navigation/DeliveryNavigationComponents.scss";

import BothSidebarsDriver from "./BothSidebarsDriver";
import DriverMobileFooter from "./DriverMobileFooter";
import NavBarDriver from "../NavBarDriver";

const DriverNavigationComponents = (props) => {
  const currentPage = window.location.href;

  const localhost = "http://localhost:3000/";
  const networkServer = "http://192.168.1.236:3000/#/";
  const livePageUrl = "https://galeana.biz/";

  const currentPageUrl = (pageUrl) => {
    if (currentPage === `${localhost}${pageUrl}`) {
      return "activePageLink";
    } else if (currentPage === `${livePageUrl}${pageUrl}`) {
      return "activePageLink";
    } else {
      return "pageLink";
    }
  };

  return (
    <>
      <NavBarDriver />
      <BothSidebarsDriver currentPageUrl={currentPageUrl} />
      <div className="thePageDelivery">{props.children}</div>
      <div className="DeliverymobileFooterContainer">
        <DriverMobileFooter currentPageUrl={currentPageUrl} />
      </div>
    </>
  );
};

export default DriverNavigationComponents;
