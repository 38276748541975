import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ActiveSubRoutes = () => {
  const userSatus = useSelector((state) => state.userDetails);
  const { user } = userSatus;

  const subscriptionActive = user?.subscriptionStatus?.active;

  return subscriptionActive ? <Outlet /> : <Navigate to="/" />;
};

export default ActiveSubRoutes;
