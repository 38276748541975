import React from "react";

import "../../styles/helpers/format/FormatDiscount.scss";

import formatCurrency from "./formatCurrency";

const FormatDiscount = ({ price, discount, discountedPrice, currency }) => {
  return (
    <div className="containerDiscount">
      {discount > 0 ? (
        <div className="discount-container">
          <div className="original-price">
            Original Price: {formatCurrency(price, currency)}
          </div>
          <div className="discount-details">
            <span className="discounted-price">
              {formatCurrency(discountedPrice, currency)} {currency}
            </span>
            <span className="discount-percentage">{discount}% OFF</span>
          </div>
        </div>
      ) : (
        <div className="price">{formatCurrency(price, currency)} {currency}</div>
      )}
    </div>
  );
};

export default FormatDiscount;
