export const DRIVER_GET_ORDER_REQUEST = "DRIVER_GET_ORDER_REQUEST";
export const DRIVER_GET_ORDER_SUCCESS = "DRIVER_GET_ORDER_SUCCESS";
export const DRIVER_GET_ORDER_FAIL = "DRIVER_GET_ORDER_FAIL";
//
export const DRIVER_DELIVERY_ORDER_REQUEST = "DRIVER_DELIVERY_ORDER_REQUEST";
export const DRIVER_DERLIVERY_ORDER_SUCCESS = "DRIVER_DERLIVERY_ORDER_SUCCESS";
export const DRIVER_DERLIVERY_ORDER_FAIL = "DRIVER_DERLIVERY_ORDER_FAIL";
export const DRIVER_DERLIVERY_ORDER_RESET = "DRIVER_DERLIVERY_ORDER_RESET";
//
export const GET_ASSING_DRIVER_REQUEST = "GET_ASSING_DRIVER_REQUEST";
export const GET_ASSING_DRIVER_SUCCESS = "GET_ASSING_DRIVER_SUCCESS";
export const GET_ASSING_DRIVER_FAIL = "GET_ASSING_DRIVER_FAIL";
//
export const GET_DRIVER_SHIPPING_REQUEST = "GET_DRIVER_SHIPPING_REQUEST";
export const GET_DRIVER_SHIPPING_SUCCESS = "GET_DRIVER_SHIPPING_SUCCESS";
export const GET_DRIVER_SHIPPING_FAIL = "GET_DRIVER_SHIPPING_FAIL";
//
export const GET_DRIVER_LIST_ORDERS_REQUEST = "GET_DRIVER_LIST_ORDERS_REQUEST";
export const GET_DRIVER_LIST_ORDERS_SUCCESS = "GET_DRIVER_LIST_ORDERS_SUCCESS";
export const GET_DRIVER_LIST_ORDERS_FAIL = "GET_DRIVER_LIST_ORDERS_FAIL";
