import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PRODUCTS_HOME_FILTER_UPDATE } from "../../constants/productsConstans";
import {
  useGetProductCategoriesHomeQuery,
  useGetProductHomeQuery,
} from "../../services/galeanaBizApi";

import "../../styles/helpers/ChipBar.scss";

import Loader from "../../components/Loader";
import { SearchFailedMessage } from "../../components/Message";
import Message from "../../components/Message";

import ProductCard_V2, {
  SkeletonProductCard,
} from "../../components/CompanyAndProductCards/ProductCard_V2";

import CompanyCardSkeleton from "../../components/CompanyAndProductCards/CompanyCardSkeleton";

const AllHomeProducts = () => {
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [nextPage, setNextpage] = useState(null);

  const [categoryId, setCategoryId] = useState(0);
  const [categoryName, setCategoryName] = useState("");

  const productsRandomOrderRef = useRef(Math.floor(Math.random() * 6));

  const { user } = useSelector((state) => state.userDetails);

  const { page, search, category } = useSelector(
    (state) => state.productsHomeFilters
  );

  const { data, error, isFetching, isLoading, originalArgs } =
    useGetProductHomeQuery({
      subscriptionStatus: user?.subscriptionStatus?.active,
      search,
      category,
      page,
      randomOrder: productsRandomOrderRef.current,
    });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    if (data?.products?.current_page === 1) {
      setProducts([...data?.products?.results]);
    } else {
      setProducts((previousProducts) => [
        ...previousProducts,
        ...data?.products?.results,
      ]);
    }

    setNextpage(data?.products?.next);
  }, [dispatch, data, isFetching, search, category]);

  useEffect(() => {
    return () => {
      dispatch({
        type: PRODUCTS_HOME_FILTER_UPDATE,
        payload: {
          page: 1,
          search: "",
          category: 0,
        },
      });
    };
  }, [dispatch]);

  const numberOfSkeletonCards = 36;
  const companyCardSkeletons = Array.from(
    { length: numberOfSkeletonCards },
    (_, index) => <SkeletonProductCard key={index} />
  );

  const isLoadingSkeleton = () => {
    const loading =
      isLoading ||
      (isFetching && originalArgs?.category !== 0) ||
      (isFetching && originalArgs?.search !== "");

    return loading;
  };

  if (isLoadingSkeleton()) {
    return (
      <div className="contentHomePages">
        <div className="homePage">
          <div className="productsCardsSection">
            <div className="productsCardsContainer">
              <div className="productsCardsWrapper">{companyCardSkeletons}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="contentHomePages">
      <div className="productsfilterButton">
        <ChipBar
          user={user}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
          setCategoryName={setCategoryName}
        />
      </div>
      {categoryName && (
        <h3 className="companyCardsTitle">Filter: {categoryName}</h3>
      )}
      <div className="homePage">
        <Products products={products} search={search} error={error} />

        <LoadMoreButton
          nextPage={nextPage}
          isFetching={isFetching}
          originalArgs={originalArgs}
          page={page}
        />
      </div>
    </div>
  );
};

export default AllHomeProducts;

const Products = ({ products, search, error }) => {
  if (search && products.length === 0) {
    return <SearchFailedMessage />;
  }

  if (error) {
    return <Message error={error}>{error}</Message>;
  }

  return (
    <div className="productsCardsSection">
      <div className="productsCardsContainer">
        <div className="productsCardsWrapper">
          {products.map((products, index) => (
            <ProductCard_V2 key={index} products={products} />
          ))}
        </div>
      </div>
    </div>
  );
};

const LoadMoreButton = ({ nextPage, isFetching, originalArgs, page }) => {
  const dispatch = useDispatch();

  if (isFetching && originalArgs?.page !== 1) return <Loader />;

  if (!nextPage) return null;

  return (
    <div className="loadMoreContent">
      <button
        className="loadMoreButton"
        disabled={isFetching && originalArgs?.page !== 1}
        onClick={() =>
          dispatch({
            type: PRODUCTS_HOME_FILTER_UPDATE,
            payload: { page: page + 1 },
          })
        }
      >
        Load More
      </button>
    </div>
  );
};

const ChipBar = ({ user, categoryId, setCategoryId, setCategoryName }) => {
  const [categories, setCategories] = useState([]);

  const dispatch = useDispatch();

  const { data, error, isFetching, isLoading, isError } =
    useGetProductCategoriesHomeQuery({
      subscriptionStatus: user?.subscriptionStatus?.active,
    });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setCategories(data?.categories);
  }, [data, isFetching]);

  const handleCategory = (id, name) => {
    setCategoryId(id);
    setCategoryName(name);
    dispatch({
      type: PRODUCTS_HOME_FILTER_UPDATE,
      payload: {
        page: 1,
        search: "",
        category: id,
      },
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Message error={error}>{error}</Message>;
  }

  return (
    <div className="chipBar">
      <div className="formContentButton">
        <div className="categoryButtonsContainer">
          <button
            className={categoryId === 0 ? "selectedCategoryBtn" : "categoryBtn"}
            onClick={() => handleCategory(0)}
          >
            All
          </button>
          {categories.map((category, index) => {
            return (
              <button
                key={index}
                onClick={() => handleCategory(category?.id, category?.name)}
                className={
                  categoryId === category?.id
                    ? "selectedCategoryBtn"
                    : "categoryBtn"
                }
              >
                {category?.name}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
