import { useUserId } from "../../../../hooks/useUserId";
import { useParams } from "react-router-dom";
import { useGetOrderDetailUserQuery } from "../../../../services/galeanaBizApi";

const useOrderDetails = () => {
  const userId = useUserId();
  const { transaction_id } = useParams();

  const {
    data: orderDetail,
    isLoading: orderDatailLoading,
    isFetching: orderDetailFeching,
    error: orderError,
  } = useGetOrderDetailUserQuery({
    userId,
    transactionId: transaction_id,
  });

  const addressUser = {
    first_name: orderDetail?.user_name,
    last_name: orderDetail?.last_name,
    telephone_number: orderDetail?.telephone,
    location: orderDetail?.location,
    street_1: orderDetail?.street_1,
    street_2: orderDetail?.street_2,
  };

  return {
    orderDetail,
    addressUser,
    orderDatailLoading,
    orderDetailFeching,
    orderError,
  };
};

export default useOrderDetails;
