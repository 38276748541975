import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBuilding,
  faBars,
  faTruck,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import "../../../styles/components/navigation/FooterAndSidebars/MobileFooter.scss";
import { useSelector } from "react-redux";

function MobileFooter({ currentPageUrl, currentPageUrl_DropdownMenu }) {
  const GetCartTotalItems = useSelector((state) => state.CartGetTotalItems);
  const { CartTotalItems } = GetCartTotalItems;

  const userStatus = useSelector((state) => state.userDetails);
  const { user } = userStatus;

  const role = user?.user?.profile?.role;

  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <>
      {openDropdown && (
        <div className="extraLinksDropdown">
          <DropDownLink
            icon={<i class="fa-solid fa-box"></i>}
            name="My Orders"
            url="myorders"
          />
          <DropDownLink
            icon={<i class="fa-solid fa-box"></i>}
            name="My Business"
            url="mybusiness"
          />

          {role === "Galeana Biz driver" ||
            (role === "Admin" && (
              <DropDownLink
                icon={<i className="fa-solid fa-truck"></i>}
                name="Driver"
                url="driver"
              />
            ))}
          {role === "Galeana Biz driver" ||
            (role === "Admin" && (
              <DropDownLink
                icon={<i className="fa-solid fa-lock" />}
                name="Admin"
                url="admin"
              />
            ))}
        </div>
      )}

      <div className="mobileFooter_v2">
        <Link to={"/"} className={currentPageUrl("")}>
          <i class="fa-solid fa-house linkIcon"></i>
          <div className="linkName">Home</div>
          <div className="activeLinkLine"></div>
        </Link>
        <Link to={"/home-business"} className={currentPageUrl("home-business")}>
          <i class="fa-solid fa-building linkIcon"></i>
          <div className="linkName">Businesses</div>
          <div className="activeLinkLine"></div>
        </Link>
        <Link to={"/favorites"} className={currentPageUrl("favorites")}>
          <i class="fa-solid fa-heart linkIcon"></i>
          <div className="linkName">Favorites</div>
          <div className="activeLinkLine"></div>
        </Link>
        <Link to={"/cart"} className={currentPageUrl("cart")}>
          <i class="fa-solid fa-cart-shopping linkIcon"></i>
          <span className="cartTotalItems">
            {CartTotalItems &&
              (CartTotalItems?.total_items !== undefined) !== null &&
              CartTotalItems?.total_items !== undefined &&
              CartTotalItems?.total_items !== 0 &&
              `${CartTotalItems?.total_items}`}
          </span>
          <div className="linkName">Cart</div>
          <div className="activeLinkLine"></div>
        </Link>

        <div
          className="pageLink"
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <i class="fa-solid fa-bars linkIcon"></i>
          <div className="linkName">More</div>
          <div className="activeLinkLine"></div>
        </div>
      </div>
    </>
  );
}

function DropDownLink(props) {
  return (
    <Link to={`/${props.url}`} className="dropdownLink">
      <div className="iconContainer">{props.icon}</div>
      <div className="linkName">{props.name}</div>
    </Link>
  );
}

export default MobileFooter;
