import React, { useEffect, useState } from "react";

import "./OpeningHours.scss";

import {
  useCreateOpeningHoursMutation,
  useGetOpeningHoursQuery,
} from "../../../../services/galeanaBizApi";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";
import validateFunction from "./function/validateFunction";
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

const OpeningHours = () => {
  const [weekDays, setWeekDays] = useState([]);
  const [show, setShow] = useState(false);

  const { company } = useSelector((state) => state.userDetails);

  const { data, isFetching, isLoading, isError, error } =
    useGetOpeningHoursQuery({ companyId: company._id });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setWeekDays(data.days);
  }, [data, isFetching]);

  return (
    <NavigationComponents>
      <div className="openingHoursContainer">
        <button className="openButton" onClick={() => setShow(true)}>
          Edit Schedule
        </button>

        <div className="weekdaysContainer">
          <GetWeekday
            days={weekDays}
            isLoading={isLoading}
            isFetching={isFetching}
            isError={isError}
            error={error}
          />
        </div>

        {show ? (
          <PostModal
            companyId={company._id}
            weekDays={weekDays}
            setShow={setShow}
          />
        ) : null}
      </div>
    </NavigationComponents>
  );
};

export default OpeningHours;
//GET
const GetWeekday = ({ days, isLoading, isFetching, isError, error }) => {
  if (isLoading || isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <Message err={error}>{error}</Message>;
  }

  return (
    <>
      {days.map((day, index) => {
        return <MidweekCard key={index} day={day} index={index} />;
      })}
    </>
  );
};

//POST Modal
const PostModal = ({ companyId, weekDays, setShow }) => {
  const [values, setValues] = useState([
    {
      day_no: "",
      opens: "",
      shuts: "",
      break_start: "",
      break_end: "",
    },
  ]);

  const [errors, setErrors] = useState([]);

  const [createWeekDays, createWeekDaysReesult] =
    useCreateOpeningHoursMutation();

  useEffect(() => {
    setValues(() =>
      weekDays.map((day) => ({
        day_no: day.number,
        opens: day.schedule?.[0]?.opens || "",
        shuts: day.schedule?.[0]?.shuts || "",
        break_start: day.break?.[0]?.start || "",
        break_end: day.break?.[0]?.end || "",
      }))
    );
  }, [weekDays]);

  const handleInputChange = (index, field, value) => {
    setValues((prevValues) =>
      prevValues.map((v, i) => (i === index ? { ...v, [field]: value } : v))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateFunction(values);

    if (isValid.length > 0) {
      setErrors(isValid);
      return;
    }

    createWeekDays({
      companyId: companyId,
      openingHours: values,
    }).then((response) => {
      if ("error" in response) {
        return;
      }
    });

    setShow(false);
  };

  if (createWeekDaysReesult.isLoading) {
    return <Loader />;
  }

  return (
    <div className="contentModal">
      <div className="background" onClick={() => setShow(false)}></div>
      <form className="formPostModal" onSubmit={handleSubmit}>
        <div className="title">Opening and Closing Times</div>
        {!!createWeekDaysReesult.error && (
          <p className="error">Something went wrong, please try again later.</p>
        )}

        <div className="tableContainer">
          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Opens</th>
                <th>Closes</th>
                <th>Break Start</th>
                <th>Break End</th>
              </tr>
            </thead>

            {values.map((day, index) => {
              const dayErrors = errors.filter((error) => error.index === index);

              const isError = dayErrors.length > 0;

              return (
                <tbody key={index}>
                  <tr>
                    <td className={isError ? "error" : null}>
                      {weekDays[index].name}
                    </td>
                    <td>
                      <input
                        type="time"
                        value={day.opens}
                        onChange={(e) =>
                          handleInputChange(index, "opens", e.target.value)
                        }
                        className={isError ? "error" : null}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        value={day.shuts}
                        onChange={(e) =>
                          handleInputChange(index, "shuts", e.target.value)
                        }
                        className={isError ? "error" : null}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        value={day.break_start}
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            "break_start",
                            e.target.value
                          )
                        }
                        className={isError ? "error" : null}
                      />
                    </td>
                    <td>
                      <input
                        type="time"
                        value={day.break_end}
                        onChange={(e) =>
                          handleInputChange(index, "break_end", e.target.value)
                        }
                        className={isError ? "error" : null}
                      />
                    </td>
                  </tr>

                  {/* Aqui se muestra el msj del error solo no encotre como acomadarlo mejor */}
                  {/* <tr className="trMessageError">
                  <td>
                    {isError && (
                      <span className="error-message">
                        {dayErrors.map((error) => error.message).join(", ")}
                      </span>
                    )}
                  </td>
                </tr> */}
                </tbody>
              );
            })}
          </table>
        </div>

        <div className="contentButton">
          <button
            className="closed"
            type="button"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>
          <button
            className="save"
            type="submit"
            disabled={createWeekDaysReesult.isLoading}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

// CARD
const MidweekCard = ({ day, index }) => {
  const TimeRange = ({ label, hours }) => {
    if (hours === null || hours === undefined) {
      return null;
    }

    return (
      <div className="Time">
        <div className="label">{label}:</div>
        <div className="hours">{hours}</div>
      </div>
    );
  };

  const Schedule = () => {
    if (!day.schedule) {
      return null;
    }
    return (
      <div className="scheduleSection">
        <div className="scheduleLabel">Schedule:</div>
        <div className="timeRange">
          {day?.schedule.map((hours, index) => (
            <div className="contentTime" key={index}>
              <TimeRange label={"Opens"} hours={hours.opens} />
              <TimeRange label={"Closes"} hours={hours.shuts} />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const Break = () => {
    if (!day.break) {
      return null;
    }
    return (
      <>
        {day?.break.map((hours, index) => (
          <div key={index}>
            <div className="scheduleSection">
              <div className="scheduleLabel">Break:</div>
              <div className="timeRange">
                <div className="contentTime" key={index}>
                  <TimeRange label={"Start"} hours={hours.start} />
                  <TimeRange label={"End"} hours={hours.end} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="businessHours" key={index}>
      <div className="contentDay">
        <div className="day">{day.name}</div>
      </div>

      <Schedule />
      <Break />

      <div className="status">{day.closed && "Closed"}</div>
    </div>
  );
};
