import React, { useState, useEffect } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  listCompanyDetailsAction,
  resetlistCompanyDetailsAction,
} from "../../actions/companyActions";

// Components
import AddToAllPages from "../../components/AddToAllPages";
import Loader from "../../components/Loader";
import Message from "../../components/Message";

import ProductsPage from "../products/ProductsPage";

import SearchAndFilterBar from "../../components/SearchAndFilterBar/SearchAndFilterBar";
import StarReviews from "../../components/StarReviews";

// SCSS
import "../../styles/pages/CompanyPage/CompanyHomePage.scss";

// Pages
import CompanyAbout from "./companybar/AboutCompany/CompanyAbout";
import CompanyContact from "./companybar/ContactCompany/CompanyContact";
import BusinessSocialMedias from "./companybar/SocialCompany/BusinessSocialMedias";

import { addFavorite, removeFavorite } from "../../actions/favoritesActions";
import CompanyService from "../company_service/CompanyService";
import { ImageLoader } from "./components/ImageLoader";

function CompanyHomePage() {
  const dispatch = useDispatch();
  const { businessId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const label = searchParams.get("label");

  const [currentPage, setCurrentPage] = useState("home");

  const { user } = useSelector((state) => state.userDetails);
  const statusUser = user?.subscriptionStatus?.active;

  const { loading, error, company } = useSelector(
    (state) => state.companyDetails
  );

  useEffect(() => {
    dispatch(resetlistCompanyDetailsAction());
    dispatch(listCompanyDetailsAction(businessId));
  }, [dispatch, businessId]);

  useEffect(() => {
    if (label) {
      setCurrentPage(label);
    }
  }, [label]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (loading) return;
    document.title = `${company?.name} - GaleanaBiz`;
  }, [company]);

  const renderPage = (statusUser, company) => {
    if (!("_id" in company)) {
      return <Loader />;
    }

    if (currentPage === "home") {
      return <ProductsPage statusUser={statusUser} company={company} />;
    } else if (currentPage === "services") {
      return <CompanyService />;
    } else if (currentPage === "about") {
      return <CompanyAbout companyId={businessId} />;
    } else if (currentPage === "contact") {
      return <CompanyContact companyId={businessId} />;
    }
  };

  const arrayButtonPages = [
    { status: "home", label: "Home" },
    { status: "services", label: "Services" },
    { status: "about", label: "About" },
    { status: "contact", label: "contact" },
  ];

  const handleCurrentPage = (status) => {
    params.set("label", status);

    setCurrentPage(status);
    setSearchParams(params);
  };

  return (
    <AddToAllPages>
      <SectionCompany company={company} loading={loading} error={error} />

      <div className="linksAndSearchBarContainer">
        <div className="companyLinksContainer">
          {arrayButtonPages.map((button, index) => {
            return (
              <div
                key={index}
                className={
                  currentPage === button.status
                    ? "activeCompanyBtn"
                    : "companyBtn"
                }
                onClick={() => handleCurrentPage(button.status)}
              >
                {button.label}
              </div>
            );
          })}
        </div>

        {currentPage === "home" && (
          <div className="searchBarContainer">
            <div className="searchBarWrapper">
              <SearchAndFilterBar companyId={businessId} />
            </div>
          </div>
        )}
      </div>

      <div key={0} className="pageContainer">
        {renderPage(statusUser, company)}
      </div>

      <BusinessSocialMedias companyId={businessId} />
    </AddToAllPages>
  );
}

export default CompanyHomePage;

const SectionCompany = ({ company, loading, error }) => {
  const dispatch = useDispatch();
  const { businessId } = useParams();

  const [logoExpand, setLogoExpand] = useState(false);
  const [isFavorite, setIsFavorite] = useState(company?.is_favorite);

  useEffect(() => {
    setIsFavorite(company?.is_favorite);
  }, [company]);

  if (loading) {
    return <Loader textSize={26} />;
  }

  if (error) {
    return (
      <Message error={error}>
        {"There was an error loading data from this company, try again"}
      </Message>
    );
  }

  return (
    <div className="companyHeader">
      <div className="topSection">
        <div className={`bannerImageContainer `}>
          <ImageLoader
            company={company}
            photo={company?.bannerImage}
            businessId={businessId}
            alt={`${company?.name} sponsor banner`}
            className={"bannerImage"}
          />
        </div>

        <div className="companyHeader">
          <div className="nameAndFollowBtnContainer">
            <div className="profileImgAndNameContainer">
              <div className={`imageContainer`}>
                <ImageLoader
                  company={company}
                  photo={company?.profileImage}
                  businessId={businessId}
                  alt={`${company?.name} profile`}
                  className={"companyProfileImage"}
                  onClick={() => setLogoExpand(true)}
                />
              </div>

              {logoExpand && (
                <div className="logoImageModal">
                  <div
                    className="background"
                    onClick={() => setLogoExpand(false)}
                  ></div>
                  <img
                    src={company?.profileImage}
                    alt=""
                    className="logoImage"
                    draggable="false"
                  />
                </div>
              )}

              <div className="nameAndReviewsContainer">
                <div className="companyNameText">{company?.name}</div>
                <div className="reviewsAndFollowBtnContainer">
                  <div className="addOrRemoveFavoritesBtnsContainer">
                    {isFavorite ? (
                      <button
                        className="RemoveFavoritesBtn"
                        onClick={() => {
                          dispatch(removeFavorite(company._id));
                          setIsFavorite(null);
                        }}
                      >
                        <i className="fa-solid fa-heart"></i>
                      </button>
                    ) : (
                      <button
                        className="addToFavoritesBtn"
                        onClick={() => {
                          dispatch(addFavorite(company._id));
                          setIsFavorite(true);
                        }}
                      >
                        <i className="fa-regular fa-heart"></i>
                      </button>
                    )}
                  </div>

                  <div className="starReviewsContainer">
                    <StarReviews value={company?.rating} color={"#d4b05c"} />
                    <Link to="reviewsCompany/" className="numReviewsLink">
                      {company?.numReviews !== 0 ? (
                        <>
                          {company?.numReviews}
                          {company?.numReviews > 1 ? " Reviews" : " Review"}
                        </>
                      ) : (
                        "No Reviews"
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
