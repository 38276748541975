import {
  SOCIAL_GET_REQUEST,
  SOCIAL_GET_SUCCESS,
  SOCIAL_GET_FAIL,
  //
  SOCIAL_ADD_REQUEST,
  SOCIAL_ADD_SUCCESS,
  SOCIAL_ADD_FAIL,
  //
  SOCIAL_PUT_REQUEST,
  SOCIAL_PUT_SUCCESS,
  SOCIAL_PUT_FAIL,
  SOCIAL_PUT_RESET,
} from "../constants/contactConstans";
/////////////////////////////////////////////////////////////////////////////////////////////

export const UpdateSocialNetworkReducer = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_PUT_REQUEST:
      return { loading: true };

    case SOCIAL_PUT_SUCCESS:
      return { loading: false, success: true };

    case SOCIAL_PUT_FAIL:
      return { loading: false, error: action.payload };

    case SOCIAL_PUT_RESET:
      return {};

    default:
      return state;
  }
};

export const AddSocialNetworkReducer = (state = {}, action) => {
  switch (action.type) {
    case SOCIAL_ADD_REQUEST:
      return { loading: true };

    case SOCIAL_ADD_SUCCESS:
      return { loading: false, success: true };

    case SOCIAL_ADD_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getSocialNetworkReducer = (
  state = { companySocialNetwork: [] },
  action
) => {
  switch (action.type) {
    case SOCIAL_GET_REQUEST:
      return { loading: true };

    case SOCIAL_GET_SUCCESS:
      return { loading: false, companySocialNetwork: action.payload };

    case SOCIAL_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
