import React, { useState } from "react";

import formatCurrency from "../../../helpers/format/formatCurrency";

const OrderDetails = ({ orderDetails }) => {
  const [showAll, setShowAll] = useState(false);

  const isAlternateReceiverPhoneDefined =
    !!orderDetails?.alternate_receiver_phone;

  // Define the maximum number of characters to show initially
  const maxCharacters = 350;

  // Show only the first `maxCharacters` or all characters if 'showAll' is true

  const displayText = showAll
    ? orderDetails?.delivery_instructions
    : orderDetails?.delivery_instructions.length > maxCharacters
    ? orderDetails?.delivery_instructions.slice(0, maxCharacters) + "..."
    : orderDetails?.delivery_instructions;

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="userInfoSection">
      <div className="dateOrdered">
        <span>Date Ordered: </span>
        {new Date(orderDetails?.date_issued).toLocaleTimeString("en-US", {
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        })}
        {" - "}
        {new Date(orderDetails?.date_issued).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </div>
      <div className="totalPrice">
        Total: {formatCurrency(orderDetails?.order_total)} MXN
      </div>
      <div className="infoBlock">
        <div className="subtitle">User Info:</div>
        <div className="infoText infoTextLarge">
          <span>
            {orderDetails?.user_name} {orderDetails?.last_name}
          </span>
        </div>
        <div className="infoText infoTextLarge marginBottom">
          <span>Phone: </span>
          {orderDetails?.telephone}
          <div className="containerLink">
            <a
              href={`tel:${orderDetails?.telephone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="iconContainer phoneIconContainer">
                <i className="fas fa-phone"></i>
              </div>
            </a>
            <a
              href={`https://wa.me/${orderDetails?.telephone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="iconContainer whatsAppIconContainer">
                <i className="fab fa-whatsapp"></i>
              </div>
            </a>
          </div>
        </div>
        {isAlternateReceiverPhoneDefined ? (
          <div className="infoText infoTextLarge marginBottom">
            <span>Alt: {orderDetails?.alternate_receiver_phone}</span>
            <div className="containerLink">
              <a
                href={`tel:${orderDetails?.alternate_receiver_phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="iconContainer phoneIconContainer">
                  <i className="fas fa-phone"></i>
                </div>
              </a>
              <a
                href={`https://wa.me/${orderDetails?.alternate_receiver_phone}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="iconContainer whatsAppIconContainer">
                  <i className="fab fa-whatsapp"></i>
                </div>
              </a>
            </div>
          </div>
        ) : null}
      </div>
      <div className="infoBlock">
        <div className="subtitle">Address:</div>
        <div className="infoText">
          <span>
            <i className="fa-solid fa-location-dot"></i>{" "}
            {orderDetails?.location}
          </span>
        </div>
        <div className="infoText">
          <span>Street 1: </span>
          {orderDetails?.street_1}
        </div>
        <div className="infoText marginBottom">
          <span>Street 2: </span>
          {orderDetails?.street_2}
        </div>
        <div className="infoText marginBottom">
          <div>Refrences: </div>
          {orderDetails?.reference}
        </div>

        {orderDetails?.delivery_instructions ? (
          <div className="infoText additionalInsturctions">
            <div>Additional instructions: </div>
            {/* {orderDetails?.delivery_instructions} */}

            <span className="instructionsText">{displayText}</span>
            {orderDetails?.delivery_instructions.length > maxCharacters && (
              <span onClick={toggleShowAll}>
                {showAll ? (
                  <button className="readMoreBtn">Read Less</button>
                ) : (
                  <button className="readMoreBtn">Read More</button>
                )}
              </span>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OrderDetails;
