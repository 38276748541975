export const FAVORITES_ADD_BUSINESS = "FAVORITES_ADD_BUSINESS";
export const FAVORITES_REMOVE_BUSINESS = "FAVORITES_REMOVE_BUSINESS";

export const FAVORITES_GET_BUSINESS_REQUEST = "FAVORITES_GET_BUSINESS_REQUEST";
export const FAVORITES_GET_BUSINESS_SUCCESS = "FAVORITES_GET_BUSINESS_SUCCESS";
export const FAVORITES_GET_BUSINESS_FAILURE = "FAVORITES_GET_BUSINESS_FAILURE";

export const FAVORITES_ADD_BUSINESS_REQUEST = "FAVORITES_ADD_BUSINESS_REQUEST";
export const FAVORITES_ADD_BUSINESS_SUCCESS = "FAVORITES_ADD_BUSINESS_SUCCESS";
export const FAVORITES_ADD_BUSINESS_FAILURE = "FAVORITES_ADD_BUSINESS_FAILURE";

export const FAVORITES_INCREMENT_SIDE_BAR_LIMIT = "FAVORITES_INCREMENT_SIDE_BAR_LIMIT";
export const FAVORITES_INCREMENT_PAGE_LIMIT = "FAVORITES_INCREMENT_PAGE_LIMIT";

export const FAVORITES_UNLOAD = "FAVORITES_UNLOAD";

export const FAVORITES_REMOVE_BUSINESS_REQUEST =
  "FAVORITES_REMOVE_BUSINESS_REQUEST";
export const FAVORITES_REMOVE_BUSINESS_SUCCESS =
  "FAVORITES_REMOVE_BUSINESS_SUCCESS";
export const FAVORITES_REMOVE_BUSINESS_FAILURE =
  "FAVORITES_REMOVE_BUSINESS_FAILURE";

export const FAVORITES_PAGE_SIZE = 12;
