import axios from "axios";

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  GET_USER_COMPANY,
} from "../constants/userConstans";
import { unloadFavorites } from "./favoritesActions";

const ApiLebaronGaleana = process.env.REACT_APP_API_URL_LEBARON_GALEANA;
const galeanaBizApiHost = process.env.REACT_APP_API_URL_GALEANA_BIZ;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getUserDetails = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const {
      userLogin: { userToken },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const { data } = await axios.get(
      `${ApiLebaronGaleana}/api/get-user/`,
      config
    );

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });

    localStorage.setItem("user", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("user");
  localStorage.removeItem("reviews");
  localStorage.removeItem("cookieDisclaimer");
  localStorage.removeItem("companyCard");
  localStorage.removeItem("cart");
  localStorage.removeItem("productsDetails");
  localStorage.removeItem("DriverProfile");

  dispatch(unloadFavorites());
  dispatch({ type: USER_LOGOUT });
};

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const { data: tokenResponse } = await axios.post(
      `${ApiLebaronGaleana}/api/login/`,
      { username: email, password: password }
    );

    const { data: userDetailsResponse } = await axios.get(
      `${ApiLebaronGaleana}/api/get-user/`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${tokenResponse.token}`,
        },
      }
    );

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: userDetailsResponse,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: tokenResponse,
    });

    localStorage.setItem("userToken", JSON.stringify(tokenResponse));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getUserCompany = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userToken },
      userDetails: { user },
    } = getState();

    if (!userToken) {
      dispatch({
        type: GET_USER_COMPANY,
        payload: null,
      });
      return;
    }

    const { data } = await axios.get(
      `${galeanaBizApiHost}/api/companies/user/${user.user.id}/`,
      { headers: { Authorization: `Token ${userToken}` } }
    );

    dispatch({
      type: GET_USER_COMPANY,
      payload: data.company,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_COMPANY,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
