import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useGetSettingsQuery } from "../../services/galeanaBizApi";

import {
  CartGetItemsAction,
  CartGetTotalItemAction,
  CartGetTotalPriceAction,
  DeleteItemAction,
} from "../../actions/cartActions";

import CartItemUnits from "./CartItemUnits";
import CartItemLocalStorage from "./CartItemLocalStorage";

import AddToAllPages from "../../components/AddToAllPages";

import Loader from "../../components/Loader";
import Message from "../../components/Message";
import formatCurrency from "../../helpers/format/formatCurrency";
import { ErrorPopup } from "../../helpers/popup/ErrorPopup";
import { AdressUserAction } from "../../actions/adress/adressUserActions";

import { IoMdClose } from "react-icons/io";

import "../../styles/pages/Cart/CartItem.scss";
import LGLogo from "../../images/LGWhiteLogo.png";

const CartItem = () => {
  const dispatch = useDispatch();
  const addressShown = useRef(true);

  const { userToken } = useSelector((state) => state.userLogin);
  const { AdressUser } = useSelector((state) => state.AdressUserGet);

  const { data: settingsData, isFetching: fetchingSettings } =
    useGetSettingsQuery();

  const [isDeliveryDisabledModalOpen, setIsDeliveryDisabledModalOpen] =
    useState(false);

  const GetItemsCart = useSelector((state) => state.CartGetItems);
  const {
    loading: GetItemsLoading,
    error: GetItemsError,
    CartItems: GetCartItems,
  } = GetItemsCart;

  const GetCartTotalPrice = useSelector((state) => state.CartGetTotalPrice);
  const {
    loading: GetTotalPriceLoading,
    error: GetTotalPriceError,
    CartTotalPrice,
  } = GetCartTotalPrice;

  const GetCartTotalItems = useSelector((state) => state.CartGetTotalItems);
  const {
    loading: GetTotalItemsLoading,
    error: GetTotalItemsError,
    CartTotalItems,
  } = GetCartTotalItems;

  const RemoveCartItem = useSelector((state) => state.CartRemoveItem);
  const { loading: RemoveItemLoading, error: RemoveItemError } = RemoveCartItem;

  useEffect(() => {
    window.scrollTo(0, 40);
  }, []);

  useEffect(() => {
    if (userToken) {
      dispatch(AdressUserAction());
    }
  }, [dispatch, userToken]);

  useEffect(() => {
    if (userToken) {
      if (!AdressUser || AdressUser === undefined) {
        addressShown.current = false;
      }

      if (AdressUser) {
        addressShown.current = true;
      }
    }
  }, [userToken, AdressUser]);

  useEffect(() => {
    dispatch(CartGetItemsAction());
    dispatch(CartGetTotalPriceAction());
    dispatch(CartGetTotalItemAction());
  }, [dispatch]);

  const loadings = () => {
    return GetItemsLoading || RemoveItemLoading;
  };

  const errors = () => {
    return GetItemsError || RemoveItemError;
  };

  const verific = () => {
    if (addressShown.current || loadings() || errors()) return null;

    if (GetTotalPriceLoading) return null;

    return (
      <ErrorPopup
        message="To view your cart or proceed with your order, please register your address."
        link={{
          url: "/profile/add-address",
          text: "Click here to add your address",
        }}
      />
    );
  };

  const CartItems = () => {
    if (loadings()) {
      return <Loader />;
    }

    if (errors()) {
      return <Message error={errors()}>An error occurred try again</Message>;
    }

    const cartTitle = (
      <div className="title">
        Your Cart (
        {GetTotalItemsLoading ? (
          "Loading..."
        ) : GetTotalItemsError ? (
          "0"
        ) : (
          <>
            {CartTotalItems?.total_items <= 0
              ? "0 Items"
              : CartTotalItems?.total_items === 1
              ? "1 Item"
              : `${CartTotalItems?.total_items || 0} Items`}
          </>
        )}
        )
      </div>
    );

    const cartTotal = (
      <div className="subtotalText">
        <span>Total: </span>
        {GetTotalPriceLoading
          ? "Loading..."
          : GetTotalPriceError
          ? "0.00"
          : formatCurrency(CartTotalPrice?.total_cost || 0.0)}
        <span> MXN</span>
      </div>
    );

    const CheckoutButton = () => {
      if (fetchingSettings) {
        return <Loader height="" />;
      }

      if (
        CartTotalItems?.total_items > 0 &&
        settingsData?.settings?.delivery_available
      ) {
        return (
          <div className="ContentCheckout">
            <Link to="/checkout">
              <button className="checkoutBtn">Checkout</button>
            </Link>
          </div>
        );
      }

      return (
        <div className="ContentCheckout">
          <Link to="#">
            <button
              onClick={() => {
                if (CartTotalItems?.total_items > 0) {
                  setIsDeliveryDisabledModalOpen(true);
                }
              }}
              className="checkoutBtn disabled"
            >
              Checkout
              <i className="fas fa-lock"></i>
            </button>
          </Link>
        </div>
      );
    };

    return (
      <div className="cartSection">
        <div className="headerContainer">
          <div className="header">
            {cartTitle}
            <div className="bottomSection">
              {cartTotal}
              <CheckoutButton />
            </div>
          </div>
          {settingsData?.settings?.cart_message_value && (
            <div className="message">
              <div className="text">
                {settingsData?.settings?.cart_message_value}
              </div>
            </div>
          )}
        </div>
        {userToken ? (
          <div className="cartItemsContainer">
            {verific()}
            {Object.values(GetCartItems || []).map((cartItem, index) => {
              return <RenderCartItem key={index} cartItem={cartItem} />;
            })}
          </div>
        ) : (
          <CartItemLocalStorage />
        )}
      </div>
    );
  };

  return (
    <AddToAllPages>
      {isDeliveryDisabledModalOpen && (
        <ErrorPopup
          message="We are currently not available for deliveries, please check in at a later time."
          onClose={() => setIsDeliveryDisabledModalOpen(false)}
        />
      )}
      <div className="cartPage">
        <CartItems />
      </div>
    </AddToAllPages>
  );
};

export default CartItem;

const RenderCartItem = ({ cartItem }) => {
  const [showProductOptions, setShowProductOptions] = useState(false);
  const [openDropdownItems, setOpenDropdownItems] = useState([]);

  const dispatch = useDispatch();

  const UpdateCartItem = useSelector((state) => state.CartUpdateItem);
  const {
    loading: UpdateItemLoading,
    error: UpdateItemError,
    success: UpdateItemSuccess,
  } = UpdateCartItem;

  const RemoveItem = (item) => {
    dispatch(DeleteItemAction(item));
  };

  const handleDropDown = (e, itemIndex) => {
    e.preventDefault();

    setShowProductOptions(false);
    if (openDropdownItems.includes(itemIndex)) {
      setOpenDropdownItems(
        openDropdownItems.filter((index) => index !== itemIndex)
      );
    } else {
      setOpenDropdownItems([...openDropdownItems, itemIndex]);
    }
  };

  return (
    <div className="cartItemsWrapper">
      {Object.values(cartItem?.items || []).map((item, itemIndex) => {
        const units = item?.count;
        const { product } = item;

        const image = product?.photo;
        const price = product?.price;

        const priceDiscount = formatCurrency(
          product?.discounted_price,
          product?.currency
        );
        const currency = product?.currency;
        const discount = product?.discount;
        const available = product?.available;
        const subscriptionActive = product?.subscription_active;
        const discountedPrice = product?.discounted_price;
        const additionalPrice = product?.cargo_extra;

        const categoryGroup = item?.attribute_groups;

        const totalPriceUnits =
          additionalPrice > 0
            ? formatCurrency(additionalPrice * units, currency)
            : discount > 0
            ? formatCurrency(discountedPrice * units, currency)
            : formatCurrency(price * units, currency);

        const dropDown = openDropdownItems.includes(itemIndex);

        return (
          <div className="productContainer" key={itemIndex}>
            <div className="productCard">
              <Link
                className="productImgContainer"
                to={`/product/${product.company_name}/${product?.id}`}
              >
                {product?.subscription_active && (
                  <div className="memberOnlyTag">
                    <img src={LGLogo} alt="" className="LGMemberTagLogo" />
                  </div>
                )}
                <img
                  src={product?.photo}
                  alt=""
                  className="productImg"
                  draggable="false"
                />
              </Link>
              <div className="infoSection infoSection__cart">
                <Link
                  className="topSection"
                  to={`/product/${product.company_name}/${product?.id}`}
                >
                  <div className="infoText productName">{product?.name}</div>
                  <div className="infoText businessName">
                    {product?.company_name}
                  </div>

                  <div className={`infoText productPrice`}>
                    {price}
                    <span className="productCurrency">{product?.currency}</span>
                  </div>
                  {item?.count > 1 && (
                    <div className={`infoText productPrice productTotalPrice`}>
                      Total: {price * item?.count}
                      <span className="productCurrency">
                        {product?.currency}
                      </span>
                    </div>
                  )}

                  {product?.discount > 0 && (
                    <div className="infoText discountedPercentage">
                      {product?.discount}% off
                    </div>
                  )}

                  {showProductOptions && (
                    <DropDownComponent
                      dropDown={dropDown}
                      categoryGroup={categoryGroup}
                      handleDropDown={handleDropDown}
                      itemIndex={itemIndex}
                      setShowProductOptions={setShowProductOptions}
                    />
                  )}

                  {product?.available ? null : (
                    <div className="infoText soldOutText">
                      Currently Unavailable
                    </div>
                  )}
                </Link>

                <div className="bottomSection">
                  <div className="removeBtn" onClick={() => RemoveItem(item)}>
                    <i className="fa-solid fa-trash"></i>
                  </div>

                  {available === true ? (
                    <CartItemUnits
                      item={item}
                      productId={product?.id}
                      UpdateItemSuccess={UpdateItemSuccess}
                      UpdateItemLoading={UpdateItemLoading}
                      UpdateItemError={UpdateItemError}
                    />
                  ) : (
                    "Unavailable"
                  )}

                  <div
                    className="showProductsExtraOptions"
                    onClick={() => setShowProductOptions(true)}
                  >
                    ?
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const DropDownComponent = ({
  dropDown,
  categoryGroup,
  handleDropDown,
  itemIndex,
  setShowProductOptions,
}) => {
  // if (!dropDown || !categoryGroup || categoryGroup.length === 0) return null;
  return (
    <div className="warpperDropDown">
      <div className="closed" onClick={(e) => handleDropDown(e, itemIndex)}>
        <IoMdClose className="iconClose" />
      </div>
      {categoryGroup?.map((attribute, index) => {
        const option = attribute?.options;
        return (
          <div className="contentDropDown" key={index}>
            <div className="nameCategory">{attribute.category}</div>

            {option.map((option, index) => {
              return (
                <div className="contentAttribute" key={index}>
                  <div className="name">{option.name}</div>
                  <div className="price">+ {formatCurrency(option.price)}</div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
