import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { AddContactActions } from "../../../../actions/contactActions";

// SCSS
import "../../../../styles/pages/CompanyPage/companybar/ContactCompany/PutContact.scss";

// Components
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

const PostContact = () => {
  const dispatch = useDispatch();
  const naviagate = useNavigate();
  const path = "/mybusiness/customization";

  const { company } = useSelector((state) => state.userDetails);
  const companyId = company._id;

  const [email, setEmail] = useState(null);
  const [phone_number, setPhoneNumber] = useState(null);
  const [whaths_number, setWhatssNumber] = useState(null);
  const [address, setAddress] = useState(null);

  const PostContact = useSelector((state) => state.PostContact);
  const { loading, error, success } = PostContact;

  useEffect(() => {
    if (success) {
      naviagate(path, { replace: true });
    }
  }, [success]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      AddContactActions(companyId, email, phone_number, whaths_number, address)
    );
  };

  return (
    <NavigationComponents>
      <form onSubmit={onSubmit} className="putContact">
        <div className="inputContainer">
          <label>
            <div className="inputTitle">Address</div>
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </label>
        </div>
        {/* <div>
          <label>
            Phone Number:
            <input
              type="phone"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </label>
        </div> */}
        <div className="inputContainer">
          <label>
            <div className="inputTitle">WhatsApp Number</div>
            <p>+52 for a MXN number, +1 for a USA number</p>
            <input
              type="phone"
              placeholder="+52 123 456 7890"
              value={whaths_number}
              onChange={(e) => setWhatssNumber(e.target.value)}
            />
          </label>
        </div>
        <div className="inputContainer">
          <label>
            <div className="inputTitle">Email</div>
            <input
              type="email"
              placeholder="example@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>
        <div className="saveBtnContainer">
          <button type="submit">Save</button>
        </div>
      </form>
    </NavigationComponents>
  );
};

export default PostContact;
