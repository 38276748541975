import React, { useEffect, useState } from "react";

import DeliveryNavigationComponents from "../../../../components/navigation/Driver-Navigation/DriverNavigationComponents";
import { useDispatch, useSelector } from "react-redux";
import { orderStatusAction } from "../../../../actions/ordersActions";
import formatCurrency from "../../../../helpers/format/formatCurrency";

//scss
import "../../../../styles/pages/Driver/pages/OrdersStatus.scss";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

const OrdersStatus = () => {
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);

  const { loading, error, current_page, num_pages, orderStatus } = useSelector(
    (state) => state.getOrderStatus
  );

  useEffect(() => {
    dispatch(orderStatusAction(page, status));
  }, [dispatch, page, status]);

  const handleFilter = (status) => {
    setStatus(status);
  };

  const loadPage = (page) => {
    dispatch(orderStatusAction(page, status));
  };

  return (
    <DeliveryNavigationComponents>
      <ButtonFilter status={status} handleFilter={handleFilter} />
      <OrderStatus
        loadPage={loadPage}
        orderStatus={orderStatus}
        loading={loading}
        error={error}
        current_page={current_page}
        num_pages={num_pages}
      />
    </DeliveryNavigationComponents>
  );
};

export default OrdersStatus;

const ButtonFilter = ({ status, handleFilter }) => {
  const buttons = [
    { status: "", label: "All" },
    { status: "delivered", label: "Delivered" },
    { status: "delivery_failed", label: "  Delivered Failed" },
    { status: "cancelled", label: "Cancelled" },
    { status: "declined", label: "Declined" },
  ];
  return (
    <div className="contentButtonFilter">
      {buttons.map((button, index) => (
        <button
          key={index}
          className={status === button.status ? "selectedStatus" : "btnFilter"}
          onClick={() => handleFilter(button.status)}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

const OrderStatus = ({
  loadPage,
  orderStatus,
  loading,
  error,
  current_page,
  num_pages,
}) => {
  const [openDropdownItems, setOpenDropdownItems] = useState([]);

  const handleAttribute = (e, itemIndex) => {
    e.preventDefault();
    if (openDropdownItems.includes(itemIndex)) {
      setOpenDropdownItems(
        openDropdownItems.filter((index) => index !== itemIndex)
      );
    } else {
      setOpenDropdownItems([...openDropdownItems, itemIndex]);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Message error={error}>
        It was not possible to load this page, I tried again.
      </Message>
    );
  }

  const CustomerOrderAttributes = ({ attribute, dropDown }) => {
    if (!dropDown) return null;
    return (
      <div className="wrapperAttributes">
        <ul>
          <li>
            <div className="categoryTitle">{attribute.category_title}</div>
            <ul>
              <li className="li">
                <div className="attributeName">{attribute.attribute_name}</div>
                <div className="attributePrice">
                  +${attribute.attribute_price}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const CustomerOrder = ({ order }) => {
    return (
      <div className="customerOrderContainer">
        <div className="sectionTitle">Customer Order:</div>

        {Object.values(order?.order_companies).map((item, index) => (
          <div className="businessContainer" key={index}>
            <div className="businessName">{item?.company_name}</div>
            <div className="totalCompany">Total Company: ${item?.total}</div>

            <div className="allItemsContainer">
              {item?.orderItem.map((product, itemIndex) => {
                const dropDown = openDropdownItems.includes(product.id);
                return (
                  <div className="productContainer" key={itemIndex}>
                    <div className="productName">
                      <div className="text">P. Name:</div>
                      <div className="name"> {product?.name}</div>
                    </div>

                    <div className="productPriceUnit">
                      Price/Unit: {product?.price}
                    </div>

                    {product?.discounted_price && (
                      <div className="productDiscuntUnit">
                        Disc. Unit: {product?.discounted_price}
                      </div>
                    )}

                    {product?.cargo_extra > 0 && (
                      <div
                        className="productExtrCostUnit"
                        onClick={(e) => handleAttribute(e, product.id)}
                      >
                        Ext. Cost/Unit: {product?.cargo_extra}
                        <div className="wrapperIcon">
                          {product.attributes.length > 0 && (
                            <div className="contentIcon">
                              {dropDown ? (
                                <i className="fas fa-chevron-up"></i>
                              ) : (
                                <i className="fas fa-chevron-down"></i>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="sectionAttributes">
                      {product.attributes.map((attribute, index) => {
                        return (
                          <CustomerOrderAttributes
                            index={index}
                            attribute={attribute}
                            dropDown={dropDown}
                          />
                        );
                      })}
                    </div>

                    <div className="productPrice">
                      <div className="text">Status Item: </div>
                      <div className="count">{product?.status}</div>
                    </div>

                    <div className="productPrice">
                      <div className="text">Total Items: </div>
                      <div className="count">{product?.count}</div>
                      <span>x</span>
                      <div className="formatPrice">
                        {formatCurrency(product?.item_total, product.currency)}
                      </div>
                      <div className="currency"> {product.currency}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const CardOrderStatus = () => {
    return (
      <div className="cardsWrapper">
        {orderStatus?.map((order, index) => {
          return (
            <div className="orderCard" key={index}>
              <div className="dateDeliveredContainer">
                {new Date(order?.delivered_at).toLocaleTimeString("en-US", {
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                {new Date(order?.delivered_at).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </div>

              <div className="infoContainer">
                <div className="infoTxt">
                  <span className="title">Status: </span>
                  <span className="info">{order?.status}</span>
                </div>

                <div className="infoTxt">
                  <span className="title">Customer: </span>
                  <span className="info">
                    {order?.user_name} {order?.last_name}
                  </span>
                </div>

                <div className="infoTxt">
                  <span className="title">Driver: </span>
                  <span className="info">
                    {order?.order_delivery_info?.driver_name}{" "}
                    {order?.order_delivery_info?.driver_last_name}
                  </span>
                </div>

                <div className="infoTxt">
                  <span className="title">Order Total: </span>
                  <span className="info">
                    {formatCurrency(order?.order_total)} MXN
                  </span>
                </div>
              </div>

              <CustomerOrder order={order} />

              {order?.delivery_description && (
                <div className="descriptionContainer">
                  <div className="title">Description: </div>
                  <div className="info">{order?.delivery_description}</div>
                </div>
              )}

              {order?.cancellation_reason && (
                <div className="descriptionContainer">
                  <div className="title">Description: </div>
                  <div className="info">{order?.cancellation_reason}</div>
                </div>
              )}

              <div className="transactionId">
                <div className="title">Transaction ID: </div>
                <div className="info">{order?.transaction_id}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const BackAndNext = () => {
    const [visibleRange, setVisibleRange] = useState([
      1,
      Math.min(5, num_pages),
    ]);

    useEffect(() => {
      const newRange = [
        Math.max(1, current_page - 4),
        Math.min(current_page + 4, num_pages),
      ];
      setVisibleRange(newRange);
    }, [current_page, num_pages]);

    const pageButtons = Array.from(
      { length: num_pages },
      (_, index) => index + 1
    ).slice(visibleRange[0] - 1, visibleRange[1]);

    return (
      <div className="backAndNextBtnsContainer">
        <button
          className="btnPage btnPage__left"
          disabled={current_page === 1}
          onClick={() => loadPage(current_page - 1)}
        >
          Back
        </button>
        {pageButtons.map((page) => (
          <button
            key={page}
            onClick={() => loadPage(page)}
            className={
              current_page === page ? "currentPage" : "currentPageNumber"
            }
          >
            {page}
          </button>
        ))}

        <button className="currentPageText"> of {num_pages}</button>

        <button
          className="btnPage btnPage__right"
          disabled={current_page === num_pages}
          onClick={() => loadPage(current_page + 1)}
        >
          Next
        </button>
      </div>
    );
  };

  return (
    <div className="ordersDeliveredOrFailed">
      <div className="pageTitle">Orders Status</div>
      <CardOrderStatus />
      <BackAndNext />
    </div>
  );
};
