import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_ADD_ITEM_LOCAL_SUCCESS,
  CART_ADD_ITEM_LOCAL_RESET,
  CART_GET_TOTAL,
  CART_GET_ITEM_TOTAL,
  CART_GET_ITEMS,
  CART_UPDATE_ITEM,
  CART_REMOVE_ITEM,
  CART_EMPTY_CART,
  //
  CART_ADD_ITEM_REQUEST,
  CART_ADD_ITEM_SUCCESS,
  CART_ADD_ITEM_FAIL,
  CART_ADD_ITEM_RESET,
  //
  CART_GET_TOTAL_REQUEST,
  CART_GET_TOTAL_SUCCESS,
  CART_GET_TOTAL_FAIL,
  CART_GET_TOTAL_RESET,
  //
  CART_GET_ITEM_TOTAL_REQUEST,
  CART_GET_ITEM_TOTAL_SUCCESS,
  CART_GET_ITEM_TOTAL_FAIL,
  //
  CART_GET_ITEMS_REQUEST,
  CART_GET_ITEMS_SUCCESS,
  CART_GET_ITEMS_FAIL,
  //
  CART_UPDATE_ITEM_REQUEST,
  CART_UPDATE_ITEM_SUCCESS,
  CART_UPDATE_ITEM_FAIL,
  CART_UPDATE_ITEM_RESET,
  //
  CART_REMOVE_ITEM_REQUEST,
  CART_REMOVE_ITEM_SUCCESS,
  CART_REMOVE_ITEM_FAIL,
  CART_REMOVE_ITEM_RESET,
  //
  CART_EMPTY_CART_REQUEST,
  CART_EMPTY_CART_SUCCESS,
  CART_EMPTY_CART_FAIL,
  //
  CART_SYNCH_CART_REQUEST,
  CART_SYNCH_CART_SUCCESS,
  CART_SYNCH_CART_FAIL,
  //
} from "../constants/cartConstants";

export const EmptyCartReducers = (state = {}, action) => {
  switch (action.type) {
    case CART_EMPTY_CART_REQUEST:
      return { loading: true, ...state };
    case CART_EMPTY_CART_SUCCESS:
      return { loading: false, success: true };
    case CART_EMPTY_CART_FAIL:
      return { loading: false, error: action.payload };

    case CART_EMPTY_CART:
      return { ...state, CartItems: null };
    default:
      return state;
  }
};

export const DeleteItemReducers = (state = {}, action) => {
  switch (action.type) {
    case CART_REMOVE_ITEM_REQUEST:
      return { ...state, loading: true };
    case CART_REMOVE_ITEM_SUCCESS:
      return { ...state, loading: false, success: true };
    case CART_REMOVE_ITEM_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CART_REMOVE_ITEM:
      localStorage.setItem("cart", JSON.stringify(action.payload));
      return { ...state, CartItems: JSON.parse(localStorage.getItem("cart")) };
    default:
      return state;
  }
};

export const UpdateCartItem = (state = { UpdateCartProduct: {} }, action) => {
  switch (action.type) {
    case CART_UPDATE_ITEM_REQUEST:
      return { ...state, loading: true };

    case CART_UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case CART_UPDATE_ITEM_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CART_UPDATE_ITEM_RESET:
      return { ...state, UpdateCartProduct: {} };

    case CART_UPDATE_ITEM:
      localStorage.setItem("cart", JSON.stringify(action.payload));
      return { ...state, CartItems: JSON.parse(localStorage.getItem("cart")) };
    default:
      return state;
  }
};

export const AddCartItems = (
  state = { loading: false, success: false, error: null },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM_REQUEST:
      return { ...state, loading: true };

    case CART_ADD_ITEM_SUCCESS:
      return { ...state, loading: false, success: true };

    case CART_ADD_ITEM_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CART_ADD_ITEM_RESET:
      return { ...state, success: false };

    case CART_ADD_ITEM:
      localStorage.setItem("cart", JSON.stringify(action.payload));
      return { ...state, CartItems: JSON.parse(localStorage.getItem("cart")) };

    case CART_ADD_ITEM_LOCAL_SUCCESS:
      return { ...state, success: true };

    case CART_ADD_ITEM_LOCAL_RESET:
      return { ...state, success: false };

    default:
      return state;
  }
};

export const CartGetTotalItem = (state = { CartTotalItems: 0 }, action) => {
  switch (action.type) {
    case CART_GET_ITEM_TOTAL_REQUEST:
      return { ...state, loading: true };

    case CART_GET_ITEM_TOTAL_SUCCESS:
      return { ...state, loading: false, CartTotalItems: action.payload };

    case CART_GET_ITEM_TOTAL_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CART_GET_ITEM_TOTAL:
      return {
        ...state,
        CartTotalItems: action.payload,
      };

    default:
      return state;
  }
};

export const CartGetTotalPrice = (state = { CartTotalPrice: 0.0 }, action) => {
  switch (action.type) {
    case CART_GET_TOTAL_REQUEST:
      return { ...state, loading: true };

    case CART_GET_TOTAL_SUCCESS:
      return { ...state, loading: false, CartTotalPrice: action.payload };

    case CART_GET_TOTAL_FAIL:
      return { ...state, loading: false, error: action.payload };

    case CART_GET_TOTAL:
      return {
        ...state,
        CartTotalPrice: action.payload,
      };

    default:
      return state;
  }
};

export const CartGetItemsReducers = (state = { CartItems: [] }, action) => {
  switch (action.type) {
    case CART_GET_ITEMS_REQUEST:
      return { ...state, loading: true };
    case CART_GET_ITEMS_SUCCESS:
      return { ...state, loading: false, CartItems: action.payload };
    case CART_GET_ITEMS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CART_GET_ITEMS:
      return { ...state, CartItems: JSON.parse(localStorage.getItem("cart")) };
    default:
      return state;
  }
};

export const SynchCartReducers = (state = {}, action) => {
  switch (action.type) {
    case CART_SYNCH_CART_REQUEST:
      return { ...state, loading: true };
    case CART_SYNCH_CART_SUCCESS:
      localStorage.removeItem("cart");
      return { ...state, loading: false, success: true };
    case CART_SYNCH_CART_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
