import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ErrorPopup } from "../../../helpers/popup/ErrorPopup";
import { useGetProductsQuery } from "../../../services/galeanaBizApi";

import { SkeletonProductCard } from "../../../components/CompanyAndProductCards/ProductCard_V2";

// SCSS
import "../../../styles/components/MyCompanyPageComponents/Dashboard/AddProductContainer.scss";
import Loader from "../../../components/Loader";

function AddProductContainer({ companyId }) {
  const [visibleWarning, setVisibleWarning] = useState(false);
  const { user } = useSelector((state) => state.userDetails);
  const { data, isFetching } = useGetProductsQuery({
    companyId,
    subscriptionStatus: true,
  });

  if (companyId === undefined || isFetching) {
    return <Loader />;
  }

  return (
    <>
      <div className="addProductContainer">
        <div className="title">Add Product / Service</div>
        <SkeletonProductCard />
        <div className="actionBtnContainer">
          {data?.total > 99 ? (
            <div
              onClick={() => setVisibleWarning(true)}
              className="actionBtn disabled"
            >
              Add Product <i className="fa-solid fa-lock" />
            </div>
          ) : (
            <Link to="/mybusiness/addproduct" className="actionBtn">
              Add Product
            </Link>
          )}

          <Link
            to="/mybusiness/services?label=add_service"
            className="actionBtn"
          >
            Add Service
          </Link>
        </div>
      </div>

      {user?.user.profile.freeAccount && visibleWarning && (
        <ErrorPopup
          message="You can add a maximum of 100 products"
          onClose={() => setVisibleWarning(false)}
        />
      )}
    </>
  );
}

export default AddProductContainer;
