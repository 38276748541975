import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../../../styles/pages/checkout/OrderCompanyProducts.scss";

import { getCompanyOrderAction } from "../../../actions/ordersActions";

import Loader from "../../../components/Loader";
import Message from "../../../components/Message";
import NavigationComponents from "../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

import formatCurrency from "../../../helpers/format/formatCurrency";

const OrderCompanyProducts = () => {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;
  const OrdersCompany = useSelector((state) => state.GetAllOrder);
  const {
    loading: OrdersCompanyLoading,
    error: OrdersCompanyError,
    current_page,
    num_pages,
    AllCompanyOrder,
  } = OrdersCompany;

  useEffect(() => {
    dispatch(getCompanyOrderAction(userCompanyId));
  }, [dispatch, userCompanyId]);

  const loadPage = (page) => {
    dispatch(getCompanyOrderAction(userCompanyId, page));
  };

  const Orders = () => {
    if (OrdersCompanyLoading) {
      return <Loader />;
    }

    if (OrdersCompanyError) {
      return (
        <Message error={OrdersCompanyError}>
          I can't load the content, try again..
        </Message>
      );
    }

    return (
      <>
        <div className="orderCardsWrapper">
          {AllCompanyOrder?.length === 0 ? (
            <p className="notOrders">There are no orders yet...</p>
          ) : null}
          {Object.values(AllCompanyOrder || []).map((order) => {
            const formatDate = (dateTimeString) => {
              const date = new Date(dateTimeString);

              const options = {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",

                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              };

              const formattedDateTime = date.toLocaleString("es-ES", options);
              return formattedDateTime;
            };

            return (
              <div class="orderCard">
                <div className="topSectionInfo">
                  <div class="orderStatus">Status: {order?.order_status}</div>
                  <div class="orderDate">{formatDate(order?.date_issued)}</div>
                </div>

                <div class="productList">
                  <div className="subtitle">Products Ordered:</div>

                  {Object.values(order?.orderItem || []).map((product) => {
                    return (
                      <div class="productItem">
                        <div className="itemLabelContainer">
                          <label>P.Name:</label>
                          <div className="itemDetail">{product?.name}</div>
                        </div>

                        <div className="itemLabelContainer">
                          <label>P.Status:</label>
                          <div className="itemDetail">{product?.status}</div>
                        </div>

                        <div className="itemDetails">
                          <div className="itemAmount">
                            P.Qty: {product?.count}
                          </div>

                          <span>x</span>

                          <div className="itemPrice">
                            <div className="item_total">
                              {formatCurrency(
                                product?.item_total,
                                product?.currency
                              )}
                              <div className="currency">{product.currency}</div>
                            </div>
                            {product?.discount > 0 ? (
                              <div className="disocunt">
                                {product?.discount}% OFF
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div class="orderTotalPrice">
                  Total: {formatCurrency(order?.total)} MXN
                </div>
              </div>
            );
          })}
        </div>

        <PaguinationButton
          AllCompanyOrder={AllCompanyOrder}
          current_page={current_page}
          num_pages={num_pages}
          loadPage={loadPage}
        />
      </>
    );
  };

  return (
    <NavigationComponents>
      <div className="orderCompanyProducts">
        <Orders />
      </div>
    </NavigationComponents>
  );
};

export default OrderCompanyProducts;

const PaguinationButton = ({
  AllCompanyOrder,
  current_page,
  num_pages,
  loadPage,
}) => {
  if (AllCompanyOrder?.length === 0 || !current_page) {
    return null;
  }

  return (
    <>
      <div className="paginationBtns">
        <button
          className="paginationBtnBack"
          disabled={current_page === 1}
          onClick={() => loadPage(current_page - 1)}
        >
          Previous
        </button>
        <button className="currentPageTxt">{`Page ${current_page} of ${num_pages}`}</button>
        <button
          className="paginationBtnNext"
          disabled={current_page === num_pages}
          onClick={() => loadPage(current_page + 1)}
        >
          Next
        </button>
      </div>
    </>
  );
};
