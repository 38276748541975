import React from "react";
import "../styles/components/Message.scss";

import { MdOutlineSearchOff } from "react-icons/md";

function Message(props) {
  return (
    <div className={props.error ? "errorMessage" : ""}>{props.children}</div>
  );
}

export default Message;

export const SearchFailedMessage = () => {
  return (
    <div className="wrapperSearchMessage">
      <div className="contentMessage">
        <div className="sectionLeft">
          <MdOutlineSearchOff className="iconSearchOff" />
        </div>
        <div className="sectionRight">
          <h1>There are no posts that match your search</h1>
          <ul>
            <li>Check the spelling of the word.</li>
            <li>Use more generic words or fewer words.</li>
            <li>Browse the categories to find a similar product</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
