import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ProductRelatedAction,
} from "../../actions/productsAction";

import { FaSearch } from "react-icons/fa";
import { PRODUCTS_FILTERS_UPDATE } from "../../constants/productsConstans";

const SearchBar = ({ companyId, categoryId }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const ListRelatedProduct = useSelector((state) => state.ProductRelated);
  const { productRelated } = ListRelatedProduct;
  const suggestedResults = productRelated?.related_products;

  const getSuggestions = (e) => {
    e.preventDefault();

    const { value } = e.target;
    setSearchText(value);

    if (value === "") {
      setShowSuggestions(false);
      dispatch({
        type: PRODUCTS_FILTERS_UPDATE,
        payload: { search: '', page: 1 }
      });
      return;
    }

    setShowSuggestions(true);
    dispatch(ProductRelatedAction(categoryId.id, searchText, companyId));
  };

  const search = (e) => {
    e.preventDefault();
    dispatch({
      type: PRODUCTS_FILTERS_UPDATE,
      payload: { search: searchText, page: 1 }
    });
  };

  return (
    <>
      <div className="searchBarContainer">
        <input
          type="text"
          placeholder="What are you looking for today?"
          className="searchBarInput"
          name="busqueda"
          value={searchText}
          onChange={getSuggestions}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setShowSuggestions(false);
              search(e);
            }
          }}
          autoComplete="off"
          required
        />

        {searchText ? (
          <button
            type="button"
            onClick={() => {
              dispatch({
                type: PRODUCTS_FILTERS_UPDATE,
                payload: { search: '', page: 1 }
              });
              setSearchText("");
              setShowSuggestions(false);
            }}
            className="cancelSearchBtn"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        ) : null}
        <button
          className="searchBtn"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            search(e);
          }}
        >
          <FaSearch />
        </button>
      </div>

      <div className="searchSuggestionsContainer">
        {showSuggestions && (
          <div className="suggestions">
            {Object.values(suggestedResults || {}).map((result) => (
              <div
                className="suggestionItem"
                key={result.id}
                onClick={(e) => {
                  setSearchText(result.name);
                  search(e);
                  setShowSuggestions(false);
                }}
              >
                <div className="iconContainer">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                {result.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBar;
