import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";

import "./Notification.scss";

import { FcOk, FcHighPriority } from "react-icons/fc";

const NotiSuccess = ({ isSuccess, message }) => {
  if (!isSuccess) return null;
  return (
    <div className="modalWrapperNotification">
      <div className="modalContainerNotification">
        <FcOk className="icon" />
        <div className="message">{message}</div>
      </div>
    </div>
  );
};

const NotiError = ({ isError, message }) => {
  if (!isError) return null;

  return (
    <div className="modalWrapperNotification">
      <div className="modalContainerNotification">
        <FcHighPriority className="icon" />
        <div className="message">{message}</div>
      </div>
    </div>
  );
};

export const Notification = ({ result, message }) => {
  const [valueStatus, setvalueStatus] = useState({
    isSuccess: false,
    isError: false,
  });

  const { isSuccess, isError } = valueStatus;

  useEffect(() => {
    setvalueStatus({
      isSuccess: result.isSuccess,
      isError: result.isError,
    });

    const timeOut = setTimeout(() => {
      setvalueStatus({
        isSuccess: false,
        isError: false,
      });
    }, 1200);

    return () => clearTimeout(timeOut);
  }, [result]);

  return ReactDOM.createPortal(
    <>
      <NotiSuccess isSuccess={isSuccess} message={"Success"} />
      <NotiError
        isError={isError}
        message={"Something went wrong, please try again later."}
      />
    </>,
    document.body
  );
};
