import React, { useState, useEffect } from "react";
import "./Disclaimer.scss";

function Disclaimer({ text, link, textLink, textButton }) {
  const [isDisclaimerActive, setIsDisclaimerActive] = useState(true);
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    const hasAcceptedDisclaimer = localStorage.getItem("cookieDisclaimer");
    if (hasAcceptedDisclaimer) {
      setIsDisclaimerActive(false);
    }
    setIsRendered(true);
  }, []);

  const handleCloseDisclaimer = () => {
    localStorage.setItem("cookieDisclaimer", true);
    setIsDisclaimerActive(false);
  };

  return isRendered && isDisclaimerActive ? (
    <div className="fixed fixed--bottom cookie-disclaimer js-cookie-disclaimer is-active">
      <div className="media media--center">
        <div className="media__object media__object--right">
          <button className="button" onClick={handleCloseDisclaimer}>
            {textButton}
          </button>
        </div>

        <div className="media__body">
          <p>
            {text}
            <a
              className="linkDisclaimer"
              href={link}
              target="_blank"
              rel="noreferrer"
            >
              {textLink}
            </a>
          </p>
        </div>
      </div>
    </div>
  ) : null;
}

export default Disclaimer;
