import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const host = process.env.REACT_APP_API_URL_GALEANA_BIZ;

export const galeanaBizApiCompanyService = createApi({
  reducerPath: "galeanaBizApiCompanyService",
  baseQuery: fetchBaseQuery({ baseUrl: `${host}/api` }),

  refetchOnReconnect: true,

  tagTypes: ["Service"],
  endpoints: (builder) => ({
    getServicesCompany: builder.query({
      query: ({ action, parameter, value, status }) => {
        const data = `/companies/services/service?action=${action}&${parameter}=${value}&subscrption_active=${status}`;
        return data;
      },
      providesTags: ["Service"],
    }),

    createServicesCompany: builder.mutation({
      query: ({ ...data }) => {
        const formData = new FormData();
        formData.append("user_id", data.user_id);
        formData.append("company_id", data.company_id);
        formData.append("photo", data.photo);

        formData.append("name", data.name);
        formData.append("hour_price", data.hour_price);
        formData.append("currency", data.currency);
        formData.append("contact_phone", data.contact_phone);
        formData.append("category_id", data.category_id);
        formData.append("description", data.description);
        formData.append("is_available", data.is_available);
        formData.append("subscription_active", data.subscription_active);

        formData.append("zones_ids", data.zones_ids);

        return {
          url: `/companies/services/service/`,
          method: "POST",
          body: formData,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => {
        return { status: response.status, data: response.data };
      },

      invalidatesTags: (result, error) => (error ? [] : ["Service"]),
    }),
    updateServicesCompany: builder.mutation({
      query: ({ ...data }) => {
        const formData = new FormData();
        formData.append("service_id", data.service_id);
        formData.append("user_id", data.user_id);
        formData.append("company_id", data.company_id);

        formData.append("name", data.name);
        formData.append("hour_price", data.hour_price);
        formData.append("currency", data.currency);
        formData.append("contact_phone", data.contact_phone);
        formData.append("category_id", data.category_id);
        formData.append("description", data.description);
        formData.append("is_available", data.is_available);
        formData.append("subscription_active", data.subscription_active);

        formData.append("zones_ids", data.zones_ids);

        if (data.photo !== "") {
          formData.append("photo", data.photo);
        }

        return {
          url: `/companies/services/service/`,
          method: "PUT",
          body: formData,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => {
        return { status: response.status, data: response.data };
      },

      invalidatesTags: (result, error) => (error ? [] : ["Service"]),
    }),
    deletServicesCompany: builder.mutation({
      query: ({ ...data }) => {
        const formData = new FormData();
        formData.append("service_id", data.service_id);
        formData.append("user_id", data.user_id);
        formData.append("company_id", data.company_id);

        return {
          url: `/companies/services/service/`,
          method: "DELETE",
          body: formData,
        };
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => {
        return { status: response.status, data: response.data };
      },

      invalidatesTags: (result, error) => (error ? [] : ["Service"]),
    }),
  }),
});

export const {
  useGetServicesCompanyQuery,
  useCreateServicesCompanyMutation,
  useUpdateServicesCompanyMutation,
  useDeletServicesCompanyMutation,
} = galeanaBizApiCompanyService;
