import React, { useEffect, useState } from "react";
import parsePhoneNumber from "../../../../../helpers/format/parsePhoneNumber";

const PhoneInput = ({ setValue, phone }) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");

  const { countryCode, formattedNumber } = parsePhoneNumber(phone);

  useEffect(() => {
    if (phone) {
      setSelectedCountryCode(countryCode);
      setTelephoneNumber(formattedNumber);
    }
  }, [phone]);

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      selectedCountryCode,
      telephoneNumber,
    }));
  }, [selectedCountryCode, telephoneNumber, setValue]);

  return (
    <div className="inputContainer phoneInputContainer">
      <label>Telephone Number</label>
      <div className="selectAndInputContainer">
        <select
          name="selectedCountryCode"
          onChange={(e) => setSelectedCountryCode(e.target.value)}
          value={selectedCountryCode}
        >
          <option value="" disabled={true}>
            Code
          </option>
          <option value="+1">USA (+1)</option>
          <option value="+52">MX (+52)</option>
        </select>

        <input
          placeholder="phone number"
          type="tel"
          maxLength="10"
          minLength="10"
          pattern="[0-9]{10}"
          value={telephoneNumber}
          onChange={(e) => {
            const inputText = e.target.value;
            // Filtrar cualquier carácter que no sea un dígito numérico
            const numericInput = inputText.replace(/\D/g, "");
            setTelephoneNumber(numericInput);
          }}
          required
        />
      </div>
    </div>
  );
};

export default PhoneInput;
