import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { CONTACT_PUT_RESET } from "../../../../constants/contactConstans";
import {
  UpdateContactActions,
  getContactActions,
} from "../../../../actions/contactActions";

// SCSS
import "../../../../styles/pages/CompanyPage/companybar/ContactCompany/PutContact.scss";

// Components
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";

const PutContact = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = `/mybusiness/customization?label=${encodeURIComponent(
    "contact"
  )}`;

  const { company } = useSelector((state) => state.userDetails);

  const [email, setEmail] = useState(null);
  const [phone_number, setPhoneNumber] = useState(null);
  const [whatsapp_number, setwhatsappNumber] = useState(null);
  const [address, setAdress] = useState(null);

  const PutContact = useSelector((state) => state.PutContact);
  const { success } = PutContact;

  const ContactGet = useSelector((state) => state.GetContact);
  const { companyContact } = ContactGet;

  const contactId = companyContact?.[0]?.id;

  useEffect(() => {
    dispatch(getContactActions(company._id));
  }, [dispatch, company._id]);

  useEffect(() => {
    if (success) {
      dispatch({ type: CONTACT_PUT_RESET });
      navigate(path, { replace: true });
    } else {
      // Si no hay información de contacto en el estado o el ID de la empresa no coincide con el ID actual
      if (
        !companyContact?.[0]?.company_name ||
        companyContact?.[0]?.id !== Number(companyContact?.[0]?.id)
      ) {
        // Dispatch para obtener el contacto de la empresa
        dispatch(getContactActions(company._id));
      } else {
        // Si hay información de contacto en el estado, actualiza el estado local con los datos
        setEmail(companyContact?.[0]?.email || null);
        setPhoneNumber(companyContact?.[0]?.phone_number || null);
        setwhatsappNumber(companyContact?.[0]?.whatsapp_number || null);
        setAdress(companyContact?.[0]?.address || null);
      }
    }
  }, [dispatch, company._id, success, companyContact, navigate]);

  const onSubmit = (e) => {
    e.preventDefault();

    const contact = {
      email: email,
      phone_number: phone_number,
      whatsapp_number: whatsapp_number,
      address: address,
    };

    dispatch(UpdateContactActions(company._id, contactId, contact));
  };

  return (
    <NavigationComponents>
      <form onSubmit={onSubmit} className="putContact">
        <div className="inputContainer">
          <label>
            <div className="inputTitle">Address</div>
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAdress(e.target.value)}
            />
          </label>
        </div>
        <div className="inputContainer">
          <label>
            <div className="inputTitle">WhatsApp Number</div>
            <p>+52 for a MXN number, +1 for a USA number</p>
            <input
              type="phone"
              placeholder="+52 123 456 7890"
              value={whatsapp_number}
              onChange={(e) => setwhatsappNumber(e.target.value)}
            />
          </label>
        </div>
        <div className="inputContainer">
          <label>
            <div className="inputTitle">Email</div>
            <input
              type="email"
              placeholder="example@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
        </div>

        <div className="saveBtnContainer">
          <button type="submit">Save</button>
        </div>
      </form>
    </NavigationComponents>
  );
};

export default PutContact;
