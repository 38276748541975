import React, { useEffect, useState } from "react";
import axios from "axios";

import DeliveryNavigationComponents from "../../../components/navigation/Driver-Navigation/DriverNavigationComponents";

import "../../../styles/pages/Driver/pages/OrderHistory.scss";

import { formatTimeAndDate } from "../../../helpers/formatDateTime";
import { Link } from "react-router-dom";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

function OrderHistory() {
  return (
    <DeliveryNavigationComponents>
      <Orders />
    </DeliveryNavigationComponents>
  );
}

export default OrderHistory;

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [next, setNext] = useState(null);

  const driver = JSON.parse(localStorage.getItem("DriverProfile"));
  const driver_id = driver?.id;

  const request = async () => {
    const data = await axios.get(
      `${ApiGaleanaBiz}/api/companies/driver-profile/order-history/?driver_id=${driver_id}`
    );

    return data;
  };

  useEffect(() => {
    request()
      .then((request) => {
        setOrders(request.data.results);
        setNext(request.data.next);
      })
      .catch((err) => {
        return err;
      });
  }, []);

  const loadMore = async () => {
    if (next) {
      try {
        const { data } = await axios.get(next);

        const response = data.results;
        const nextPage = data.next;

        if (response && response.length > 0) {
          setOrders((prev) => [...prev, ...response]);
          setNext(nextPage);
        }
      } catch (err) {
        return err;
      }
    }
  };

  return (
    <div className="contentOrders">
      <h1 className="title">Order History</h1>
      <div className="sectionCradOrders">
        {orders.map((order, index) => {
          return <CardOrder key={index} order={order} />;
        })}
      </div>
      <div className="contentLoadMore">
        {next && (
          <button className="loadMoreButton" onClick={loadMore}>
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

const CardOrder = ({ order }) => {
  return (
    <Link
      to={`/driver-order/${order?.transaction_id}/`}
      className="wrapperCard"
    >
      <div className="contentCard">
        <div className="infoDate">
          <span className="title">Date: </span>
          <span className="info">{formatTimeAndDate(order?.delivered_at)}</span>
        </div>

        <div className="infoContainer">
          <div className="infoTxt">
            <span className="title">Status: </span>
            <span className="info">{order?.status}</span>
          </div>

          <div className="infoTxt">
            <span className="title">Location: </span>
            <span className="info">{order?.location}</span>
          </div>

          <div className="infoTxt">
            <span className="title">Transaction id: </span>
            <span className="info">{order?.transaction_id}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};
