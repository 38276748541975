import {
  ORDERS_USER_GET_REQUEST,
  ORDERS_USER_GET_SUCCESS,
  ORDERS_USER_GET_FAIL,

  ///COMPANY///
  ORDERS_COMPANY_GET_REQUEST,
  ORDERS_COMPANY_GET_SUCCESS,
  ORDERS_COMPANY_GET_FAIL,
  //
  ORDERS_STATUS_REQUEST,
  ORDERS_STATUS_SUCCESS,
  ORDERS_STATUS_FAIL,
  //
  ORDER_GENERATE_WORDS_REQUEST,
  ORDER_GENERATE_WORDS_SUCCESS,
  ORDER_GENERATE_WORDS_FAIL,
  ORDER_GENERATE_WORDS_RESET,
  //
  ORDER_DECLINED_REQUEST,
  ORDER_DECLINED_SUCCESS,
  ORDER_DECLINED_FAILURE,
  ORDER_DECLINED_RESET,
} from "../constants/ordersConstans";

//DECLINED
export const DeclinedOrderReducers = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DECLINED_REQUEST:
      return { loading: true };

    case ORDER_DECLINED_SUCCESS:
      return { loading: false, success: true };

    case ORDER_DECLINED_FAILURE:
      return { loading: false, error: action.payload };

    case ORDER_DECLINED_RESET:
      return { ...state, success: false };

    default:
      return state;
  }
};

////WordRandom
export const GenerateRandomWord = (state = { RandomWord: {} }, action) => {
  switch (action.type) {
    case ORDER_GENERATE_WORDS_REQUEST:
      return { loading: true };

    case ORDER_GENERATE_WORDS_SUCCESS:
      return { loading: false, RandomWord: action.payload };

    case ORDER_GENERATE_WORDS_FAIL:
      return { loading: false, error: action.payload };

    case ORDER_GENERATE_WORDS_RESET:
      return {};

    default:
      return state;
  }
};

//////////////////////////OrderDrivers///////////////////////////

export const orderStatusReducer = (
  state = {
    loading: true,
    error: null,
    orderStatus: [],
  },
  action
) => {
  switch (action.type) {
    case ORDERS_STATUS_REQUEST:
      return { loading: true, error: null };

    case ORDERS_STATUS_SUCCESS:
      const { orderStatus, current_page, num_pages } = action.payload;
      return {
        loading: false,

        orderStatus: orderStatus,
        current_page: current_page,
        num_pages: num_pages,
      };

    case ORDERS_STATUS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

///////////////////cOMPANY ORDER///////////////////////////////////////////////////////

export const getOrderCompanyReducer = (
  state = {
    AllCompanyOrder: [],
    count: null,
    current_page: null,
    num_pages: null,
  },
  action
) => {
  switch (action.type) {
    case ORDERS_COMPANY_GET_REQUEST:
      return { loading: true };

    case ORDERS_COMPANY_GET_SUCCESS:
      const { count, current_page, num_pages, AllCompanyOrder } =
        action.payload;
      return {
        loading: false,
        count: count,
        current_page: current_page,
        num_pages: num_pages,
        AllCompanyOrder: AllCompanyOrder,
      };

    case ORDERS_COMPANY_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

//////////////////ORDER USER///////////////////////////////////////////////

export const GetOrderReducer = (
  state = { AllUserOrder: [], next: null, previous: null },
  action
) => {
  switch (action.type) {
    case ORDERS_USER_GET_REQUEST:
      return { loading: true };

    case ORDERS_USER_GET_SUCCESS:
      const { next, previous, AllUserOrder } = action.payload;
      return {
        loading: false,
        next: next,
        previous: previous,
        AllUserOrder: AllUserOrder,
      };

    case ORDERS_USER_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
