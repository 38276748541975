import React, { useEffect, useState } from "react";
import AddToAllPages from "../../../components/AddToAllPages";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AddAdressUserAction,
  AdressUserAction,
} from "../../../actions/adress/adressUserActions";

import "../../../styles/pages/adress/usersadress/UserPostAdress.scss";
import { ADRESS_USER_ADD_RESET } from "../../../constants/adressConstans";

const UserPostAdress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addAdressUser = useSelector((state) => state.AdressUserPost);
  const { loading, error, success } = addAdressUser;

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [postal_code, setPostalCode] = useState("31870");
  const [country, setCountry] = useState("Mexico");
  const [state, setState] = useState("Chihuahua");
  const [municipality, setMunicipality] = useState("Galeana");
  const [neighborhood, setNeighborhood] = useState("");
  const [exterior_number, setExteriorNumber] = useState("");
  const [interior_number, setInteriorNumber] = useState("");
  const [street_1, setStreet_1] = useState("");
  const [street_2, setStreet_2] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [telephone_number, setTelephoneNumber] = useState("");
  const [additional_instructions, setAdditionalInstructions] = useState("");

  const combinedPhoneNumber = selectedCountryCode + telephone_number;

  useEffect(() => {
    if (success) {
      dispatch(AdressUserAction());
      dispatch({ type: ADRESS_USER_ADD_RESET });
      navigate("/profile/address", { replace: true });
    }
  }, [success]);

  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(
      AddAdressUserAction(
        first_name,
        last_name,
        postal_code,
        country,
        state,
        municipality,
        neighborhood,
        exterior_number,
        interior_number,
        street_1,
        street_2,
        combinedPhoneNumber,
        additional_instructions
      )
    );
  };
  return (
    <AddToAllPages>
      <form className="userPostAddressPage " onSubmit={onSubmit}>
        <div className="pageTitle">Register Address</div>

        <div className="inputContainer">
          <div className="title">First Name</div>
          <input
            type="text"
            placeholder="First Name"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>

        <div className="inputContainer">
          <div className="title">Last Name</div>
          <input
            type="text"
            placeholder="Last Name"
            value={last_name}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        <div className="inputContainer">
          <div className="title">Postal Code</div>
          <select
            type="text"
            value={postal_code}
            onChange={(e) => setPostalCode(e.target.value)}
          >
            <option value="" disabled>
              Select Postal Code
            </option>
            <option value="31870">31870</option>
          </select>
        </div>

        <div className="inputContainer">
          <div className="title">Location</div>
          <select
            type="text"
            value={neighborhood}
            onChange={(e) => setNeighborhood(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Location
            </option>
            <option value="Galeana">Galeana</option>
            <option value="Lagunitas">Lagunitas</option>
            <option value="Lebaron">Colonia Lebaron</option>
            <option value="Angostura">Angostura</option>
            <option value="San Jeronimo">San Jeronimo</option>
          </select>
        </div>

        <div className="inputContainer">
          <div className="title">
            Exterior Number <span className="optionalTxt"></span>
          </div>
          <input
            type="text"
            placeholder="Exterior Number"
            value={exterior_number}
            onChange={(e) => setExteriorNumber(e.target.value)}
          />
        </div>

        <div className="inputContainer">
          <div className="title">
            Interior Number <span className="optionalTxt"></span>
          </div>
          <input
            type="text"
            placeholder="Interior Number"
            value={interior_number}
            onChange={(e) => setInteriorNumber(e.target.value)}
          />
        </div>

        <div className="inputContainer">
          <div className="title">Street 1</div>
          <input
            type="text"
            placeholder="Street 1"
            value={street_1}
            onChange={(e) => setStreet_1(e.target.value)}
            required
          />
        </div>

        <div className="inputContainer">
          <div className="title">
            Street 2 <span className="optionalTxt"></span>
          </div>
          <input
            type="text"
            placeholder="Street 2"
            value={street_2}
            onChange={(e) => setStreet_2(e.target.value)}
          />
        </div>

        <div className="phoneNumberInputContainer">
          <div className="title">Phone Number</div>
          <div className="inputsContainer">
            <select
              onChange={(e) => setSelectedCountryCode(e.target.value)}
              value={selectedCountryCode}
              required
            >
              <option value="" disabled>
                Code
              </option>
              <option value="+1">+1 (USA)</option>
              <option value="+52">+52 (MX)</option>
            </select>
            <input
              placeholder="phone number"
              type="tel"
              maxLength="10"
              minLength="10"
              pattern="[0-9]{10}"
              value={telephone_number}
              onChange={(e) => {
                const inputText = e.target.value;
                // Filtra cualquier carácter que no sea un dígito numérico
                const numericInput = inputText.replace(/\D/g, "");
                setTelephoneNumber(numericInput);
              }}
              required
            />
          </div>
        </div>

        <div className="inputContainer">
          <div className="title">Reference</div>
          <textarea
            type="text"
            placeholder="Please add a descriptive refrence"
            value={additional_instructions}
            onChange={(e) => setAdditionalInstructions(e.target.value)}
            required
          />
        </div>

        <div className="saveBtnContainer">
          <button type="submit">Save</button>
        </div>
      </form>
    </AddToAllPages>
  );
};

export default UserPostAdress;
