import axios from "axios";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompaniesQuery } from "../../services/galeanaBizApi";
import { COMPANIES_FILTERS_UPDATE } from "../../constants/companyConstants";
import { CompanyCategoryAction } from "../../actions/companyActions";
import CompanyCardSkeleton from "../../components/CompanyAndProductCards/CompanyCardSkeleton";
import ChipBar from "../../helpers/ChipBar";
import { Link } from "react-router-dom";

import Message from "../../components/Message";
import { SearchFailedMessage } from "../../components/Message";

import CompanyCard from "../../components/CompanyAndProductCards/CompanyCard";
import Loader from "../../components/Loader";
import AddToAllPages from "../../components/AddToAllPages";

import "../../styles/pages/HomePage.scss";
import "../../styles/components/CompanyAndProductCards/SponsorCard.scss";

const apiHost = process.env.REACT_APP_API_URL_GALEANA_BIZ;

const AllHomeCompanies = () => {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));

  /*
   * To allow for paginated response with a random order, we send a random
   * list index with each request that indicates which randomized list
   * the backend should use. We use a max of 5 to limit memory usage.
   */
  const companiesRandomOrderRef = useRef(Math.floor(Math.random() * 6));

  const userId = user?.user?.id;

  const [filtered, setFiltered] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [business, setBusiness] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [sponsors, setSponsors] = useState({
    current: 0,
    isNext: false,
  });

  const { search, category } = useSelector((state) => state.comapanyFilters);

  const {
    data,
    error: errorCompanies,
    isLoading: isLoadingCompanies,
    isError: isErrorCompanies,

    isFetching,
  } = useGetCompaniesQuery({
    search,
    category,
    randomOrder: companiesRandomOrderRef.current,
  });

  useEffect(() => {
    return () => {
      setSelectedCategory("");

      setFiltered(false);

      dispatch({
        type: COMPANIES_FILTERS_UPDATE,
        payload: {
          search: "",
          category: 0,
        },
      });
    };
  }, [dispatch]);

  useEffect(() => {
    axios.get(`${apiHost}/api/companies/used-categories/`).then((response) => {
      setCategories(response.data);
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }
    setBusiness(data?.results);
    setNextCursor(data?.next);
  }, [dispatch, data, search, category, isFetching]);

  const loadMoreCompanies = async () => {
    if (nextCursor) {
      setIsLoading(true);
      try {
        const response = await axios.get(nextCursor);

        if (response.data.results && Array.isArray(response.data.results)) {
          setBusiness((prevCompanies) => [
            ...prevCompanies,
            ...response.data.results,
          ]);
          setNextCursor(response.data.next);
          setIsLoading(false);
        }
      } catch (err) {
        dispatch({
          type: COMPANIES_FILTERS_UPDATE,
          payload:
            err.response && err.response.data.detail
              ? err.response.data.detail
              : err.message,
        });
      }
    }
  };

  useEffect(() => {
    dispatch(CompanyCategoryAction());
    setIsLoading(false);
  }, [dispatch]);

  const btnFilter = () => {
    return (
      <ChipBar
        allcategories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setFiltered={setFiltered}
        setIsLoading={setIsLoading}
        setSponsors={setSponsors}
      />
    );
  };

  const numberOfSkeletonCards = 36;
  const companyCardSkeletons = Array.from(
    { length: numberOfSkeletonCards },
    (_, index) => <CompanyCardSkeleton key={index} />
  );

  let sponsoredCompanies = [];
  const sponsorRef = useRef(null);
  if (business && business !== null && business !== undefined && filtered) {
    sponsoredCompanies = business.filter((company) => company.is_sponsor);
  }

  const nextSponsor = () => {
    let newCurrentSponsor = sponsors.current;
    if (newCurrentSponsor === sponsoredCompanies.length - 1) {
      newCurrentSponsor = -1;
    }

    newCurrentSponsor = newCurrentSponsor + 1;

    setSponsors({
      current: newCurrentSponsor,
      isNext: true,
    });
  };

  const previousSponsor = () => {
    let newCurrentSponsor = sponsors.current;
    if (newCurrentSponsor < 1) {
      newCurrentSponsor = sponsoredCompanies.length;
    }

    newCurrentSponsor = newCurrentSponsor - 1;

    setSponsors({
      current: newCurrentSponsor,
      isNext: false,
    });
  };

  return (
    <AddToAllPages>
      <div className="contentHomePages">
        <div className="homePage">
          {isLoadingCompanies || isFetching ? (
            <div className="companyCardsSection">
              <div className="companyCardsContainer">
                <div className="companyCardsWrapper">
                  {companyCardSkeletons}
                </div>
              </div>
            </div>
          ) : isErrorCompanies ? (
            <Message error={isErrorCompanies}>{errorCompanies}</Message>
          ) : (
            <>
              <div className="companiesfilterButton">{btnFilter()}</div>
              {sponsoredCompanies.length > 0 && (
                <div className="sponsorsCarousel">
                  <Link
                    to={`/business/${sponsoredCompanies[sponsors.current]._id}`}
                    ref={sponsorRef}
                    className="sponsorBannerImgContainer"
                  >
                    <img
                      src={sponsoredCompanies[sponsors.current].sponsor_banner}
                      alt={`${
                        sponsoredCompanies[sponsors.current].name
                      } Sponsor banner`}
                    />

                    <div className="sponsorTxt">
                      {sponsoredCompanies[sponsors.current].name} • Sponsor
                    </div>
                  </Link>
                  {sponsoredCompanies.length > 1 && (
                    <>
                      <button className="control prev" onClick={nextSponsor}>
                        <span>
                          <i className="fa-solid fa-angle-left"></i>
                        </span>
                      </button>
                      <button
                        className="control next"
                        onClick={previousSponsor}
                      >
                        <span>
                          <i className="fa-solid fa-angle-right"></i>
                        </span>
                      </button>
                    </>
                  )}
                </div>
              )}
              <h3 className="companyCardsTitle">{selectedCategory}</h3>
              <div className="companyCardsSection">
                <div className="companyCardsContainer">
                  <Companies
                    filtered={filtered}
                    business={business}
                    search={search}
                  />
                </div>
              </div>
            </>
          )}

          {nextCursor && (
            <div className="loadMoreContent">
              {isLoading ? (
                <Loader />
              ) : (
                <button
                  className="loadMoreButton"
                  disabled={isLoading}
                  onClick={loadMoreCompanies}
                >
                  Load More
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </AddToAllPages>
  );
};

export default AllHomeCompanies;

function Companies({ filtered, business, search }) {
  if (search && business.length === 0) {
    return <SearchFailedMessage />;
  }

  if (business && business !== null && business !== undefined && filtered) {
    // Filtra las compañías según la categoría seleccionada si está filtrado
    const filteredCompanies = Object.values(business || {});

    return (
      <div className="companyCardsWrapper">
        {Object.values(filteredCompanies || [])
          .filter((company, index, self) => {
            return index === self.findIndex((c) => c._id === company._id);
          })
          .map((company, index) => (
            <CompanyCard key={index} company={company} />
          ))}
        <div className="sponsorCardContainer">
          <div className="sponsorCard">
            <div className="cardTitleContainer">
              <p className="sponsorCardTitle">
                Feature your business on this page!!
              </p>
            </div>
            <Link to={`/sponsor`} className="sponsorCardButton">
              Tell me more
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (!filtered && business && business !== null && business !== undefined) {
    // Muestra todas las compañías si no hay filtro aplicado

    return (
      <div className="companyCardsWrapper">
        {Object.values(business || {})
          .filter((company, index, self) => {
            return index === self.findIndex((c) => c._id === company._id);
          })
          .map((company, index) => {
            return <CompanyCard key={index} company={company} />;
          })}
      </div>
    );
  }

  return <h1>Error Loading Businesses</h1>;
}
