export default function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return;
  // Check if the phone number starts with '+52' or '+1'
  if (phoneNumber.startsWith("+52")) {
    // Remove the '+' and store the country code
    const countryCode = phoneNumber.slice(0, 3);

    // Remove the country code and spaces from the rest of the number
    const formattedNumber = phoneNumber.slice(3).replace(/\s+/g, "");

    // Insert hyphens between groups of digits
    const formattedWithHyphens =
      countryCode +
      " " +
      formattedNumber.slice(0, 3) +
      "-" +
      formattedNumber.slice(3, 6) +
      "-" +
      formattedNumber.slice(6);

    return formattedWithHyphens;
  } else if (phoneNumber.startsWith("+1")) {
    // Remove the '+' and store the country code
    const countryCode = phoneNumber.slice(0, 2);

    // Remove the country code and spaces from the rest of the number
    const formattedNumber = phoneNumber.slice(2).replace(/\s+/g, "");

    // Insert hyphens between groups of digits
    const formattedWithHyphens =
      countryCode +
      " " +
      formattedNumber.slice(0, 3) +
      "-" +
      formattedNumber.slice(3, 6) +
      "-" +
      formattedNumber.slice(6);

    return formattedWithHyphens;
  }
  return phoneNumber;
}
