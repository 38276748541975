import {
  FAVORITES_GET_BUSINESS_REQUEST,
  FAVORITES_GET_BUSINESS_SUCCESS,
  FAVORITES_GET_BUSINESS_FAILURE,
  //
  FAVORITES_ADD_BUSINESS_REQUEST,
  FAVORITES_ADD_BUSINESS_SUCCESS,
  FAVORITES_ADD_BUSINESS_FAILURE,
  //
  FAVORITES_INCREMENT_PAGE_LIMIT,
  FAVORITES_INCREMENT_SIDE_BAR_LIMIT,
  //
  FAVORITES_REMOVE_BUSINESS_REQUEST,
  FAVORITES_REMOVE_BUSINESS_SUCCESS,
  FAVORITES_REMOVE_BUSINESS_FAILURE,
  FAVORITES_UNLOAD,
} from "../constants/favoritesConstants";

export const favoritesReducer = (state = {
  loading: false,
  success: false,
  entries: [],
  loaded: false,
  sideBarLimit: 1,
  pageLimit: 1,
  next: null,
  previous: null,
  error: ''
}, action) => {
  switch (action.type) {
    case FAVORITES_UNLOAD:
      return {
        ...state,
        loaded: false,
      };

    case FAVORITES_GET_BUSINESS_REQUEST:
      return { ...state, loading: true };

    case FAVORITES_GET_BUSINESS_SUCCESS:
      const { next, previous, entries, sideBarLimit, pageLimit } = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        next: next,
        previous: previous,
        entries: entries,
        sideBarLimit: sideBarLimit,
        pageLimit: pageLimit,
      };

    case FAVORITES_INCREMENT_SIDE_BAR_LIMIT:
      return { ...state, sideBarLimit: action.payload };

    case FAVORITES_INCREMENT_PAGE_LIMIT:
      return { ...state, pageLimit: action.payload };

    case FAVORITES_GET_BUSINESS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FAVORITES_ADD_BUSINESS_REQUEST:
      return { ...state, loading: true };

    case FAVORITES_ADD_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        next: action.payload.next,
        previous: action.payload.previous,
        entries: action.payload.entries,
      };

    case FAVORITES_ADD_BUSINESS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FAVORITES_REMOVE_BUSINESS_REQUEST:
      return { ...state, loading: true };

    case FAVORITES_REMOVE_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        next: action.payload.next,
        previous: action.payload.previous,
        entries: action.payload.entries,
      };

    case FAVORITES_REMOVE_BUSINESS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
