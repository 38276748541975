import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CompanyCategoryAction,
  createCompanyAction,
} from "../actions/companyActions";

import { COMPANY_CREATE_RESET } from "../constants/companyConstants";

// SCSS
import "../styles/pages/CompanyPage/CrateCompany.scss";

// Images
import AddImagePlaceholder from "../images/placeholders/AddImage.png";
import GreyToWhiteGradient from "../images/GreyWhiteGradient.jpg";

// Components
import AddToAllPages from "../components/AddToAllPages";
import StarReviews from "../components/StarReviews";

import { getUserCompany } from "../actions/userAction";
import { imageCompression } from "../helpers/compressor/imageCompression";

const CreateCompany = () => {
  const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { company: userCompany } = useSelector((state) => state.userDetails);
  const userCompanyId = userCompany?._id;

  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [categoryId, setCategoryId] = useState(0);

  const [profileImage, setProfileImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [fakeImage, setFakeImage] = useState(null);
  const [fakeImageBanner, setFakeImageBanner] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState({
    profileImage: false,
    bannerImage: false,
    name: false,
    location: false,
    category: false,
  });

  const [success, setSuccess] = useState({
    profileImage: false,
    bannerImage: false,
  });

  const { company } = useSelector((state) => state.userDetails);

  const { success: succesCreateCompnay } = useSelector(
    (state) => state.companyCreate
  );

  const { categories } = useSelector((state) => state.companyCategories);

  useEffect(() => {
    dispatch(CompanyCategoryAction());
    dispatch(getUserCompany());
  }, [dispatch]);

  useEffect(() => {
    if (company) {
      setFakeImage(company?.profileImage);
      setFakeImageBanner(company?.bannerImage);
    }
  }, [company]);

  useEffect(() => {
    const clearError = () => {
      setError({
        profileImage: false,
        bannerImage: false,
        name: false,
        location: false,
        category: false,
      });
    };

    const timeoutId = setTimeout(clearError, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);

  useEffect(() => {
    if (succesCreateCompnay) {
      dispatch({ type: COMPANY_CREATE_RESET });
      dispatch(getUserCompany());
    }
  }, [dispatch, succesCreateCompnay]);

  useEffect(() => {
    if (success.profileImage === true) {
      navigate("/");
    }
  }, [success.profileImage, navigate]);

  const uploadHandler = async (e) => {
    const file = e.target.files[0];
    const newError = { ...error };

    if (file) {
      try {
        const compressedImageFile = await imageCompression(file);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);

        setFakeImage(compressedImageURL);
        setProfileImage(compressedImageFile);
        newError.profileImage = false;
      } catch (err) {
        newError.profileImage =
          "Error compressing the image Profile. Please try again.";
      }
    }
    setError(newError);
  };

  const uploadImageProfileToApi = async (e) => {
    const formData = new FormData();
    const newError = { ...error };
    const newSuccess = { ...success };

    formData.append("profileImage", profileImage);
    formData.append("company_id", userCompanyId);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${ApiGaleanaBiz}/api/companies/post-image-profile/`,
        formData,
        config
      );

      if (response.status === 200) {
        newSuccess.profileImage = true;
        newError.profileImage = false;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          newError.profileImage =
            "The image you are trying to upload was not found.";
        }
      } else if (error.request) {
        newError.profileImage = "An error occurred while making the request.";
      } else {
        newError.profileImage = "An unexpected error occurred.";
      }
      newSuccess.profileImage = false;
    }

    setError(newError);
    setSuccess(newSuccess);
  };

  const uploadBannerHandler = async (e) => {
    const file = e.target.files[0];
    const newError = { ...error };

    if (file) {
      try {
        const compressedImageFile = await imageCompression(file);
        const compressedImageURL = URL.createObjectURL(compressedImageFile);
        setFakeImageBanner(compressedImageURL);
        setBannerImage(compressedImageFile);
        newError.bannerImage = false;
      } catch (error) {
        newError.bannerImage =
          "Error compressing the image Banner. Please try again.";
      }
    }
    setError(newError);
  };

  const uploadImageBannerToApi = async (e) => {
    const formData = new FormData();
    const newError = { ...error };
    const newSuccess = { ...success };

    formData.append("bannerImage", bannerImage);
    formData.append("company_id", userCompanyId);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${ApiGaleanaBiz}/api/companies/post-image-banner/`,
        formData,
        config
      );

      if (response.status === 200) {
        newSuccess.bannerImage = true;
        newError.bannerImage = false;
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          newError.bannerImage =
            "The image you are trying to upload was not found.";
        }
      } else if (error.request) {
        newError.bannerImage = "An error occurred while making the request.";
      } else {
        newError.bannerImage = "An unexpected error occurred.";
      }
      newSuccess.bannerImage = false;
    }
    setError(newError);
    setSuccess(newSuccess);
  };

  const CancelForm = () => {
    navigate("/", { replace: true });
  };

  const onSubmitImages = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    try {
      const newError = {
        ...error,
      };

      setIsSubmitting(true);

      if (profileImage) {
        if (bannerImage) {
          await uploadImageProfileToApi();
          await uploadImageBannerToApi();
        } else {
          await uploadImageProfileToApi();
        }

        newError.profileImage = false;
      } else {
        newError.profileImage = "Profile image is required";
      }

      setError(newError);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onSubmitBusiness = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    try {
      const newErrors = {
        ...error,
        name: !name && "Name is required",
        location: !location && "Location is required",
        category: !categoryId && "Category is required",
      };

      const hashErrors = Object.values(newErrors).some(
        (error) => typeof error === "string"
      );

      setError(newErrors);

      if (!hashErrors) {
        setIsSubmitting(true);

        dispatch(createCompanyAction(name, location, categoryId));

        setIsSubmitting(false);
      }
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <AddToAllPages>
      <div className="CreatedCompanyPage">
        <div className="dummyCompanyCardAndBannerContainer">
          {succesCreateCompnay || userCompanyId ? (
            <>
              <div className="bannerImageContainer">
                <img
                  src={fakeImageBanner ? fakeImageBanner : GreyToWhiteGradient}
                  alt=""
                  className="bannerImage"
                />
              </div>

              <h3 className="sectionTitle">Card Preview</h3>

              <div className="companyCardContainerWrapper">
                <div className="companyCard_v3_container dummyCompanyCardContainer">
                  <Link to="" className="companyCard_v3">
                    <div className="companyImgContainer">
                      <img
                        src={fakeImage ? fakeImage : GreyToWhiteGradient}
                        alt=""
                        className="companyLogoImg"
                        draggable="false"
                      />
                    </div>
                    <div className="infoSection">
                      <div className="adjustForPadding">
                        <div className="businessName">
                          {name ? name : "Enter Business Name"}
                        </div>
                        <div className="companyLocation">
                          <i className="fa-solid fa-location-dot"></i>{" "}
                          {location ? location : "Enter Location"}
                        </div>

                        <div className="reviewsContainer">
                          <StarReviews value={5} />

                          <div className="reviewsTextContainer">
                            {`123 reviews`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bannerImageContainer">
                <img
                  src={fakeImageBanner ? fakeImageBanner : GreyToWhiteGradient}
                  alt=""
                  className="bannerImage"
                />
              </div>

              <h3 className="sectionTitle">Card Preview</h3>

              <div className="companyCardContainerWrapper">
                <div className="companyCard_v3_container dummyCompanyCardContainer">
                  <Link to="" className="companyCard_v3">
                    <div className="companyImgContainer">
                      <img
                        src={fakeImage ? fakeImage : GreyToWhiteGradient}
                        alt=""
                        className="companyLogoImg"
                        draggable="false"
                      />
                    </div>
                    <div className="infoSection">
                      <div className="adjustForPadding">
                        <div className="businessName">
                          {name ? name : "Enter Business Name"}
                        </div>
                        <div className="companyLocation">
                          <i className="fa-solid fa-location-dot"></i>{" "}
                          {location ? location : "Enter Location"}
                        </div>

                        <div className="reviewsContainer">
                          <StarReviews value={5} />

                          <div className="reviewsTextContainer">
                            {`123 reviews`}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="companyFormContainer">
          {succesCreateCompnay || userCompanyId ? (
            <div className="contentUploadedImage">
              <form onSubmit={onSubmitImages}>
                <div className="imageInputContainer">
                  <div className="inputTitle">
                    <div className="inputTitleText">Image Profile</div>
                  </div>
                  <label
                    className={`customFileUpload ${
                      error.profileImage ? "error" : null
                    }`}
                  >
                    <input
                      type="file"
                      placeholder="Image"
                      id="fileUpload"
                      onChange={uploadHandler}
                    />
                    Click to Add Image
                  </label>
                  {error.profileImage && (
                    <span className="error-message" style={{ color: "red" }}>
                      {error.profileImage}
                    </span>
                  )}
                </div>
                <div className="imageInputContainer">
                  <div className="inputTitle">
                    <div className="inputTitleText">Image Banner</div>
                  </div>
                  <label className="customFileUpload">
                    <input
                      type="file"
                      placeholder="ImageBanner"
                      id="fileUpload"
                      onChange={uploadBannerHandler}
                    />
                    Click to Add Banner
                  </label>
                  {error.bannerImage && (
                    <span className="error-message" style={{ color: "red" }}>
                      {error.bannerImage}
                    </span>
                  )}
                </div>

                <div className="saveAndCancelBtnsContainer">
                  <div className="saveBtnContainer">
                    <button
                      id="saveCompany"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <form className="CreatedCompanyForm" onSubmit={onSubmitBusiness}>
              <div className="inputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Name</div>
                </div>
                <input
                  type="text"
                  className={error.name ? "error" : null}
                  placeholder="Enter business name here"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                {error.name && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.name}
                  </span>
                )}
              </div>
              <div className="inputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Location</div>
                </div>
                <select
                  value={location}
                  className={error.location ? "error" : null}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="" disabled>
                    selected Location
                  </option>
                  <option value="Galeana">Galeana</option>
                  <option value="Lagunitas">Lagunitas</option>
                  <option value="Lebaron">Lebaron</option>
                  <option value="Angostura">Angostura</option>
                  <option value="San Jeronimo">San Jeronimo</option>
                </select>
                {error.location && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.location}
                  </span>
                )}
              </div>

              <div className="inputContainer">
                <div className="inputTitle">
                  <div className="inputTitleText">Select Category</div>
                </div>
                <select
                  value={categoryId}
                  onChange={(e) => setCategoryId(e.target.value)}
                  className={error.category ? "error" : null}
                >
                  <option value={0} disabled>
                    selected Category
                  </option>

                  {categories &&
                    categories !== null &&
                    categories !== undefined &&
                    categories.map((category, index) => {
                      return (
                        <option key={index} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })}
                </select>
                {error.category && (
                  <span className="error-message" style={{ color: "red" }}>
                    {error.category}
                  </span>
                )}
              </div>

              <div className="saveAndCancelBtnsContainer">
                <div className="deleteBtnContainer">
                  <button id="deleteCompany" onClick={CancelForm}>
                    Cancel
                  </button>
                </div>
                <div className="saveBtnContainer">
                  <button
                    id="saveCompany"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </AddToAllPages>
  );
};

export default CreateCompany;
