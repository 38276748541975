import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";

import { Link } from "react-router-dom";

import "../../../styles/components/navigation/MyBusinessNavigation/MobileFooter.scss";

function MobileFooter({ currentPageUrl }) {
  return (
    <>
      <div className="myBusinessMobileFooter">
        <Link to="/mybusiness" className={currentPageUrl("mybusiness")}>
          <i className="fa-solid fa-table-cells-large"></i>

          <div className="linkText">Dashboard</div>
        </Link>

        <Link
          to="/mybusiness/table-products"
          className={currentPageUrl("mybusiness/table-products")}
        >
          <i className="fas fa-shopping-basket"></i>

          <div className="linkText">Products</div>
        </Link>

        <Link
          to={`/mybusiness/services?label=${encodeURIComponent(
            "all_services"
          )}`}
          className={currentPageUrl("mybusiness/services")}
        >
          <i className="fas fa-tools"></i>

          <div className="linkText">Services</div>
        </Link>

        <Link
          to="/mybusiness/orders-company"
          className={currentPageUrl("mybusiness/orders-company")}
        >
          <i className="fa-solid fa-truck-ramp-box"></i>

          <div className="linkText">Orders</div>
        </Link>

        <Link
          to="/mybusiness/setings-hours"
          className={currentPageUrl("mybusiness/setings-hours")}
        >
          <i class="fa-solid fa-clock"></i>

          <div className="linkText">Hours </div>
        </Link>

        <Link
          to="/mybusiness/customization"
          className={currentPageUrl("mybusiness/customization")}
        >
          <i className="fa-solid fa-pencil"></i>

          <div className="linkText">Customization</div>
        </Link>

        {/* <DropDownBtn>
          <DropDownMenu
            currentPage={currentPage}
            localhost={localhost}
            networkServer={networkServer}
            currentPageUrl={currentPageUrl}
          />
        </DropDownBtn> */}
      </div>
      <div className="extraSpacing"></div>
    </>
  );
}

export default MobileFooter;

function DropDownBtn(props) {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <div className="myBusinessMoreBtnContainer">
      {openDropdown ? props.children : null}
      <div className="pageLink" onClick={() => setOpenDropdown(!openDropdown)}>
        <i className="fa-solid fa-pencil"></i>
        <div className="linkText">Customization</div>
      </div>
    </div>
  );
}

function DropDownMenu({ currentPage, localhost, networkServer, livePageUrl }) {
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);

  const animationTime = 300;

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function DropDownItem(props) {
    const currentPageUrl = (pageUrl) => {
      if (currentPage === `${localhost}${pageUrl}`) {
        return "activeMyBusinessDropDownItem";
      } else if (currentPage === `${networkServer}${pageUrl}`) {
        return "activeMyBusinessDropDownItem";
      } else if (currentPage === `${livePageUrl}${pageUrl}`) {
        return "activePageLink";
      } else {
        return "myBusinessDropDownItem";
      }
    };

    return (
      <Link
        to={props.linkDirection}
        className={currentPageUrl(props.addColorUrl)}
        onClick={() => props.goToMenu && setActiveMenu(props.goToMenu)}
      >
        <div className="leftSection">
          {props.leftIcon ? (
            <span className="iconBtn">{props.leftIcon}</span>
          ) : null}

          {props.children}
        </div>

        <span className="iconRight">{props.rightIcon}</span>
      </Link>
    );
  }

  return (
    <div className="myBusinessDropDownContent" style={{ height: menuHeight }}>
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={animationTime}
        classNames="menuPrimary"
        onEnter={calcHeight}
      >
        <div className="menu">
          <DropDownItem
            linkDirection={"/mybusiness/company/about"}
            addColorUrl={"mybusiness/company/about"}
            leftIcon={<i className="fas fa-info-circle"></i>}
          >
            About
          </DropDownItem>
          <DropDownItem
            linkDirection={"/mybusiness/company/contact"}
            addColorUrl={"mybusiness/company/contact"}
            leftIcon={<i className="fas fa-address-book"></i>}
          >
            Contact
          </DropDownItem>{" "}
          <DropDownItem
            linkDirection={"/mybusiness/company/social"}
            addColorUrl={"mybusiness/company/social"}
            leftIcon={<i className="fas fa-share-alt"></i>}
          >
            Social Medias
          </DropDownItem>
          <DropDownItem
            linkDirection={"/mybusiness/adress"}
            addColorUrl={"mybusiness/adress"}
            leftIcon={<i className="fas fa-map-marker-alt"></i>}
          >
            Adress
          </DropDownItem>
        </div>
      </CSSTransition>
    </div>
  );
}
