import { useUserId } from "../../../../hooks/useUserId";
import { useParams } from "react-router-dom";
import { useUpdateCancelledOrderMutation } from "../../../../services/galeanaBizApi";
import { useState } from "react";

const usePutCancelledOrder = () => {
  const userId = useUserId();
  const { transaction_id } = useParams();

  const [isRedirected, setIsRedirected] = useState(false);

  const [cancelledOrder, resultCancelledOrder] =
    useUpdateCancelledOrderMutation();

  const handleCancelledOrder = () => {
    const confirmed = window.confirm("Are you sure to cancel the order?");

    if (confirmed) {
      cancelledOrder({ userId, transactionId: transaction_id }).then(
        (response) => {
          if ("error" in response) {
            return;
          }
          setIsRedirected(true);
        }
      );
    }
  };

  return {
    isRedirected,
    handleCancelledOrder,
    resultCancelledOrder,
  };
};

export default usePutCancelledOrder;
