import axios from "axios";
import {
  FAVORITES_GET_BUSINESS_REQUEST,
  FAVORITES_GET_BUSINESS_SUCCESS,
  FAVORITES_GET_BUSINESS_FAILURE,
  //
  FAVORITES_ADD_BUSINESS_REQUEST,
  FAVORITES_ADD_BUSINESS_SUCCESS,
  FAVORITES_ADD_BUSINESS_FAILURE,
  //
  FAVORITES_INCREMENT_SIDE_BAR_LIMIT,
  FAVORITES_INCREMENT_PAGE_LIMIT,
  //
  FAVORITES_REMOVE_BUSINESS_REQUEST,
  FAVORITES_REMOVE_BUSINESS_SUCCESS,
  FAVORITES_REMOVE_BUSINESS_FAILURE,
  FAVORITES_PAGE_SIZE,
  //
  FAVORITES_UNLOAD,
} from "../constants/favoritesConstants";

const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const unloadFavorites = () => async (dispatch) => {
  dispatch({ type: FAVORITES_UNLOAD });
};

export const incrementSidebarLimit = () => async (dispatch, getState) => {
  const { favorites } = getState()

  dispatch({ type: FAVORITES_INCREMENT_SIDE_BAR_LIMIT, payload: favorites.sideBarLimit + 1 });
};

export const incrementPageLimit = () => async (dispatch, getState) => {
  const { favorites } = getState()

  dispatch({ type: FAVORITES_INCREMENT_PAGE_LIMIT, payload: favorites.pageLimit + 1 });
};

export const getFavorites = (page, updateSideBarLimit = false, updatePageLimit = false) => async (dispatch, getState) => {
  try {
    dispatch({ type: FAVORITES_GET_BUSINESS_REQUEST });

    const {
      userLogin: { userToken },
      userDetails: { user },
      favorites,
    } = getState();

    if (! user) {
      return
    }

    const userId = parseInt(user?.user?.id);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const url = page ? page : `${ApiGaleanaBiz}/api/companies/favorites/${userId}/`
    const { data } = await axios.get(url, config);
    const entries = page ? [...favorites.entries, ...data.results] : data.results

    dispatch({
      type: FAVORITES_GET_BUSINESS_SUCCESS,
      payload: {
        next: data.next,
        previous: data.previous,
        entries: entries,
        sideBarLimit: updateSideBarLimit ? favorites.sideBarLimit + 1 : favorites.sideBarLimit,
        pageLimit: updatePageLimit ? favorites.pageLimit + 1 : favorites.pageLimit,
      },
    });
  } catch (err) {
    dispatch({
      type: FAVORITES_GET_BUSINESS_FAILURE,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const removeFavorite = id => async (dispatch, getState) => {
  try {
    dispatch({ type: FAVORITES_REMOVE_BUSINESS_REQUEST });

    const {
      userLogin: { userToken },
      userDetails: { user },
      favorites,
    } = getState();

    const userId = parseInt(user?.user?.id);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${userToken.token}`,
      },
    };

    const { data } = await axios.delete(
      `${ApiGaleanaBiz}/api/companies/favorites/${userId}/?page_size=${Math.max(favorites.sideBarLimit, favorites.pageLimit) * FAVORITES_PAGE_SIZE}`,
      { data: { id: id } },
      config
    );

    dispatch({
      type: FAVORITES_REMOVE_BUSINESS_SUCCESS,
      payload: {
        next: data.next,
        previous: data.previous,
        entries: data.results,
      }
    });
  } catch (err) {
    dispatch({
      type: FAVORITES_REMOVE_BUSINESS_FAILURE,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const addFavorite = companyId => async (dispatch, getState) => {
    try {
      dispatch({ type: FAVORITES_ADD_BUSINESS_REQUEST });

      const {
        userLogin: { userToken },
        userDetails: { user },
        favorites,
      } = getState();

      const userId = parseInt(user?.user?.id);

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Token ${userToken.token}`,
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/favorites/${userId}/?page_size=${Math.max(favorites.sideBarLimit, favorites.pageLimit) * FAVORITES_PAGE_SIZE}`,
        { company_id: parseInt(companyId) },
        config
      );

      dispatch({
        type: FAVORITES_ADD_BUSINESS_SUCCESS,
        payload: {
          next: data.next,
          previous: data.previous,
          entries: data.results,
        }
    });
    } catch (err) {
      dispatch({
        type: FAVORITES_ADD_BUSINESS_FAILURE,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };
