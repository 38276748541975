import {
  DRIVER_GET_PROFILE_REQUEST,
  DRIVER_GET_PROFILE_SUCCESS,
  DRIVER_GET_PROFILE_FAIL,
} from "../constants/driverProfileConstans";

export const DriverProfileReducer = (state = { Driver: [] }, action) => {
  switch (action.type) {
    case DRIVER_GET_PROFILE_REQUEST:
      return { loading: true };

    case DRIVER_GET_PROFILE_SUCCESS:
      return { loading: false, Driver: action.payload };

    case DRIVER_GET_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
