import React, { useEffect, useState } from "react";

import DefaultImage from "../../images/GreyWhiteGradient.jpg";

const ImageLoader = ({ image, alt, className }) => {
  const [photo, setphoto] = useState(null);

  useEffect(() => {
    const imgElement = new Image();

    imgElement.src = image;

    imgElement.onload = () => {
      setphoto(image);
    };

    imgElement.onerror = () => {
      setphoto(null);
    };
  }, [image]);

  return (
    <img
      src={photo ? photo : DefaultImage}
      loading="lazy"
      decoding="async"
      draggable="false"
      alt={alt}
      className={className}
    />
  );
};

export default ImageLoader;
