import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateDriverRoute = () => {
  const userDetails = useSelector((state) => state.userDetails);

  const userRole = userDetails.user?.user?.profile?.role;
  if ((userRole && userRole === "Galeana Biz driver") || userRole === "Admin") {
    return <Outlet />;
  }

  return <Navigate replace to="/" />;
};

export default PrivateDriverRoute;
