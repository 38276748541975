import {
  PRODUCT_CATEGORY_DETAILS_REQUEST,
  PRODUCT_CATEGORY_DETAILS_SUCCESS,
  PRODUCT_CATEGORY_DETAILS_FAIL,
  CATEGORIES_IN_USE_REQUEST,
  CATEGORIES_IN_USE_SUCCESS,
  CATEGORIES_IN_USE_FAIL,
  //
  PRODUCT_RELATED_REQUEST,
  PRODUCT_RELATED_SUCCCESS,
  PRODUCT_RELATED_FAIL,
  //
  PRODUCTS_FILTERS_UPDATE,

  //
  PRODUCTS_HOME_FILTER_UPDATE,
} from "../constants/productsConstans";

////////// CATEGOIRES PRODUCT//////////////////////////////////////////

export const productCategoriesReducers = (
  state = { categories: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_DETAILS_REQUEST:
      return { ...state, loading: true };

    case PRODUCT_CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case PRODUCT_CATEGORY_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const categoriesInUseReducer = (
  state = { categoriesInUse: [] },
  action
) => {
  switch (action.type) {
    case CATEGORIES_IN_USE_REQUEST:
      return { ...state, loading: true };

    case CATEGORIES_IN_USE_SUCCESS:
      return {
        ...state,
        loading: false,
        categoriesInUse: action.payload,
      };

    case CATEGORIES_IN_USE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const productsFiltersReducer = (
  state = {
    page: 1,
    search: "",
    category: 0,
    priceRange: "",
    order: "desc",
    sortBy: "date_created",
  },
  action
) => {
  switch (action.type) {
    case PRODUCTS_FILTERS_UPDATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export const productsHomeFiltersReducer = (
  state = {
    page: 1,
    search: "",
    category: 0,
  },
  action
) => {
  switch (action.type) {
    case PRODUCTS_HOME_FILTER_UPDATE:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export const listRelatedReducer = (state = { productRelated: [] }, action) => {
  switch (action.type) {
    case PRODUCT_RELATED_REQUEST:
      return { loading: true };
    case PRODUCT_RELATED_SUCCCESS:
      return { loading: false, productRelated: action.payload };
    case PRODUCT_RELATED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
