import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSocialNetworkActions } from "../../../../actions/socialNetworkActions";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

// Images
import TikTokLogo from "../../../../images/TikTok.png";

const GetSocial = ({ companySocialNetwork, SocialLoading, SocialError }) => {
  return (
    <div className="getSocial">
      {SocialLoading && <Loader />}
      {SocialError && <Message>{SocialError}</Message>}

      {Object.values(companySocialNetwork || []).map((social) => {
        return (
          <>
            <div className="titleContainer">
              <h2>Your Social Medias</h2>
              <Link to={`/put-social-company`} className="updateAboutLink">
                Edit Links
              </Link>
            </div>

            <div className="checkIfTheyWorkTxt">
              Click the links below to verify you added the correct social media
              URLs
            </div>

            <div className="allSocialMediasContainer">
              {social?.facebook_url ? (
                <Link
                  target="_blank"
                  to={social.facebook_url}
                  className="socialMediaLink"
                >
                  <i className="fa-brands fa-square-facebook"></i>
                  <div className="urlText">{social.facebook_url}</div>
                </Link>
              ) : null}
              {social?.instagram_url ? (
                <Link
                  target="_blank"
                  to={social.instagram_url}
                  className="socialMediaLink"
                >
                  <i className="fab fa-instagram"></i>

                  <div className="urlText">{social.instagram_url}</div>
                </Link>
              ) : null}
              {social?.tiktok_url ? (
                <Link
                  target="_blank"
                  to={social.tiktok_url}
                  className="socialMediaLink"
                >
                  <img src={TikTokLogo} className="tiktokLogo" />

                  <div className="urlText">{social.tiktok_url}</div>
                </Link>
              ) : null}
              {social?.twiter_url ? (
                <Link
                  target="_blank"
                  to={social.twiter_url}
                  className="socialMediaLink"
                >
                  <i className="fab fa-twitter"></i>

                  <div className="urlText">{social.twiter_url}</div>
                </Link>
              ) : null}
              {social?.youtube_url ? (
                <Link
                  target="_blank"
                  to={social.youtube_url}
                  className="socialMediaLink"
                >
                  <i className="fab fa-youtube"></i>

                  <div className="urlText">{social.youtube_url}</div>
                </Link>
              ) : null}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default GetSocial;
