import React, { useEffect, useState } from "react";
import NavigationComponents from "../../../../components/navigation/MyBusiness-Navigation/NavigationComponents";
import ExtraPagesNavbar, {
  ExtraPagesButton,
} from "../../components/ExtraPagesNavbar";
import AboutBusiness from "./views/AboutBusiness";
import ContactBiusiness from "./views/ContactBiusiness";
import SocialBuisness from "./views/SocialBuisness";
import CompanyAdress from "./views/CompanyAdress";

const Customization = () => {
  const [currentPage, setCurrentPage] = useState("about");

  const Pages = () => {
    if (currentPage === "about") {
      return <AboutBusiness />;
    } else if (currentPage === "contact") {
      return <ContactBiusiness />;
    } else if (currentPage === "social") {
      return <SocialBuisness />;
    } else if (currentPage === "address") {
      return <CompanyAdress />;
    }
  };

  const buttonsPages = [
    { status: "about", label: "About" },
    { status: "contact", label: "Contact" },
    { status: "social", label: "Social" },
    { status: "address", label: "Address" },
  ];

  return (
    <NavigationComponents>
      <ExtraPagesNavbar>
        {buttonsPages.map((btn, index) => (
          <div key={index}>
            <ExtraPagesButton
              btnName={btn.label}
              value={currentPage}
              status={btn.status}
              setValue={setCurrentPage}
            />
          </div>
        ))}
      </ExtraPagesNavbar>
      <Pages />
    </NavigationComponents>
  );
};

export default Customization;
