import React, { useEffect } from "react";
import { Link } from "react-router-dom";

// SCSS
import "../styles/pages/PageNotFound.scss";

// Components
import AddToAllPages from "../components/AddToAllPages";

function PageNotFound() {
  setTimeout(function () {
    const notFoundElement = document.getElementsByClassName(
      "not-found-animation"
    );
    notFoundElement[0].classList.add("hinge-falling");
  }, 2200);

  return (
    <AddToAllPages>
      <div className="pageNotFound">
        <div className="contentContainer">
          <p>sorry, but you fell in an unknown page...</p>

          <div className="hanging404TextContainer">
            <div className="box-not-found">
              <h1 className="not-found-animation">404</h1>
            </div>
          </div>

          <Link to="/" className="goBackToHomeBtn">
            <button>Go back to home</button>
          </Link>
        </div>
      </div>
    </AddToAllPages>
  );
}

export default PageNotFound;
