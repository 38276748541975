///////////contact//////////////////////////////////////////
export const CONTACT_GET_REQUEST = "CONTACT_GET_REQUEST";
export const CONTACT_GET_SUCCESS = "CONTACT_GET_SUCCESS";
export const CONTACT_GET_FAIL = "CONTACT_GET_FAIL";
//
export const CONTACT_ADD_REQUEST = "CONTACT_ADD_REQUEST";
export const CONTACT_ADD_SUCCESS = "CONTACT_ADD_SUCCESS";
export const CONTACT_ADD_FAIL = "CONTACT_ADD_FAIL";
//
export const CONTACT_PUT_REQUEST = "CONTACT_PUT_REQUEST";
export const CONTACT_PUT_SUCCESS = "CONTACT_PUT_SUCCESS";
export const CONTACT_PUT_FAIL = "CONTACT_PUT_FAIL";
export const CONTACT_PUT_RESET = "CONTACT_PUT_RESET";

/////social/////////////////////////////////////////////

export const SOCIAL_GET_REQUEST = "SOCIAL_GET_REQUEST";
export const SOCIAL_GET_SUCCESS = "SOCIAL_GET_SUCCESS";
export const SOCIAL_GET_FAIL = "SOCIAL_GET_FAIL";
//
export const SOCIAL_ADD_REQUEST = "SOCIAL_ADD_REQUEST";
export const SOCIAL_ADD_SUCCESS = "SOCIAL_ADD_SUCCESS";
export const SOCIAL_ADD_FAIL = "SOCIAL_ADD_FAIL";
//
export const SOCIAL_PUT_REQUEST = "SOCIAL_PUT_REQUEST";
export const SOCIAL_PUT_SUCCESS = "SOCIAL_PUT_SUCCESS";
export const SOCIAL_PUT_FAIL = "SOCIAL_PUT_FAIL";
export const SOCIAL_PUT_RESET = "SOCIAL_PUT_RESET";
