import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSocialNetworkActions } from "../../../../../actions/socialNetworkActions";

// SCSS
import "../../../../../styles/pages/CompanyPage/SocialMediaPage/SocialCompany.scss";

// Components
import GetSocial from "../../../../CompanyPage/companybar/SocialCompany/GetSocial";

const SocialBuisness = () => {
  const dispatch = useDispatch();

  const { company } = useSelector((state) => state.userDetails);
  const userCompanyId = company._id;

  const SocialGet = useSelector((state) => state.GetSocial);
  const {
    loading: SocialLoading,
    error: SocialError,
    companySocialNetwork,
  } = SocialGet;

  //funciona la llamda a la api
  useEffect(() => {
    dispatch(getSocialNetworkActions(userCompanyId));
    document.title = `Social Medias - My Business`;
  }, [dispatch, userCompanyId]);
  return (
    <div className="aboutBusiness">
      {companySocialNetwork &&
      Object.keys(companySocialNetwork).length === 0 ? (
        <div className="addBtnContainer">
          <Link to={"/post-social-company"} className="addBtn">
            Add Social Medias
          </Link>
        </div>
      ) : null}

      <div className="socialCompany">
        <GetSocial
          companySocialNetwork={companySocialNetwork}
          SocialLoading={SocialLoading}
          SocialError={SocialError}
        />
      </div>
    </div>
  );
};

export default SocialBuisness;
