import axios from "axios";

import {
  CONTACT_GET_REQUEST,
  CONTACT_GET_SUCCESS,
  CONTACT_GET_FAIL,
  //
  CONTACT_ADD_REQUEST,
  CONTACT_ADD_SUCCESS,
  CONTACT_ADD_FAIL,
  //
  CONTACT_PUT_REQUEST,
  CONTACT_PUT_SUCCESS,
  CONTACT_PUT_FAIL,
} from "../constants/contactConstans";
const ApiGaleanaBiz = process.env.REACT_APP_API_URL_GALEANA_BIZ;
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const getContactActions = (routeParamsCompanyId) => async (dispatch) => {
  try {
    dispatch({ type: CONTACT_GET_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const { data } = await axios.get(
      `${ApiGaleanaBiz}/api/companies/contact/get-contact/${routeParamsCompanyId}/`,
      config
    );

    dispatch({ type: CONTACT_GET_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: CONTACT_GET_FAIL,
      payload:
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message,
    });
  }
};

export const UpdateContactActions =
  (routeParamsCompanyId, contactId, contact) => async (dispatch) => {
    try {
      dispatch({ type: CONTACT_PUT_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.put(
        `${ApiGaleanaBiz}/api/companies/contact/put-contact/`,
        { company_id: routeParamsCompanyId, contact_id: contactId, ...contact },
        config
      );

      dispatch({ type: CONTACT_PUT_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: CONTACT_PUT_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };

export const AddContactActions =
  (CompanyId, email, phone_number, whaths_number, address) =>
  async (dispatch) => {
    try {
      dispatch({ type: CONTACT_ADD_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${ApiGaleanaBiz}/api/companies/contact/post-contact/`,
        {
          company_id: CompanyId,
          email: email,
          phone_number: phone_number,
          whatsapp_number: whaths_number,
          address: address,
        },
        config
      );

      dispatch({ type: CONTACT_ADD_SUCCESS, payload: data });
    } catch (err) {
      dispatch({
        type: CONTACT_ADD_FAIL,
        payload:
          err.response && err.response.data.detail
            ? err.response.data.detail
            : err.message,
      });
    }
  };
