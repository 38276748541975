import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { addFavorite, removeFavorite } from "../../actions/favoritesActions";

// scss
import "../../styles/components/CompanyAndProductCards/CompanyCard.scss";

// Components
import { format, isAfter, isBefore, isWithinInterval, parse } from "date-fns";
import DefaultImg from "../../images/GreyWhiteGradient.jpg";
import StarReviews from "../StarReviews";
import { useGetOpeningHoursQuery } from "../../services/galeanaBizApi";

function CompanyCard({ company, dontShowFavorite }) {
  const [weekDays, setWeekDays] = useState([]);
  const [favoriteActive, setFavoriteActive] = useState(company?.is_favorite);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { data, isFetching } = useGetOpeningHoursQuery({
    companyId: company._id,
  });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setWeekDays(data.days);
  }, [data, isFetching]);

  useEffect(() => {
    setFavoriteActive(company?.is_favorite);
  }, [company?.is_favorite]);

  const isClosedV2 = () => {
    const now = new Date();
    const currentWeekDay = format(now, "EEEE");

    for (const day of weekDays) {
      if (currentWeekDay === day.name) {
        if (day.closed === null || day.closed) {
          return false;
        }

        const openingTime = parse(
          day.schedule?.[0]?.opens,
          "HH:mm",
          new Date()
        );
        const closingTime = parse(
          day.schedule?.[0]?.shuts,
          "HH:mm",
          new Date()
        );

        if (isBefore(now, openingTime) || isAfter(now, closingTime)) {
          return true;
        }

        const breakStartString = day.break?.[0]?.start;
        const breakEndString = day.break?.[0]?.end;

        if (breakStartString && breakEndString) {
          const breakStartTime = parse(breakStartString, "HH:mm", new Date());
          const breakEndTime = parse(breakEndString, "HH:mm", new Date());

          if (
            isWithinInterval(now, { start: breakStartTime, end: breakEndTime })
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  useEffect(() => {
    if (company?.profileImage) {
      const image = new Image();
      image.src = `${company.profileImage}?id=${company._id}`;
      image.onload = () => {
        setImageLoaded(true);
      };
      image.onerror = () => {
        setImageLoaded(true);
      };
    }
  }, [company?.profileImage, company?._id]);

  return (
    <div className="companyCard_v3_container">
      <Link to={`/business/${company?._id}`} className="companyCard_v3">
        <div className="companyImgContainer">
          {!imageLoaded ? (
            <img
              src={DefaultImg}
              className="companyLogoImg"
              alt="Loading"
              draggable="false"
            />
          ) : (
            <img
              src={company?.profileImage || DefaultImg}
              className="companyLogoImg"
              alt="Company Logo"
              draggable="false"
            />
          )}
          {isClosedV2() && (
            <div className="closedTextContainer">
              <div className="closedText">Closed</div>
            </div>
          )}
        </div>
        <div className="infoSection">
          <div className="adjustForPadding">
            <div className="businessName">{company?.name}</div>
            <div className="companyLocation">
              <i className="fa-solid fa-location-dot"></i> {company?.location}
            </div>
            <div className="reviewsContainer">
              <StarReviews value={company?.rating} color={"#d4b05c"} />

              <div className="reviewsTextContainer">
                {`${company?.numReviews} reviews`}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* ==================================== */}
      {/* ============ DISCLAIMER ============ */}
      {/* ==================================== */}
      {/* This is commented so that Manuel can help me add this feature later */}

      {/* {!dontShowFavorite && (
        <>
          {favoriteActive ? (
            <div
              className="heartButtonContainer heartBtnLiked"
              onClick={() => {
                dispatch(removeFavorite(company._id));
                setFavoriteActive(null);
              }}
            >
              <i className="fa-solid fa-heart"></i>
            </div>
          ) : (
            <div
              className="heartButtonContainer heartBtnUnliked"
              onClick={() => {
                dispatch(addFavorite(company._id));
                setFavoriteActive(true);
              }}
            >
              <i className="fa-regular fa-heart"></i>
            </div>
          )}
        </>
      )} */}
    </div>
  );
}

export default CompanyCard;
