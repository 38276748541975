import React from "react";
import { Link } from "react-router-dom";

import Loader from "../../../../components/Loader";
import Message from "../../../../components/Message";

const GetAbout = ({ CompanyAbout, AboutLoading, AboutError }) => {
  return (
    <div className="companyAbout">
      {AboutLoading && <Loader />}
      {AboutError && <Message>{AboutError}</Message>}

      {Object.values(CompanyAbout || []).map((about) => {
        return (
          <>
            <div className="titleContainer">
              <h2>About</h2>
              <Link to={"/put-about-company"} className="updateAboutLink">
                Update About
              </Link>
            </div>

            <div className="companyAboutContainer" key={about.id}>
              <div className="imgContainer">
                <img
                  className="aboutUsImage"
                  src={about?.image}
                  alt="about-us"
                />
              </div>
              <div className="companyAboutInfo">
                <span className="title">{about?.title}</span>
                <p>{about?.description}</p>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default GetAbout;
