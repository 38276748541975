import React, { useEffect, useState } from "react";

// SCSS
import "../styles/helpers/ChipBar.scss";

import { useDispatch } from "react-redux";
import { COMPANIES_FILTERS_UPDATE } from "../constants/companyConstants";

const ChipBar = ({
  allcategories,
  selectedCategory,
  setSelectedCategory,

  setFiltered,
  setIsLoading,
  setSponsors,
}) => {
  const dispatch = useDispatch();

  const [categoryId, setCategoryId] = useState(0);

  const onsubmit = (e) => {
    e.preventDefault();

    dispatch({
      type: COMPANIES_FILTERS_UPDATE,
      payload: {
        category: categoryId,
      },
    });

    setFiltered(!!selectedCategory);

    setIsLoading(false);
    setSponsors({
      current: 0,
      isNext: false,
    });
  };

  return (
    <div className="chipBar">
      <form className="formContentButton" onSubmit={onsubmit}>
        <div className="categoryButtonsContainer">
          <button
            className={
              selectedCategory === "" ? "selectedCategoryBtn" : "categoryBtn"
            }
            onClick={() => {
              setCategoryId(0);
              setSelectedCategory("");
            }}
            value={0}
            type="submit"
          >
            All
          </button>
          {allcategories.map((category, index) => {
            return (
              <button
                key={index}
                onClick={(e) => {
                  setCategoryId(Number(e.target.value));
                  setSelectedCategory(category?.name);
                }}
                type="submit"
                value={category?.id}
                className={
                  selectedCategory === category?.name
                    ? "selectedCategoryBtn"
                    : "categoryBtn"
                }
              >
                {category?.name}
              </button>
            );
          })}
        </div>
      </form>
    </div>
  );
};

export default ChipBar;
