import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBuilding,
  faLock,
  faBriefcase,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

import {
  getFavorites,
  incrementSidebarLimit,
} from "../../../actions/favoritesActions";
import { FAVORITES_PAGE_SIZE } from "../../../constants/favoritesConstants";

import { format, isAfter, isBefore, parse, isWithinInterval } from "date-fns";

// scss
import "../../../styles/components/navigation/FooterAndSidebars/LargeSidebar.scss";
import { useGetOpeningHoursQuery } from "../../../services/galeanaBizApi";

function LargeSidebar({ currentPageUrl }) {
  const dispatch = useDispatch();

  const GetCartTotalItems = useSelector((state) => state.CartGetTotalItems);
  const { CartTotalItems } = GetCartTotalItems;

  const { userDetails, favorites } = useSelector((state) => state);
  const role = userDetails.user?.user?.profile?.role;

  const loadMoreFavorites = () => {
    if (
      favorites.sideBarLimit * FAVORITES_PAGE_SIZE <
      favorites.entries.length
    ) {
      dispatch(incrementSidebarLimit());
      return;
    }

    dispatch(getFavorites(favorites.next, true));
  };

  const UserorAdmin = () => {
    if (role === "Galeana Biz driver") {
      return null;
    }
    return (
      <>
        <Link to="/" className={currentPageUrl("")}>
          <div className="iconContainer">
            <FontAwesomeIcon icon={faHouse} className="linkIcon" />
          </div>
          <div className="linkText">Home</div>
        </Link>

        <Link to="/home-business" className={currentPageUrl("home-business")}>
          <div className="iconContainer">
            <FontAwesomeIcon icon={faBuilding} className="linkIcon" />
          </div>
          <div className="linkText">Business</div>
        </Link>

        <Link to="/favorites" className={currentPageUrl("favorites")}>
          <div className="iconContainer">
            <i className="fa-solid fa-heart"></i>
          </div>
          <div className="linkText">Favorites</div>
        </Link>

        <Link to="/cart" className={currentPageUrl("cart")}>
          <div className="cartIconContainer">
            <i className="fa-solid fa-cart-shopping"></i>
            <span className="cartTotalItems">
              {CartTotalItems &&
                (CartTotalItems?.total_items !== undefined) !== null &&
                CartTotalItems?.total_items !== undefined &&
                CartTotalItems?.total_items !== 0 &&
                `${CartTotalItems?.total_items}`}
            </span>
          </div>
          <div className="linkText">Cart</div>
        </Link>

        <Link to="/myorders" className={currentPageUrl("myorders")}>
          <div className="iconContainer">
            <i className="fas fa-box"></i>
          </div>
          <div className="linkText">My Orders</div>
        </Link>

        <Link to="/mybusiness" className={"pageLink"}>
          <div className="iconContainer">
            <FontAwesomeIcon icon={faBriefcase} className="linkIcon" />
          </div>
          <div className="linkText">My Business</div>
        </Link>
      </>
    );
  };

  const DriverorAdmin = () => {
    if (role !== "Admin" && role !== "Galeana Biz driver") {
      return null;
    }

    return (
      <>
        <Link to="/driver" className={"pageLink"}>
          <div className="iconContainer">
            <FontAwesomeIcon icon={faTruck} className="linkIcon" />
          </div>
          <div className="linkText">Delivery</div>
        </Link>

        <Link to="/admin" className="pageLink">
          <div className="iconContainer">
            <FontAwesomeIcon icon={faLock} className="linkIcon" />
          </div>
          <div className="linkText">Admin</div>
        </Link>
      </>
    );
  };

  return (
    <div className="largeSidebar">
      <div className="topLinksContainer">
        <UserorAdmin />
        <DriverorAdmin />
      </div>
      {favorites.entries.length > 0 ? (
        <>
          {role === "Galeana Biz driver" ? null : (
            <>
              <div className="favoritesSectionContainer">
                <div className="favoritesTitleContainer">
                  <div className="titleText">Favorite Businesses</div>
                </div>

                <div className="favoritesContainer">
                  {favorites.entries
                    .slice(0, favorites.sideBarLimit * FAVORITES_PAGE_SIZE)
                    .map((favorite) => {
                      return (
                        <Link
                          key={favorite.id}
                          to={`/business/${favorite.company._id}`}
                          className={`favorite ${currentPageUrl(
                            `business/${favorite.company._id}`
                          )}`}
                        >
                          <div className="imageContainer">
                            <img
                              src={favorite.company.profileImage}
                              alt="Biz Logo"
                              draggable="false"
                            />

                            <BusinessIsClosed company={favorite.company} />
                          </div>
                          <div className="infoContainer">
                            <div className="companyName">
                              {favorite.company.name}
                            </div>
                            <div className="companyLocation">
                              <i class="fa-solid fa-location-dot"></i>{" "}
                              {favorite.company.location}
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  {(!!favorites.next ||
                    favorites.sideBarLimit * FAVORITES_PAGE_SIZE <
                      favorites.entries.length) && (
                    <button
                      disabled={favorites.loading}
                      className="seeMore"
                      onClick={loadMoreFavorites}
                    >
                      <i className="fa-solid fa-chevron-down"></i>
                      <div className="seeMoreTxt">See more</div>
                    </button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
      <div className="extraInfoContainer">
        <div>
          For more information please contact:
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=18018353995"
              target="_blank"
              rel="noreferrer"
            >
              A.D. Lebaron
            </a>
          </div>
          <div>or</div>
          <a
            href="https://api.whatsapp.com/send?phone=526361357593"
            target="_blank"
            rel="noreferrer"
          >
            Martin Arreola jr.
          </a>
        </div>
      </div>
    </div>
  );
}

export default LargeSidebar;

const BusinessIsClosed = ({ company }) => {
  const [weekDays, setWeekDays] = useState([]);

  const { data, isFetching } = useGetOpeningHoursQuery({
    companyId: company._id,
  });

  useEffect(() => {
    if (!data || isFetching) {
      return;
    }

    setWeekDays(data.days);
  }, [data, isFetching]);

  const businessIsClosed = () => {
    const now = new Date();
    const currentWeekDay = format(now, "EEEE");

    for (const day of weekDays) {
      if (currentWeekDay === day.name) {
        if (day.closed === null || day.closed) {
          return false;
        }

        const openingTime = parse(
          day.schedule?.[0]?.opens,
          "HH:mm",
          new Date()
        );
        const closingTime = parse(
          day.schedule?.[0]?.shuts,
          "HH:mm",
          new Date()
        );

        if (isBefore(now, openingTime) || isAfter(now, closingTime)) {
          return true;
        }

        const breakStartString = day.break?.[0]?.start;
        const breakEndString = day.break?.[0]?.end;

        if (breakStartString && breakEndString) {
          const breakStartTime = parse(breakStartString, "HH:mm", new Date());
          const breakEndTime = parse(breakEndString, "HH:mm", new Date());

          if (
            isWithinInterval(now, { start: breakStartTime, end: breakEndTime })
          ) {
            return true;
          }
        }
      }
    }

    return false;
  };

  return (
    <>
      {businessIsClosed() && (
        <div className="closedTextContainer">
          <div className="closedText">Closed</div>
        </div>
      )}
    </>
  );
};
