import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getUserDetails, logout } from "../../actions/userAction";

import Message from "../../components/Message";

import AddToAllPages from "../../components/AddToAllPages";
import NoAccountBanner from "../../components/authentication/NoAccountBanner";

// Images
import DefaultProfilePicture from "../../images/default-profile-icon.jpg";

// SCSS
import "../../styles/pages/profilePage/ProfilePage.scss";
import {
  useGetSettingUserQuery,
  useUpdateSettingUserMutation,
  useUpdateSettingsDriverMutation,
} from "../../services/galeanaBizApi";
import { DriverProfileActions } from "../../actions/driver-profileActions";

const lebaronGaleanaHost = process.env.REACT_APP_LEBARON_GALEANA_HOST;

const ProfilePage = () => {
  const dispatch = useDispatch();

  const [logoutModalActive, setLogoutModalActive] = useState(false);
  const [profileImageModalActive, setProfileImageModalActive] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userToken } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;
  const user_id = user.user.id;

  const isDriver = user.user.profile.role === "Galeana Biz driver";

  const { data, isFetching } = useGetSettingUserQuery(user_id);
  const [updateSettingUser] = useUpdateSettingUserMutation();

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const exitprofile = () => {
    dispatch(logout());
  };

  const toggleLanguage = (user_id, value) => {
    updateSettingUser({ user_id, language_value: value });
  };

  const Toggle = () => {
    if (data?.settingUser?.language_value === "en") {
      return (
        <button
          className="btnLenguage"
          onClick={() => toggleLanguage(user_id, "es")}
        >
          <div className="iconContainer">
            <i className="fa-solid fa-language"></i>
          </div>
          <div className="language">EN</div>
        </button>
      );
    }

    return (
      <button
        className="btnLenguage"
        onClick={() => toggleLanguage(user_id, "en")}
      >
        <div className="iconContainer">
          <i className="fa-solid fa-language"></i>
        </div>
        <div className="language">ES</div>
      </button>
    );
  };

  return (
    <AddToAllPages>
      {userToken ? (
        <>
          {loading || isFetching ? (
            <SkeletonProfile />
          ) : error ? (
            <Message>{error}</Message>
          ) : (
            <div className="profilePage">
              {profileImageModalActive ? (
                <div className="profilePictureModal">
                  <div
                    className="background"
                    onClick={() => setProfileImageModalActive(false)}
                  ></div>
                  <img
                    src={user.user.profile.image}
                    alt=""
                    className="profilePicture"
                    draggable="false"
                  />
                </div>
              ) : null}
              <div className="profileSection">
                <div className="leftSide">
                  <div className="profilePictureContainer">
                    {user?.user?.profile?.image ? (
                      <img
                        src={user.user.profile.image}
                        alt=""
                        className="profilePicture"
                        draggable="false"
                        onClick={() => setProfileImageModalActive(true)}
                      />
                    ) : (
                      <img
                        src={DefaultProfilePicture}
                        alt=""
                        className="profilePicture"
                        draggable="false"
                      />
                    )}
                    <div className="usernameText">{user?.user?.username}</div>
                    <a
                      href={`${lebaronGaleanaHost}/dashboard/profile`}
                      target="_blank"
                      rel="noreferrer"
                      className="updateProfileLink"
                    >
                      Edit Profile
                    </a>
                  </div>
                </div>

                <div className="rightSide">
                  <div className="profileTitleContainer">
                    <div className="titleText">Profile</div>
                  </div>

                  <div className="infoSection">
                    <ConfigDriver
                      isDriver={isDriver}
                      userId={user_id}
                      dispatch={dispatch}
                    />

                    <div className="infoContainer">
                      <div className="infoTitleText">Subscription:</div>
                      <div className="dataText">
                        {user?.user?.profile?.subscriptionActive
                          ? " Active"
                          : " Expired"}
                      </div>
                    </div>
                    <div className="infoContainer">
                      <div className="infoTitleText">Username:</div>
                      <div className="dataText">{user?.user?.username}</div>
                    </div>
                    <div className="infoContainer">
                      <div className="infoTitleText">First Name:</div>
                      <div className="dataText">{user?.user?.first_name}</div>
                    </div>
                    <div className="infoContainer">
                      <div className="infoTitleText">Last Name:</div>
                      <div className="dataText">{user?.user?.last_name}</div>
                    </div>
                    <div className="infoContainer">
                      <div className="infoTitleText">Email:</div>
                      <div className="dataText">{user?.user?.email}</div>
                    </div>
                    <div className="infoContainer">
                      <div className="infoTitleText">Address:</div>
                      <Link className="addressBtn" to={"/profile/address"}>
                        See Address
                      </Link>
                    </div>
                    <div className="infoContainer">
                      <div className="infoTitleText">Message Language:</div>
                      <Toggle />
                    </div>
                  </div>
                </div>
              </div>
              {logoutModalActive ? (
                <>
                  <div className="logoutModal">
                    <div
                      className="background"
                      onClick={() => setLogoutModalActive(false)}
                    ></div>

                    <div className="modalContent">
                      <div className="textContainer">
                        <div className="titleText">Are you sure?</div>
                        <div className="questionText">
                          Are you sure you want to logout of GaleanaBiz?
                        </div>
                      </div>

                      <div className="buttonsContainer">
                        <button
                          className="cancelBtn"
                          onClick={() => setLogoutModalActive(false)}
                        >
                          Cancel
                        </button>
                        <button className="logoutBtn" onClick={exitprofile}>
                          Logout
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="logoutBtnContainer">
                <button
                  className="logoutBtn"
                  onClick={() => setLogoutModalActive(true)}
                >
                  Logout
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <NoAccountBanner />
      )}
    </AddToAllPages>
  );
};

export default ProfilePage;

const ConfigDriver = ({ isDriver, userId, dispatch }) => {
  const [value, setValue] = useState({
    availability_status: "",
  });

  const [driver, setDriver] = useState([]);

  const { Driver, loading } = useSelector((state) => state.Driverprofile);

  const [updateSettingDriver] = useUpdateSettingsDriverMutation();

  useEffect(() => {
    if (!driver || loading) {
      return;
    }

    setDriver(Driver);
  }, [Driver]);

  useEffect(() => {
    setValue({
      availability_status: value.availability_status,
    });
  }, []);

  const toggleStatus = (availability_status) => {
    updateSettingDriver({
      driverId: driver.id,
      userId,
      availabilityStatus: availability_status,
    }).then(() => {
      dispatch(DriverProfileActions());
    });
  };

  const isStatus = driver?.settings?.availability_status;

  const ToggleStatusDriver = () => {
    if (isStatus === "available") {
      return (
        <button
          className="btnLenguage"
          onClick={() => toggleStatus("unavailable")}
          style={{ color: "green" }}
        >
          <div className="language">ON Servece</div>
        </button>
      );
    }

    return (
      <button
        className="btnLenguage"
        onClick={() => toggleStatus("available")}
        style={{ color: "red" }}
      >
        <div className="language">OFF Servece</div>
      </button>
    );
  };

  if (!isDriver) return null;

  return (
    <div className="infoContainer">
      <div className="infoTitleText">Status Driver:</div>
      <ToggleStatusDriver />
    </div>
  );
};

const SkeletonProfile = () => {
  return (
    <div className="skeletonProfilePage">
      <div className="profileSection">
        <div className="leftSide">
          <div className="profilePictureContainer">
            <div className="profilePicture"></div>
            <div className="usernameText">
              <div className="skeletonText"></div>
            </div>
            <div className="updateProfileLink">
              <div className="skeletonText"></div>
            </div>
          </div>
        </div>

        <div className="rightSide">
          <div className="titleText">
            <div className="skeletonText"></div>
          </div>
          <div className="infoSection">
            <div className="infoContainer">
              <div className="infoTitleText">
                <div className="skeletonText"></div>
              </div>
              <div className="dataText">
                <div className="skeletonText"></div>
              </div>
            </div>
            <div className="infoContainer">
              <div className="infoTitleText">
                <div className="skeletonText"></div>
              </div>
              <div className="dataText">
                <div className="skeletonText"></div>
              </div>
            </div>
            <div className="infoContainer">
              <div className="infoTitleText">
                <div className="skeletonText"></div>
              </div>
              <div className="dataText">
                <div className="skeletonText"></div>
              </div>
            </div>
            <div className="infoContainer">
              <div className="infoTitleText">
                <div className="skeletonText"></div>
              </div>
              <div className="dataText">
                <div className="skeletonText"></div>
              </div>
            </div>
            <div className="infoContainer">
              <div className="infoTitleText">
                <div className="skeletonText"></div>
              </div>
              <div className="dataText">
                <div className="skeletonText"></div>
              </div>
            </div>
            <div className="infoContainer">
              <div className="infoTitleText">
                <div className="skeletonText"></div>
              </div>
              <div className="dataText">
                <div className="skeletonText"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
